<template>
    <v-card v-if="!removed" class="card rounded-xl" min-width="90%">
        <v-card-title> 
            <v-row class="right-row-edit"> 
                <p style="font-weight: 550;" v-if="this.course === 'W4ALF-English-2022'">Job Readiness</p>
                <p style="font-weight: 550;" v-else>{{this.course}}</p>
            </v-row>
        </v-card-title>
        <v-card-subtitle> 
            <v-row class="right-row-edit"> 
                <p>{{this.classDates()}}</p>
            </v-row>
        </v-card-subtitle>
        <v-divider></v-divider>
            <v-card-text style="font-size: 0.89rem;font-weight: 500;">
                <v-row class="right-row-edit justify-center ma-0">
                    <v-btn
                        class="this-share-btn"
                        rounded
                        text
                        small
                        @click="viewEnrollments()"
                        v-if="this.$store.state.isAuthenticated"
                    >
                        Enrollments
                        <v-icon small>mdi-school-outline</v-icon>
                    </v-btn>
                </v-row>
                <v-row class="right-row-edit justify-center ma-0 mt-2">
                    <v-btn  
                        class="this-share-btn" 
                        rounded
                        text
                        small
                        @click="datesDialog=true"
                        >
                        Edit dates 
                        <v-icon small>mdi-calendar</v-icon>
                    </v-btn>
                </v-row>
                <v-row class="right-row-edit justify ma-0 mt-2">
                    <v-btn
                        class="this-share-btn"
                        rounded
                        text
                        small
                        @click="deleteDialog=true"
                        v-if="this.$store.state.isAuthenticated"
                    >
                        Remove
                        <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                </v-row>
            </v-card-text>
            <v-dialog
                v-model="datesDialog"
                max-width="600px"
                class="justify-center"
                style="justify-content: center !important"
            >
                <v-card class="rounded-lg" style="align-content: center; justify-content: center">
                    <v-card-title class="justify-center">
                        <v-row class="right-row-edit justify-center pt-5"> 
                            <p style="font-weight: 550;" v-if="this.course === 'W4ALF-English-2022'">Job Readiness</p>
                            <p style="font-weight: 550;" v-else>{{this.course}}</p>
                        </v-row>
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                            <p> Select new class dates. </p>
                    </v-card-subtitle>
                    <v-divider class="mb-10 ml-5 mr-5 mt-0"></v-divider>
                    <v-card-text class="pa-0 text-center" style="justify-content: center !important">
                        <div class="dateRow" elevation-0 style="width: 100%" v-if="this.newDates.length <= 1">
                            <v-date-picker
                                width="90%"
                                v-model="newDates"
                                range
                                no-title
                                color="#6A2E85"
                                elevation="0"
                                class="classDates mt-4"
                                :min=newDates[0]
                            ></v-date-picker>
                        </div>
                        <div class="dateRow" style="width: 100%" v-else>
                            <v-date-picker
                                v-model="newDates"
                                range
                                no-title
                                color="#6A2E85"
                                elevation="0"
                                width="90%"
                                class="classDates mt-4"
                            ></v-date-picker>
                        </div>
                        <v-btn class="this-share-btn" color="#3c7872" rounded @click="datesDialog=false" text small>Cancel</v-btn>
                        <v-btn class="this-share-btn" color="#3c7872" elevation-19 rounded small text @click="updateClass()"><p class="ma-1">Submit</p> <v-icon class="ml-2" left>mdi-check</v-icon></v-btn>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-5">
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="deleteDialog"
                max-width="600px"
                class="justify-center"
                style="justify-content: center !important"
            >
                <v-card class="rounded-lg" style="align-content: center; justify-content: center">
                    <v-card-title class="justify-center">
                        <v-row class="right-row-edit justify-center pt-5"> 
                            <p style="font-weight: 550;" v-if="this.course === 'W4ALF-English-2022'">Job Readiness</p>
                            <p style="font-weight: 550;" v-else>{{this.course}}</p>
                        </v-row>
                    </v-card-title>
                    <v-card-subtitle class="text-center">    
                        <p> Are you sure you want to delete this class? </p>
                    </v-card-subtitle>
                    <v-divider class="mb-10 ml-5 mr-5 mt-0"></v-divider>
                    <v-card-text class="pa-0 text-center" style="justify-content: center !important">
                        <v-btn class="this-share-btn" color="#3c7872" rounded @click="deleteDialog=false" text small>No<v-icon class="ml-2" left>mdi-close</v-icon></v-btn>
                        <v-btn class="this-share-btn" color="#3c7872" elevation-2 rounded small text @click="deleteClass()"><p class="ma-1">Yes</p> <v-icon class="ml-2" left>mdi-check</v-icon></v-btn>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-5">
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="showDeleteError"
                max-width="600px"
                class="justify-center"
                style="justify-content: center !important"
            >
                <v-card>
                    <v-card-title class="justify-center">
                        <v-row class="right-row-edit justify-center pt-5"> 
                            <p style="font-weight: 550;">Error</p>
                        </v-row>
                    </v-card-title>
                    <v-divider class="mb-10 ml-5 mr-5 mt-0"></v-divider>
                    <v-card-subtitle class="text-center">    
                        <p> There are already students enrolled in this class. This means the class may not be deleted. Try editing the class dates instead. </p>
                    </v-card-subtitle>
                    <v-card-text class="pa-0 text-center" style="justify-content: center !important">
                        <v-btn class="this-share-btn" color="#3c7872" rounded @click="showDeleteError=false" text small>Close<v-icon class="ml-2" left>mdi-close</v-icon></v-btn>
                        <v-btn class="this-share-btn" color="#3c7872" elevation-2 rounded small text @click="editDates()"><p class="ma-1">Edit dates</p> <v-icon class="ml-2" left>mdi-calendar</v-icon></v-btn>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-5">
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-card>
    
</template>

<script>
import { mapGetters } from "vuex"
import {UPDATE_CLASS, DELETE_CLASS} from '@/apollo/mutations';

export default {
    name: 'ClassCard',
    props: {
        name: {type: String},
        startDate: {type: String},
        endDate: {type: String},
        course: {type: String},
        dates: {type: String},
        numEnrollments: {type: Number},
        fullClassName: {type: String}
    },
    data: () => ({
        showLanguageModal: false,
        url: "",
        datesDialog: false,
        deleteDialog: false,
        newDates: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(Date.now() + 600000000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
        localStartDate: "",
        localEndDate: "",
        removed: false,
        levels: [
            {value: 0, key: 'Not proficient'},
            {value: 1, key: 'Beginner'},
            {value: 2, key: 'Elementary Proficiency'},
            {value: 3, key: 'Limited working proficiency'},
            {value: 4, key: 'Highly proficient'},
            {value: 5, key: 'Native/full pro ficiency'}
        ],
        showDeleteError: false
    }),
    computed: {
      ...mapGetters('user', ['activeUser']),
        setUrl () {
            return "https://ep.w4al.services/classenrolment/" + this.activeUser.givenName + this.activeUser.familyName + "|" + this.startDate
        },
        dateRangeText () {
            return this.newDates.join(' - ')
        }
    },
    mounted() {
        this.localStartDate = this.startDate
        this.localEndDate = this.endDate
    },
    methods: {
        viewEnrollments() {
            // console.log("Pushing this class: ", this.fullClassName + " with dates: ", this.startDate)
            this.$store.commit("setCurrentClassStartDate", this.localStartDate)
            this.$store.commit("setCurrentClassCourseName", this.course)
            this.$store.commit("setCurrentClassName", this.fullClassName)
            this.$store.commit("setCurrentClassEndDate", this.localEndDate)
            this.$router.push({name: 'ViewClassEnrollments', params: {id: this.fullClassName, classId: this.fullClassName, course: this.course, startDate: this.localStartDate, endDate: this.localEndDate}})
        },
        toggleLanguageModal () {
            this.showLanguageModal = !this.showLanguageModal
        },
        classDates() {
            var months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            let [year, month, day] = this.localStartDate.split('-');
            let sd = new Date(+year, +month-1, +day); 
            [year, month, day] = this.localEndDate.split('-');
            let ed = new Date(+year, +month-1, +day); 
            return sd.getDate() + " " + months[sd.getMonth()] + " " + sd.getFullYear() + " - " + ed.getDate() + " " + months[ed.getMonth()] + " " + ed.getFullYear()
        },
      async updateClass() {
        this.localStartDate = this.newDates[0]
        this.localEndDate = this.newDates[1]
        await this.$apollo.mutate({
          mutation: UPDATE_CLASS,
          variables: {
            name: this.fullClassName,
            class: {
              name: this.fullClassName,
              course: this.course,
              startDate: this.localStartDate,
              endDate: this.localEndDate,
              facilitator: "NA",
              classFormat: -1,
              hub: "NA",
            }
          }
        }).then(
            this.datesDialog = false
        ).catch(
            this.datesDialog = false
        )

      },
      async deleteClass() {
        try {
          await this.$apollo.mutate({
            mutation: DELETE_CLASS,
            variables: {
              name: this.fullClassName,
              course: this.course,
            }
          })
          this.deleteDialog = false,
              this.removed = true
        } catch (e) {
          this.deleteDialog = false
          this.showDeleteError = true
        }
      },
      editDates() {
            this.datesDialog=true 
            this.showDeleteError=false
        }
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.home {
    margin: 0 auto;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
}
.this-right {
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  display: flex;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: center;
    margin-top: 2px;
}
.right-row-edit-right {
    width: 100%;
    justify-content:right;
    margin-top: 2px;
}
.right-row-icon {
    width: 100%;
    justify-content: right;
    margin-bottom: 20px;
}
.this-share-btn {
    color: #6A2E85;
    text-transform: none;
    text-decoration: none !important;
    display: inline-block;
}
.classDates {
    justify-content: center;
    width: 20;
}
.dateRow {
    justify-content: center;
    align-content: center;
    margin-top: -20px;
}
.text-green {
    color: #3c7872;
}
</style>
