<template>
  <v-container class="w-100">
<!--    <v-row style="min-height: 28vh;">-->
        <v-row class="flex-grow-1 mt-6" style="min-height: 22vh; max-height: 100%; flex-wrap: nowrap;overflow-x:scroll; white-space: nowrap; overflow-y:hidden; margin: 10px;justify-content: center">
          <InfoCard :numberEducation="totalFurtherEducation" :numberSalvations="totalSalvations" :numberJobs="totalEmployed" :numberStudents="totalStudents"></InfoCard>
        </v-row>
<!--    </v-row>-->
    <v-row style="min-height: 16vh; justify-content: center;">
      <v-col cols="12" style="justify-content: center">
        <v-row class=" mt-0 mb-0 flex-grow-1" style="min-height: 100%; flex-wrap: nowrap;overflow-x:scroll; white-space: nowrap; overflow-y:hidden; margin: 10px;justify-content: center; align-content: center" align="center">

          <v-btn x-large rounded elevation="8" class="ma-6;" @click="createDialog=true">
            <v-icon>
              mdi-plus
            </v-icon>
            New Group
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row style="min-height: 46vh; max-height: 46vh;overflow-y: scroll; padding-bottom: 12px">
      <v-col cols="12" style="justify-content: center">
        <v-row class="flex-grow-1" style="min-height: 10%; flex-wrap: nowrap;overflow-x:scroll; white-space: nowrap; overflow-y:hidden; margin: 10px;justify-content: center">
          <v-card-title>
            My Groups
          </v-card-title>

        </v-row>

        <v-row class="flex-grow-1" style="overflow-y: scroll;min-height: 90%; flex-wrap: nowrap;overflow-x:scroll; white-space: nowrap; overflow-y:hidden; margin: 10px;justify-content: center">
          <v-list width="100%">
            <v-list-item v-for="cl in classes" :key="cl.name">

              <v-list-item-content>
                <v-list-item-title>
                  {{cl.course}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ cl.startDate }} - {{cl.endDate}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>


                <v-btn text  @click="editClassAction(cl)" v-if="cl.savedCount <= 0 && cl.employedCount <= 0 && cl.furtherEducationCount <= 0" style="text-transform: none">
                  Review
                  <v-icon color="#6A2E85" class="ml-2">mdi-pencil</v-icon>
                </v-btn>
                <v-btn text  @click="editClassAction(cl)" v-else style="text-transform: none">
                  Reviewed
                  <v-icon class="ml-2" color="green">mdi-check</v-icon>
                </v-btn>

              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-row>
      </v-col>
    </v-row>
    <create-group-dialog v-model="createDialog"></create-group-dialog>
    <review-group-dialog v-model="reviewDialog" :selectedClass="selectedClass" :selectedActualClass="selectedActualClass"></review-group-dialog>
  </v-container>
</template>

<script>
import InfoCard from "@/views/Activators/InfoCard.vue";
import CreateGroupDialog from "@/views/Activators/CreateGroupDialog.vue";
import ReviewGroupDialog from "@/views/Activators/ReviewGroupDialog.vue";
import {LIST_CLASSES, LIST_COURSES} from "@/apollo/queries";
import {apolloClient} from "@/main";
import {mapGetters} from "vuex";
export default {
  name: "HomeActivator",
  components: {CreateGroupDialog, InfoCard, ReviewGroupDialog},
  computed: {
    ...mapGetters('user', ['activeUser']),
  },
  data() {
    return {
      totalSalvations: 0,
      totalEmployed: 0,
      totalFurtherEducation: 0,
      totalStudents: 0,
      createDialog: false,
      reviewDialog: false,
      classes: [],
      // The list of actual EP classes, used for editing classes
      actualClasses: [],
      courses: [],
      selectedClass: null,
      selectedActualClass: null,
    };
  },
  async mounted() {
    await this.listCourses()
    await this.listClasses()
  },
  watch: {
    async reviewDialog() {
      this.classes = []
      await this.listClasses()
    },
    async createDialog() {
      this.classes = []
      await this.listClasses()
    },
  },
  methods: {
    async listClasses() {
      this.totalSalvations = 0
      this.totalEmployed = 0
      this.totalFurtherEducation = 0
      this.totalStudents = 0
      await apolloClient.query({
        query: LIST_CLASSES,
        variables: {
          filter: this.activeUser.phoneNumber.replace(/ /g,'')
        }
      }).then(response => {
        let classes = response.data.listClasses
        classes.reverse()
        this.actualClasses = classes
        for (let i = 0; i < classes.length; i++) {
          let course = this.courses.filter(c => {
            return c.name === classes[i].course}
          )
          let courseDisplay = course[0].displayName
          let startDate = this.extractDate(classes[i].startDate)
          let endDate = this.extractDate(classes[i].endDate)
          this.classes.push({name: classes[i].name, course: courseDisplay, startDate: startDate, endDate: endDate, savedCount: classes[i].savedCount, employedCount: classes[i].employedCount, furtherEducationCount: classes[i].furtherEducationCount, enrollmentsCount: classes[i].enrollmentsCount})
          // gather totals
          this.totalSalvations += classes[i].savedCount
          this.totalEmployed += classes[i].employedCount
          this.totalFurtherEducation += classes[i].furtherEducationCount
          this.totalStudents += classes[i].enrollmentsCount
        }
      })
    },
    async listCourses() {
      await apolloClient.query({
        query: LIST_COURSES,
        variables: {
          filter: "Accelerator"
        },
      }).then((response) => {
        console.log(response.data)
        for (let i = 0; i < response.data.listCourses.length; i++) {
          this.courses.push({
            name: response.data.listCourses[i].courseId,
            displayName: response.data.listCourses[i].courseName
          })
        }
        console.log(this.courses)
      })
    },
    editClassAction(cl) {
      this.reviewDialog = true
      this.selectedClass = cl
      this.selectedActualClass = this.actualClasses.filter(ac => ac.name === cl.name)[0]

    },
    extractDate(date) {
      let months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
      let str = date.split(" ")
      let year, month, day
      if (str.length === 5) {
        year = str[0].split(":")[1]
        month = str[2].split(":")[1]
        day = str[4].split(":")[1]
      } else {
        year = str[0].split(":")[1]
        month = str[1].split(":")[1]
        day = str[2].split(":")[1]
      }
      if (parseInt(month) < 10) {
        month = "0" + month
      }
      if (parseInt(day) < 10) {
        day = "0" + day
      }
      let niceMonth = months[+month-1]
      return day + " " + niceMonth + " " + year
    },

  }
}
</script>

<style scoped>

</style>