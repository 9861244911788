<template>
    <!-- <v-row class="home"> -->
    <v-card class="home" style="background: whitesmoke;">
        <v-card-title class="text-center justify-center py-6" style="background-color: #F5F5F5">
            <h3 class="text-h5 basil--text">
                My Classes
            </h3>
        </v-card-title>
        <v-tabs
            color="#3c7872"
            v-model="tab"
            grow
            background-color="#F5F5F5"
        >
            <v-tab>Foundations</v-tab>
            <v-tab>Phase 2</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="min-width: 100%">
            <v-tab-item style="min-height: 100%; background: whitesmoke">
                <v-container style="justify-content: center; width: 100%; max-width: 500px">
                <!-- <v-col class="this-right"> -->
                    <v-row class="this-list" v-if="list.length !== 0">
                        <v-list-item v-for="i in list" :key="i.range"> 
                            <v-row class="right-row">
                                <class-card :name="i.class" :startDate="i.startDate" :endDate="i.endDate" :course="i.course" :dates="i.range" :numEnrollments="i.ne" :fullClassName="i.fullClass"> </class-card>
                            </v-row>
                        </v-list-item>
                    </v-row>
                    <v-row class="this-list" v-else>
                        <v-list-item> 
                            <v-row class="right-row">
                                <v-card class="card rounded-xl" min-width="90%" @click="viewEnrollment()">
                                    <v-card-title> 
                                    <v-row class="right-row-edit">
                                            <p style="white-space: pre-wrap; word-break:break-word">
                                                You have not started any classes.
                                            </p>
                                    </v-row> 
                                    
                                    </v-card-title>
                                        <v-card-text style="font-size: 0.89rem;font-weight: 400;">
                                            <v-row class="right-row-edit">
                                                <p class="black--text">
                                                    Ready to start?
                                                </p>
                                            </v-row>
                                            <v-row>
                                                <div class="my-4 text-subtitle-1">
                                                    <v-btn outlined rounded class="this-signin-btn" @click="buildClass">Create Class</v-btn>
                                                </div>
                                            </v-row>

                                        </v-card-text>
                                </v-card>
                            </v-row>
                        </v-list-item>
                    </v-row>
            <!-- </v-col>  -->
                </v-container>
            </v-tab-item>
            <v-tab-item style="background: whitesmoke;">
                <v-row class="right-row" style="margin-top: 20px">
                    <v-col cols="6">
                        <v-autocomplete
                        v-model="month"
                        :loading="loading"
                        :items="months"
                        :search-input.sync="monthSearch"
                        cache-items
                        solo
                        return-object
                        label="Select month"
                        color="#3c7872"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                        v-model="year"
                        :loading="loading"
                        :items="years"
                        :search-input.sync="yearSearch"
                        cache-items
                        solo
                        label="Select year"
                        color="#3c7872"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                
                <v-row class="right-row" v-if="eCafeStudents.length > 0">
                    <p class="basil--text" style="margin-left: 5%; margin-right: 5%;">Showing Phase 2 enrollments for {{month.text}}, {{year}}.</p>
                </v-row>
                
                <v-divider style="margin-left: 5%; margin-right: 5%; margin-bottom: 30px"></v-divider>
                
                <v-row class="this-list" v-if="eCafeStudents.length === 0">
                        <v-list-item> 
                            <v-row class="right-row">
                                <v-card class="card rounded-xl" min-width="90%">
                                    <v-card-title> 
                                    <v-row class="right-row-edit">
                                            <p style="white-space: pre-wrap; word-break:break-word; font-size: 1.2rem;font-weight: 500;">
                                                No ECafe enrollments for {{month.text}}, {{year}}.
                                            </p>
                                    </v-row> 
                                    
                                    </v-card-title>
                                        <v-card-text style="font-size: 0.89rem;font-weight: 400;">
                                            <v-row class="right-row-edit">
                                                <p class="black--text">
                                                    Want to enrol students?
                                                </p>
                                            </v-row>
                                            <v-row>
                                                <div class="my-4 text-subtitle-1">
                                                    <v-btn outlined rounded class="this-signin-btn" @click="enrolStudents()">Enrol Students now</v-btn>
                                                </div>
                                            </v-row>

                                        </v-card-text>
                                </v-card>
                            </v-row>
                        </v-list-item>
                </v-row>
                
                <!-- ECafe enrollments list -->
                <v-row class="this-list" v-else>
                        <v-list-item> 
                            <v-row :key="componentKey" class="right-row" style="width: 100%" v-model="eCafeStudents">
                                <div v-for="i in eCafeStudents" :key="i.id" >
                                    <div style="margin-bottom: 20px; width: 100%">
                                        <e-cafe-enrollment-card :studentName="i.name" :phoneNumber="i.phoneNumber" :courses="i.courses"> </e-cafe-enrollment-card>
                                    </div>
                                </div>
                            </v-row>
                        </v-list-item>
                </v-row>
                <v-divider v-if="eCafeStudents.length > 0" style="margin-left: 5%; margin-right: 5%; margin-top: 5px; margin-bottom: 20px"></v-divider>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { LIST_CLASSES, LIST_ENROLLMENTS, GET_MEMBER } from '@/apollo/queries';
import ClassCard from "../../components/ClassCard.vue";
import ECafeEnrollmentCard from '../../components/ECafe/ECafeEnrollmentCard.vue';
    export  default {
    name: 'ClassViewer',
    async mounted () {
        await this.getList(),
        await this.setMonth(null)
    },
    components: {
        ClassCard,
        ECafeEnrollmentCard
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        years () {
          let currentYear = new Date().getFullYear()
          return [(currentYear-1).toString(), currentYear.toString(), (currentYear+1).toString()]
        },
        list() {
          let unsortedList = this.courses.map((itm, i) => {
            return {range: this.range[i], startDate: this.startDates[i], endDate: this.endDates[i], course: itm, class: this.classNames[i], ne: this.getEnr(this.fullClassName[i]), fullClass: this.fullClassName[i]}
          })
          return unsortedList.sort((a,b) => new Date(b.startDate) - new Date(a.startDate));
        },
        eCafeList: {
            get () {
                return []
            }

        },
    },
    data: () =>  ({
        componentKey: 0,
        showModal: false,
        course: 0,
        courseKeys: ["W4AL","WIRED"],
        classes: [],
        classNames: [],
        fullClassName: [],
        range: [],
        courses: [],
        numEnrollments: [],
        eCafeEnrollments: [],
        eCafeStudents: [],
        tab: 0,
        loading: false,
        year: new Date().getFullYear().toString(),
        months: [
          {text: "January", value: "01-01"},
          {text: "February", value: "02-01"},
          {text: "March", value: "03-01"},
          {text: "April", value: "04-01"},
          {text: "May", value: "05-01"},
          {text: "June", value: "06-01"},
          {text: "July", value: "07-01"},
          {text: "August", value: "08-01"},
          {text: "September", value: "09-01"},
          {text: "October", value: "10-01"},
          {text: "November", value: "11-01"},
          {text: "December", value: "12-01"},
        ],
        month: "",
        startDates: [],
        endDates: [],
        monthSearch: null,
        yearSearch: null,
    }),
    watch: {
        month() {
            this.eCafeStudents = []
            this.componentKey++
            this.listECafeEnrollments()
        },
        year() {
            this.eCafeStudents = []
            this.componentKey++
            this.listECafeEnrollments()
        }
    },
    methods: {
      async listECafeEnrollments() {
        let q = await this.$apollo.query({
          query: LIST_ENROLLMENTS,
          variables: {
            filter: this.activeUser.givenName + "" + this.activeUser.familyName + "|" + this.year + "-" + this.month.value,
            tag: "ECafe"
          }
        })
        let e = q.data.listEnrollments;
            for (var i = 0; i < e.length; i++) {
                let s = e[i]
                this.eCafeEnrollments.push(s)
                this.getMember(s.student, s.course)
            }
        },
      async getMember (id, course) {
        await this.$apollo.query({
          query: GET_MEMBER,
          variables: {
            name: id,
          },
        }).then(response => {
                let studentIndex = this.eCafeStudents.findIndex(student => student.id === id);
                if (studentIndex === -1) {
                    this.eCafeStudents.push({id: id, name: response.data.getMember.givenName + " " + response.data.getMember.familyName, phoneNumber: response.data.getMember.phoneNumber, courses: [course]})
                } else {
                    this.eCafeStudents[studentIndex].courses.push(course)
                }
                // console.log(this.eCafeStudents)
            })
        },
        setMonth(value) {
            if (value === null) {
                this.month = this.months[new Date().getMonth()]
            } else {
                this.month = this.months[this.months.indexOf(value)-1]
            }
        },
        buildClass () {
            this.$router.push({ name: 'ClassBuilder'})
        },
        enrolStudents () {
            this.$router.push({ name: 'EnrolStudents'})
        },
        getEnr(id) {
           for (var i = 0; i < this.numEnrollments.length; i++) {
            //    console.log("NE[", i, "]: ", this.numEnrollments[i])
            //    console.log("id: ", id)
               if (this.numEnrollments[i].label === id) {
                   return this.numEnrollments[i].value
               }
           }
        },
      async getList () {
        await this.$apollo.query({
          query: LIST_CLASSES,
          variables: {
            filter: this.activeUser.phoneNumber.replace(/ /g,'')
          }
        }).then(response => {
                    for (var i = 0; i < response.data.listClasses.length; i++) {
                        let name = response.data.listClasses[i].name
                        let arr = name.split("|")
                        let courseId = response.data.listClasses[i].course
                        let startDate = this.extractDate(response.data.listClasses[i].startDate)
                        this.startDates.push(startDate)
                        let endDate = this.extractDate(response.data.listClasses[i].endDate)
                        this.endDates.push(endDate)
                        this.range.push(arr[2])
                        this.courses.push(courseId)
                        this.fullClassName.push(name)
                        this.classNames.push(arr[0])
                        this.getNumEnrollments(name, courseId)
                    }
                })
        },
      async getNumEnrollments (classId, courseId) {
        await this.$apollo.query({
          query: LIST_ENROLLMENTS,
          variables: {
            filter: classId,
            tag: "Class",
            parent: courseId,
          }
        }).then(response => {
                    this.numEnrollments.push({label: classId, value: response.data.listEnrollments.length})
                })
        },
        extractDate(date) {
            let str = date.split(" ")
            var year, month, day
            if (str.length === 5) {
                year = str[0].split(":")[1]
                month = str[2].split(":")[1]
                day = str[4].split(":")[1]
            } else {
                year = str[0].split(":")[1]
                month = str[1].split(":")[1]
                day = str[2].split(":")[1]
            }

            if (parseInt(month) < 10) {
                month = "0" + month
            }
            if (parseInt(day) < 10) {
                day = "0" + day
            }
            return year + "-" + month + "-" + day
        },
    }
  }
</script>

<style scoped>
.home {
    margin: 0 auto;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    justify-content: center;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
  margin: 2px;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.this-list {
    justify-content: center;
    background:whitesmoke;
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.this-signin-btn {
  color: #3c7872;
}
.this-right {
  box-sizing: border-box;
  background: rgb(143, 166, 155);
  color: #fff;
}
.active-btn{
    background: #3C7872;
}
.btnRow {
    justify-content: center;
    align-content: center;
    margin: 20px;
}
.cardRow {
    justify-content: center;
    align-content: center;
    margin: 12px;
}
.chip {
    margin-left: 10px;
    margin-right: 10px;
}
.title {
    justify-content: center;
    align-content: center;
    margin-top: 5px;
}
.create-btn{
    padding: 20px;
    color: #3c7872;
}
.card{
    margin: 20px;
}
.basil--text {
  color: #1b154b !important;
  font-weight: 500;
}
  </style>