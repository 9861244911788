<template>
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
        <v-row class="right-row">
          <h1>Welcome.</h1>
        </v-row>
      </v-col>
      
      <v-col cols="12" sm="12" md="5" lg="4" class="right">
        <v-row class="right-input" style="height: 100%; min-width: 100%;display: flex;">
          <!-- Phone Number -->
          <v-col v-if="this.usePhoneNumber" cols="12" class="top-col" >
              <v-row class="full-row"> 
                <h2 class="h2">Enter your phone number</h2>
              </v-row>
              <v-row class="full-row"> 
                <vue-tel-input :ignoredCountries=this.ignored :preferredCountries=this.preferred validCharactersOnly class="this-input-phone" mode="international" required v-model="phoneNumber"  defaultCountry="ZA"></vue-tel-input>
              </v-row>
              <v-row class="full-row">
                <span v-if="!this.exists">
                    <h4 class="h4">{{errorTitle}}</h4>
                    <h6 class="h6">{{errorMessage}}</h6>
                    <v-btn @click="changeExistence" class="signin-btn" type="submit" rounded color="white" dark>
                        Try again
                    </v-btn>
                </span>
                <span v-else>
                    <v-row class="buttons">
                        <v-row class="full-row"> 
                            <v-btn v-if="!this.phoneNumber" class="signin-btn" type="submit" rounded color="white" dark disabled>
                                <p style="margin:20px">
                                  Verify
                                </p> 
                            </v-btn>
                            <v-btn v-else class="signin-btn" type="submit" rounded color="white" required dark @click="getMember('whatsapp')">
                                  <p style="margin:20px">
                                  Verify
                                </p> 
                            </v-btn>
                        </v-row>
                    </v-row>
                </span>
              </v-row>
              <v-row class="full-row">
              <v-btn class="not-receiving-signin-btn" @click="usePhoneNumber = false" plain>Use email instead</v-btn>
              </v-row>
              <div class="text-center">
              <v-dialog
                v-model="showModal"
                width="500"
                height="80%"
              >
                <v-card rounded>
                  <v-card-title class="text-h5  lighten-2">
                      You're new here.
                  </v-card-title>
                  <v-card-text>
                      <p class="popup-p-font">
                        Are you a student or a trained W4AL facilitator or activator?
                      </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-col>
                      <v-row style="min-width: 100%!important; justify-content: center;">
                        <v-btn
                          color="#3c7872"
                          text
                          @click="studentRegisterNav"
                          style="text-transform: none; width: 100%"
                        >Student</v-btn>
                      </v-row>
                      <v-divider style="margin-left:20px; margin-right: 20px; margin: 20px"></v-divider>
                      <v-row style="min-width: 100%!important; justify-content: center;">
                        <v-btn
                          color="#3c7872"
                          text
                          @click="facRegisterNav"
                          style="text-transform: none; width: 100%"
                        >
                          W4AL Facilitator
                        </v-btn>
                      </v-row>
                      <v-row style="min-width: 100%!important; justify-content: center;">
                        <v-btn
                          color="#3c7872"
                          text
                          @click="actRegisterNav"
                          style="text-transform: none; width: 100%"
                        >
                          W4AL Activator
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              </div>
          </v-col>
          <!-- Email -->
          <v-col v-else class="top-col">
            <v-row class="right-row"> 
                <h2 class="h2">Enter your email</h2>
             </v-row>
            <v-row class="email-row"> 
                <v-text-field
                    label="Email address"
                    required
                    dark
                    dense
                    v-model="email"
                    class="hidden-sm-and-up"
                  ></v-text-field>
                  <v-text-field
                    label="Email address"
                    required
                    dark
                    dense
                    v-model="email"
                    class="hidden-xs-only"
                  ></v-text-field>
            </v-row>
            <v-row class="info-right-row">  
              <p class="red--text buttons" v-if="errors.email">{{errors.email}}</p>
            </v-row>  
            <v-row class="right-row">
                <div v-if="!this.exists"> 
                    <h4 class="h4">{{errorTitle}}</h4>
                    <h6 class="h6">{{errorMessage}}</h6>
                    <v-btn @click="changeExistence" class="signin-btn" type="submit" rounded color="white" dark>
                        Try again
                    </v-btn>
                </div>
                <div v-else>
                    <v-row class="buttons">
                        <v-row class="right-row"> 
                            <v-btn v-if="this.errors.email || this.email === ''" class="signin-btn" type="submit" rounded color="white" dark disabled>
                                <p style="margin:20px">
                                  Verify Email
                                </p> 
                            </v-btn>
                            <v-btn v-else class="signin-btn" type="submit" rounded color="white" required dark @click="getMember('email')">
                                 <p style="margin:20px">
                                  Verify Email
                                </p> 
                            </v-btn>
                        </v-row>
                    </v-row>
                </div>
            </v-row>
            <v-row class="right-row">
              <v-btn class="not-receiving-signin-btn" @click="usePhoneNumber = true" plain>Use phone instead</v-btn>
            </v-row>
            <div class="text-center">
              <v-dialog
                v-model="showModal"
                width="500"
                height="80%"
              >
                <v-card rounded>
                  <v-card-title class="text-h5  lighten-2">
                      You're new here.
                  </v-card-title>

                  <v-card-text>
                      <p class="popup-p-font">
                        Are you a student or a trained W4AL facilitator or activator?
                      </p>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-row width="100%">
                      <v-btn
                        color="#3c7872"
                        text
                        @click="studentRegisterNav"
                        style="text-transform: none; width: 100%"
                      >Student</v-btn>
                    </v-row>
                    <v-divider style="margin-left:20px; margin-right: 20px; margin: 20px"></v-divider>
                    <v-row width="100%">
                      <v-btn
                      color="#3c7872"
                      text
                      @click="facRegisterNav"
                      style="text-transform: none; width: 100%"
                    >
                      W4AL Facilitator
                    </v-btn>
                    </v-row>
                    <v-row width="100%">
                      <v-btn
                      color="#3c7872"
                      text
                      @click="actRegisterNav"
                      style="text-transform: none; width: 100%"
                    >
                      W4AL Activator
                    </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <!-- Footer -->
          <v-col cols="12" class="bottom-col">
            <p class="terms">
              By continuing, you agree to our terms and <a class="a" target="_blank" href="https://www.termsfeed.com/live/5f435d93-fa3f-4ab7-bd82-902a20e8e877">privacy policy</a>.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
</template>

<script>
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import Vue from 'vue'
  import { GET_MEMBER } from "@/apollo/queries";
  import { SEND_MESSAGE_TOTP, SEND_VERIFICATION } from "@/apollo/mutations";
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import OneTimePin from "../Login/OneTimePin.vue"
  import OneTimePinRegister from "../Register/OneTimePin.vue"
  import RegisterFacilitator from "../Register/RegisterFacilitator.vue"
  import SecondaryEnter from './SecondaryEnter.vue';
  Vue.component('vue-phone-number-input', VuePhoneNumberInput);


  export  default {
    name: 'EnterPage',
    data: () => ({
      usePhoneNumber: true,
      phoneNumber: "",
      msg: [],
      preferred: ['US', 'ZA', 'MX', 'KE', 'MZ', 'BR', 'UG', 'PY', 'CO', 'BO', 'SV', 'HN', 'GT', 'CL', 'PE'],
      ignored: ['BG'],
      exists: true,
      errorMessage: "",
      errorTitle: "",
      email: "",
      showModal: false,
      stepper: 1,
      commMethod: false, 
      errors: [
        {'email': ""}
      ],
    }),
    mounted () {
      this.getContact()
    },
    watch: {
      phoneNumber(value) {
        this.validatePhone(value)
      },
      email(value) {
        this.validateEmail(value)
      }
    },
    methods: {
      getContact () {
        // console.log(this.$route.query.contact)
        let contact = this.$route.query.contact
        if (contact === undefined) {
          return
        }
        if (contact.length > 0) {
          if (contact.includes("@")) {
            this.email = contact
            this.usePhoneNumber = false
          } else {
            this.phoneNumber = contact
          }
        }
      },
      changeExistence() {
        if (this.exists) {
          this.exists = false
        } else {
          this.exists = true
        }
      },
      validatePhone(value) {
        const regEx = /^(?:[0-9]●?){6,14}[0-9]$/
        if (regEx.test(value)) {
          this.msg['phoneNumber'] = '';
        } else{
          this.msg['phoneNumber'] = 'Invalid Phone Number';
        } 
      },
      validateEmail (value) {
        if (value !== '') { 
          const regEx = /^\w+([.-]]?\w+)*@\w+([.-]?\w+)*(\.\w+)+ *$/;
          const facRegEx = /@work4aliving.org/gm;
          if (!regEx.test(value)) {
            this.errors['email'] = 'Invalid Email Address';
          } else if (facRegEx.test(value)) {
            this.errors['email'] = 'This is a facilitator email address. If you are a facilitator, use your phone number to sign in.';  
          } else {
            this.errors['email'] = '';
          }
        } else {
          this.errors['email'] = '';
        }
      },
      async getMember(method) {
        let name;
        this.commMethod = method;
        if (this.usePhoneNumber) {
          name = this.phoneNumber;
        } else {
          name = this.email;
        }

        // console.log("getMember called with name:", name); // log the name being used

        try {
          const response = await this.$apollo.query({
            query: GET_MEMBER,
            variables: { name: name },
          });

          // console.log("Response received:", response); // log the entire response

          this.exists = true;
          if (response.data.getMember != null) {
            // Log details about the member
            // console.log("Member found:", response.data.getMember);

            // Update the memberId in the Vuex store.
            this.$store.commit('setName', response.data.getMember.name);

            // Rest of your code...
            this.setRole(response.data.getMember);
            await this.sendVerification(method);
            if (this.usePhoneNumber) {
              this.$store.commit('setPhoneNumber', this.phoneNumber);
              await this.$router.push({
                name: 'OneTimePin',
                component: OneTimePin,
                params: {UseEmail: false, familyName: response.data.getMember.familyName}
              });
            } else {
              // Set the phone number of the fetched user (fetched by email).
              this.$store.commit('setPhoneNumber', response.data.getMember.phoneNumber);
              this.$store.commit('setEmail', this.email);
              // named route with params to let the router build the url
              await this.$router.push({ name: 'OneTimePin', component: OneTimePin, params: { UseEmail: true, Whatsapp: false, familyName: response.data.getMember.familyName } });
            }

          } else {
            console.log("Member not found"); // log a message if no member found

            // Register flow
            // Only asks whether a member is a fac or not if they sign in with their phone number.
            if (this.usePhoneNumber) {
              this.showModal = true;
            } else {
              // Nav to the secondary enter flow
              this.$store.commit('setFacRegister', false);
              this.$store.commit('setActRegister', false);
              await this.sendVerification(method);
              this.$store.commit('setEmail', this.email);
              await this.$router.push({ name: 'SecondaryEnter', component: SecondaryEnter });
            }
          }
        } catch (error) {
          console.error("An error occurred in getMember:", error); // log any errors
        }
      },
      setRole(member) {
        if (member.role === 2) {
          this.$store.commit("setFacilitator")
        } else if (member.role === 3) {
          this.$store.commit("setActivator")
        } else {
          this.$store.commit("setStudent")
        }
      },
      async studentRegisterNav () {
        this.$store.commit('setFacRegister', false)
        this.$store.commit('setActRegister', false)
        this.$store.commit('setPhoneNumber', this.phoneNumber)
        this.showModal = false
        await this.sendVerification(this.commMethod)
        await this.$router.push({name:'OneTimePinRegister', component: OneTimePinRegister, params: {Whatsapp: true}})
      },
      async facRegisterNav () {
        this.$store.commit('setFacRegister', true)
        this.$store.commit('setActRegister', false)
        this.showModal = false
        // This needs to be set here because it follows a different path via email
        this.$store.commit('setPhoneNumber', this.phoneNumber)
        await this.$router.push({name:'RegisterFacilitator', component: RegisterFacilitator})
      },
      async actRegisterNav () {
        this.$store.commit('setActRegister', true)
        this.$store.commit('setFacRegister', false)
        this.showModal = false
        // This needs to be set here because it follows a different path via email
        this.$store.commit('setPhoneNumber', this.phoneNumber)
        await this.$router.push({name:'RegisterFacilitator', component: RegisterFacilitator})
      },
      async sendVerification (method) {
        if (this.usePhoneNumber) {
          let v =  {
            verifyPhoneNumber: this.phoneNumber,
          }
          await this.$apollo.mutate({
            mutation: SEND_MESSAGE_TOTP,
            variables: v,
          }).then((response) => {
                this.$store.commit("setTotpKey", response.data.sendMessageTOTP)
              }).catch((error) => {
                // Error
                console.error(error)
              })
        } else {
          let v = {
            email: "",
            verifyEmail: this.email,
            method: method
          }
          // TODO: Set up email verification sending
          await this.$apollo.mutate({
            mutation: SEND_VERIFICATION,
            variables: v,
          }).then((response) => {
                this.$store.commit("setVerificationSID", response.data.sendVerification)
              }).catch((error) => {
                // Error
                console.error(error)
              })
        }

      },
    }
  }
</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.info-right-row {
  min-width: 100%;
  justify-content: center;
  padding: 2px;
}
.email-row {
  min-width: 80%;
  max-width:10mm;
  justify-content: center;
  padding: 2px;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-bottom {
  width: 100%;
  justify-content: center;
  margin-left: 0px;
  margin-right: 0px !important;
  align-content: end;
  justify-content: center;
  height: 20%;
}
.right-row {
  width: 70%;
  justify-content: center;
}
.full-row {
  width: 100%;
  justify-content: center;
  margin-left: 0px;
}
.h2 {
  text-align: center;
  padding: 10px;
}
.signin-btn {
  width: 100%;
  color: #3c7872;
  margin-top: 10px;
}
.signin-btn-2 {
  width: 80%;
  color: white;
  margin: 10px;
}
.this-enter-btn {
  width: 51%;
  color: white;
  margin-bottom: 15px;
}
.h5 {
  text-align: center;
  padding: 30px;
  margin-top: 20px;
}
.h3{
  text-align: center;
}
.h4{
  text-align: center;
}
.h6{
  text-align: center;
  margin-bottom: 20px;
}
.p{
  text-align: center;
  padding: 20px;
}
.atag {
  color: rgb(165, 207, 241);
}
.buttons {
  width: 90%;
  margin: 10px;
  justify-content: center;
  text-align: center;
  margin-left: 0px;
}
.rounded-text{
  border-radius: 12px;
}
.form {
  margin-top: 50px;
}
.this-input-phone{
  background: #a9c9c6;
  border: white;
  color: #3c7872;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 70%;
}
.this-enter-btn {
  width: 60%;
  color: white;
  margin-top: 30px;
}
.popup-p-font {
  font-size: 1.2em;
}
.not-receiving-signin-btn {
  width: 80%;
  color: #ffffff;
  background: #3c7872;
  margin: 10px;
  margin-top: 40px;
  text-transform:inherit;
}
.top-col{
  height: 90%; 
  min-width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.bottom-col{
  height: 10%; 
  min-width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.terms {
  min-width: 100%;
  color: #c2c2c2;
  justify-content: center;
  text-align: center;
  /* align-items: center;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;*/
  font-size: small;
}
.a {
  color: cadetblue;
}
</style>