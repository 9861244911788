<template>
    <v-container grid-list-lg>
        <v-autocomplete
        v-model="hub"
        :loading="loading"
        :items="hubs"
        cache-items
        return-object
        solo
        label="Select location"
        color="#3c7872"
        ></v-autocomplete>
        <v-row>
            <v-col cols="6">
                <v-autocomplete
                v-model="month"
                :loading="loading"
                :items="months"
                :search-input.sync="monthSearch"
                cache-items
                solo
                return-object
                label="Select month"
                color="#3c7872"
                ></v-autocomplete>
            </v-col>
            <v-col cols="6">
                <v-autocomplete
                v-model="year"
                :loading="loading"
                :items="years"
                :search-input.sync="yearSearch"
                cache-items
                solo
                label="Select year"
                color="#3c7872"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-autocomplete
          v-model="selectedCourses"
          :loading="loading"
          :items="courses"
          item-text="courseName"
          :search-input.sync="courseSearch"
          cache-items
          return-object
          solo
          label="Select courses"
          color="#3c7872"
          chips
          clearable
          deletable-chips
          multiple
          small-chips
        ></v-autocomplete>

        <v-divider style="margin-left: 5%; margin-right: 5%; margin-bottom: 20px"></v-divider>
        <v-row class="btnRow">
          <v-chip-group
            mandatory
            elevation-12
            active-class="active-btn  white--text"
            filter
            v-model="studentStatus"
          >
            <v-chip class="chip" elevation-12>
              Registered student
              <v-icon right style="margin-right: 1px">
                mdi-account-outline
              </v-icon>
            </v-chip>

            <v-chip disabled class="chip" elevation-12>
              New student
              <v-icon style="margin-right:1px">
                mdi-plus
              </v-icon>
            </v-chip>
          </v-chip-group>
        </v-row>
        <div v-if="studentStatus === 0">
          <v-autocomplete
          v-model="student"
          :loading="loading"
          :items="students"
          item-text="name"
          :search-input.sync="studentSearch"
          return-object
          cache-items
          solo
          label="Select student (search)"
          color="#3c7872"
          >
          
            <!-- <template v-slot:selection="{ item }">
              {{ getText(item) }}
            </template>
            <template v-slot:item="{ item }">
              {{ getText(item) }}
            </template> -->
              <template v-slot:chip="{ props, item }">
                <v-chip
                  v-bind="props"
                  :prepend-avatar="item.raw.avatar"
                  :text="item.raw.name"
                ></v-chip>
              </template>
          </v-autocomplete>
          <v-row style="justify-content: center;">
            <v-btn class="enrolBtn" v-if="selectedCourses === null || selectedCourses === [] || student === null || hub === '' || hub === null" disabled elevation-24>Enrol student <v-icon right>mdi-arrow-right</v-icon></v-btn>
            <v-btn class="enrolBtn" v-else elevation-24 @click="enrolStudent()">Enrol student <v-icon right>mdi-arrow-right</v-icon></v-btn>
          </v-row>
        </div>
        <div v-else>
          <vue-tel-input :ignoredCountries=this.ignored :preferredCountries=this.preferred class="this-input-phone-large hidden-xs-only" mode="international" required v-model="phoneNumber" label="Student Phone Number" defaultCountry="ZA"></vue-tel-input>
          <vue-tel-input :ignoredCountries=this.ignored :preferredCountries=this.preferred class="this-input-phone hidden-sm-and-up" mode="international" required v-model="phoneNumber" label="Student Phone Number" defaultCountry="ZA"></vue-tel-input>
          <p style="margin-left: 5%; margin-right: 5%; margin-top: 20px; margin-bottom: 30px"></p>
          <v-text-field
            v-model="firstName"
            :loading="loading"
            :items="items"
            cache-items
            solo
            label="Student First Name"
            color="#3c7872"
          ></v-text-field>

          <v-text-field
            v-model="lastName"
            :loading="loading"
            :items="items"
            cache-items
            solo
            label="Student Last Name"
            color="#3c7872"
          ></v-text-field>
          <v-row style="justify-content: center;">
            <v-btn class="enrolBtn" v-if="selectedCourses === null || selectedCourses === [] || phoneNumber === '' || firstName === ''" disabled elevation-24>Enrol student <v-icon right>mdi-arrow-right</v-icon></v-btn>
            <v-btn class="enrolBtn" v-else elevation-24 @click="enrolStudent()">Enrol student <v-icon right>mdi-arrow-right</v-icon></v-btn>
          </v-row>
        </div>

        <v-row style="justify-content: center;">
          <v-btn class="enrol-btn-inverse hidden-sm-and-up" rounded @click="navToClasses()" text>
              View Classes
              <v-icon
                  right
                  dark
              >
                  mdi-account-eye-outline
              </v-icon>
          </v-btn>
          <v-btn class="enrol-btn-inverse-large hidden-xs-only" rounded @click="navToClasses()" text>
              View Classes
              <v-icon
                  right
                  dark
              >
                  mdi-account-eye-outline
              </v-icon>
          </v-btn>
        </v-row>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          color="green"
          text
          rounded="pill"
        >
          {{ text }}
        </v-snackbar>
        <v-snackbar
          v-model="errorSnackbar"
          :timeout="errorTimeout"
          color="#890707"
          
          rounded="pill"
        >
          {{ errorText }}
        </v-snackbar>
        <v-snackbar
          v-model="existsSnackbar"
          :timeout="errorTimeout"
          color="#890707"
          
          rounded="pill"
        >
          {{ existsText }}
        </v-snackbar>
    </v-container>
</template>

<script>
  import {GET_CLASS, LIST_COURSES, LIST_MEMBERS, GET_MEMBER, LIST_HUBS} from "@/apollo/queries";
  import { mapGetters } from "vuex";
  import { apolloClient } from "@/main"
  import { CREATE_CLASS, CREATE_ENROLLMENT } from '@/apollo/mutations';
  export default {
    name: 'NewECafeEnrollment',
    data () {
      return {
        // newClassId: "",
        snackbar: false,
        timeout: 1500,
        errorSnackbar: false,
        errorTimeout: 3000,
        existsSnackbar: false,
        text: 'Enrolled student',
        errorText: "Enrol failed. Student could already be enrolled.",
        existsText: "Student exists. This is only for new students.",
        studentStatus: 0,
        loading: false,
        items: [],
        courseSearch: null,
        monthSearch: null,
        yearSearch: null,
        studentSearch: null,
        select: null,
        phoneNumber: "",
        state: "",
        courses: [],
        selectedCourses: [],
        firstName: "",
        lastName: "",
        year: new Date().getFullYear().toString(),
        month: "",
        months: [
          {text: "January", value: "01-01"},
          {text: "February", value: "02-01"},
          {text: "March", value: "03-01"},
          {text: "April", value: "04-01"},
          {text: "May", value: "05-01"},
          {text: "June", value: "06-01"},
          {text: "July", value: "07-01"},
          {text: "August", value: "08-01"},
          {text: "September", value: "09-01"},
          {text: "October", value: "10-01"},
          {text: "November", value: "11-01"},
          {text: "December", value: "12-01"},
        ],
        students: [],
        student: null,
        preferred: ['US', 'ZA', 'MX', 'KE', 'MZ', 'BR', 'UG'],
        ignored: ['BG'],
        exists: false,
        hub: "",
        hubs: [],
      }
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        years () {
          let currentYear = new Date().getFullYear()
          return [(currentYear-1).toString(), currentYear.toString(), (currentYear+1).toString()]
        },
    },
    async mounted () {
      await this.listCourses(),
      await this.listMembers(),
      this.setMonth(null),
      await this.listHubs()
      this.hub = this.activeUser.baseHub
    },
    methods: {
      async listHubs() {
        await apolloClient.query({
          query: LIST_HUBS,
        })
        .then((response) => {
          for (var i = 0; i < response.data.listHubs.length; i++) {
            this.hubs.push(response.data.listHubs[i].name)
          }
        })
      },
      setMonth(value) {
          if (value === null) {
              this.month = this.months[new Date().getMonth()]
          } else {
              console.log(value)
              console.log(this.months.indexOf(value)-1)
              this.month = this.months[this.months.indexOf(value)-1]
          }
      },
      async enrolStudent () {
        let classId = this.activeUser.givenName + "" + this.activeUser.familyName + "|" + this.year + "-" + this.month.value
        // console.log("ClassId: ", classId)
        let classExists = await this.getClass(classId)
        // console.log("Class Exists: ", classExists)
        for (var i = 0; i < classExists.length; i++) {
          if (classExists[i] === false) {
            await this.createClass(classId, this.selectedCourses[i])
          }
          this.createEnrollment(classId, this.selectedCourses[i])
        }
      },
      async getMember () {
          let contact
          contact = this.phoneNumber.replace(/ /g,'')
          await apolloClient.query({
              query: GET_MEMBER,
              variables: {
                name: contact,
              },
          }).then(response => {
              if (response.data.getMember != null) {
                  this.exists = true
              } else {
                  this.exists = false
              }
          })
      },
      async createEnrollment (classId, course) {
        let v
          if (this.studentStatus === 0) {
            v = {
              course: course.courseId,
              enrolledClass: classId,
              name: this.student.phoneNumber,
              student: this.student.phoneNumber,
              paid: true
            }
          } else {
            // New student
            await this.getMember()
            // console.log("EXISTS: ", this.exists)
            if (this.exists) {
                this.existsSnackbar = true
                return 
            }
            v = {
              course: course.courseId,
              enrolledClass: classId,
              name: this.phoneNumber.replace(/ /g, ""),
              student: this.phoneNumber.replace(/ /g, ""),
              paid: true
            }
          }
          await apolloClient.mutate({
            mutation: CREATE_ENROLLMENT,
            variables: v
          }).then(resp => {
            if (resp.data.createEnrollment) {
              this.snackbar = true
            } else {
              this.errorText = "Student already enrolled in " + course.courseId
              this.errorSnackbar = true
              this.errorText = "Enrol failed. Student could already be enrolled."
            }
          })
          .catch( resp => {
            if (!resp.data) {
              this.errorText = "Student already enrolled in " + course.courseId
              this.errorSnackbar = true
              this.errorText = "Enrol failed. Student could already be enrolled."
            }
          })
        // }
      },
      async getClass (classId) {
        var arr = []
        for (var i = 0; i < this.selectedCourses.length; i++) {
          let q = await apolloClient.query({
            query: GET_CLASS,
            variables: {
              name: classId,
              courseId: this.selectedCourses[i].courseId
            }
          })
          if (q.data.getClass) {
            arr.push(true)
          } else {
            arr.push(false)
          }
        }
        return arr
      },
      async createClass (classId, course) {
        let lastday = new Date(parseInt(this.year), parseInt(this.month.value), 0).getDate();
        await apolloClient.mutate({
          mutation: CREATE_CLASS,
          variables: {
            course: course.courseId,
            name: classId,
            startDate: this.year + "-" + this.month.value,
            endDate: this.year + "-" + this.month.value.split("-")[0] + "-" + lastday,
            facilitator: this.activeUser.phoneNumber.replace(/ /g, ""),
            classFormat: 3,
            hub: this.hub
          }
        })
      },
      getText: (item) => `${item.givenName} ${item.familyName}, ${item.phoneNumber}`,
      async listCourses () {
          await apolloClient.query({
              query: LIST_COURSES,
              variables: {
                filter: "2"
              }
          }).then(response => {
            this.courses = response.data.listCourses
          }).catch(ball => {
            console.log("Ball is: ", ball)
          })
      },
      async listMembers () {
          await apolloClient.query({
              query: LIST_MEMBERS,
              variables: {
                filter: "students"
              }
          }).then(response => {
            this.students = response.data.listMembers
            console.log("Students: ", this.students)
          }).catch(ball => {
            console.log("Ball is: ", ball)
          })
      },
      querySelections (v) {
        this.loading = true
        // Simulated ajax query
        setTimeout(() => {
          this.items = this.states.filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          })
          this.loading = false
        }, 500)
      },
      navToClasses () {
          this.$router.push({name: 'ClassViewer'})
      },
    },
  }
</script>

<style scoped>
.infoText {
  font-weight: 500;
  color: #1b154b !important;
}
.right-row {
  width: 70%;
  justify-content: center;
}
.this-input-phone-large{
  margin: 0px;
  color: black;
  border-radius: 0px;
  border: white;
  background: white;
  padding: 5px;
  -webkit-box-shadow: 1px 11px 20px -3px #000000;
  box-shadow: 1px 1px 6px -3px #000000;
}
.this-input-phone{
  margin: 0px;
  color: black;
  border-radius: 0px;
  border: white;
  background: white;
  padding: 5px;
  -webkit-box-shadow: 1px 11px 20px -3px #000000;
  box-shadow: 1px 1px 6px -3px #000000;
}
.enrolBtn{
  background: white !important;
  color: #3c7872;
  margin: 10px;
  text-transform: inherit;
  min-width: 200px;
  width: 200px;
  border-radius: 20px;
}
.enrol-btn-inverse{
  color: #3c7872;
  background: whitesmoke;
  margin: 10px;
  text-transform: inherit;
  min-width: 200px;
  width: 200px;
  border-radius: 20px;
}
.enrol-btn-inverse-large{
  color: #3c7872;
  background: whitesmoke;
  margin: 10px;
  text-transform: inherit;
  min-width: 200px;
  width: 200px;
  border-radius: 20px;
  width: 40%;
}
.active-btn{
    background: #3C7872;
}
.chip {
    margin-left: 10px;
    margin-right: 10px;
}
.btnRow {
    justify-content: center;
    align-content: center;
    margin-bottom: 20px;
}
</style>
<!-- box-shadow: 1px 1px 8px -5px #000000; -->