<template>
  <v-layout fill-height justify-center align-center>
    <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
  </v-layout>
</template>

<script>
export default {
  name: 'LoadingSpinner'
}
</script>
