<template>
  <v-dialog v-model="show" persistent @input="emitCloseEvent">
    <v-card>
      <v-card-title>
        <span class="headline">Create New Group</span>
      </v-card-title>
      <v-card-subtitle>
        <span>Select course, dates and location</span>
      </v-card-subtitle>
      <v-divider class="ma-5 mt-1"></v-divider>
      <v-card-text>
        <v-row style="justify-content: center" class="pt-2">
          <v-col cols="12" class="pt-0 pb-0">
            <v-select return-object dense outlined v-model="course" placeholder="Select course" label="Course" :items="courses" item-text="displayName" item-value="name"></v-select>
          </v-col>
        </v-row>
        <v-row style="justify-content: center" class="pt-0">
          <v-col cols="12" class="pt-0 pb-0">
            <v-select dense outlined v-model="numStudents" placeholder="Number of students" label="Number of students" :items="items"></v-select>
          </v-col>
        </v-row>
        <v-row style="justify-content: center" class="pt-0">
          <v-col cols="6" class="pb-0 pt-0">
            <v-text-field dense outlined placeholder="Country" v-model="country"></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-0 pt-0">
            <v-text-field dense outlined placeholder="Area" v-model="area"></v-text-field>
          </v-col>
        </v-row>

        <v-row style="justify-content: center" class="hidden-md-and-up">
          <v-col class="dateRow" style="width: 90%; justify-content: center" v-if="this.dates.length <= 1">
            <v-date-picker
                width="90%"
                v-model="dates"
                range
                no-title
                color="#6A2E85"
                class="classDates mt-4 mr-0"
                style="max-width: 500px; justify-content: center; margin: 0; display: contents"
                :min=dates[0]
            ></v-date-picker>
            </v-col>
            <v-col class="dateRow" style="width: 90%; justify-content: center" v-else>
              <v-date-picker
                  v-model="dates"
                  range
                  no-title
                  color="#6A2E85"
                  width="90%"
                  class="classDates mt-4 mr-0"
                  style="max-width: 500px; justify-content: center; margin: 0; display: contents"
              ></v-date-picker>
          </v-col>
        </v-row>
        <v-row style="justify-content: center" class="hidden-sm-and-down">
          <v-col class="dateRow" style="max-width: 70%; justify-content: center" v-if="this.dates.length <= 1">
            <v-date-picker
                width="90%"
                v-model="dates"
                range
                no-title
                color="#6A2E85"
                class="classDates mt-4 mr-0"
                style="max-width: 500px; justify-content: center; margin: 0; display: contents"
                :min=dates[0]
            ></v-date-picker>
          </v-col>
          <v-col class="dateRow" style="max-width: 70%; justify-content: center" v-else>
            <v-date-picker
                v-model="dates"
                range
                no-title
                color="#6A2E85"
                width="90%"
                class="classDates mt-4 mr-0"
                style="max-width: 500px; justify-content: center; margin: 0; display: contents"
            ></v-date-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="showErr">
        <v-row  style="justify-content: center; margin-bottom: 10px; width: 100%">
          Could not create class. Try again.
        </v-row>
      </v-card-actions>
      <v-card-actions>
        <v-row style="justify-content: center; margin-bottom: 10px; width: 100%">
          <v-col cols="6" class="pr-0">
            <v-btn @click="show = false" style=" width: 100%" text class="pa-0" color="#666666">
              Close
            </v-btn>
          </v-col>
          <v-col cols="6" class="pl-0">
            <v-btn @click="show = false" style=" width: 100%" text class="pa-0" v-if="course === null || numStudents === 0 || country === '' || area === '' || dates.length !== 2" disabled>
              Create
            </v-btn>
            <v-btn @click="createClass" style=" width: 100%" text class="pa-0" v-else>
              Create
            </v-btn>
          </v-col>
        </v-row>

      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import {CREATE_CLASS} from "../../apollo/mutations";
import {LIST_COURSES} from "@/apollo/queries";
import { apolloClient } from "@/main";
import {mapGetters} from "vuex";
export default {
  props: {
    value: Boolean
  },
  computed: {
    ...mapGetters('user', ['activeUser']),
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      dates: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(Date.now() + 600000000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
      country: "",
      area: "",
      numStudents: 0,
      items: Array.from(Array(50).keys()).map((n) => n + 1),
      course: null,
      courses: [],
      showErr: false
    };
  },
  watch: {
    value(val) {
      this.createDialog = val;
    },
  },
  async mounted() {
    await this.listCourses()
  },
  methods: {
    emitCloseEvent() {
      this.$emit("input", false);
    },
    async listCourses() {
      await apolloClient.query({
        query: LIST_COURSES,
        variables: {
          filter: "Accelerator"
        },
      }).then((response) => {
        for (let i = 0; i < response.data.listCourses.length; i++) {
          this.courses.push({name: response.data.listCourses[i].courseId, displayName:response.data.listCourses[i].courseName})
        }
      })
    },
    async createClass() {
      await apolloClient.mutate({
        mutation: CREATE_CLASS,
        variables: {
          course: this.course.name,
          name: this.activeUser.givenName + "" + this.activeUser.familyName + "|" + this.dates[0],
          startDate: this.dates[0],
          endDate: this.dates[1],
          facilitator: this.activeUser.phoneNumber.replace(/ /g, ''),
          classFormat: 4,
          hub: "SMALL GROUP",
          enrollmentsCount: this.numStudents
        }
      }).then(() => {
        this.show = false
      }).catch(err => {
        console.log(err)
        this.showErr = true
        if (!this.$store.state.isAuthenticated || (!this.$store.state.facilitator && !this.$store.state.activator)) {
          let user = this.$store.getters.activeUser
          let userId = user.phoneNumber.replace(/\s/g, "")
          this.$store.commit('deleteUserKey', userId)
          this.$store.commit('setFirstName', "")
          this.$store.commit('setPhoneNumber', "")
          this.$store.commit('logout')
          this.$router.push({name: 'HomePage'})
          this.showModal = false
        }
      })
    },
  }
}
</script>

<style scoped>
.dateRow {
  justify-content: center !important;
  align-content: center !important;
  margin-top: -20px;
}
</style>
