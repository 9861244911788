<template>
    <v-row class="home">
      <v-col class="this-right">
            <v-row class="right-row">
                <v-card class="this-header-card rounded-lg" min-width="95%">
                    <v-card-title class="pb-0 pl-0">
                        <v-row class="right-row-left-align">
                            <h3 style="margin-top:20px;margin-left:20px;"> Post-class check-in</h3>
                        </v-row> 
                    </v-card-title>
                    <v-row class="right-row-left-align"> 
                        <p class="question">How to complete the form:</p>
                    </v-row>
                    <v-card-text class="pa-0">
                        <v-row class="right-row-left-align"> 
                            <p style="margin-left:20px; margin-right: 20px">The button you choose indicates your agreement with the statement. For Example:</p>
                        </v-row>
                    </v-card-text>
                    <v-carousel class="right-row pt-0" height="140" v-model="carousel" hide-delimiters>
                        <v-carousel-item
                        v-for="c in 5"
                        :key="c"
                        >   
                                <v-card-text class="pa-0">
                                    <v-row class="right-row">
                                        <v-radio-group v-model="exVal" row>
                                            <v-tooltip bottom v-for="n in 5" :key="n">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-radio
                                                        :value="n"
                                                        :color="colors[n-1]"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-radio>
                                                </template>
                                                <span>{{labels[n-1]}}</span>
                                            </v-tooltip>
                                        </v-radio-group>
                                    </v-row>
                                    <v-row class="right-row">
                                        {{labels[exVal-1]}}
                                    </v-row>
                                </v-card-text>
                        </v-carousel-item>
                    </v-carousel>
                    <v-card-text class="pa-0">
                        <v-row class="right-row-left-align"> 
                            <p class="question" style="margin-right: 20px">Now that you know how to answer questions, complete the form below 👇</p>
                        </v-row>
                        <br>
                    </v-card-text>
                </v-card>
            </v-row>

            <div v-for="i in relevantQ.length" :key="i">
                    <v-row class="right-row">
                        <v-card class="this-card rounded-lg" min-width="95%">
                                <v-card-text>
                                    <v-row class="right-row-left-align"> 
                                        <p class="question">{{relevantQ[i-1].label}}</p>
                                    </v-row>
                                    <v-row class="right-row"> 
                                        <v-radio-group v-model="relevantQ[i-1].value" row>
                                            <v-tooltip bottom v-for="n in 5" :key="n">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-radio
                                                        :key="n"
                                                        :value="n"
                                                        :color="colors[n-1]"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-radio>
                                                </template>
                                                <span>{{labels[n-1]}}</span>
                                            </v-tooltip>
                                        </v-radio-group>
                                    </v-row>
                                    <v-row class="right-row" v-if="relevantQ[i-1].value !== -1" >
                                        {{labels[relevantQ[i-1].value-1]}}
                                    </v-row>
                                    <v-row class="right-row" v-else >
                                        <p></p>
                                    </v-row>
                                </v-card-text>
                        </v-card>
                    </v-row>
                </div>

        <v-alert v-if="error" type="error" class="mt-2 mb-2">
          {{ error }}
        </v-alert>

        <v-row class="right-row-right-align">
                <v-btn v-if="!allAnswered" color="white" disabled rounded class="submit-btn">Submit</v-btn>
                <v-btn v-else color="white" rounded class="submit-btn" @click="createReport()">Submit</v-btn>
            </v-row>

            
            <v-dialog
                v-model="dialog"
                width="300"
                persistent
                >
                <v-card>
                    <v-card-title class="text-h5 card-title">
                        Congratulations
                    </v-card-title>
                    <v-card-text>
                        <v-row class="right-row-extra-margin mt-2 mb-2" style="justify-content: center">
                                <p class="black--text" style="margin-top:20px;"> You earned 10 XP! </p>
                                <v-spacer></v-spacer>
                                <v-btn style="margin-top:20px;" color="background" text>
                                    <font-awesome-icon icon="fa-solid fa-coins" shake class="fa-cog" size="xl"/>
                                    <font-awesome-icon icon="fa-solid fa-coins" shake class="fa-cog" size="xl"/>
                                </v-btn>
                        </v-row>
                        <v-divider></v-divider>
                    </v-card-text>
                </v-card>
                </v-dialog>
      </v-col> 
    </v-row>
</template>

<script>
import { CREATE_SELF_ESTEEM_CHECKIN, CREATE_EMPLOYMENT_HOPE_CHECKIN, CREATE_ENTITLEMENT_CHECKIN } from '@/apollo/mutations'
import { GET_CHECKIN_TOKEN, GET_CHECKIN_REPORT } from '@/apollo/queries'
import { apolloClient } from '@/main'
import { mapGetters, mapMutations } from "vuex";
import {v4 as uuidv4} from "uuid";

function isValidBase64(str) {
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  return base64Regex.test(str);
}
function logCaller(value) {
  try {
    throw new Error("Who called me with: " + value);
  } catch (e) {
    console.log(e.stack);
  }
}

export default {
  name: 'PostCourseCheckin',
  props: {
    encodedEnr: {type: String},
    encodedClassId: {type: String},
    encodedCourseId: {type: String},
    encodedReport: {type: String}
  },
  watch: {
    carousel: {
      handler(val) {
        this.exVal = val+1;
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters('user', ['activeUser']),
    allAnswered() {
      return this.relevantQ.every(item => item.value !== -1) && this.relevantQ.length > 0;
    }
  },
  data: () => ({
    reporter: "",
    parsedReportName: "",
    error: null,
    enr: "",
    classId: "",
    courseId: "",
    report: "",
    exVal: 1,
    carousel: 0,
    courseQuery: "",
    classQuery: "",
    enrollmentQuery: "",
    reportQuery: "",
    checkinToken: "",
    radioGroup: 1,
    dialog: false,
    SE: true,
    E: false,
    EH: false,
    relevantQ: [],
    seQ: [
        {label: "I tend to think less of myself than I should:", value: -1},
        {label: "I am highly effective at the things I do:", value: -1},
        {label: "I am very comfortable with myself:", value: -1},
        {label: "I am almost always able to achieve what I try for:", value: -1},
        {label: "I am secure in my sense of self-worth:", value: -1},
        {label: "It is sometimes unpleasant for me to think about myself:", value: -1},
        {label: "At times, I find it difficult to achieve the things that are important to me:", value: -1},
        {label: "I have a negative attitude toward myself:", value: -1},
        {label: "I feel great about who I am:", value: -1},
        {label: "I sometimes deal badly with challenges:", value: -1},
        {label: "I never doubt my personal worth:", value: -1},
        {label: "I perform very well at many things:", value: -1},
        {label: "I sometimes fail to achieve my goals:", value: -1},
        {label: "I am very talented:", value: -1},
        {label: "I do not have enough respect for myself:", value: -1},
    ],
    eQ: [
        {label: "Everybody has the right to expect help from the government when in need:", value: -1},
        {label: "Disadvantaged persons deserve institutional help:", value: -1},
        {label: "The government should take care of the livelihood of the poorest:", value: -1},
        {label: "The government has a duty to ensure decent living conditions for people:", value: -1},
        {label: "It is the duty of the government to care for all citizens:", value: -1},
        {label: "When working or looking for a job, I am respectful towards who I am:", value: -1},
        {label: "I am worthy of working in a good job:", value: -1},
    ],
    ehQ: [
        {label: "When working or looking for a job, I am respectful towards who I am:", value: -1},
        {label: "I am worthy of working in a good job:", value: -1},
        {label: "I am capable of working in a good job:", value: -1},
        {label: "I have the strength to overcome any obstacles when it comes to working:", value: -1},
        {label: "I am aware of what my skills are to be employed in a good job.:", value: -1},
        {label: "I am aware of what my resources are to be employed in a good job:", value: -1},
        {label: "I am able to use my skills to move toward career goals.:", value: -1},
        {label: "I am able to use my resources to move toward career goals.:", value: -1},
        {label: "I am on the road toward my career goals.:", value: -1},
        {label: "I am in the process of moving forward reaching my goals:", value: -1},
        {label: "Even if I am not able to achieve my financial goals right away, I will find a way to get there:", value: -1},
        {label: "My current path will take me to where I need to be in my career.:", value: -1},
    ],
    colors: [
        "#7A0505",
        "#C40808",
        "gray",
        "#4B958E",
        "#3c7872",
    ],
    labels: [
        "Strongly Disagree",
        "Disagree", "Neutral", "Agree",
        "Strongly Agree"
    ],
  }),
  async mounted() {
    await this.decodeAndSetValues();
    await this.getReport();
  },

  methods: {
    ...mapMutations(['setFirstName', 'setPhoneNumber', 'setJWT', 'setVerificationSID', 'setCheckinToken', 'logout']),

    async decodeAndSetValues() {
      try {
        this.enr = this.decode(this.encodedEnr);
        this.classId = this.decode(this.encodedClassId);
        this.courseId = this.decode(this.encodedCourseId);
        this.report = this.decode(this.encodedReport);

        if (!this.enr.match(/^\+\d{11}$/)) {
          throw new Error("Invalid enrollment ID: " + this.enr);
        }
      } catch (error) {
        console.error("Error decoding values:", error);
      }
    },

    decode(str) {
      if (str === undefined) {
        logCaller(str);
      }
      if (isValidBase64(str)) {
        try {
          return window.atob(str);
        } catch (err) {
          console.error('Failed to decode string:', str, err);
          throw err;
        }
      } else {
        console.error('String is not a valid Base64:', str);
        return null;
      }
    },

    determineReportType(reportName) {
      if (reportName.startsWith("SE")) {
        this.relevantQ = this.seQ;
        this.SE = true;
        this.E = false;
        this.EH = false;
      } else if (reportName.startsWith("EN")) {
        this.relevantQ = this.eQ;
        this.E = true;
        this.EH = false;
        this.SE = false;
      } else {
        this.relevantQ = this.ehQ;
        this.EH = true;
        this.SE = false;
        this.E = false;
      }
    },

    async getReport() {
      try {
        const { jwt } = this.$store.state;

        if (jwt !== "") await this.getCheckinToken();

        if (!this.$route.query.report && !this.report) {
          console.error("Report query parameter is missing from the route or the report data is not set.");
          return;
        }

        const reportName = this.report || this.$route.query.report;

        const reportData = await this.fetchWithQuery(GET_CHECKIN_REPORT, { name: reportName });

        if (reportData && reportData.getReport) {
          this.determineReportType(reportName);
        }
      } catch (error) {
        console.error("Error in getReport:", error);
      }
    },

    async fetchWithQuery(query, variables) {
      try {
        const response = await apolloClient.query({ query, variables });
        if (response.data.getReport && response.data.getReport.reporter) {
          this.reporter = response.data.getReport.reporter;
        }
        return response.data;
      } catch (error) {
        console.error(`Error fetching data with query ${query}`, error);
      }
    },

    prepareReportVars(reportType, reportSubtype, reportTail, values, preCheckinType) {
      const pc = {
        enrollment: this.enr,
        classId: this.classId,
        courseId: this.courseId,
        responses: values
      };

      return {
        name: this.generateReportName(reportType, preCheckinType),
        member: this.reporter,
        reporter: this.reporter,
        reportType,
        [reportSubtype]: pc
      };
    },

    getReportDetails() {
      const reportTail = `${this.enr}|${this.classId}|${this.courseId}`;
      const values = this.relevantQ.map(obj => obj.value);
      const preCheckinType = this.SE ? 'SE' : this.E ? 'EN' : 'EH'; // Determine the pre-checkin type

      if (this.SE) {
        return {
          vars: this.prepareReportVars(5, 'selfEsteemCheckin', reportTail, values, preCheckinType),
          mutation: CREATE_SELF_ESTEEM_CHECKIN
        };
      } else if (this.E) {
        return {
          vars: this.prepareReportVars(9, 'entitlementCheckin', reportTail, values, preCheckinType),
          mutation: CREATE_ENTITLEMENT_CHECKIN
        };
      } else {
        return {
          vars: this.prepareReportVars(7, 'employmentHopeCheckin', reportTail, values, preCheckinType),
          mutation: CREATE_EMPLOYMENT_HOPE_CHECKIN
        };
      }
    },

    generateReportName(reportType, preCheckinType) {
      const prefix = preCheckinType.substring(0, 2);
      return `${prefix}-post-${uuidv4()}`;
    },

    async createReport() {
      try {
        const { vars, mutation } = this.getReportDetails();
        // console.log("Payload being sent: Mutation:", mutation, "Vars:", vars);

        await apolloClient.mutate({
          mutation: mutation,
          variables: vars
        });
        await this.$router.push({name: 'ProfileContent'});

      } catch (error) {
        console.error("Error in createReport:", error);

        // Check if the error message is related to "no more items in iterator".
        if (error.message.includes("no more items in iterator")) {
          console.warn("Caught the 'no more items in iterator' warning. Rerouting regardless.");
          await this.$router.push({name: 'ProfileContent'});
        } else {
          this.error = error.message || 'An error occurred while creating the report.';
        }
      }
    },

    async getCheckinToken() {
      const data = await this.fetchWithQuery(GET_CHECKIN_TOKEN, { phoneNumber: this.enrollmentQuery });
      if (data) {
        this.setCheckinToken(data.getCheckinToken);
        this.checkinToken = data.getCheckinToken;
      }
    },

  }
}
</script>

<style scoped>
.home {
    margin: 0;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    justify-content: center;

}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
  margin: 0px;
}
.right-row-right-align {
    width: 100%;
    justify-content: right;
    margin: 1px;
}
.right-row-left-align {
    width: 100%;
    justify-content: left;
    margin: 1px;
}
.header-row{
    justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.this-list {
    justify-content: center;
    background: rgb(143, 166, 155);
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.card-title {
  padding: 2px;
  background: #6A2E85;
  color: antiquewhite;
  justify-content: center;
}
.this-signin-btn {
  color: #3c7872;
}
.this-right {
  box-sizing: border-box;
  background: rgb(143, 166, 155);
  color: #fff;
}
.active-btn{
    background: #3C7872;
}
.btnRow {
    justify-content: center;
    align-content: center;
    margin: 20px;
}
.cardRow {
    justify-content: center;
    align-content: center;
    margin: 12px;
}
.chip {
    margin-left: 10px;
    margin-right: 10px;
}
.title {
    justify-content: center;
    align-content: center;
    margin-top: 5px;
}
.this-card{
    margin: 10px;
}
.this-header-card{
    margin: 10px;
    border-top: 10px solid #6A2e85 !important;
}
.no-wrap{
    white-space: nowrap;
    justify-content: center;
}
.this-share-btn {
    color: #6A2E85;
    text-decoration: none !important;
    display: inline-block;
    text-transform: none;
}
.submit-btn {
    color: #6A2E85;
    background: #D2DFDD;
    text-transform: none;
    margin: 20px;
}
.question {
    margin-top:20px;
    margin-left:20px;
    font-size: 16px;
    letter-spacing: .1px;
    line-height: 24px;
    word-break: break-word;
    color:black;
    font-weight: 500;
}
.large .v-radio {
    padding: 10px;
}
.large i {
    font-size: 48px;
}
.large label {
padding-left: 24px;
}

.large [class*="__ripple"] {
left: 0;
}
.fa-cog {
  color: rgb(234, 200, 46);
}
</style>