<template>
    <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable>
        <v-card >
            <v-card-title class="justify-center grey lighten-3 title-space">
                Edit language
            </v-card-title>
            <v-card-text>
                <v-form class="form">
                    <v-row class="info-right-row">  
                        <v-text-field
                            class="rounded-text"
                            label="Language"
                            required
                            outlined
                            filled
                            dense
                            :value="this.currentLanguage"
                            @input="updateSpokenLanguage($event)"
                        ></v-text-field>
                    </v-row>
                    <v-row class="info-right-row">
                        <v-select
                            :value="this.skillProp"
                            @input="updateLevel($event)"
                            :items="levels"
                            item-text="key"
                            item-value="value"
                            label="Language proficiency"
                            filled
                            class="rounded-text"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row class="right-row-edit"> 
                    <v-btn class="edit-btn-cancel" rounded text align-left large @click="deleteLanguage"> <v-icon small>mdi-delete</v-icon> Delete</v-btn>
                    <v-btn class="edit-btn-cancel" rounded text large @click="showModal=false">Cancel</v-btn>
                    <v-btn class="edit-btn-save" rounded large color="#3c7872" @click="updateUser"> Save</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: 'EditLanguageModal',
    data: () => ({
        spokenLanguage: "",
        level: -1,
        levels: [
            {value: 0, key: 'Not proficient'},
            {value: 1, key: 'Beginner'},
            {value: 2, key: 'Elementary Proficiency'},
            {value: 3, key: 'Limited working proficiency'},
            {value: 4, key: 'Highly proficient'},
            {value: 5, key: 'Native/full proficiency'}
        ],

    }),
    props: 
    // ['showLanguageModal']
    {
        showLanguageModal: {
            type: Boolean
        },
        currentLanguage: {
            type: String
        },
        currentSkill: {
            type: Number
        }
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        showModal: {
            get () {
                return this.showLanguageModal
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
               return this.$emit("close")
            }
        },
        skillProp: {
            get () {
                return this.currentSkill
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
                this.level = this.currentSkill
                return this.currentSkill
            }
        }
    },
    methods: {
        updateSpokenLanguage(e) {
            this.spokenLanguage = e
        },
        updateLevel(e) {
            this.level = e
        },
        async updateUser () {
            let tmp = this.setFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            // this.$store.dispatch('getUser')
            this.$store.getters.activeUser
            this.showModal = false
        },
        setFields (stateUser) {
            if (this.spokenLanguage === "") {
                this.spokenLanguage = this.currentLanguage
            }
            if (this.level === -1) {
                this.level = this.currentSkill
            }
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            for (let i = 0; i < tmp.languageCodes.length; i++) {
                if (tmp.languageCodes[i] === this.currentLanguage && tmp.languageSkills[i] === this.currentSkill) {
                    tmp.languageCodes[i] = this.spokenLanguage
                    tmp.languageSkills[i] = this.level
                }
            }
            // tmp.languageCodes[tmp.languageCodes.length] = this.spokenLanguage
            // tmp.languageSkills[tmp.languageSkills.length]= this.level
            // console.log("tmpUser after change: ", tmp)
            return tmp
        },
        async deleteLanguage () {
            let tmp = this.removeFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            // this.$store.dispatch('getUser')
            this.$store.getters.activeUser
            this.showModal = false
        },
        removeFields (stateUser) {
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            for (let i = 0; i < tmp.languageCodes.length; i++) {
                if (tmp.languageCodes[i] === this.currentLanguage && tmp.languageSkills[i] === this.currentSkill) {
                    // tmp.languageCodes[i] = this.spokenLanguage
                    // tmp.languageSkills[i] = this.level
                    tmp.languageCodes.splice(i, 1)
                    tmp.languageSkills.splice(i, 1)
                    break
                }
            }
            // tmp.languageCodes[tmp.languageCodes.length] = this.spokenLanguage
            // tmp.languageSkills[tmp.languageSkills.length]= this.level
            return tmp
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 10px;
}

</style>
