<template>
    <v-row class="home">
      <v-col class="this-right">
        <v-list-item v-if="this.showNone"> 
                <v-row class="right-row">
                    <v-card class="card rounded-xl" min-width="90%">
                        <v-card-text> 
                            <v-row class="right-row-edit no-wrap">
                                <v-if></v-if>
                                <strong> {{$store.state.currentClassCourseName}}</strong> 
                            </v-row>
                            <v-row class="right-row-edit no-wrap">
                                <strong> {{$store.state.currentClassStartDate}} - {{$store.state.currentClassEndDate}}</strong> 
                            </v-row>
                            <v-divider style="margin-left: 5%; margin-right: 5%; margin-top: 15px; margin-bottom: 15px"></v-divider>
                            <v-row class="right-row-edit no-wrap">
                                <p> No enrollments.</p>
                            </v-row> 
                            <v-row class="right-row-edit no-wrap">
                                <v-btn rounded color="whitesmoke" elevation-10 class="goBtn" @click="enrolNav">Enrol students <v-icon>mdi-arrow-right</v-icon></v-btn>
                            </v-row> 
                        </v-card-text>

                        <!-- We are no longer sharing classes over whatsapp -->
                        <!-- <v-card-text>
                            <v-row class="header-row">
                                <ShareNetwork
                                    network="Whatsapp"
                                    title="Welcome to W4AL! Follow this link to enroll in the class."
                                    :description="'https://ep.w4al.services/classenrolment/' + activeUser.givenName + '|' + activeUser.familyName + '|' + this.course + '|' + this.dates"
                                    url=""
                                >
                                    <v-btn
                                        class="this-share-btn"
                                        rounded
                                        text
                                        small
                                        v-if="this.$store.state.isAuthenticated"
                                        >
                                        Share invite
                                        <v-icon style="margin: 2px;">mdi-share-variant</v-icon>
                                    </v-btn>
                                </ShareNetwork>
                            </v-row>
                        </v-card-text> -->
                    </v-card>
                </v-row>
            </v-list-item>
            <v-col cols=12 v-else>
                    <v-card class="rounded-xl" min-width="90%" color="#F4F4F4">
                        <v-card-title primary-title class="black--text lighten">
                            <h4>
                                Enrollments in this class
                            </h4>
                        </v-card-title>
                        <v-divider style="margin-left:5%; margin-right:5%;"> </v-divider>
                        <v-card-text>
                            <strong> {{$store.state.currentClassCourseName}}</strong> 
                            <br>
                            <strong> {{classDates()}}</strong> 
                            <br>
                            <strong v-if="enrollments.length === 1"> {{enrollments.length}} enrollment </strong>
                            <strong v-else> {{enrollments.length}} enrollments </strong>
                            <br>
                            <strong :key="numPayments"> {{numPayments}} paid </strong>
                            <br>
                            <strong> {{numReviewed}} reviewed </strong>
                        </v-card-text>
                    </v-card>
                    <br>
              <div v-if="!loading" >
                    <div v-for="i in enrollments.length" :key="i.student">
                        <div style="margin-bottom: 20px">
                          <class-enrollment-card
                              :enrollment="enrollments[i-1]"
                              :student="students[i-1]"
                          ></class-enrollment-card>
                        </div>
                        
                        <!-- <v-card v-if="reviewing[i-1]" class="card rounded-xl" v-bind:style='{"border-top" : (paidSwitch ? "10px solid #3c7872 !important" : "10px solid #9A0000 !important" )}' elevation-24 min-width="90%">
                            <v-card-title> 
                                <v-row class="right-row-edit">
                                    <v-col cols=9>
                                        <div> <p class="student-header-text"> {{students[i-1].givenName}}</p> <p class="student-phone-text"> {{students[i-1].phoneNumber}} </p> </div>
                                    </v-col>
                                    <v-col cols=3>
                                        <v-switch
                                            class="switch"
                                            v-model="payments[i-1]"
                                            inset
                                            color="#008D1F"
                                            @change="conditionalMemberUpdate(enrollments[i-1].name, enrollments[i-1])"
                                        ></v-switch>
                                        <p class="payments">{{payments[i-1] ? 'Paid' : 'Not paid'}}</p>
                                    </v-col>
                                </v-row> 
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols=9>
                                        <v-btn class="review-btn" elevation-24 xl rounded color="#6D6D6D">Submit</v-btn>
                                    </v-col>
                                    <v-col cols=3>
                                        <v-btn
                                            class="this-cancel-btn"
                                            rounded
                                            text
                                            small
                                            @click="setReviewing(i-1, true)"
                                            v-model="reviewing[i-1]"
                                        > Cancel
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card> -->

                        <!-- <v-card class="card rounded-xl" v-bind:style='{"border-top" : (paidSwitch ? "10px solid #3c7872 !important" : "10px solid #9A0000 !important" )}' elevation-24 min-width="90%">
                            <v-card-title> 
                                <v-row class="right-row-edit">
                                    <v-col cols=9>
                                        <div> <p class="student-header-text"> {{students[i-1].givenName}}</p> <p class="student-phone-text"> {{students[i-1].phoneNumber}} </p> </div>
                                    </v-col>
                                    <v-col cols=3>
                                        <v-switch
                                            class="switch"
                                            v-model="payments[i-1]"
                                            inset
                                            color="#008D1F"
                                            @change="conditionalMemberUpdate(enrollments[i-1].name, enrollments[i-1])"
                                        ></v-switch>
                                        <p class="payments">{{payments[i-1] ? 'Paid' : 'Not paid'}}</p>
                                    </v-col>
                                </v-row> 
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols=9>
                                        <v-btn @click="setReviewing([i-1], true)" class="review-btn" elevation-24 xl rounded color="#6D6D6D"> Review </v-btn>
                                    </v-col>
                                    <v-col cols=3>
                                        <v-btn
                                            class="this-cancel-btn"
                                            rounded
                                            text
                                            small
                                            color="white"
                                        ><v-icon large @click="deleteUser(enrollments[i-1].name, enrollments[i-1])" style="margin: 2px;">mdi-delete-outline</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-expand-transition>
                                <v-card
                                    v-if="reviewing[i-1]"
                                    class="transition-fast-in-fast-out v-card--reveal"
                                    style="height: 100%;"
                                >
                                    <v-card-text class="pb-0">
                                    <p class="text-h4 text--primary">
                                        Origin
                                    </p>
                                    <p>late 16th century (as a noun denoting a place where alms were distributed): from medieval Latin eleemosynarius, from late Latin eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’ </p>
                                    </v-card-text>
                                    <v-card-actions class="pt-0">
                                    <v-btn
                                        text
                                        color="teal accent-4"
                                        @click="reviewing[i-1] = false"
                                    >
                                        Close
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-expand-transition>
                        </v-card> -->
                    </div>
              </div>
            </v-col>
      </v-col> 
    </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {LIST_ENROLLMENTS, GET_MEMBER} from '../../apollo/queries';
import { apolloClient } from "../../main"
import ClassEnrollmentCard from "../../components/ClassEnrollmentCard.vue"
export  default {
    components: { ClassEnrollmentCard },
    name: 'ViewClassEnrollments',
    mounted () {
        this.getList()
    },
    props: {
        classId: {type: String},
        course: {type: String},
        startDate: {type: String},
        endDate: {type: String}
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
    },
    data: () =>  ({
        enrollments: [],
        students: [],
        payments: [],
        showNone: false,
        paidSwitch: false,
        reviewing: [],
        numPayments: 0,
        numReviewed: 0,
        loading: true,
    }),
    methods: {
        enrolNav () {
            this.$router.push({name: 'EnrolStudents'})
        },
        async getList () {
            let tmpId 
            if (this.classId === undefined) {
                tmpId = this.$route.params.id
            } else {
                tmpId = this.classId
            }
             await apolloClient.query({
                query: LIST_ENROLLMENTS,
                variables: {
                    filter: tmpId,
                    tag: "Class",
                    parent: this.$store.state.currentClassCourseName,
                }
                }).then(async response => {
                    if (response.data.listEnrollments.length === 0) {
                        this.showNone = true
                    }
                    for (var i = 0; i < response.data.listEnrollments.length; i++) {
                        let s = response.data.listEnrollments[i]
                        this.enrollments.push(s)
                        this.payments.push(this.enrollments[i].paid)
                        this.reviewing.push(false)
                        if (this.enrollments[i].paid) {
                            this.numPayments += 1
                        }
                        if (this.enrollments[i].enrollmentReport !== null) {
                            this.numReviewed += 1
                        }
                        await this.getMember(s.student)
                    }
                    this.loading = false;
                })
        },
        async getMember (s) {
            await apolloClient.query({
                query: GET_MEMBER,
                variables: {
                    name: s,
                },
            }).then(response => {
                // console.log("getMember(s):", response.data)
                this.students.push(response.data.getMember)
            })
        },
        classDates() {
            var months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            let [year, month, day] = this.$store.state.currentClassStartDate.split('-');
            let sd = new Date(+year, +month-1, +day); 
            [year, month, day] = this.$store.state.currentClassEndDate.split('-');
            let ed = new Date(+year, +month-1, +day); 
            return sd.getDate() + " " + months[sd.getMonth()] + " " + sd.getFullYear() + " - " + ed.getDate() + " " + months[ed.getMonth()] + " " + ed.getFullYear()
        }
    }
  }
</script>

<style scoped>
.home {
    margin: 0 auto;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    justify-content: center;

}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
  margin: 2px;
}
.right-row-edit {
    width: 100%;
    justify-content: left;
    margin: 1px;
}
.header-row{
    justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.this-list {
    justify-content: center;
    background: rgb(143, 166, 155);
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.goBtn {
    text-transform: none;
    color: #3C7872;
}
.this-signin-btn {
  color: #3c7872;
}
.this-right {
  box-sizing: border-box;
  background: rgb(143, 166, 155);
  color: rgb(143, 166, 155);
}
.active-btn{
    background: #3C7872;
}
.btnRow {
    justify-content: center;
    align-content: center;
    margin: 20px;
}
.cardRow {
    justify-content: center;
    align-content: center;
    margin: 12px;
}
.chip {
    margin-left: 10px;
    margin-right: 10px;
}
.title {
    justify-content: center;
    align-content: center;
    margin-top: 5px;
}
.create-btn{
    padding: 20px;
    color: #3c7872;
}
.card{
    padding: 20px;
    margin: 5px;
    background: #D9D9D9;
}
.no-wrap{
    white-space: nowrap;
    justify-content: center;
}
.this-delete-btn {
    color: white;
}
.this-delete-btn {
    color: white;
    text-transform: initial;
}
.student-header-text {
    color: #6D6D6D;
    font-weight: 501;
    font-size: 1.2em;
    margin-bottom:0px
}
.student-phone-text {
    color: #6D6D6D;
    font-size: 0.7em;
}
.review-btn {
    background-color: #fff !important;
    text-transform: initial;
    font-weight: 501;
    font-size: 0.9em;
    color: #6D6D6D;
    width: 90%;
    max-width: 250px;
    min-width: 45%;
}
.switch {
    margin-top: 0px;
    margin-bottom:-20px
}
.payments{
    color: #6D6D6D;
    font-size: 0.5em;
    margin-bottom: -20px;
    white-space: nowrap;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
  </style>