<template>
  <section class="sectioncontainer">
    <v-row class="signin">
      <!-- <v-col cols="8" class="left"> -->
      <!-- </v-col> -->
      <v-col cols="12" class="right">
        <div v-if="Verified">
            <h2 class="h2"> Verified </h2>
            <v-icon  color="white"> mdi-account-check-outline </v-icon>
        </div>
        <div v-else>
            <h2 class="h2"> Verification failed </h2>
            <v-row> 
                <v-btn class="signin-btn" :to="{name: 'Register'}" rounded color="white">Try again</v-btn>
            </v-row>
        </div>
        
        
      </v-col> 
    </v-row>
  </section>
</template>

<script>
    export default {
        name: 'CheckVerify',
        props: {
            Verified: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>
.sectioncontainer {
    background: #fff;
    width: 100%;
}
.signin {
  margin: 0 auto;
  height: 92vh;
}
.h2 {
  text-align: center;
  margin-top: 200px;
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:120px;
}
.infoh5{
  color: #d1d1d1;
  text-align: center;
}
.h5{
  text-align: center;
  padding: 10px
}
  </style>