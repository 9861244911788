import Vue from "vue";
import { GET_MEMBER, SEARCH_STUDENT } from "@/apollo/queries"
import { apolloClient } from '@/main'
import { UPDATE_MEMBER } from "@/apollo/mutations.js";

// Initialize your state
const initialState = {
    user_key: "",
    list: {},
    students: {}
};

const state = { ...initialState };

const getters = {
    activeUser(state) {
        return state.list[state.user_key];
    },
    getStudent: (state) => (studentId) => {
        return state.students[studentId] || null;
    },
}
const actions = {
    async fetchStudent({ commit, state }, studentId) {
        if (!state.students[studentId]) {
            try {
                const response = await apolloClient.query({
                    query: SEARCH_STUDENT,
                    variables: {
                        request: {
                            memberId: studentId,
                        }
                    }
                });
                if (response.data) {
                    commit('setStudent', { studentId, student: response.data.searchStudent.member, firstName: response.data.searchStudent.GivenName, surname: response.data.searchStudent.FamilyName});
                } else {
                    console.error("fetchStudent: No data in the response");
                }
            } catch(error) {
                console.error("Error fetching student data:", error);
            }
        }
    },

    async getUser({ commit, rootState }) {
        try {
            let user = await apolloClient.query({
                query: GET_MEMBER,
                variables: {
                    name: rootState.phoneNumber
                },
            });

            if (user.data.getMember !== null) {
                const key  = user.data.getMember.phoneNumber.replace(/ /g, "");

                let tmpUser = {}
                tmpUser[key] = user.data.getMember
                commit("setUserKey", key);
                commit("setUserList", tmpUser);

            } else {
                console.error("getUser - user.data.getMember is null: ", user.data.getMember);
            }
        } catch (error) {
            console.error("Could not fetch user", error);
        }
    },
    async updateUser({ commit, rootState }, mask) {
        await apolloClient.mutate({
            mutation: UPDATE_MEMBER,
            variables: {
                name: rootState.phoneNumber,
                member: mask
            }
        }).then(user => {
            let tmpUser = JSON.parse(JSON.stringify(user));
            if (tmpUser && tmpUser.data.updateMember){
                if (user.data.updateMember.birthDate !== null) {
                    tmpUser.data.updateMember.birthDate = convertDOB(user.data.updateMember.birthDate);
                }
                const newPhoneNumber = user.data.updateMember.phoneNumber;
                const key = newPhoneNumber.replace(/ /g, "");
                commit("updateUserList", { key, tmpUser });

                // If the phone number has changed, update the user_key in the state as well
                if (newPhoneNumber !== rootState.phoneNumber) {
                    commit("setUserKey", key);
                }
            } else{
                console.error('updateUser: tmpUser is invalid:', tmpUser);
                console.trace('updateUser - call stack:');
            }
        }).catch(error => {
            console.error('Error in updateUser:', error);
        });
    },
};
const mutations = {
    setStudent(state, { studentId, student }) {
        state.students[studentId] = student;
    },
    deleteUserKey(state, key) {
        Vue.delete(state.list, key)
    },
    setUserKey(state, key) {
        state.user_key = key;
    },
    setUserList(state, list) {
        state.list = list;
    },
    updateUserList(state, {key, tmpUser}) {
        Vue.set(state.list, key, tmpUser.data.updateMember);
    },
    RESET_USER_STATE(state) {
        Object.assign(state, initialState);
    }
};

// utility functions
function convertDOB (dob) {
    let str = dob.split(" ")
    var year, month, day
    if (str.length === 5) {
        year = str[0].split(":")[1]
        month = str[2].split(":")[1]
        day = str[4].split(":")[1]
    } else {
        year = str[0].split(":")[1]
        month = str[1].split(":")[1]
        day = str[2].split(":")[1]
    }

    if (parseInt(month) < 10) {
        month = "0" + month
    }
    if (parseInt(day) < 10) {
        day = "0" + day
    }
    return year + "-" + month + "-" + day
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
