<template>
  <v-dialog v-model="localDialog" persistent max-width="290">
    <v-card>
      <v-card-title class="headline">Are you sure?</v-card-title>
      <v-card-text>
        You are about to permanently delete this enrollment. Do you want to proceed?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1"
               @click="closeDialog">No</v-btn>
        <v-btn color="red darken-1"
               @click="emitConfirmDelete">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EnrollmentDialog',
  props: {
    dialog: Boolean,
    confirmDelete: Boolean,
  },
  created() {
    // console.log('Initial value of dialog:', this.dialog);
  },
  data() {
    return {
      localDialog: this.dialog,
    };
  },
  watch: {
    dialog(newVal) {
      console.log('Dialog prop changed:', newVal); // Debug log
      this.localDialog = newVal;
    },
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
      this.$emit('update:dialog', false);
    },
    emitConfirmDelete() {
      this.$emit('confirmDelete'); // Emit the event to the parent
      this.closeDialog();
    },
  },
}
</script>
