import gql from "graphql-tag";

export const CREATE_MEMBER = gql`
    mutation createMember ($name: String!, $phoneNumber: String!, $email: String!, $gender: Int, $familyName: String!, $givenName: String!, $race: Int, $birthDate: String, $role: Int!, $baseHub: String!, $identificationNumber: String, $highSchoolGraduate: Boolean, $universityGraduate: Boolean , $grow: GrowInput) {
        createMember(name: $name, phoneNumber: $phoneNumber, email: $email, gender: $gender, familyName: $familyName, givenName: $givenName, race: $race, birthDate: $birthDate, role: $role, baseHub: $baseHub identificationNumber: $identificationNumber highSchoolGraduate:$highSchoolGraduate universityGraduate:$universityGraduate grow: $grow)
    }
`;

export const CREATE_CLASS = gql`
  mutation createClass ($course: String!, $name: String!, $startDate: String, $endDate: String, $facilitator: String!, $classFormat: Int!, $hub: String!, $enrollmentsCount: Int) {
    createClass(course: $course, name: $name, startDate: $startDate, endDate: $endDate, facilitator: $facilitator, classFormat: $classFormat, hub: $hub, enrollmentsCount: $enrollmentsCount)
  }
`;

export const CREATE_ENROLLMENT = gql`
  mutation createEnrollment ($course: String!, $enrolledClass: String!, $name: String!, $student: String!, $paid: Boolean!) {
    createEnrollment(course: $course, enrolledClass: $enrolledClass, name: $name, student: $student, paid: $paid)
  }
`;

export const DELETE_ENROLLMENT = gql`
  mutation deleteEnrollment($name: String!, $classId: String!, $courseId: String!) {
    deleteEnrollment(name: $name, classId: $classId, courseId: $courseId)
  }
`;

export const DELETE_CLASS = gql`
  mutation deleteClass($name: String!, $course: String!) {
    deleteClass(name: $name, course: $course)
  }
`;

// Issue 63: Create inidivudal mutations per report type to create a report
// export const CREATE_REPORT = gql`
//   mutation createReport ($name: String!, $member: String!, $reporter:String, $reportType:Int!, $selfEsteemCheckin: SelfEsteemCheckinInput, $entitlementCheckin: EntitlementCheckinInput, $employmentHopeCheckin:EmploymentHopeCheckinInput, $enrollmentReport: EnrollmentReportInput, $studentEmploymentReport: StudentEmploymentReportInput) {
//     createReport(name: $name, member: $member, reporter: $reporter, reportType: $reportType, selfEsteemCheckin:$selfEsteemCheckin, entitlementCheckin:$entitlementCheckin, employmentHopeCheckin:$employmentHopeCheckin, enrollmentReport: $enrollmentReport, studentEmploymentReport: $studentEmploymentReport)
//   }
// `;
export const CREATE_ENROLLMENT_REPORT = gql`
    mutation createEnrollmentReport ($name: String!, $member: String!, $reporter:String, $reportType:Int!, $enrollmentReport: EnrollmentReportInput) {
        createReport(name: $name, member: $member, reporter: $reporter, reportType: $reportType, enrollmentReport: $enrollmentReport)
    }
`;

export const CREATE_SELF_ESTEEM_CHECKIN = gql`
    mutation createSelfEsteemCheckin ($name: String!, $member: String!, $reporter:String, $reportType:Int!, $selfEsteemCheckin: SelfEsteemCheckinInput) {
        createReport(name: $name, member: $member, reporter: $reporter, reportType: $reportType, selfEsteemCheckin: $selfEsteemCheckin)
    }
`;

export const CREATE_ENTITLEMENT_CHECKIN = gql`
    mutation createEntitlementCheckin ($name: String!, $member: String!, $reporter:String, $reportType:Int!, $entitlementCheckin: EntitlementCheckinInput) {
        createReport(name: $name, member: $member, reporter: $reporter, reportType: $reportType, entitlementCheckin: $entitlementCheckin)
    }
`;

export const CREATE_EMPLOYMENT_HOPE_CHECKIN = gql`
    mutation createEmploymentHopeCheckin ($name: String!, $member: String!, $reporter:String, $reportType:Int!, $employmentHopeCheckin: EmploymentHopeCheckinInput) {
        createReport(name: $name, member: $member, reporter: $reporter, reportType: $reportType, employmentHopeCheckin: $employmentHopeCheckin)
    }
`;

export const CREATE_STUDENT_EMPLOYMENT_REPORT = gql`
    mutation createStudentEmploymentReport ($name: String!, $member: String!, $reporter:String, $reportType:Int!, $studentEmploymentReport: StudentEmploymentReportInput) {
        createReport(name: $name, member: $member, reporter: $reporter, reportType: $reportType, studentEmploymentReport: $studentEmploymentReport)
    }
`;

export const CREATE_OWN_BUSINESS_REPORT = gql`
    mutation createOwnBusinessReport ($name: String!, $member: String!, $reporter:String, $reportType:Int!, $ownBusinessReport: OwnBusinessReportInput) {
        createReport(name: $name, member: $member, reporter: $reporter, reportType: $reportType, ownBusinessReport: $ownBusinessReport)
    }
`;

export const VERIFY = gql`
mutation verify($code: String!, $verifyPhoneNumber: String, $verifyEmail: String , $Sid: String) {
  verify (code: $code, verifyPhoneNumber: $verifyPhoneNumber, verifyEmail: $verifyEmail, Sid: $Sid)
}`;

export const VERIFY_TOTP = gql`
  mutation verifyTOTP($code: String!,$key:String!) {
    verifyTOTP (code: $code, key: $key)
  }`;

export const SEND_VERIFICATION = gql`
mutation sendVerification ($verifyPhoneNumber: String, $verifyEmail: String, $method: String!) {
  sendVerification (verifyPhoneNumber: $verifyPhoneNumber, verifyEmail: $verifyEmail, method: $method) 
}`;

export const SEND_MESSAGE_TOTP = gql`
  mutation sendMessageTOTP ($verifyPhoneNumber: String) {
    sendMessageTOTP (verifyPhoneNumber: $verifyPhoneNumber)
  }`;

export const WELCOME_USER = gql`
mutation welcomeUser ($phoneNumber: String, $email: String, $method: String!, $body: String!) {
  welcomeUser (phoneNumber: $phoneNumber, email: $email, method: $method, body: $body) 
}`;

export const UPDATE_MEMBER_DETAILS = gql`
  mutation UpdateMemberDetails($name: String!, $member: MemberDetailsInput!) {
    updateMemberDetails(name: $name, member: $member) {
        name
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation updateMember ($name: String!, $member: MemberInput!) {
    updateMember(name: $name, member: $member) {
      name
      phoneNumber
      email
      gender
      languageCodes
      languageSkills
      race
      givenName
      familyName
      role
      nameShort
      birthDate
      addressLines
      sublocality
      locality
      postalCode
      baseHub
      highSchoolGraduate
      universityGraduate
      identificationNumber
      grow {
        profile
        classId
        enrollments
        workExperience {
          jobTitle
          company
          duration
          description
        }
        education {
          title
          school
          duration
          description
          educationLevel
          qualification
          state
          industry
        }
        skills {
          skill
          skillLevel
          description
          verified
        }
      }
    }
  }
`

export const UPDATE_ENROLLMENT = gql`
  mutation updateEnrollment($course: String!, $enrolledClass: String!, $name: String!, $enrollment: EnrollmentInput!) {
    updateEnrollment(course: $course, enrolledClass: $enrolledClass, name: $name, enrollment: $enrollment) {
      course
      enrolledClass
      name
      student
      paid
      preCheckin
      postCheckin
      enrollmentReport
    }
  }`

export const AUTHENTICATE_USER = gql`
  mutation authenticateUser ($method: Int!, $contactDetail: String!) {
    authenticateUser(method: $method, contactDetail: $contactDetail)
  }`

export const VERIFY_AUTHENTICATION = gql`
  mutation verifyAuthentication ($token: String!) {
    verifyAuthentication(token: $token)
  }`

export const UPDATE_ENROLLMENT_REPORT = gql`
    mutation updateEnrollmentReport ($report: ReportInput!) {
        updateReport(report: $report) {
          name
          member
          reporter
          reportType
          enrollmentReport {
            enrollment
            classId
            courseId
            feedback
            excellence
            attitude
            communication
            interaction
            listening
            interview
            morals
            confidence
            timeManagement
            npsScore
            studentOutcome
            salvationCommitment
            skillsCourse
            furtherEducation
            startedBusiness
            discipleshipLinked
            priorSalvation
            interviewSent
            r50Challenge
            r50ChallengeMoney
            r50ChallengeComment
            deepDiveChallenge
            deepDiveChallengeComment
          }
        }
    }
`;

export const UPDATE_STUDENT_EMPLOYMENT_REPORT = gql`
  mutation updateStudentEmploymentReport ($report: ReportInput!) {
    updateReport(report: $report) {
      name
      member
      reporter
      reportType
      studentEmploymentReport {
        employerName
        jobTitle
        startDate
        endDate
        reasonForLeaving
        currentlyEmployed
        employmentType
        yearsLookingForJob
        salaryRange
        jobSatisfaction
        jobOpportunities
        promoted
        willingToShareProofOfEmployment
        sharedProofOfEmployment
        proofOfEmploymentType
        referenceName
        referenceEmail
        referenceRelationship
        studentPreferredPhoneNumber
        studentHub
        studentFacilitatorName
        enterToWinPrizes
        createdBy
        updatedBy
        hub
        reviewedByFacilitator
        facilitatorComment
      }
    }
  }
`;

// TODO: Implement this method bridge
export const UPDATE_CLASS = gql`
mutation updateClass ($name: String!, $class: ClassInput!) {
  updateClass(name: $name, class: $class) {
    course
    name
    startDate
    endDate
    facilitator
    classFormat
    hub
    employedCount
    savedCount
    furtherEducationCount
      enrollmentsCount
  }
}`