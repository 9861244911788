<template>
    <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable>
        <v-card class="rounded-xl">
            <v-card-title class="justify-center grey lighten-3 title-space">
                Create Skill
            </v-card-title>
            <v-card-text>
                <p class="textArea">Provide details of a skill you possess. This should, ideally, be something useful to a business. </p>
                <p>For example: Microsoft Word</p>
                <v-form class="form">
                    <v-row class="info-right-row">  
                        <v-text-field
                            class="rounded-text"
                            label="Skill"
                            required
                            outlined
                            filled
                            dense
                            @input="updateLocalSkill($event)"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-select
                            @input="updateLocalSkillLevel($event)"
                            :items="levels"
                            item-text="key"
                            item-value="value"
                            label="Select skill level"
                            filled
                            class="rounded-text"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-row>
                    <v-row class="info-right-row">  
                        <v-textarea
                            class="rounded-text"
                            label="Information (recommended)"
                            required
                            filled
                            rows="2"
                            @input="upadteLocalDesc($event)"
                        ></v-textarea>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row class="right-row-edit"> 
                    <v-btn class="edit-btn-cancel" rounded text large @click="showModal=false">Cancel</v-btn>
                    <v-tooltip bottom v-if="skill === '' || skillLevel === -1">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-on="on">
                                    <v-btn class="edit-btn-save" v-bind="attrs" disabled rounded large color="#3c7872" @click="updateSkill"> Save</v-btn>
                                </div>
                            </template>
                            <span>Complete the form.</span>
                        </v-tooltip>
                    <v-btn class="edit-btn-save" v-else rounded large color="#3c7872" @click="updateSkill"> Save</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: 'SkillModal',
    data: () => ({
        skill: "",
        skillLevel: -1,
        description: "",
        level: -1,
        levels: [
            {value: 0, key: 'Novice'},
            {value: 1, key: 'Beginner'},
            {value: 2, key: 'Skillful'},
            {value: 3, key: 'Experienced'},
            {value: 4, key: 'Expert'}
        ],
        newSkill: {
            skill: "",
            skillLevel: -1,
            description: ""
        }
    }),
    props: 
    // ['showLanguageModal']
    {
        showSkillModal: {
            type: Boolean
        }
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        showModal: {
            get () {
                return this.showSkillModal
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
               return this.$emit("close")
            }
        },
    },
    methods: {
        updateLocalSkill(e) {
            this.skill = e
        },
        updateLocalSkillLevel(e) {
            this.skillLevel = e
        },
        upadteLocalDesc (e) {
            this.description = e
        },
        async updateSkill () {
            let tmp = this.setFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            this.$store.dispatch('user/getUser')
            this.$store.getters.activeUser
            this.showModal = false
            this.resetValues()
        },
        setFields (stateUser) {
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            if (tmp.grow.skills.length === 0) {
                this.newSkill.skill = this.skill
                this.newSkill.skillLevel = this.skillLevel
                this.newSkill.description = this.description
                this.newSkill.verified = false
                tmp.grow.skills[0] = this.newSkill
                return tmp 
            }
            tmp.grow.skills[tmp.grow.skills.length] = JSON.parse(JSON.stringify(tmp.grow.skills[tmp.grow.skills.length-1]))
            // for (let i = 0; i < tmp.grow.skills.length; i++) {
            //     console.log("work: ", tmp.grow.skills[i])
            // }
            //  = tmp.grow.workExperience[tmp.grow.workExperience.length -1]
            tmp.grow.skills[tmp.grow.skills.length-1].skill = this.skill
            tmp.grow.skills[tmp.grow.skills.length-1].skillLevel = this.skillLevel
            tmp.grow.skills[tmp.grow.skills.length-1].description = this.description
            tmp.grow.skills[tmp.grow.skills.length-1].verified = false
            // console.log("tmp user after change: ", tmp)
            return tmp
        },
        resetValues() {
            this.skill = ""
            this.skillLevel = -1
            this.description = ""
            this.verified = false
        }
    }
}
</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.this-right {
  box-sizing: border-box;
  background: #3C7872;
  color: #3c7872;
  min-height: 94vh;
} 
.right {
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  display: flex;
  align-items: center;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.left-row {
  width: 100%;
  justify-content: left;
  padding: 20px;
}
.left-row-small {
  width: 100%;
  justify-content: left;
  padding: 30px;
  margin-bottom: 50px;
}
.this-signin-btn {
  width: 51%;
  color: #3c7872;
  margin-bottom: 15px;
}
.this-enter-btn {
  width: 51%;
  color: white;
  margin-bottom: 15px;
}
.text {
  justify-content: center;
  padding: 30px;
}
.align {
  justify-content: center;
  width: 100%;
}
.buttons {
  margin: 0;
  justify-content: center;
}
.h2 {
  text-align: center;
  color: rgb(196, 196, 196);
  width: 100%;
}
.h2-bold {
  text-align: center;
  color: rgba(255, 255, 255);
  width: 100%;
}

.img{
  margin-top: 100px;
}
.active-btn{
    background: #3C7872;
}
.edit-btn {
  width: 80%;
  color: #000000;
  background: #ffffff;
  margin: 10px;
  margin-top: 40px;
}
.name{
  font-weight: 700;
}
.rounded-text{
  border-radius: 12px;
}
.title-space{
  margin-bottom: 0px;
}
.save-chip {
  color: white;
}
.cancel-chip {
  color: #3c7872;
}
.edit-btn-save{
    padding: 20px;
    color: white;
}
.edit-btn-cancel{
    padding: 20px;
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 20px;
    padding: 20px;
}
.divider {
    margin-bottom: 20px;
}
.addressCol {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
}
.textArea{
    border-radius: 12px;
    margin-top: 20px;
}
.center {
    justify-content: center;
}
.form {
    margin-top: 30px;
}
.addBtn {
    color: #3C7872;
    margin: 20px;
}
.btnRow {
    justify-content: center;
    align-content: center;
}
.info-row {
    background:#ececec;
    border-radius: 12px;
    padding: 10px;
}
.card{
    margin: 20px;
}
</style>