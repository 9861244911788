import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import store from './store'
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core'
import VueApollo from 'vue-apollo'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from "@apollo/client/link/context"
import VueSocialSharing from 'vue-social-sharing'
import { REFRESH_TOKEN } from "./apollo/queries"
import Vuelidate from 'vuelidate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoins } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.use(VueSocialSharing);
Vue.use(VueApollo)
Vue.use(VueTelInput)
Vue.use(Vuelidate)
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faCoins)

Vue.config.productionTip = false

// HTTP connection to the API
const httpLink = createHttpLink({
  //TODO - Env Production
  // uri: 'https://ep.w4al.services/graphql',
  //TODO - Env Development
  uri: 'http://localhost:8000/graphql'
})

const cache = new InMemoryCache()

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

let authLink = setContext(async (_, { headers }) => {
  let token = store.getters.jwtGetter;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
const errorHandler = (error) => {
  // Handle GraphQL errors
  if (error.graphQLErrors) {
    error.graphQLErrors.map(({ message, locations, path }) =>
        console.log(`GraphQL error: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }

  // Handle network errors
  if (error.networkError) {
    console.log(`Network error: ${error.networkError}`);
  }
};

const loggingLink = new ApolloLink((operation, forward) => {
  // console.log(`GraphQL request: ${operation.operationName}`);
  return forward(operation).map(response => {
    // console.log(`GraphQL response: ${operation.operationName}`, response);
    if (response.errors) {
      response.errors.forEach((error) => {
        console.error(`Error message during ${operation.operationName}: ${error.message}`);
        if (error.extensions) {
          console.error(`Error extensions: ${JSON.stringify(error.extensions)}`);
        }
      });
    }
    return response;
  });
});


const apolloClient = new ApolloClient({
  link: ApolloLink.from([loggingLink, authLink, httpLink]),
  cache,
  defaultOptions: defaultOptions,
  onError: errorHandler
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

function isTokenExpired(token) {
  if (token !== "") {
    const expiry = (JSON.parse(window.atob(token.split('.')[1]))).StandardClaims.exp;
    const currentTime = Math.floor((new Date).getTime() / 1000);

    // console.log(`Token expiry: ${expiry}`);
    // console.log(`Current time: ${currentTime}`);

    return currentTime >= expiry;
  } else {
    return false;
  }
}

async function generateToken(token) {
  const phoneNumber = JSON.parse(window.atob(token.split('.')[1])).StandardClaims.sub;
  try {
    let refreshToken = store.getters.refreshGetter;
    if (typeof refreshToken !== 'string' || refreshToken === null) {
      throw new Error('Refresh token is null or not a string');
    }
    const response = await apolloClient.query({
      query: REFRESH_TOKEN,
      variables: {
        refreshToken: store.getters.refreshGetter,
      },
    });
    store.commit('setJWT', response.data.refreshToken[0]);
    store.commit('setRefreshToken', response.data.refreshToken[1]);
    store.commit('setPhoneNumber', phoneNumber);
  } catch (err) {
    console.error(err);
    throw new Error('Failed to refresh token');
  }
}

new Vue({
  Vuelidate,
  vuetify,
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')

export {apolloClient}
export { isTokenExpired, generateToken };
