import { render, staticRenderFns } from "./RegisterPage.vue?vue&type=template&id=70a7fe25&scoped=true&"
import script from "./RegisterPage.vue?vue&type=script&lang=js&"
export * from "./RegisterPage.vue?vue&type=script&lang=js&"
import style0 from "./RegisterPage.vue?vue&type=style&index=0&id=70a7fe25&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a7fe25",
  null
  
)

export default component.exports