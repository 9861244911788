<template>
    <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable>
        <v-card >
            <v-card-title class="justify-center grey lighten-3 title-space">
                Edit education
            </v-card-title>
            <v-card-text>
                <p class="textArea">Provide details of education you have completed. It can be a school grade (matric/grade 11), degree or diploma etc.</p>
                <v-form class="form">
                    <v-row class="info-right-row">  
                        <v-text-field
                            class="rounded-text"
                            label="Grade/Degree/Diploma/Field Of Study"
                            required
                            outlined
                            filled
                            dense
                            @input="updateLocalTitle($event)"
                            :value="this.localTitle"
                        ></v-text-field>
                    </v-row>
                    <v-row class="info-right-row">  
                        <v-text-field
                            class="rounded-text"
                            label="School/University"
                            required
                            outlined
                            filled
                            dense
                            @input="updateLocalSchool($event)"
                            :value="this.localSchool"
                        ></v-text-field>
                    </v-row>
                    <v-row class="info-right-row">
                        <v-select
                            class="rounded-text"
                            :items="educationLevels"
                            item-text="key"
                            item-value="value"
                            label="Education type"
                            outlined
                            filled
                            dense
                            :value="this.localEducationLevel"
                            @input="updateEducationLevel($event)"
                            ></v-select> 
                    </v-row>
                    <v-row class="info-right-row">
                        <v-select
                            class="rounded-text"
                            v-if="this.localEducationLevel === 1"
                            :items="schoolQualifications"
                            item-text="key"
                            item-value="value"
                            label="Select your highest qualification"
                            outlined
                            filled
                            dense
                            :value="this.localQualification"
                            @input="updateQualification($event)"
                            ></v-select>
                        <v-select
                            class="rounded-text"
                            v-else-if="this.localEducationLevel === 2"
                            :items="furtherQualifications"
                            label="Select your highest qualification"
                            item-text="key"
                            item-value="value"
                            outlined
                            filled
                            dense
                            :value="this.localQualification"
                            @input="updateQualification($event)"
                            ></v-select> 
                        <v-select
                            class="rounded-text"
                            v-else
                            disabled
                            label="Qualification"
                            outlined
                            filled
                            dense
                            :value="this.localQualification"
                        ></v-select> 
                    </v-row>
                    <v-row class="info-right-row">
                        <v-select
                            class="rounded-text"
                            v-if="this.localEducationLevel === 2"
                            :items="industries"
                            item-text="key"
                            item-value="value"
                            label="Industry"
                            outlined
                            filled
                            dense
                            :value="this.localIndustry"
                            @input="updateIndustry($event)"
                            ></v-select> 
                            <!-- return-object -->
                    </v-row>
                    <!-- <v-row class="duration-row"> 
                        <h4>{{this.currentDuration}}</h4>
                        <v-spacer></v-spacer>
                            <v-icon @click="this.showDuration = !this.showDuration">{{ this.showDuration ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-row> -->
                    <!-- <v-expand-transition> -->
                        <!-- <div v-show="this.showDuration"> -->
                        <v-row class="info-right-row">  
                            <v-select
                                class="rounded-text"
                                outlined
                                filled
                                dense
                                :items="years"
                                label="End year"
                                @input="updateLocalDuration($event)"
                                :value="this.localDuration"
                            ></v-select> 
                        </v-row> 
                        <!-- </div> -->
                    <!-- </v-expand-transition> -->
                    <!-- <v-row>
                        <v-textarea
                            name="input-7-1"
                            filled
                            label="Description (optional)"
                            auto-grow
                            class="textArea"
                            @input="updateDescription($event)"
                            :value="this.currentDescription"
                        ></v-textarea>
                    </v-row> -->
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row class="right-row-edit"> 
                    <v-btn class="edit-btn-cancel" rounded text align-left large @click="deleteEducation"> <v-icon small>mdi-delete</v-icon> Delete</v-btn>
                    <v-btn class="edit-btn-cancel" rounded text large @click="showModal=false">Cancel</v-btn>
                    <v-btn class="edit-btn-save" rounded large color="#3c7872" v-if="localTitle === '' || localSchool === '' || localDuration === '' || localEducationLevel === 0 || localQualification === 0" disabled> Save</v-btn>
                    <v-btn class="edit-btn-save" rounded large color="#3c7872" @click="updateEducation" v-else> Save</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: 'EditEducationModal',
    data: () => ({
        localTitle: "",
        localSchool: "",
        localDuration: "",
        localDesc: "",
        localEducationLevel: 0,
        localQualification: 0,
        localIndustry: 0,
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        showDuration: false,
        months: [
            {value: 1, key: "Jan"},
            {value: 2, key: "Feb"},
            {value: 3, key: "Mar"},
            {value: 4, key: "Apr"},
            {value: 5, key: "May"},
            {value: 6, key: "Jun"},
            {value: 7, key: "Jul"},
            {value: 8, key: "Aug"},
            {value: 9, key: "Sep"},
            {value: 10, key: "Oct"},
            {value: 11, key: "Nov"},
            {value: 12, key: "Dec"},
        ],
        educationLevels: [{value: 1, key:"High School"}, {value: 2, key: "Further Education"}],
        educationLevel: {name:"High School", value: 1},
        schoolQualifications: [{value: 1, key: "Less than Grade 9"}, {value: 2, key: "Grade 9"}, {value: 3, key: "Grade 10"}, {value: 4, key: "Grade 11"}, {value: 5, key: "Grade 12"}],
        furtherQualifications: [{value: 6, key: "Training Course"},{value: 7, key: "Diploma"},{value: 8, key: "Bachelors Degree"},{value: 9, key: "Honours degree"},{value: 10, key: "Masters degree"},{value: 11, key: "Doctorate degree"}],
        qualification: null,
        industries: [{value: 2, key: "Admin and Business Support"}, {value: 28, key: "Architecture"}, {value: 23, key: "Advertising and Marketing"}, {value: 3, key: "Agriculture"}, {value: 5, key: "Arts and Entertainment"}, {value: 6, key: "Construction"}, {value: 27, key: "Customer Service"}, {value: 7, key: "Education"}, {value: 19, key: "Engineering"}, {value: 8, key: "Finance and Insurance"}, {value: 4, key: "Forestry"}, {value: 9, key: "Healthcare"}, {value: 1, key: "Hospitality"}, {value: 25, key: "Human Resources"}, {value: 24, key: "Legal"}, {value: 20, key: "Life Sciences"}, {value: 11, key: "Manufacturing"}, {value: 12, key: "Mining"}, {value: 13, key: "Personal Services"}, {value: 15, key: "Real Estate"}, {value: 16, key: "Retail"}, {value: 14, key: "Security"}, {value: 21, key: "Technology"}, {value: 22, key: "Tourism"}, {value: 17, key: "Transport and Logistics"}, {value: 18, key: "Warehousing"}],
        industry: {value: 0, key: ""},
    }),
    props: 
    // ['showLanguageModal']
    {
        showEducationModal: {
            type: Boolean
        },
        currentTitle: {
            type: String
        },
        currentSchool: {
            type: String
        },
        currentDuration: {
            type: String
        },
        currentDescription: {
            type: String
        },
        currentEducationLevel: {
            type: Number
        },
        currentQualification: {
            type: Number
        },
        currentIndustry: {
            type: Number
        }
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        years () {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1980}, (_, index) => 1981 + index).reverse()
        },
        showModal: {
            get () {
                return this.showEducationModal
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
               return this.$emit("close")
            }
        },
    },
    mounted() {
        this.localTitle = this.currentTitle
        this.localSchool = this.currentSchool
        this.localDuration = parseInt(this.currentDuration)
        this.localEducationLevel = this.currentEducationLevel
        this.localQualification = this.currentQualification
        this.localIndustry = this.currentIndustry
    },
    methods: {
        updateLocalTitle(e) {
            this.localTitle = e
        },
        updateLocalSchool(e) {
            this.localSchool = e
        },
        updateLocalStartMonth (e) {
            this.startMonth = e
        },
        updateLocalStartYear (e) {
            this.startYear = e
        },
        updateLocalEndMonth (e) {
            this.endMonth = e
        },
        updateLocalDuration (e) {
            this.localDuration = e
        },
        updateDescription(e) {
            this.localDesc = e
        },
        updateEducationLevel(e) {
            this.localEducationLevel = e
        },
        updateQualification(e) {
            this.localQualification = e
        },
        updateIndustry(e) {
            this.localIndustry = e
        },
        async updateEducation () {
            let tmp = this.setFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            // this.$store.dispatch('getUser')
            this.$store.getters.activeUser
            this.showModal = false
        },
        setFields (stateUser) {
            if (this.localTitle === "") {
                this.localTitle = this.currentTitle
            }
            if (this.localSchool === "") {
                this.localSchool = this.currentSchool
            }
            if (this.localDesc === "") {
                this.localDesc = this.currentDescription
            }
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            // console.log("tmpUser before language change: ", tmp.grow.education)
            for (let i = 0; i < tmp.grow.education.length; i++) {
                if (tmp.grow.education[i].title === this.currentTitle && tmp.grow.education[i].school === this.currentSchool) {
                    tmp.grow.education[i].title = this.localTitle
                    tmp.grow.education[i].school = this.localSchool
                    tmp.grow.education[i].duration = this.localDuration.toString()
                    tmp.grow.education[i].description = this.localDesc
                    tmp.grow.education[i].educationLevel = this.localEducationLevel
                    tmp.grow.education[i].qualification = this.localQualification
                    tmp.grow.education[i].state = 0
                    tmp.grow.education[i].industry = this.localIndustry
                }
                // console.log("work: ", tmp.grow.education[i])
            }
            //  = tmp.grow.workExperience[tmp.grow.workExperience.length -1]
            // console.log("tmpUser after change: ", tmp)
            return tmp
        },
        async deleteEducation () {
            let tmp = this.removeFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            // this.$store.dispatch('getUser')
            this.$store.getters.activeUser
            this.showModal = false
        },
        removeFields (stateUser) {
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            // console.log("tmpUser before language change: ", tmp.languageCodes)
            for (let i = 0; i < tmp.grow.education.length; i++) {
                // console.log("code: ", tmp.grow.education[i])
                // console.log("skill: ", tmp.grow.education[i])
                if (tmp.grow.education[i].title === this.currentTitle && tmp.grow.education[i].school === this.currentSchool) {
                    tmp.grow.education.splice(i, 1)
                    break
                }
            }
            // console.log("tmpUser after language change: ", tmp.languageCodes)
            // tmp.languageCodes[tmp.languageCodes.length] = this.spokenLanguage
            // tmp.languageSkills[tmp.languageSkills.length]= this.level
            return tmp
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 10px;
}
.duration-row {
    margin: 20px;
    margin-bottom: 40px;
}
</style>
