<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">Students Found</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Base Hub</th>
              <th class="text-left">First Name</th>
              <th class="text-left">Surname</th>
              <th class="text-left">ID Number</th>
              <th class="text-left">Phone Number</th>
              <th class="text-left">Email</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="student in students" :key="student.id" @click="viewDetails(student.name)">
              <td>{{ student.baseHub }}</td>
              <td>{{ student.givenName }}</td>
              <td>{{ student.familyName }}</td>
              <td>{{ student.identificationNumber }}</td>
              <td>{{ student.phoneNumber }}</td>
              <td>{{ student.email }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    students: [],
  }),
  methods: {
    open(students) {
      this.students = students;
      this.dialog = true;
    },
    viewDetails(memberId) {
      this.$emit('student-selected', memberId);
    },
  },
};
</script>
