<template>
    <v-col cols="12" @click="toggleLanguageModal">
        <!-- v-if="!this.$route.fullPath.startsWith('/profiles')" -->
        <EditLanguageModal v-if="this.$route.fullPath.startsWith('/profiles/content')" :showLanguageModal="this.showLanguageModal" :currentLanguage="this.code" :currentSkill="this.skill" @close="toggleLanguageModal"/>
        <v-row class="right-row-edit">
            <h4>{{this.code}}</h4>,&nbsp;<p>{{this.levels[this.skillProp].key}}</p>
        </v-row> 
        <v-row class="right-row-edit">
            <v-slider
                :value="this.skillProp"
                color="#3c7872"
                track-color="#8CBFAD"
                readonly
                min="0"
                max="5"
            ></v-slider>
        </v-row>
        <v-divider></v-divider>
    </v-col>
</template>

<script>
import { mapGetters } from "vuex"
import EditLanguageModal from './EditLanguageModal.vue'
export default {
    name: 'LanguageList',
    components: {
        EditLanguageModal
    },
    props: {
        code: {type: String},
        skill: {type: Number},
    },
    data: () => ({
        showLanguageModal: false,
        levels: [
            {value: 0, key: 'Not proficient'},
            {value: 1, key: 'Beginner'},
            {value: 2, key: 'Elementary Proficiency'},
            {value: 3, key: 'Limited working proficiency'},
            {value: 4, key: 'Highly proficient'},
            {value: 5, key: 'Native/full proficiency'}
        ]
    }),
    computed: {
      ...mapGetters('user', ['activeUser']),
        skillProp: {
            get () {
                return this.skill
            },
            set () {
               return this.skill
            }
        }  
    },
    methods: {
        editLanguage() {

        },
        toggleLanguageModal () {
            this.showLanguageModal = !this.showLanguageModal
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: left;
}
.right-row-icon {
    width: 100%;
    justify-content: right;
    margin-bottom: 20px;
}

</style>
