<template>
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
        <v-row class="right-row">
          <h1>Welcome.</h1>
        </v-row>
      </v-col>
       
      <v-col cols="12" sm="12" md="5" lg="4" class="right">
        <v-row class="right-input">
             <v-row class="right-row"> 
                <h2 class="h2">Enter a phone number</h2>
             </v-row>
             <v-row class="right-row"> 
                <h4 class="infoh5">This is for employers and W4AL to get hold of you. It should be a number you would receive job offers on. It does not have to be yours.</h4>
             </v-row>
            <v-row class="right-row"> 
                <vue-tel-input :ignoredCountries=this.ignored :preferredCountries=this.preferred validCharactersOnly class="this-input-phone" mode="international" required v-model="phoneNumber"  defaultCountry="ZA"></vue-tel-input>
            </v-row>
            <v-row class="right-row">
                    <v-row class="buttons">
                        <v-row class="right-row"> 
                            <v-btn class="signin-btn" type="submit" rounded color="white" required dark @click="otpNav">
                                 <p style="margin:20px">
                                  Continue
                                </p> 
                            </v-btn>
                        </v-row>
                    </v-row>
            </v-row>
            <v-row v-if="existsError" class="right-row"> 
                <h4 class="infoh5 red--text">An account with this phone number already exists, please use a different number.</h4>
            </v-row>
        </v-row>
        
        </v-col> 
    </v-row>
</template>
<script>
import OneTimePinRegister from '../Register/OneTimePin.vue'
import { apolloClient } from "@/main"
import {GET_MEMBER} from "@/apollo/queries";
export default {
    name: 'SecondaryEnter',
    data: () => ({
        phoneNumber: "",
        existsError: false,
        preferred: ['US', 'ZA', 'MX', 'KE', 'MZ', 'BR', 'UG'],
        ignored: ['BG'],
    }),
    methods: {
        async otpNav () {
          await apolloClient.query({
                query: GET_MEMBER,
                variables: {
                    name: this.phoneNumber,
                },
            }).then(response => {
                // console.log("Response: ", response)
                if (response.data.getMember != null) {
                    this.existsError = true
                } else {
                    this.$store.commit("setPhoneNumber", this.phoneNumber)
                    this.$router.push({name: 'OneTimePinRegister', component: OneTimePinRegister, params: {Whatsapp: false, secondaryEnter: true, email: this.$store.state.email}}) 
                }
            })
        },
    }
}
</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.right-row {
  width: 70%;
  justify-content: center;
}
.this-input-phone{
  background: #a9c9c6;
  border: white;
  color: #3c7872;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 70%;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 10px;
}
.infoh5{
  color: #e4e2e2;
  text-align: center;
  padding: 20px;
  max-width: 90%;
}
.buttons {
  width: 90%;
  margin: 10px;
  justify-content: center;
  margin-left: 20px;
}
.signin-btn {
  width: 80%;
  color: #3c7872;
  margin: 10px;
}
</style>