<template>
  <v-card class="home-card">
    <v-card-title class="home-card-title">
      <h3 class="text-h5 basil--text">Enrol students</h3>
    </v-card-title>

    <v-tabs color="#3c7872" v-model="tab" grow background-color="#F5F5F5">
      <v-tab>Foundations</v-tab>
      <v-tab>Phase 2</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="tabs-items">
      <v-tab-item class="tab-item">
        <template v-if="classes.length > 0">
          <v-container class="enrollment-container">
            <div class="enrollment-details">
              <v-alert v-if="alert" text :value="alert" color="green" light border="left" elevation-10 colored-border icon="mdi-check" transition="slide-y-transition">
                <strong>Added student!</strong>
              </v-alert>
              <v-col class="d-flex" cols="12" sm="12">
                <v-select
                    :items="transformedClasses"
                    item-text="displayText"
                    item-value="id"
                    label="Select class"
                    solo color="#3c7872"
                    v-model="localSelectedClass"
                    @input="setClass"
                ></v-select>
              </v-col>
              <div class="info-container">
                <v-icon>mdi-calendar-account-outline</v-icon>
                <span>{{ dateInfo }}</span>
              </div>

              <div class="info-container">
                <v-icon>mdi-account-school-outline</v-icon>
                <span>{{ enrollmentInfo }}</span>
              </div>
            </div>
            <v-list dense v-for="(student, index) in students" :key="index">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ student.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <!-- Add Student Button -->
            <v-row class="right-row">
              <v-btn color="#3c7872" class="btn" outlined rounded @click="showModal=true">
                Add student <v-icon right dark> mdi-plus </v-icon>
              </v-btn>
            </v-row>
            <br/>
            <v-row class="right-row">
              <v-btn color="#3c7872" class="btn" outlined rounded @click="navToClass()">
                <span>View class</span>
                <v-icon right dark>mdi-account-multiple</v-icon>
              </v-btn>
            </v-row>

            <!-- Enrollment Modal -->
            <v-dialog v-model="showModal" scrollable width="600">
              <v-card color="grey lighten-4" width="100%">
                <v-card-title style="justify-content: center;">Enrol student</v-card-title>
                <v-card-text>
                  <v-text-field color="#3c7872" v-model="firstName" label="First Name" required outlined dense filled class="textfield"></v-text-field>
                  <v-row class="info-right-row">
                    <p class="red--text" v-if="errors.firstName">Invalid input</p>
                  </v-row>
                  <v-text-field color="#3c7872" v-model="lastName" label="Last Name" required outlined dense filled class="textfield"></v-text-field>
                  <v-row class="info-right-row">
                    <p class="red--text" v-if="errors.lastName">Invalid input</p>
                  </v-row>
                  <vue-tel-input :ignoredCountries="ignored" :preferredCountries="preferred" class="this-input-phone" mode="international" required v-model="phoneNumber" defaultCountry="ZA"></vue-tel-input>
                  <v-row class="right-row" v-if="errors['phone'] && phoneNumber !== ''">
                    <p class="red--text">This is an invalid phone number.</p>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row class="right-row">
                    <v-btn color="#3c7872" class="btn" outlined rounded @click="createMember()" :disabled="loading">
                      <v-progress-circular v-if="loading" indeterminate size="24" color="white"></v-progress-circular>
                      <span v-else>Add student</span>
                      <v-icon v-if="!loading" right dark>mdi-check</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-actions>
                <v-card-actions v-if="enrollmentErr">
                  <v-row class="right-row">
                    <p class="red--text">{{enrollmentErr}}</p>
                  </v-row>
                </v-card-actions>
                <v-card-actions v-if="enrollmentErr">
                  <v-row class="right-row">
                    <v-btn color="#3c7872" class="btn" width="50%" outlined rounded @click="acceptErrorAndClose()">
                      Close <v-icon right dark> mdi-close </v-icon>
                    </v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </template>
        <div v-else class="no-classes-card">
          <div class="card-content">
            <h2>No Classes Available</h2>
            <p>It seems you don't have any classes. Would you like to create one?</p>
          </div>
          <v-btn color="primary" @click="pushToClassCreation()">Create Class</v-btn>
        </div>
      </v-tab-item>
      <v-tab-item class="tab-item">
        <new-e-cafe-enrollment class="e-cafe-enrollment"></new-e-cafe-enrollment>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CREATE_ENROLLMENT,
  WELCOME_USER,
  CREATE_MEMBER,
} from "@/apollo/mutations";
import {
  GET_MEMBER_ID_BY_PHONE,
  GET_MEMBER,
  LIST_ENROLLMENTS,
  LIST_CLASSES
} from "@/apollo/queries";
import { apolloClient } from "@/main";
import NewECafeEnrollment from "../../components/ECafe/NewECafeEnrollment.vue";

// const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default {
  name: 'EnrolStudents',
  components: { NewECafeEnrollment },

  computed: {
    ...mapGetters('user', ['activeUser']),
    courseName() {
      return this.$store.state.currentClassCourseName;
    },
    className() {
      return this.$store.state.currentClassName;
    },
    startDate() {
      return this.$store.state.currentClassStartDate;
    },
    endDate() {
      return this.$store.state.currentClassEndDate;
    },
    dateRangeText() {
      return this.dates.join(' - ');
    },
    transformedClasses() {
      return this.classes.map(cls => ({
        ...cls,
        displayText: `${cls.course} - ${this.parseDate(cls.startDate)}`
      }));
    },
    selectedClass: {
      get() {
        return this.classes.find(cls => cls.id === this.$store.state.currentClassName) || null;
      },
      set(newValue) {
        if (newValue && newValue.id) {
          this.setClass(newValue);
        }
      }
    },

    dateInfo() {
      return `${this.extractDate(this.startDate)} - ${this.extractDate(this.endDate)}`;
    },
    enrollmentInfo() {
      return `${this.numEnrollments} enrolled`;
    }
  },

  data: () => ({
    alert: false,
    loading: false,
    dates: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(Date.now() + 600000000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
    showModal: false,
    showErr: false,
    errors: {},
    students: [],
    firstName: "",
    lastName: "",
    methodsKeys: ["SMS", "email"],
    preferred: ['US', 'ZA', 'MX', 'KE', 'MZ', 'BR', 'UG'],
    ignored: ['BG'],
    email: "",
    phoneNumber: "",
    enrollmentErr: "",
    numEnrollments: 0,
    classes: [],
    localSelectedClass: null,
    tab: null
  }),

  mounted() {
    this.getList();
    this.getNumberOfEnrollments();
  },
  created() {
    // Initialize the localSelectedClass based on the currentClassName in the Vuex store when the component is created
    this.localSelectedClass = this.classes.find(cls => cls.id === this.$store.state.currentClassName) || null;
  },

  methods: {
    ...generateHelperMethods.bind(this)(),
    ...generateDataFetchingMethods.bind(this)(),
    ...generateDataManipulationMethods.bind(this)(),
    ...generateNavigationMethods.bind(this)(),
    ...generateValidationMethods.bind(this)(),

    setClass(selectedComposite) {
      // Find the matching class object from this.classes
      const selectedClass = this.classes.find(cls => {
        return `${cls.course} - ${this.extractDate(cls.startDate)}` === selectedComposite;
      });

      if (selectedClass) {
        this.$store.commit("setCurrentClassStartDate", selectedClass.startDate);
        this.$store.commit("setCurrentClassCourseName", selectedClass.course);
        this.$store.commit("setCurrentClassName", selectedClass.name);
        this.$store.commit("setCurrentClassEndDate", selectedClass.endDate);
      } else {
        console.error("No matching class found for selected value:", selectedComposite);
      }
    }

  }
};

// Helper methods
function generateHelperMethods() {
  return {
    parseDate(dateStr) {
      const matches = dateStr.match(/year:(\d+)\s+month:(\d+)\s+day:(\d+)/);
      if (matches) {
        return `${matches[1]}-${matches[2].padStart(2, '0')}-${matches[3].padStart(2, '0')}`;
      }
      return dateStr;
    },

    extractDate(date) {
      if (!date || typeof date !== 'string') {
        console.error("Date format unexpected:", date);
        return 'N/A'; // return a default value
      }

      // Use a regular expression to extract year, month, and day
      const matches = date.match(/year:(\d+)\s+month:(\d+)\s+day:(\d+)/);

      if (!matches) {
        console.error("Date format unexpected:", date);
        return 'N/A'; // return a default value
      }

      return `${matches[1]}-${matches[2].padStart(2, '0')}-${matches[3].padStart(2, '0')}`;
    },

    formatPhoneNumber() {
      return this.phoneNumber.replace(/ /g, '');
    },

    isEmailMethod() {
      return this.methodsKeys[this.method] === 'email';
    },

    resetModalFields() {
      this.phoneNumber = "";
      this.email = "";
      this.method = 0;
      this.firstName = "";
      this.lastName = "";
      this.exists = false;
      this.showModal = false;
      this.enrollmentErr = "";
    },

    hide_alert() {
      setTimeout(() => {
        this.alert = false;
      }, 4000);
    },
  };
}

// Fetching data related methods
function generateDataFetchingMethods() {
  return {
    async getList() {
      try {
        const response = await apolloClient.query({
          query: LIST_CLASSES,
          variables: {
            filter: this.activeUser.phoneNumber.replace(/ /g, '')
          }
        });
        this.classes = JSON.parse(JSON.stringify(response.data.listClasses.map(this.createClassObject)));
        console.log("Classes:", this.classes)

        // If there's no current class set in Vuex, set the first class as default
        if (!this.$store.state.currentClassName && this.classes.length > 0) {
          this.setClass(this.classes[0]);
        }

        // Always ensure localSelectedClass is set after fetching classes
        this.localSelectedClass = this.classes.find(cls => cls.id === this.$store.state.currentClassName) || null;
      } catch (err) {
        console.error("ERROR:", err);
      }
    },

    async getNumberOfEnrollments() {
      if (!this.className) {
        this.numEnrollments = 0;
        return;
      }
      try {
        const response = await apolloClient.query({
          query: LIST_ENROLLMENTS,
          variables: {
            filter: this.className,
            tag: "Class",
            parent: this.courseName
          }
        });
        this.numEnrollments = response.data.listEnrollments.length;
      } catch (err) {
        console.error("Error fetching enrollments:", err);
      }
    },

    async fetchMemberID(phoneNumber) {
      try {
        const response = await apolloClient.query({
          query: GET_MEMBER_ID_BY_PHONE,
          variables: { phoneNumber }
        });
        return response.data.memberId;
      } catch (error) {
        console.log("Error during fetchMemberID:", error);
      }
    },

    async getMember() {
      const contact = this.isEmailMethod() ? this.email : this.formatPhoneNumber();
      try {
        const response = await apolloClient.query({
          query: GET_MEMBER,
          variables: { name: contact },
        });
        console.log("getMember response:", response.data.getMember)
        return response.data.getMember !== null;
      } catch (error) {
        console.error("Error during getMember:", error);
        return false;
      }
    }
  };
}

// Manipulating and handling data methods
function generateDataManipulationMethods() {
  return {
    createClassObject(classItem) {
      const { name, course, startDate, endDate } = classItem;
      return {
        name,
        course,
        startDate,
        endDate
      };
    },

    async createMember() {
      this.loading = true;

      try {
        const phone = this.formatPhoneNumber();
        const email = this.isEmailMethod() ? this.email : "";
        const memberExists = await this.getMember();

        if (memberExists) {
          console.log("Member already exists, creating Enrollment...");
          await this.createEnrollment();
          return;
        }

        console.log("Member does not exist, creating Member...");
        await apolloClient.mutate({
          mutation: CREATE_MEMBER,
          variables: {
            name: this.firstName,
            phoneNumber: phone,
            email: email,
            familyName: this.lastName,
            givenName: this.firstName,
            role: 1,
            grow: {
              teachers: [this.activeUser.email],
              profile: "",
              workExperience: [],
              education: [],
              skills: [],
              classId: [],
              enrollments: []
            },
            baseHub: this.activeUser.baseHub
          }
        });
        await this.createEnrollment();
      } catch (err) {
        console.log('createMember ERROR:', err);
      } finally {
        this.loading = false;
      }
    },

    async createEnrollment() {
      this.loading = true;
      const phone = this.formatPhoneNumber();
      const memberId = await this.fetchMemberID(this.formatPhoneNumber());
      if (!memberId) {
        console.error("Member ID not found");
        return;
      }

      try {
        const resp = await apolloClient.mutate({
          mutation: CREATE_ENROLLMENT,
          variables: {
            course: this.courseName,
            enrolledClass: this.className,
            name: phone,
            student: memberId,
            paid: false
          }
        });

        if (resp.data.createEnrollment !== null) {
          console.log("Enrollment created successfully!");
          await this.getNumberOfEnrollments();
          await this.welcomeUser(phone);
        }
      } catch (error) {
        this.enrollmentErr = error.message || "An unexpected error occurred during enrollment.";
        console.error("Enrollment Error:", error);
      }
      this.loading = false;
      this.resetModalFields();
      this.alert = true;
      this.hide_alert();
    },
  };
}
// Navigation related methods
  function generateNavigationMethods() {
    return {
      navToClass() {
        this.$router.push({
          name: 'ViewClassEnrollments',
          params: {
            id: this.$store.state.currentClassName,
            classId: this.$store.state.currentClassName,
            course: this.$store.state.currentClassCourseName,
            dates: `${this.$store.state.currentClassStartDate}-${this.$store.state.currentClassEndDate}`
          }
        });
      },

      pushToClassCreation() {
        this.$router.push({ name: 'ClassBuilder' });
      },

      async welcomeUser(phone) {
        if (this.methodsKeys[this.method] === 'SMS' || this.methodsKeys[this.method] === 'Whatsapp') {
          phone = phone.replace(/ /g,'')
        }

        const body = `Hi ${this.firstName}, your W4AL enrolment is almost complete. Now login and complete your digital profile.`;

        try {
          await apolloClient.mutate({
            mutation: WELCOME_USER,
            variables: {
              phoneNumber: phone,
              email: this.email,
              method: this.methodsKeys[this.method],
              body: body
            }
          });

          this.showModal = false;
          this.numEnrollments += 1;
          this.enrollmentErr = "";
          this.phoneNumber = "";
          this.email = "";
          this.method = 0;
          this.firstName = "";
          this.exists = false;

        } catch (error) {
          console.log(error);
          this.enrollmentErr = "Added student, but couldn't send welcome message. Send private message to confirm enrollment.";
          this.numEnrollments += 1;
        }
        this.alert = true;
        this.hide_alert();
      },
    };
  }

  function generateValidationMethods() {
    return {
      validateName(value) {
        this.validateUsingRegex(value, /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð-]* ?)*?$/u, 'firstName');
      },

      validateLastName(value) {
        this.validateUsingRegex(value, /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð-]* ?)*?$/u, 'lastName');
      },

      validatePhone(value) {
        this.validateUsingRegex(value, /^(\+\d{1,3})?\s?(\d)*\s?\d{3}\s?\d*$/, 'phone');
      },

      validateEmail(value) {
        if (value !== '') {
          this.validateUsingRegex(value, /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{1,10})$/, 'email');
        } else {
          this.showErr = false;
        }
      },

      validateUsingRegex(value, regex, fieldName) {
        if (regex.test(value)) {
          this.errors[fieldName] = '';
          this.showErr = false;
        } else {
          this.errors[fieldName] = `Invalid ${fieldName}`;
          console.log(this.errors[fieldName]);
          this.showErr = true;
        }
      }
    };
}
</script>

<style scoped>
.home-card {
  background: whitesmoke;
}
.home-card-title {
  background-color: #F5F5F5;
  text-align: center;
  padding: 2em 0;
}
.tabs-items {
  min-width: 100%;
  background: whitesmoke;
}
.tab-item {
  min-height: 100%;
  margin: 10px;
}
.enrollment-container {
  background: whitesmoke;
  justify-content: center;
  max-width: 500px;
  width: 100%;
}
.enrollment-details {
  background-color: whitesmoke;
  padding: 1em 0;
}
.home {
  margin: 0 auto;
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
}
.right-row {
  width: 100%;
  justify-content: center;
}
.info-right-row {
  min-width: 100%;
  justify-content: center;
  padding: 2px;
}
.info-container {
  display: flex;
  align-items: center;
  gap: 8px; /* space between icon and text */
}
.right-row {
  width: 100%;
  justify-content: center;
  margin: 0 0 0 0;
}
.btn {
  width: 80%;
  color: #3c7872;
  text-transform: initial;
}
.btn-large {
  width: 40%;
  color: #3c7872;
  text-transform: initial;
}
.textfield{
  justify-content: center;
}
.basil--text {
  color: #1b154b !important;
  font-weight: 500;
}
.no-classes-card {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.card-content {
  margin-bottom: 20px;
}

</style>

