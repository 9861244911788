<template>
  <v-dialog v-model="show" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Review Group</span>
      </v-card-title>
      <v-card-subtitle class="pt-1">
        <span>{{selectedClass.course}} | {{selectedClass.enrollmentsCount}} students enrolled</span>
      </v-card-subtitle>
      <v-divider class="ma-5 mt-1"></v-divider>
      <v-card-text>
        <v-row style="justify-content: center" class="pt-1">
          <v-col cols="12" class="pt-0 pb-0">
            <p>How many students in this group gave their lives to the Lord?</p>
            <v-select color="#6A2E85" return-object dense outlined v-model="numSalvations" placeholder="Salvations" label="Salvations" :items="numList"></v-select>
          </v-col>
        </v-row>
        <v-row style="justify-content: center" class="pt-1">
          <v-col cols="12" class="pt-0 pb-0">
            <p>How many are now working?</p>
            <v-select color="#6A2E85" return-object dense outlined v-model="numWorking" placeholder="Students working" label="Students working" :items="numList"></v-select>
          </v-col>
        </v-row>
        <v-row style="justify-content: center" class="pt-1">
          <v-col cols="12" class="pt-0 pb-0">
            <p>How many are pursuing further education?</p>
            <v-select color="#6A2E85" return-object dense outlined v-model="numEducation" placeholder="Furthering education" label="Furthering education" :items="numList"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row style="justify-content: center; margin-bottom: 10px; width: 100%">
          <v-col cols="6" class="pr-0">
            <v-btn @input="emitCloseEvent" @click="show = false" style=" width: 100%" text class="pa-0" color="#666666">
              Close
            </v-btn>
          </v-col>
          <v-col cols="6" class="pl-0">
            <v-btn v-if="numEducation === -1 || numSalvations === -1 || numWorking === -1" disabled style=" width: 100%" text class="pa-0">
              Done
            </v-btn>
            <v-btn v-else color="#6A2E85" @click="reviewClass" style=" width: 100%" text class="pa-0">
              Done
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
<!--      {{selectedClass}}-->
<!--      {{selectedActualClass}}-->
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {apolloClient} from "@/main";
import {UPDATE_CLASS} from "@/apollo/mutations";

export default {
  name: "ReviewGroupDialog",
  props: {
    value: Boolean,
    selectedClass: {
      type: Object,
      default: null
    },
    selectedActualClass: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('user', ['activeUser']),
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    numList: function () {
      return Array.from(Array(this.selectedClass.enrollmentsCount + 1).keys()).map((n) => n)
    },
    numSalvations: {
      get () {
        return this.selectedClass.savedCount
      },
      set(value) {
        this.numSalvationsAggr = value
      }
    },
    numWorking: {
      get () {
        return this.selectedClass.employedCount
      },
      set(value) {
        this.numWorkingAggr = value
      }
    },
    numEducation:  {
      get () {
        return this.selectedClass.furtherEducationCount
      },
      set(value) {
        this.numEducationAggr = value
      }
    }
  },
  watch: {
    value(val) {
      this.reviewDialog = val;
    },
  },
  data() {
    return {
      // The Aggr suffix is because these data fields are set in the computed
      // method for the corresponding name. For example, the first <v-select>
      // models a variable called numSalvations. To read this value is easy,
      // but updating it is tough. We therefore set a new value
      // numSalvationsAggr equal to the incoming value. This new copied
      // abstracted value is then used for updates.
      numSalvationsAggr: 0,
      numWorkingAggr: -1,
      numEducationAggr: -1,
      loading: false
    };
  },
  methods: {
    emitCloseEvent() {
      this.$emit("input", false);
    },
    async reviewClass() {
      let startDate = this.extractDate(this.selectedActualClass.startDate)
      let endDate = this.extractDate(this.selectedActualClass.endDate)
      await apolloClient.mutate({
        mutation: UPDATE_CLASS,
        variables: {
          name: this.selectedActualClass.name,
          class: {
            name: this.selectedActualClass.name,
            course: this.selectedActualClass.course,
            startDate: startDate,
            endDate: endDate,
            facilitator: this.selectedActualClass.facilitator,
            classFormat: this.selectedActualClass.classFormat,
            hub: this.selectedActualClass.hub,
            savedCount: this.numSalvationsAggr,
            employedCount: this.numWorkingAggr,
            furtherEducationCount: this.numEducationAggr,
            enrollmentsCount: this.selectedClass.enrollmentsCount
          }
        }
      }).catch()
      // this.loading = true,
      // this.sleep(2000),
      // this.loading = false,
      this.show = false
    },
    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },

    extractDate(date) {
      let str = date.split(" ")
      let year, month, day
      if (str.length === 5) {
        year = str[0].split(":")[1]
        month = str[2].split(":")[1]
        day = str[4].split(":")[1]
      } else {
        year = str[0].split(":")[1]
        month = str[1].split(":")[1]
        day = str[2].split(":")[1]
      }

      if (parseInt(month) < 10) {
        month = "0" + month
      }
      if (parseInt(day) < 10) {
        day = "0" + day
      }
      return year + "-" + month + "-" + day
    },
  },
}
</script>

<style scoped>

</style>