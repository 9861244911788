<template>
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
         <v-row class="right-row">
          <h1>Welcome to Work 4 A Living</h1>
         </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="4" class="right">
        <v-row class="right-input">
          <v-row class="right-row"> 
            <h2 class="h2" v-if="Whatsapp">Authenticating, please see your messages</h2>
            <h2 class="h2" v-else-if="this.$store.state.facRegister">Authenticating, please see your emails</h2>
            <h2 class="h2" v-else-if="this.secondaryEnter">We sent you an email</h2>
            <h2 class="h2" v-else>Authenticating, please see your messages</h2>
          </v-row>
          <!-- <v-progress-circular
            :size="270"
            color="purple"
            indeterminate
            class="progress"
          ></v-progress-circular> -->
          <v-row class="right-row"> 
            <h4 class="infoh5">Enter the code sent to your device.</h4>
          </v-row>
          <v-row class="otp-row"> 
            <v-col class="otp-col">
              <v-otp-input
                length="4"
                plain
                type="number"
                color=white
                outlined
                v-model="code"
                width="10px"
              ></v-otp-input>
            </v-col>
          </v-row>
          <v-row class="buttons">
            <v-row class="right-row"> 
              <v-btn class="otp-signin-btn" @click="verify" rounded>Continue</v-btn>
            </v-row>
            <!-- <v-row class="right-row"> 
              <v-btn class="otp-signin-btn" v-if="this.verified" :to="{name:'InfoPage', params: {phoneNumber: phoneNumber}}" rounded>Continue</v-btn>
              <v-btn class="otp-signin-btn" v-else disabled rounded>Continue</v-btn>
            </v-row> -->
          </v-row>
          <v-dialog
            v-model="dialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row class="right-row">
                <v-btn class="not-receiving-signin-btn" v-on="on" v-bind="attrs" plain>Not receiving code</v-btn>
              </v-row>
            </template>

            <v-card>
              <v-card-title class="justify-center grey lighten-5">
                <h4 class="card-h5"> 
                  Not receiving code
                </h4>
              </v-card-title>

              <v-card-actions>
                <v-row class="resend-input">
                  <v-btn class="resend" plain @click="resend('whatsapp')">
                    <v-icon left>
                      mdi-android-messages
                    </v-icon>
                    Resend
                  </v-btn>
                  <v-btn class="resend" plain @click="resend('sms')">
                    <v-icon left>
                      mdi-android-messages
                    </v-icon>
                    Resend via SMS
                  </v-btn>
                  <v-btn v-if="this.$store.state.facRegister || this.secondaryEnter" class="resend" plain @click="resend('email')">
                    <v-icon left>
                      mdi-email-outline
                    </v-icon>
                    Resend via email
                  </v-btn>
              </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col> 
    </v-row>
</template>

<script>
  import {GET_TOKEN} from "../../apollo/queries"
  import {SEND_VERIFICATION, VERIFY, VERIFY_TOTP, SEND_MESSAGE_TOTP} from "../../apollo/mutations"
  import {apolloClient} from '../../main'
  import InfoPage from "@/views/Register/InfoPage.vue";
  export  default {
    name: 'OneTimePinRegister',
    mounted () {
      this.phoneNumber = this.$store.state.phoneNumber
    },
    data: () => ({
      verified: false,
      code: "",
      dialog: false,
      phoneNumber: ""
    }),
    props: 
    {
        email: {
          type: String,
          default: ""
        },
        SMS: {
            type: Boolean,
            default: false
        },
        Whatsapp: {
            type: Boolean,
            default: false
        },
        firstName: {
          type: String,
          default: "User"
        },
        secondaryEnter: {
          type: Boolean,
          default: false
        }
    },
    methods: {
      async resend (method) {
        this.dialog = false
        if (this.email !== "emailDefault" || this.secondaryEnter || method === 'email') {
          apolloClient.mutate({
            mutation: SEND_VERIFICATION,
            variables: {
              verifyEmail: this.email,
              verifyPhoneNumber: this.phoneNumber,
              method: 'email'
            }
          })
          .then((response) => {
              this.$store.commit("setVerificationSID", response.data.sendVerification)
          })
        } else {
            apolloClient.mutate({
            mutation: SEND_MESSAGE_TOTP,
            variables: {
              verifyPhoneNumber: this.phoneNumber,
            }
          })
          .then((response) => {
              this.$store.commit("setTotpKey", response.data.sendMessageTOTP)
          })
        }
      },
      // This is only written to create a member. Then it is overwritten in InfoPage.vue.
      async getToken() {
        try {
          const response = await  apolloClient.query({
            query: GET_TOKEN,
            variables: {
              phoneNumber: this.phoneNumber
            }
          })
          // Set tokens from the response
          this.$store.commit('setJWT', response.data.getToken[0])
          this.$store.commit('setRefreshToken', response.data.getToken[1])
          return response.data.getToken[0]
        } catch (e) {
          console.error(e)
          return ""
        }
      },
      async verify () {
        // if (this.email === "luther@work4aliving.org") {
        //   const token = await this.getToken()
        //   console.log("Token: ", token)
        //   if (token.length > 0) {
        //     this.$store.commit('setPhoneNumber', this.phoneNumber)
        //     if (this.$store.state.actRegister) {
        //       this.$store.commit('setEmail', this.email)
        //       this.$store.commit("setActivator")
        //       await this.$router.push({name:'InfoPage', params: {phoneNumber: this.phoneNumber, facilitator: false, activator: true}})
        //     } else if (this.$store.state.facRegister) {
        //       this.$store.commit('setEmail', this.email)
        //       this.$store.commit("setFacilitator")
        //       await  this.$router.push({name:'InfoPage', params: {phoneNumber: this.phoneNumber, facilitator: true}})
        //     } else {
        //       this.$store.commit("setStudent")
        //       await this.$router.push({name:'InfoPage', params: {phoneNumber: this.phoneNumber, secondaryEnter: this.secondaryEnter}})
        //     }
        //   } else {
        //     await this.$router.push({name: "HomePage"})
        //   }
        // } else {

          let v = {
            code: this.code,
            Sid: this.$store.state.verificationSID
          }
          if (this.$store.state.facRegister || this.secondaryEnter || this.$store.state.actRegister) {
            let v = {
              code: this.code,
              Sid: this.$store.state.verificationSID,
              verifyEmail: this.email
            }
            try {
              const response = await apolloClient.mutate({
                mutation: VERIFY,
                variables: v
              })
              if (response.data.verify == "Verified") {
                const token = await this.getToken()
                if (token.length > 0) {
                  this.$store.commit('setPhoneNumber', this.phoneNumber)
                  if (this.$store.state.facRegister) {
                    this.$store.commit('setEmail', this.email)
                    // Only set facilitator state once verified
                    this.$store.commit("setFacilitator")
                    await this.$router.push({name:'InfoPage', params: {phoneNumber: this.phoneNumber, facilitator: true}})
                  } else if (this.$store.state.actRegister) {
                    this.$store.commit('setEmail', this.email)
                    // Only set activator state once verified
                    this.$store.commit("setActivator")
                    await this.$router.push({name:'InfoPage', component: InfoPage,  params: {phoneNumber: this.phoneNumber, facilitator: false, activator: true}})
                  } else {
                    this.$store.commit("setStudent")
                    await this.$router.push({name:'InfoPage', params: {phoneNumber: this.phoneNumber, secondaryEnter: this.secondaryEnter}})
                  }
                } else {
                  await this.$router.push({name: "HomePage"})
                }
              }
            } catch (error) {
              console.error(error);
            }
          } else {
            let v = {
              code: this.code,
              key: this.$store.state.totpKey
            }
            try {
              const response = await apolloClient.mutate({
                mutation: VERIFY_TOTP,
                variables: v
              })
              if (response.data.verifyTOTP == "Verified") {
                const token = await this.getToken()
                if (token.length > 0) {
                  this.$store.commit('setPhoneNumber', this.phoneNumber)
                  if (this.$store.state.facRegister) {
                    this.$store.commit('setEmail', this.email)
                    // Only set facilitator state once verified
                    this.$store.commit("setFacilitator")
                    await this.$router.push({name:'InfoPage', params: {phoneNumber: this.phoneNumber, facilitator: true}})
                  } else if (this.$store.state.actRegister) {
                    this.$store.commit('setEmail', this.email)
                    // Only set activator state once verified
                    this.$store.commit("setActivator")
                    await this.$router.push({name:'InfoPage', component: InfoPage,  params: {phoneNumber: this.phoneNumber, facilitator: false, activator: true}})
                  } else {
                    this.$store.commit("setStudent")
                    await this.$router.push({name:'InfoPage', params: {phoneNumber: this.phoneNumber, secondaryEnter: this.secondaryEnter}})
                  }
                } else {
                  await this.$router.push({name: "HomePage"})
                }
              }
            } catch (error) {
              console.error(error);
            }
          }
          console.log(v)
        // }
      },
  }
  }

</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 70%;
  justify-content: center;
}
.otp-row {
  width: 70%;
  justify-content: center;
  margin: 10px;
}
.h2 {
  text-align: center;
  padding: 10px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.otp-signin-btn {
  width: 80%;
  color: #3c7872;
  margin: 10px;
}
.not-receiving-signin-btn {
  width: 80%;
  color: #ffffff;
  background: #3c7872;
  margin: 10px;
  margin-top: 40px;
}
.infoh5{
  color: #e4e2e2;
  text-align: center;
  padding: 20px;
}
.h5{
  text-align: center;
  padding: 10px
}
.resend{
  width: 100%;
  color: #3c7872;
  background: white;
  margin: 10px;
}
.resend-input{
  margin: 0 auto;
  justify-content: center;
}
.buttons {
  width: 90%;
  margin: 10px;
  justify-content: center;
}
  </style>