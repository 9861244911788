<!-- This file is very ciritical in the magic-link authentication process. 
This exposed route will be hit by whatsapp with an argument 
(the encrypted auth key). The param will be used to verify that 
user's phone number on the authentication neuron. From there, 
we can proceed as logged in. This means for every authentication, 
there will be two signed-in tabs. The other tab that initiated 
the log in will hang and constantly request until the table's 
value has been changed to true by this file's functionality. -->

<template>
    <!-- <h1>OIYAAAAA</h1> -->
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
        <v-row class="right-row">
            <h1>Welcome.</h1>
            </v-row>
      </v-col>
       <v-col cols="12" sm="12" md="5" lg="4" class="right">
            <v-row class="right-input">
                <v-overlay :value="!verified">
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="purple"
                        indeterminate
                    ></v-progress-circular>
                </v-overlay>
                <div v-if="this.verified">
                    <v-icon>mdi-check-circle</v-icon>
                    Verified! {{accessToken}}
                </div>
            </v-row>
       </v-col>
    </v-row>
   
</template>

<script>
import { apolloClient } from "../../main"
import {VERIFY_AUTHENTICATION} from "../../apollo/mutations"
export default {
    name: 'VerifyAuthentication',
    data: () => ({
        key: "",
        verified: false,
        accessToken: ""
    }),
    beforeMount(){
        let key = this.$route.params.key
        this.verifyAuthentication(key)
    },
    methods: {
        verifyAuthentication: async function(key) {
            await apolloClient.mutate({
                mutation: VERIFY_AUTHENTICATION,
                variables: {
                    token: key
                }
            }).then((response) => {
                if (response.data.verifyAuthentication != null) {
                    // Response is verified, then set verified = true.
                    this.verified = true
                    this.$store.commit('setJWT', response.data.verifyAuthentication[0])
                    this.accessToken = response.data.verifyAuthentication[0]
                    this.$store.commit('setRefreshToken', response.data.verifyAuthentication[1])
                }
            })
        }
    }
}

</script>

<style>

</style>