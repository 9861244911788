<template>
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
        <v-row class="right-row">
          <h1>Welcome to Work 4 A Living</h1>
        </v-row>
      </v-col>

      <v-col cols="12" sm="12" md="5" lg="4" class="right">
      <v-row class="right-input">
        <v-row class="right-row"> 
         <h2 class="h2">Create account</h2>
        </v-row>
        <v-row class="right-row"> 
          <vue-tel-input class="input-phone" mode="international" v-model="phoneNumber" defaultCountry="ZA"></vue-tel-input>
        </v-row>
        <v-row class="right-row"> 
          <v-btn class="signin-btn" v-if="this.phoneNumber" @click.native="getMember('whatsapp')" type="submit" rounded color="white" required dark>
            Authenticate with Whatsapp
          </v-btn> 
          <v-btn class="signin-btn" v-else type="submit" rounded color="white" dark disabled>
            Authenticate with Whatsapp
          </v-btn>
        </v-row>
        <v-row class="right-row"> 
          <v-btn class="signin-btn-2" v-if="this.phoneNumber" @click.native="getMember('sms')" outlined type="submit" rounded>
            Authenticate with SMS
          </v-btn>
          <v-btn class="signin-btn-2" v-else outlined type="submit" rounded dark disabled>
            Authenticate with SMS
          </v-btn>
          <h4>{{errorTitle}}</h4>
          <h6>{{errorMessage}}</h6>
        </v-row>
        <v-row class="right-row"> 
          <p class="h5"> Already joined? <a href="http://localhost:8080/login" class="atag"> Sign in</a> </p>
        </v-row>
      </v-row>
      </v-col> 
    </v-row>
</template>

<script>
  const countries = require('i18n-iso-countries')
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
  import {SEND_MESSAGE_TOTP} from "../../apollo/mutations"
  import {GET_MEMBER} from "../../apollo/queries";
import { apolloClient } from '../../main';
  export  default {
    name: 'RegisterPage',
    computed: {
      countries () {
        const list = countries.getNames('en', { select: 'official' })
        console.log(list)
        return Object.keys(list).map((key) => ({ value: key, text: list[key] }))
      },
    },
    data: () => ({
      phoneNumber: "",
      valid: false,
      errors: [],
      country: null,
      msg: [],
      errorTitle: "",
      errorMessage: ""
      // whatsapp: false,
      // SMS: false
    }),
    watch: {
      phoneNumber(value) {
        this.validatePhone(value)
      }
    },
    methods: {
      validatePhone(value) {
        const regEx = /^(?:[0-9]●?){6,14}[0-9]$/
        if (regEx.test(value)) {
          this.msg['phoneNumber'] = '';
        } else{
          this.msg['phoneNumber'] = 'Invalid Phone Number';
        } 
      },
      async getMember(method) {
        apolloClient.query({
            query: GET_MEMBER,
            variables: {
                name: this.phoneNumber,
            },
        })
        .then((response) => {
            if (response.data.getMember != null) {
              // this.exists = true
              // this.$store.commit('setFirstName', response.data.getMember.familyName)
              // this.sendVerification(method)
              // // named route with params to let the router build the url
              // this.$router.push({name:'OneTimePin', params: {phoneNumber: this.phoneNumber, Whatsapp: true}})
              console.log("This phone number is already linked to an account.", response.data.getMember)
              this.errorTitle = "Account already exists"
              this.errorMessage = `There is already an account registered for ${this.phoneNumber}`
            }
        })
        .catch(async () => {
          await this.sendVerification(method)
        })
      },
      async sendVerification () {
        apolloClient.mutate({
          mutation: SEND_MESSAGE_TOTP,
          variables: {
            verifyPhoneNumber: this.phoneNumber,
          }
        })
        .then(async (response) => {
            this.$store.commit("setTotpKey", response.data.sendMessageTOTP)
            console.log(this.$store.state.totpKey)
            await this.$router.push({name:'OneTimePinRegister', params: {phoneNumber: this.phoneNumber, Whatsapp: true}})
        })
        .catch((error) => {
          // Error
          console.error(error)
      })
    },
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  /*font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  line-height: 1.6;
  background: black;*/
}
.signin {
  margin: 0;
  height: 100%;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 10px;
}
.signin-btn {
  width: 80%;
  color: #3c7872;
  margin: 10px;
}
.signin-btn-2 {
  width: 80%;
  color: white;
  margin: 10px;
}
.h5{
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
.h1{
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
.input-phone{
  color: #3c7872;
  background: #4B958E;
  border: #020000;
  margin-top: 20px;
  margin-bottom: 30px;
}
.atag {
  color: rgb(165, 207, 241);
}
  </style>