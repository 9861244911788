<template>
    <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable>
        <v-card >
            <v-card-title class="justify-center grey lighten-3 title-space">
                Create Education
            </v-card-title>
            <v-card-text>
                <p class="textArea">Provide details of education you <strong>have completed. </strong></p>
                <v-form class="form">
                            <v-row class="info-right-row">  
                                <v-text-field
                                    class="rounded-text"
                                    label="Qualification title"
                                    required
                                    outlined
                                    filled
                                    dense
                                    @input="updateLocalTitle($event)"
                                    ></v-text-field>
                            </v-row>
                            <v-row class="info-right-row">
                                <v-text-field
                                    class="rounded-text"
                                    label="School/University/College"
                                    required
                                    outlined
                                    filled
                                    dense
                                    @input="updateLocalSchool($event)"
                                ></v-text-field>
                            </v-row>
                            <v-row class="info-right-row">
                                <v-select
                                    class="rounded-text"
                                    :items="educationLevels"
                                    item-text="key"
                                    item-value="value"
                                    label="Education type"
                                    outlined
                                    filled
                                    dense
                                    return-object
                                    v-model="educationLevel"
                                    ></v-select> 
                            </v-row>
                            <v-row class="info-right-row">
                                <v-select
                                    class="rounded-text"
                                    v-if="educationLevel.value === 1"
                                    :items="schoolQualifications"
                                    item-text="key"
                                    item-value="value"
                                    label="Select your highest qualification"
                                    outlined
                                    filled
                                    dense
                                    return-object
                                    v-model="qualification"
                                ></v-select>
                                <v-select
                                    class="rounded-text"
                                    v-else-if="educationLevel.value === 2"
                                    :items="furtherQualifications"
                                    label="Select your highest qualification"
                                    item-text="key"
                                    item-value="value"
                                    outlined
                                    filled
                                    dense
                                    return-object
                                    v-model="qualification"
                                ></v-select> 
                                <v-select
                                    class="rounded-text"
                                    v-else
                                    disabled
                                    label="Qualification"
                                    outlined
                                    filled
                                    dense
                                    return-object
                                    v-model="qualification"
                                ></v-select> 
                            </v-row>
                            <v-row class="info-right-row">
                                <v-select
                                    class="rounded-text"
                                    v-if="educationLevel.value === 2"
                                    :items="industries"
                                    item-text="key"
                                    item-value="value"
                                    label="Industry"
                                    outlined
                                    filled
                                    dense
                                    return-object
                                    v-model="industry"
                                    ></v-select> 
                            </v-row>
                            <v-row class="info-right-row">  
                                <v-select
                                    class="rounded-text"
                                    outlined
                                    filled
                                    dense
                                    :value="2022"
                                    :items="years"
                                    label="End year"
                                    @input="updateLocalEndYear($event)"
                                ></v-select> 
                            </v-row>
                        </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row class="right-row-edit"> 
                    <v-btn class="edit-btn-cancel" rounded text large @click="showModal=false">Cancel</v-btn>
                    <v-tooltip bottom  v-if="title === '' || school === '' || educationLevel === null || qualification === null || (educationLevel.value === 2 && industry === null )">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-on="on">
                                    <v-btn disabled class="edit-btn-save" v-bind="attrs" rounded large color="#3c7872" @click="updateEducation"> Save</v-btn>
                                </div>
                            </template>
                            <span>Complete the form.</span>
                        </v-tooltip>
                    <v-btn v-else class="edit-btn-save" rounded large color="#3c7872" @click="updateEducation"> Save</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: 'EducationModal',
    data: () => ({
        title: "",
        school: "",
        duration: "",
        description: "",
        months: [
            {value: 1, key: "Jan"},
            {value: 2, key: "Feb"},
            {value: 3, key: "Mar"},
            {value: 4, key: "Apr"},
            {value: 5, key: "May"},
            {value: 6, key: "Jun"},
            {value: 7, key: "Jul"},
            {value: 8, key: "Aug"},
            {value: 9, key: "Sep"},
            {value: 10, key: "Oct"},
            {value: 11, key: "Nov"},
            {value: 12, key: "Dec"},
        ],
        startMonth: "",
        // startYear: 2021,
        endMonth: "",
        endYear: 2022,
        newEducation: {
            title: "",
            school: "",
            duration: "",
            description: "",
            educationLevel: 0,
            qualification: 0,
            state: 0,
            industry: 0
        },
        educationLevels: [{value: 1, key:"High School"}, {value: 2, key: "Further Education"}],
        educationLevel: {name:"High School", value: 1},
        schoolQualifications: [{value: 1, key: "Less than Grade 9"}, {value: 2, key: "Grade 9"}, {value: 3, key: "Grade 10"}, {value: 4, key: "Grade 11"}, {value: 5, key: "Grade 12"}],
        furtherQualifications: [{value: 6, key: "Training Course"},{value: 7, key: "Diploma"},{value: 8, key: "Bachelors Degree"},{value: 9, key: "Honours degree"},{value: 10, key: "Masters degree"},{value: 11, key: "Doctorate degree"}],
        qualification: null,
        industries: [{value: 2, key: "Admin and Business Support"}, {value: 28, key: "Architecture"}, {value: 23, key: "Advertising and Marketing"}, {value: 3, key: "Agriculture"}, {value: 5, key: "Arts and Entertainment"}, {value: 6, key: "Construction"}, {value: 27, key: "Customer Service"}, {value: 7, key: "Education"}, {value: 19, key: "Engineering"}, {value: 8, key: "Finance and Insurance"}, {value: 4, key: "Forestry"}, {value: 9, key: "Healthcare"}, {value: 1, key: "Hospitality"}, {value: 25, key: "Human Resources"}, {value: 24, key: "Legal"}, {value: 20, key: "Life Sciences"}, {value: 11, key: "Manufacturing"}, {value: 12, key: "Mining"}, {value: 13, key: "Personal Services"}, {value: 15, key: "Real Estate"}, {value: 16, key: "Retail"}, {value: 14, key: "Security"}, {value: 21, key: "Technology"}, {value: 22, key: "Tourism"}, {value: 17, key: "Transport and Logistics"}, {value: 18, key: "Warehousing"}],
        industry: {value: 0, key: ""},
    }),
    props: 
    // ['showLanguageModal']
    {
        showEducationModal: {
            type: Boolean
        }
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        showModal: {
            get () {
                return this.showEducationModal
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
               return this.$emit("close")
            }
        },
        years () {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1980}, (_, index) => 1981 + index).reverse()
        }
    },
    methods: {
        updateLocalTitle(e) {
            this.title = e
        },
        updateLocalSchool(e) {
            this.school = e
        },
        updateLocalStartMonth (e) {
            this.startMonth = e
        },
        updateLocalStartYear (e) {
            this.startYear = e
        },
        updateLocalEndMonth (e) {
            this.endMonth = e
        },
        updateLocalEndYear (e) {
            this.endYear = e
        },
        updateDescription (e) {
            this.description = e
        },
        async updateEducation () {
            let tmp = this.setFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            this.$store.dispatch('user/getUser')
            this.$store.getters.activeUser
            this.showModal = false
            this.resetValues()
        },
        setFields (stateUser) {
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            // Handling the case where we can't update based on a template because there is no template.
            if (tmp.grow.education.length === 0) {
                this.newEducation.title = this.title
                this.newEducation.school = this.school
                this.newEducation.duration = this.endYear.toString()
                this.newEducation.description = this.description
                this.newEducation.educationLevel = this.educationLevel.value
                this.newEducation.qualification = this.qualification.value
                this.newEducation.state = 0
                this.newEducation.industry = this.industry.value
                tmp.grow.education[0] = this.newEducation
                return tmp
            }
            // tmp.grow.profile = this.profileContent
            tmp.grow.education[tmp.grow.education.length] = JSON.parse(JSON.stringify(tmp.grow.education[tmp.grow.education.length-1]))
            // for (let i = 0; i < tmp.grow.education.length; i++) {
            //     console.log("education: ", tmp.grow.workExperience[i])
            // }
            //  = tmp.grow.workExperience[tmp.grow.workExperience.length -1]
            tmp.grow.education[tmp.grow.education.length-1].title = this.title
            tmp.grow.education[tmp.grow.education.length-1].school = this.school
            tmp.grow.education[tmp.grow.education.length-1].duration = this.endYear.toString()
            tmp.grow.education[tmp.grow.education.length-1].description = this.description
            tmp.grow.education[tmp.grow.education.length-1].educationLevel = this.educationLevel.value
            tmp.grow.education[tmp.grow.education.length-1].qualification = this.qualification.value
            tmp.grow.education[tmp.grow.education.length-1].state = 0
            tmp.grow.education[tmp.grow.education.length-1].industry = this.industry.value
            // console.log("tmp user after change: ", tmp)
            return tmp
        },
        resetValues() {
            this.title = ""
            this.school = ""
            this.description = ""
            this.endYear = 2022
            this.educationLevel = {name:"High School", value: 1},
            this.qualification = null
            this.industry = {value: 0, key: ""}
        }
    }
}
</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.this-right {
  box-sizing: border-box;
  background: #3C7872;
  color: #3c7872;
  min-height: 94vh;
} 
.right {
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  display: flex;
  align-items: center;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.left-row {
  width: 100%;
  justify-content: left;
  padding: 20px;
}
.left-row-small {
  width: 100%;
  justify-content: left;
  padding: 30px;
  margin-bottom: 50px;
}
.this-signin-btn {
  width: 51%;
  color: #3c7872;
  margin-bottom: 15px;
}
.this-enter-btn {
  width: 51%;
  color: white;
  margin-bottom: 15px;
}
.text {
  justify-content: center;
  padding: 30px;
}
.align {
  justify-content: center;
  width: 100%;
}
.buttons {
  margin: 0;
  justify-content: center;
}
.h2 {
  text-align: center;
  color: rgb(196, 196, 196);
  width: 100%;
}
.h2-bold {
  text-align: center;
  color: rgba(255, 255, 255);
  width: 100%;
}

.img{
  margin-top: 100px;
}
.active-btn{
    background: #3C7872;
}
.edit-btn {
  width: 80%;
  color: #000000;
  background: #ffffff;
  margin: 10px;
  margin-top: 40px;
}
.name{
  font-weight: 700;
}
.rounded-text{
  border-radius: 12px;
}
.title-space{
  margin-bottom: 0px;
}
.save-chip {
  color: white;
}
.cancel-chip {
  color: #3c7872;
}
.edit-btn-save{
    padding: 20px;
    color: white;
}
.edit-btn-cancel{
    padding: 20px;
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 20px;
    padding: 20px;
}
.divider {
    margin-bottom: 20px;
}
.addressCol {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
}
.textArea{
    border-radius: 12px;
    margin-top: 20px;
}
.center {
    justify-content: center;
}
.form {
    margin-top: 30px;
}
.addBtn {
    color: #3C7872;
    margin: 20px;
}
.btnRow {
    justify-content: center;
    align-content: center;
}
.info-row {
    background:#ececec;
    border-radius: 12px;
    padding: 10px;
}
.card{
    margin: 20px;
}
</style>