<template>
  <v-card-text>
    <v-row class="right-row">
      <v-select
          color="#6A2E85"
          v-model="localAttitude"
          :items="items"
          label="Attitude"
          :reduce="item => item.code"
          @change="$emit('update:attitude', $event)"
      ></v-select>
    </v-row>
    <v-row class="right-row">
      <v-select
          color="#6A2E85"
          v-model="localCommunication"
          :items="items"
          label="Communication"
          :reduce="item => item.code"
          @change="$emit('update:communication', $event)"
      ></v-select>
    </v-row>
    <v-row class="right-row">
      <v-select
          color="#6A2E85"
          v-model="localTimeManagement"
          :items="items"
          label="Time Management"
          :reduce="item => item.code"
          @change="$emit('update:timeManagement', $event)"
      ></v-select>
    </v-row>
    <v-row class="right-row">
      <v-select
          color="#6A2E85"
          v-model="localInterview"
          :items="items"
          label="Interview"
          :reduce="item => item.code"
          @change="$emit('update:interview', $event)"
      ></v-select>
    </v-row>
    <v-row class="right-row">
      <v-select
          color="#6A2E85"
          v-model="localStudentOutcome"
          :items="outcomes"
          label="Overall outcome"
          @change ="$emit('update:studentOutcome', $event)"
      ></v-select>
    </v-row>
    <v-row class="right-row">
      <v-checkbox
          v-model="localPriorSalvation"
          label="Already a believer?"
          color="#006b00"
          @change="$emit('update:priorSalvation', $event)"
      ></v-checkbox>
    </v-row>
    <v-row class="right-row">
      <v-checkbox
          v-model="localSalvationCommitment"
          label="Made a commitment to Christ?"
          color="#006b00"
          @change="$emit('update:salvationCommitment', $event)"
      ></v-checkbox>
    </v-row>
    <v-row class="right-row">
      <v-checkbox
          v-model="localDiscipleshipLinked"
          label="Linked to discipleship?"
          color="#006b00"
          @change="$emit('update:discipleshipLinked', $event)"
      ></v-checkbox>
    </v-row>
    <v-row class="right-row">
      <v-checkbox
          v-model="localSkillsCourse"
          label="Continued to do a W4AL skills course?"
          color="#006b00"
          @change ="$emit('update:skillsCourse', $event)"
      ></v-checkbox>
    </v-row>
    <v-row class="right-row">
      <v-checkbox
          v-model="localFurtherEducation"
          label="Pursuing other education?"
          color="#006b00"
          @change ="$emit('update:furtherEducation', $event)"
      ></v-checkbox>
    </v-row>
    <v-row class="right-row">
      <v-checkbox
          v-model="localInterviewSent"
          label="Sent for an interview?"
          color="#006b00"
          @change ="$emit('update:interviewSent', $event)"
      ></v-checkbox>
    </v-row>
    <br>
    <v-checkbox
        v-model="localR50ChallengeBool" label="Show R50 Challenge review?"
        color="#006b00"
    ></v-checkbox>
    <div v-if="localR50ChallengeBool">
      <h3>💰 R50-Challenge Feedback</h3>
      <br>
      <v-row class="right-row">
        <v-select
            color="#6A2E85"
            v-model="localR50Challenge"
            :items="items"
            label="R50-Challenge Participation"
            :reduce="item => item.code"
            @change="$emit('update:r50Challenge', $event)"
        ></v-select>
      </v-row>
      <v-row class="right-row">
        <v-select
            color="#6A2E85"
            v-model="localR50ChallengeMoney"
            :items="r50outcomes"
            label="R50-Challenge Outcome"
            :reduce="item => item.code"
            @change="$emit('update:localR50ChallengeMoney', $event)"
        ></v-select>
      </v-row>
      <v-row class="right-row">
        <v-text-field
            color="#6A2E85"
            v-model="localR50ChallengeComment"
            label="R50-Challenge Comment"
            @change ="$emit('update:r50ChallengeComment', $event)"
        ></v-text-field>
      </v-row>
    </div>
    <v-checkbox
        v-model="localDeepDiveChallengeBool" label="Show DIIP Dive review?"
        color="#006b00"
        ></v-checkbox>
    <div v-if="localDeepDiveChallengeBool">
      <br>
      <h3>️👩🏽‍🤝‍👨🏾 Diipdive Feedback</h3>
      Discipline, Identity, Integrity and Perseverance
      <br>
      <v-row class="right-row">
        <v-select
            color="#6A2E85"
            v-model="localDeepDiveChallenge"
            :items="items"
            label="Diipdive Participation"
            :reduce="item => item.code"
            @change="$emit('update:deepDiveChallenge', $event)"
        ></v-select>
      </v-row>
      <v-row class="right-row">
        <v-text-field
            color="#6A2E85"
            v-model="localDeepDiveChallengeComment"
            label="Diipdive Comment"
            @change ="$emit('update:deepDiveChallengeComment', $event)"
        ></v-text-field>
      </v-row>
      <br>
    </div>
    <h3>☑️ Overall feedback</h3>
    <br>
    <v-row class="right-row">
      <v-text-field
          color="#6A2E85"
          v-model="localFeedback"
          label="Feedback"
          @change ="$emit('update:feedback', $event)"
      ></v-text-field>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: 'EnrollmentFields',
  data () {
    return {
      localDeepDiveChallengeBool: false,
      localR50ChallengeBool: false,
    }
  },
  props: {
    items: Array,
    outcomes: Array,
    r50outcomes: Array,
    attitude: [Number, String],
    communication: [Number, String],
    timeManagement: [Number, String],
    interview: [Number, String],
    studentOutcome: [Number, String],
    priorSalvation: Boolean,
    salvationCommitment: Boolean,
    discipleshipLinked: Boolean,
    skillsCourse: Boolean,
    furtherEducation: Boolean,
    interviewSent: Boolean,
    startedBusiness: Boolean,
    employed: Boolean,
    feedback: String,
    r50Challenge: [Number, String],
    r50ChallengeMoney: [Number, String],
    r50ChallengeComment: String,
    deepDiveChallenge: [Number, String],
    deepDiveChallengeComment: String,
  },
  computed: {
    localAttitude: {
      get() {
        return this.attitude;
      },
      set(value) {
        this.$emit('update:attitude', value);
      }
    },
    localCommunication: {
      get() {
        return this.communication;
      },
      set(value) {
        this.$emit('update:communication', value);
      }
    },
    localTimeManagement: {
      get() {
        return this.timeManagement;
      },
      set(value) {
        this.$emit('update:timeManagement', value);
      }
    },
    localInterview: {
      get() {
        return this.interview;
      },
      set(value) {
        this.$emit('update:interview', value);
      }
    },
    localStudentOutcome: {
      get() {
        return this.studentOutcome;
      },
      set(value) {
        this.$emit('update:studentOutcome', value);
      }
    },
    localPriorSalvation: {
      get() {
        return this.priorSalvation;
      },
      set(value) {
        this.$emit('update:priorSalvation', value);
      }
    },
    localSalvationCommitment: {
      get() {
        return this.salvationCommitment;
      },
      set(value) {
        this.$emit('update:salvationCommitment', value);
      }
    },
    localDiscipleshipLinked: {
      get() {
        return this.discipleshipLinked;
      },
      set(value) {
        this.$emit('update:discipleshipLinked', value);
      }
    },
    localSkillsCourse: {
      get() {
        return this.skillsCourse;
      },
      set(value) {
        this.$emit('update:skillsCourse', value);
      }
    },
    localFurtherEducation: {
      get() {
        return this.furtherEducation;
      },
      set(value) {
        this.$emit('update:furtherEducation', value);
      }
    },
    localInterviewSent: {
      get() {
        return this.interviewSent;
      },
      set(value) {
        this.$emit('update:interviewSent', value);
      }
    },
    localStartedBusiness: {
      get() {
        return this.startedBusiness;
      },
      set(value) {
        this.$emit('update:startedBusiness', value);
      }
    },
    localEmployed: {
      get() {
        return this.employed;
      },
      set(value) {
        this.$emit('update:employed', value);
      }
    },
    localFeedback: {
      get() {
        return this.feedback;
      },
      set(value) {
        this.$emit('update:feedback', value);
      }
    },
    localR50Challenge: {
      get() {
        return this.r50Challenge;
      },
      set(value) {
        this.$emit('update:r50Challenge', value);
      }
    },
    localR50ChallengeMoney: {
      get() {
        return this.r50ChallengeMoney;
      },
      set(value) {
        this.$emit('update:r50ChallengeMoney', value);
      }
    },
    localR50ChallengeComment: {
      get() {
        return this.r50ChallengeComment;
      },
      set(value) {
        this.$emit('update:r50ChallengeComment', value);
      }
    },
    localDeepDiveChallenge: {
      get() {
        return this.deepDiveChallenge;
      },
      set(value) {
        this.$emit('update:deepDiveChallenge', value);
      }
    },
    localDeepDiveChallengeComment: {
      get() {
        return this.deepDiveChallengeComment;
      },
      set(value) {
        this.$emit('update:deepDiveChallengeComment', value);
      }
    },
  }
};
</script>

