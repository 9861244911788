<template>
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
        <v-row class="info-right-row">
          <v-icon color=#30ac7c right size=150 > mdi-account-check-outline </v-icon>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="4" class="right">
        <v-row class="right-input"> 
          <v-row class="info-right-row"> 
            <h2 class="info-h2">Please tell us more about yourself</h2>
          </v-row>
          <v-row class="textfield">  
          <v-form @submit.prevent="submit" class="width">
            <v-row v-if="this.facilitator" class="info-right-row"> 
              <v-autocomplete
                v-model="hub"
                :loading="loading"
                :items="hubs"
                item-text="hubName"
                cache-items
                return-object
                label="Select hub"
                color="#3c7872"
                dark
                dense
                filled
              ></v-autocomplete>
            </v-row>
            <v-row class="info-right-row">  
              <v-text-field
                v-model="firstName"
                label="First Name"
                required
                outlined
                dense
                dark
                filled
                class="textfield"
              ></v-text-field>
            </v-row>
            <v-row class="info-right-row"> 
              <p class="red--text" v-if="errors.firstName">{{errors.firstName}}</p>
            </v-row>
            <v-row class="info-right-row"> 
              <v-text-field
                v-model="lastName"
                label="Last Name"
                required
                outlined
                dense
                dark
                filled
              ></v-text-field>
            </v-row>
            <v-row class="info-right-row"> 
              <v-text-field
                v-model="identificationNumber"
                label="Identification Number (ID)"
                required
                outlined
                dense
                dark
                filled
              ></v-text-field>
            </v-row>
            <v-row v-if="!this.facilitator && !activator && !this.secondaryEnter" class="info-right-row"> 
              <v-text-field
                v-model="email"
                label="Email (optional)"
                required
                outlined
                dense
                dark
                filled
              ></v-text-field>
            </v-row>
            <v-row class="info-right-row" v-if="errors.email">  
              <p class="red--text" v-if="errors.email">{{errors.email}}</p>
            </v-row>  
            <v-row class="info-right-row">  
              <v-select 
                v-model="gender"
                label="Gender" 
                :items="genders"
                :reduce="gender => gender.code"
                outlined
                dark
                filled
                dense
                class="select"
                >
              </v-select>
            </v-row>
            <v-row class="info-right-row">  
              <v-select 
                v-model="race"
                label="Race" 
                :items="races"
                :reduce="race => race.code"
                outlined
                dark
                filled
                dense
                >
              </v-select>
            </v-row>
            <v-row class="info-right-row">  
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      label="Date of birth"
                      v-model="date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      light
                      outlined
                      dark
                      filled
                      dense
                      append-icon="mdi-calendar"
                  >
                  
                  </v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  no-title
                  :active-picker="activePicker"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  min="1930-01-01"
                  @change="save"
                  color="#30ac7c"
                  class="classDates mt-4"
              ></v-date-picker>
              </v-menu>
            </v-row>
              <!-- </v-row> -->
          </v-form>
          </v-row>
          <v-btn v-if="(this.firstName === '') || this.gender === 0 || this.race === -1 || (this.errors.firstName !== '') || (this.hub === '' && facilitator)" class="info-signin-btn" disabled rounded>Submit</v-btn>
          <v-btn v-else class="info-signin-btn" rounded @click="createMember">Submit</v-btn>

          <!-- <v-btn class="info-signin-btn-2" outlined rounded :to="{ name: 'HomeUser', params: { firstName: this.firstName}}" @click.native="createMember" >Skip</v-btn> -->
          <!-- <v-btn v-else class="signin-btn-2" outlined rounded to="/login">Skip</v-btn> -->
        </v-row>
      </v-col> 
    </v-row>
</template>

<script>
import {CREATE_MEMBER} from "../../apollo/mutations"
import {GET_TOKEN, LIST_HUBS} from "../../apollo/queries"
import {apolloClient } from "../../main"
export  default {
    name: 'InfoPage',
    props: {
        phoneNumber: {
            type: String,
            default: '{ERROR}'
        },
        // facilitator: {
        //   type: Boolean,
        //   deafult: false
        // },
        // activator: {
        //   type: Boolean,
        //   deafult: false
        // },
        secondaryEnter: {
          type: Boolean,
          default: false
        }
    },
    computed: {
      facilitator () {
        return this.$store.state.facilitator
      },
      activator () {
        return this.$store.state.activator
      }
    },
    data: () => ({
      hub: "",
      loading: false,
      date: null,
      menu: false,
      activePicker: null,
      firstName: "",
      lastName: "",
      identificationNumber: "",
      email: "",
      errors: [
        {'firstName': ""},
        {'lastName': ""},
        {'email': ""}
      ],
      gender: 0,
      genders : [
        {value: 1, text: 'Female'},
        {value: 2, text: 'Male'},
      ],
      race: -1,
      races : [
        {value: 0, text: 'Other'},
        {value: 1, text: 'Black'},
        {value: 2, text: 'Asian'},
        {value: 3, text: 'Coloured'},
        {value: 4, text: 'White'},
        {value: 5, text: 'Middle Eastern'}, 
        {value: 6, text: 'Indian'},
        {value: 7, text: 'Mixed'},
      ],
      birthDate: "",
      hubs: []
    }),
    mounted () {
      this.listHubs()
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      email(value){
        // binding this to the data value in the email input
        this.validateEmail(value);
      },
      firstName(value){
        this.validateName(value)
      },
      lastName(value){
        this.validateLastName(value)
      }
    },
    methods: {
      async listHubs() {
        await apolloClient.query({
          query: LIST_HUBS,
        })
        .then((response) => {
          for (var i = 0; i < response.data.listHubs.length; i++) {
            this.hubs.push(response.data.listHubs[i].name)
          }
        })
      },
      validateName (value) {
         // Support international names with unicode:
        const regEx = /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð-]* ?)*?$/u;
        if (regEx.test(value)) {
          this.errors['firstName'] = '';
        } else{
          this.errors['firstName'] = 'Invalid first name';
          console.log(this.errors.firstName)
        } 
      },
      validateLastName (value) {
         // Support international names with unicode:
        const regEx = /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð-]* ?)*?$/u;
        if (regEx.test(value)) {
          this.errors['lastName'] = '';
        } else{
          this.errors['lastName'] = 'Invalid last name';
          console.log(this.errors.firstName)
        } 
      },
      validateEmail (value) {
        if (value !== '') { 
          const regEx = /^\w+([.-]]?\w+)*@\w+([.-]?\w+)*(\.\w+)+ *$/;
          if (regEx.test(value)) {
            this.errors['email'] = '';
          } else{
            this.errors['email'] = 'Invalid Email Address';
          } 
        } else {
          this.errors['email'] = '';
        }
      },
      save (date) {
        this.$refs.menu.save(date)
      },
    async createMember () {
      let memberRole
      // Set member role and default hub for Activators as Small Group
      if (this.facilitator) {
        memberRole = 2
      } else if (this.activator) {
        memberRole = 3
        this.hub = "SMALL GROUP"
      } else {
        memberRole = 1
      }
      let e
      if (this.facilitator || this.secondaryEnter || this.activator) {
        e = this.$store.state.email
      } else {
        e = this.email
      }
      await apolloClient.mutate({
        mutation: CREATE_MEMBER,
        variables: {
          name: this.firstName,
          phoneNumber: this.phoneNumber,
          email: e,
          gender: this.gender,
          familyName: this.lastName,
          givenName: this.firstName,
          race: this.race,
          birthDate: this.date,
          role: memberRole,
          baseHub: this.hub,
          identificationNumber: this.identificationNumber,
          highSchoolGraduate: false,
          universityGraduate: false
        }
      })
      .then((data) => {
        this.getToken()
        this.$store.dispatch("user/getUser")
        console.log("data: ", data)
        this.$store.commit('setFirstName', this.firstName)
        this.verified = true;
        this.$store.commit('authenticate')
        if (this.facilitator) {
          this.$router.push({ name: 'HomeFacilitator', params: { firstName: this.firstName}})
        } else if (this.activator) {
          this.$router.push({ name: 'HomeActivator', params: { firstName: this.firstName}})
        } else {
          if (this.$store.state.enrolling) {
            console.log(this.$store.state.enrollingClass)
            console.log(this.$store.state.enrollingClass)
            this.$router.push({name: 'ClassEnrollment', params: {id: this.$store.state.enrollingClass}})
          } else {
            this.$router.push({name: 'InfoStepper'})
          }
        }
      }).catch((error) => {
        // Error
        console.error(error)
      })
    },
    async getToken() {
        await apolloClient.query({
          query: GET_TOKEN,
          variables: {
            phoneNumber: this.phoneNumber
          }
        })
        .then((response) => {
          // Result
          this.$store.commit('updateAccessToken', response.data.getToken)
          this.$store.commit('setPhoneNumber', this.phoneNumber)
          this.$store.commit('setJWT', response.data.getToken[0])
          this.$store.commit('setRefreshToken', response.data.getToken[1])
        })
    }
    },
    
    
  }
</script>

<style scoped>
.sectioncontainer {
    background: #fff;
    width: 100%;
}
.signin {
  margin: 0;
  height: 100%;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.info-right-row {
  min-width: 100%;
  justify-content: center;
  padding: 2px;
}
.info-h2 {
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
}
.info-signin-btn {
  width: 80%;
  color: #3c7872;
  margin: 10px;
  margin-top: 20px;
}
.info-signin-btn-2 {
  width: 80%;
  color: white;
  margin: 10px;
}
.text-white >>> .v-text-field__slot input {
    color: white
}
.error{
  font: red
}
.verified-icon{
  text-align: center;
  display: flex;
  margin-top: 100px;
}
.textfield{
  justify-content: center;
}
.classDates {
    justify-content: center;
    width: 20;
}
.width{
  width: 70%;
  
}
  </style>
   