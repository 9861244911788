<template>
  <v-row class="signin">
    <v-col cols="12" class="right" >
     <v-row class="right-input">
      <v-row class="right-row">
        <!-- <v-img
          class="img hidden-sm-and-down"
          :src="require('../../assets/w4al.jpeg')"
          contain
          height="200"
          max-width="60%"
        /> -->
        <img 
          class="img hidden-sm-and-down"
          :src="require('../../assets/maintenance.gif')"
          height="400"
        />
        <img 
          class="img hidden-sm-and-up"
          :src="require('../../assets/maintenance.gif')"
          height="200"
        />
        <!-- <v-img
          class="img hidden-md-and-up"
          :src="require('../assets/w4al.jpeg')"
          contain

        /> -->
      </v-row>
        
        <v-row class="left-row"> 
          <h1 class="h2-bold hidden-sm-and-down font-weight-black">
            Sorry, we are currently down for maintenance.
          </h1>
          <h1 class="h2 hidden-sm-and-down font-weight-thin">
            We've got something special in store.
          </h1>
        </v-row>
        <v-row class="left-row-small">
          <!-- <h2 class="h2 hidden-md-and-up font-weight-thin">
            Empowering individuals 
          </h2>
          <h2 class="h2-bold hidden-md-and-up font-weight-black">
            to excel in the workplace. 
          </h2> -->
          <h2 class="h2-bold hidden-md-and-up font-weight-black">
            Sorry, we are currently down for maintenance.
          </h2>
          <h2 class="h2 hidden-md-and-up font-weight-thin">
            We've got something special in store.
          </h2>
        </v-row>
        <div class="share-network-list">
        </div>
     </v-row>
    </v-col>
    </v-row>
    <!-- </v-parallax> -->
</template>

<script>
    export default {
        name: 'MaintenancePage',
        data: () => ({
          firstName: "SonnyBill",
          phoneNumber: "+27 74 419 0174",
          image: {backgroundImage: "../../assets/Untitled.png"},
        }),
        beforeMount(){
        },
    }
</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.this-right {
  box-sizing: border-box;
  background: #3C7872;
  color: white;
  min-height: 94vh;
} 
.right {
  box-sizing: border-box;
  background:  #F0EEF3;
  color: #fff;
  display: flex;
  align-items: center;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.left-row {
  width: 100%;
  justify-content: left;
  padding: 20px;
}
.left-row-small {
  width: 100%;
  justify-content: left;
  padding: 30px;
  margin-bottom: 50px;
}
.this-signin-btn {
  width: 51%;
  color: #3c7872;
  margin-bottom: 15px;
}
.this-enter-btn {
  width: 51%;
  color: white;
  margin-bottom: 15px;
  text-transform: lowercase;
}
.this-fac-btn {
  width: 51%;
  color: white;
  margin-top: 100px;
}
.text {
  justify-content: center;
  padding: 30px;
}
.align {
  justify-content: center;
  width: 100%;
}
.buttons {
  margin: 0;
  justify-content: center;
  width: 100%;
  padding: 5px;
}
.buttons-2 {
  margin-top: 30px;
  justify-content: center;
}
.h2 {
  text-align: center;
  color: rgb(196, 196, 196);
  width: 100%;
}
.h2-bold {
  text-align: center;
  color: #3c7872;
  width: 100%;
}

.img{
  margin-top: 100px;
}
</style>