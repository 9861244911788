<template>
    <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable>
        <v-card
            class="mx-auto"
            
        >
        <v-tabs fixed-tabs color="#3c7872" v-model="activeTab">
            <v-tab>Quick build</v-tab>
            <v-tab>Write your own</v-tab>
            <v-tab-item>
            <!-- <v-container fluid> -->
                <v-card-title class="text-h6 font-weight-regular justify-space-between">
                    <span>{{ currentTitle }}</span>
                    <v-avatar
                        color="#6A2E85"
                        class="subheading white--text"
                        size="24"
                        v-text="step"
                    ></v-avatar>
                    </v-card-title>
                    <v-card-subtitle>
                        <span class="grey--text text--darken-1">
                            {{currentSubTitle}}
                        </span>
                    </v-card-subtitle>
                    <v-window v-model="step">
                    <v-window-item :value="1">
                        <v-card-text>
                            <v-text-field
                                label="Where do you live?"
                                :value=activeUser.sublocality
                                v-model="areaOfResidence"
                                hint="This is the city/town you live in. For example, East London."
                                :rules="wordsRules"
                                counter="3"
                                color="#3c7872"
                            >
                                <template v-slot:counter="{ props }">
                                    <v-counter v-bind="props" :value="employment.trim().split(' ').length"></v-counter>
                                </template>
                            </v-text-field>
                            <!-- </v-card-text>
                            <v-card-text> -->
                            <v-select
                                label="Are you employed?"
                                :items=items
                                v-model="selected"
                                color="#3c7872"
                            >
                            </v-select>
                            <v-text-field
                                label="What is your current job?"
                                v-if="selected==='Yes'"
                                :rules="wordsRules"
                                counter="3"
                                hint="This should be short. Example: truck driver or waiter."
                                v-model="employment"
                                color="#3c7872"
                            >
                                <template v-slot:counter="{ props }">
                                    <v-counter v-bind="props" :value="employment.trim().split(' ').length"></v-counter>
                                </template>
                            </v-text-field>
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-text style="max-height: 30vh; overflow: scroll">
                            <v-chip-group
                                column
                                multiple
                                active-class="deep-gray--text text--accent-4"
                                max="3"
                                v-model="strIndex"
                            >
                                <v-chip
                                    filter
                                    outlined
                                    v-for="tag in strengths"
                                    :key="tag"
                                >
                                {{ tag }}
                                </v-chip>
                            </v-chip-group>
                        <!-- </div> -->
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="3">
                        <v-card-text style="max-height: 30vh; overflow: scroll">
                            <v-chip-group
                                column
                                multiple
                                active-class="deep-gray--text text--accent-4"
                                max="2"
                                v-model="weIndex"
                            >
                                <v-chip
                                    filter
                                    outlined
                                    v-for="tag in weaknesses"
                                    :key="tag"
                                >
                                {{ tag }}
                                </v-chip>
                            </v-chip-group>
                        </v-card-text>

                    </v-window-item>

                    <v-window-item :value="4">
                        <v-card-text style="max-height: 30vh; overflow: scroll">
                            <v-chip-group
                                column
                                multiple
                                active-class="deep-gray--text text--accent-4"
                                max="3"
                                v-model="indIndex"
                            >
                                <v-chip
                                    filter
                                    outlined
                                    v-for="tag in industries"
                                    :key="tag"
                                >
                                {{ tag }}
                                </v-chip>
                            </v-chip-group>
                        <!-- </div> -->
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="5">
                        <v-card-text style="max-height: 30vh; overflow: scroll">
                        
                        </v-card-text>
                    </v-window-item>
                    </v-window>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <!-- <v-btn
                        :disabled="step === 1"
                        text
                        @click="step--"
                    >
                        Rebuild
                    </v-btn> -->
                    <v-spacer></v-spacer>
                    <v-btn
                        class="nextBtn"
                        v-if="step < 4"
                        depressed
                        @click="step++"
                    >
                        Next
                    </v-btn>
                    <v-btn
                        class="nextBtn"
                        v-if="step === 4"
                        depressed
                        @click="getBio()"
                    >
                        Next
                    </v-btn>
                    <!-- Not currently using this step. We call 
                    showModal in updateProfileContent. Users 
                    can edit their info in write your own tab. -->
                    <v-btn
                        v-if="step===5"
                        class="nextBtn"
                        depressed
                        @click="step=1"
                    >
                        Rebuild profile
                    </v-btn>
                </v-card-actions>
            <!-- </v-container> -->
            </v-tab-item>
            <v-tab-item>
                <v-card-text style="max-height: 30vh; overflow: scroll">
                    <v-textarea
                        name="input-7-1"
                        filled
                        label="Bio"
                        style="overflow: scroll;"
                        color="#3c7872"
                        v-model="profileContent"
                        >
                        <!-- :value="activeUser.grow.profile"
                        @input="updateLocalBio($event)" -->
                    </v-textarea>
                </v-card-text>
                <v-card-actions style="justify-content: center">
                    <v-btn
                        class="nextBtn"
                        depressed
                        @click="setNewCustomProfile(profileContent)"
                    >
                        Done
                    </v-btn>
                </v-card-actions>
            </v-tab-item>
        </v-tabs>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: 'ProfileModal',
    data: () => ({
        profileContent: "",
        step: 1,
        selected: 'No',
        items: ['No', 'Yes'],
        wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 3 words'],
        employment: '',
        areaOfResidence: '',
        localBio: "",
        strengths: [
            'leadership',
            'communication',
            'teamwork',
            'being detail-oriented',
            'analytical thinking',
            'multitasking',
            'problem solving',
            'organizing',
            'management skills',
            'decision making',
            'writing',
            'marketing',
            'sales',
            'listening',
            'work ethic',
            'adaptability',
            'Microsoft Office',
            'time management',
            'honesty',
            'creativity',
            'patience',
            'public speaking',
            'logical thinking',
            'professionalism',
            'conflict resolution'
        ],
        weaknesses: [
            'self-criticism',
            'people-pleasing',
            'technology',
            'communication',
            'perfectionsim',
            'being too detail orientated',
            'procrastination',
            'teamwork',
            'being shy',
            'saying no',
            'public speaking',
            'conflict resolution',
            'impatience',
            'multitasking'
        ],
        industries: [
            // https://www.indeed.com/career-advice/finding-a-job/types-of-industry
            'technology',
            'advertising and marketing',
            'hospitality',
            'construction',
            'transport',
            'sales',
            'media',
            'agriculture',
            'logistics',
            'finance',
            'design',
            'food and beverage',
            'health care',
            'education',
            'energy',
            'entertainment',
            'mining',
            'manufacturing',
            'fashion'
        ],
        strIndex: [],
        weIndex: [],
        indIndex: [],
        active_tab: 0,
    }),
    props: 
    // ['showLanguageModal']
    {
        showProfileModal: {
            type: Boolean
        }
    },
    async beforeMount() {
      await this.$store.dispatch('user/getUser');
      // After dispatching the 'getUser' action, we can get the user data from the store
      this.user = this.$store.state.user;
    },
    computed: {
        ...mapGetters('user', ['activeUser']),
        currentTitle () {
            switch (this.step) {
                case 1: return 'Personal Info'    
                case 2: return 'Strengths'
                case 3: return 'Weaknesses'
                case 4: return 'Industries of work'
                default: return 'Your profile is populated.'
            }
        },
        currentSubTitle () {
            switch (this.step) {
                case 1: return 'Edit the required fields if necessary'    
                case 2: return 'Select three of your strengths.'
                case 3: return 'Select at least one of your weaknesses.'
                case 4: return 'Select up to three industries you could work in.'
                default: return 'Click the "WRITE YOUR OWN" tab to view your content.'
            }
        },
        showModal: {
            get () {
                return this.showProfileModal
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
               return this.$emit("close")
            }
        },
        activeTab () {
            if (this.activeUser.grow.profile !== '') {
                return 1
            } else {
                return 0
            }
        }
    },
    mounted () {
        this.profileContent = this.activeUser.grow.profile
    },
    methods: {
        updateLocalBio(e) {
            this.localBio = e
        },
        setNewCustomProfile(val) {
          console.log('Profile Content:', val); // Debug Log
            this.updateProfileContent(val)
            this.updateProfile()
        },
        getBio () {
            var s1, s2, s3, s4, i
            this.step++
            // console.log("IN GET BIO!!")
            // Info
            if (this.activeUser.sublocality !== null && this.employment !== '') {
                s1 = 'Hi, my name is ' + this.activeUser.givenName + ' ' + this.activeUser.familyName + '. '
                s1 += "I live in " + this.activeUser.sublocality + " and I work as a " + this.employment + ". "
            } else if (this.areaOfResidence !== '' && this.employment !== '') {
                s1 = 'Hi, my name is ' + this.activeUser.givenName + ' ' + this.activeUser.familyName + '. '
                s1 += "I live in " + this.areaOfResidence + " and I work as a " + this.employment + ". "
            } 
            else if (this.activeUser.sublocality !== null) {
                s1 = 'Hi, my name is ' + this.activeUser.givenName + ' ' + this.activeUser.familyName + ' and I live in ' + this.activeUser.sublocality + '. '
            } else if (this.areaOfResidence !== '') {
                s1 = 'Hi, my name is ' + this.activeUser.givenName + ' ' + this.activeUser.familyName + ' and I live in ' + this.areaOfResidence + '. '
            } else if (this.employment !== "") {
                s1 = 'Hi, my name is ' + this.activeUser.givenName + ' ' + this.activeUser.familyName + ' and I work as a ' + this.employment + '. '
            } else {
                s1 = 'Hi, my name is ' + this.activeUser.givenName + ' ' + this.activeUser.familyName + ". "
            }
            
            // Strengths
            if (this.strIndex.length === 0) {
                s2 = ""
            } else if (this.strIndex.length === 1) {
                s2 = 'My biggest strength is '
                s2 += this.strengths[this.strIndex[0]] + ". "
            } else {
                s2 = 'My strengths include '
                for (i = 0; i < this.strIndex.length - 1; i++) {
                    if (i === this.strIndex.length - 2) {
                        s2 += this.strengths[this.strIndex[i]]
                    } else {
                        s2 += this.strengths[this.strIndex[i]] + ', '
                    }
                    // s2 += this.strengths[this.strIndex[i]] + ', '
                }
                s2 += ' and ' + this.strengths[this.strIndex[this.strIndex.length-1]] + '. '
            }
            
            // Weaknesses
            if (this.weIndex.length === 0) {
                s3 = ""
            } else if (this.weIndex.length === 1) {
                s3 = 'A weakness of mine is '
                for (i = 0; i < this.weIndex.length - 2; i++) {
                    s3 += this.weaknesses[this.weIndex[i]]
                }
                s3 += this.weaknesses[this.weIndex[this.weIndex.length-1]] + '. However, I am aware of this weakness and I am actively working on it. '
            } else {
                s3 = 'Some of my weaknesses are '
                s3 += this.weaknesses[this.weIndex[this.weIndex.length-2]] + ' and ' + this.weaknesses[this.weIndex[this.weIndex.length-1]] + '. However, I am aware of these weaknesses and I am actively working on them. '
            }
            
            
            // Industries
            if (this.indIndex.length === 0) {
                s4 = ""
            } else {
                s4 = 'I am open to employment opportunities in any of the following industries: ' 
                for (i = 0; i < this.indIndex.length - 1; i++) {
                    if (i === this.indIndex.length - 2) {
                        s4 += this.industries[this.indIndex[i]]
                    } else {
                        s4 += this.industries[this.indIndex[i]] + ', '
                    }
                }
                if (this.indIndex.length === 1) {
                    s4 += this.industries[this.indIndex[this.indIndex.length-1]] + '.'
                } else {
                    s4 += ' and ' + this.industries[this.indIndex[this.indIndex.length-1]] + '.'
                }
            }
            this.profileContent = s1 + s2 + s3 + s4
            this.updateProfile()
            return s1 + s2 + s3 + s4
        },
        updateProfileContent(e) {
            this.profileContent = e
        },
        async updateProfile () {
            let tmp = this.setFields(this.activeUser)
          console.log('Temp object for updateUser:', tmp); // Debug Log
            await this.$store.dispatch('user/updateUser', tmp)
            await this.$store.dispatch('user/getUser')
            this.$store.getters.activeUser
            this.showModal = false
        },
        setFields (stateUser) {
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            tmp.grow.profile = this.profileContent
            // console.log("tmp user after change: ", tmp)
            // // tmp.languageCodes = this.activeUser.languageSkills
            // // tmp.languageSkills = this.activeUser.languageSkills
            // for (let i = 0; i < tmp.languageCodes.length; i++) {
            //     console.log("code: ", tmp.languageCodes[i])
            //     console.log("skill: ", tmp.languageSkills[i])
            // }
            // tmp.languageCodes[tmp.languageCodes.length] = this.spokenLanguage
            // tmp.languageSkills[tmp.languageSkills.length]= this.level
            return tmp
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.textArea{
    border-radius: 12px;
    margin-top: 20px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 10px;
}
.nextBtn {
    background: #d0d0d0;
    color: #3c7872;
}
</style>
