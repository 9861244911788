<template>
    <v-row class="signin">
        <v-col cols="12">
            <v-row class="right-input" style="width: 90%; max-width: 500px;">
                <v-row class="title">
                    <v-card-title>Create class</v-card-title>
                </v-row>
                <v-row class="cardRow"> 
                    <div>Select which course you will be running 
                    and then select the dates of the class. Once the class is created, you can register students for the class</div>
                </v-row>
                <v-row class="btnRow"> 
                    <v-chip-group column mandatory v-model="course" active-class="active-btn  white--text">
                        <v-chip filter class="chip">Job Readiness</v-chip>
                        <v-chip filter class="chip">WIRED</v-chip>
                    </v-chip-group>
                </v-row>
                <v-row class="btnRow">
                    <v-autocomplete
                        v-model="hub"
                        :items="hubs"
                        cache-items
                        return-object
                        solo
                        label="Select location"
                        color="#3c7872"
                    ></v-autocomplete>
                </v-row>
                <div class="dateRow" style="width: 90%" v-if="this.dates.length <= 1">
                    <v-date-picker
                        width="90%"
                        v-model="dates"
                        range
                        no-title
                        color="#6A2E85"
                        elevation="3"
                        class="classDates mt-4"
                        :min=dates[0]
                    ></v-date-picker>
                </div>
                <div class="dateRow" style="width: 90%" v-else>
                    <v-date-picker
                        v-model="dates"
                        range
                        no-title
                        color="#6A2E85"
                        elevation="3"
                        width="90%"
                        class="classDates mt-4"
                    ></v-date-picker>
                </div>
                    
                
                <v-row class="cardRow" width="100%">
                  <v-btn :disabled="this.hub === '' || this.hub === null || !validDates(dates)"
                         :color="this.hub === '' || this.hub === null || !validDates(dates) ? 'grey' : 'teal'"
                         class="create-btn"
                         rounded
                         large
                         @click="createClass()">
                    <v-progress-circular v-if="loading"
                                         indeterminate
                                         color="white"
                                         size="24">
                    </v-progress-circular>
                    <span v-else :style="{color: this.hub !== '' && this.hub !== null && validDates(dates) ? 'white' : ''}">Create</span>
                  </v-btn>

                </v-row>
                <v-row class="cardRow" width="100%" v-if="!validDates(dates)" > 
                    <h5 class="red--text">{{invalidClassError}}</h5>
                </v-row> 
                <v-row class="cardRow" width="100%" v-if="this.showErr" > 
                    <h5 class="red--text">Unable to create class. Make sure the dates are unique.</h5>
                </v-row>
               
                <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable> 
                    <v-card>
                        <v-card-title class="justify-center grey lighten-3 title-space">
                            Class Created
                        </v-card-title>
                        <v-card-text>
                            <v-row class="cardRow"> 
                                Share the invitation on your class Whatsapp group. This allows students to enroll in the class and complete the pre-class check in.
                            </v-row>
                            <v-row class="cardRow"> 
                                <ShareNetwork 
                                    network="whatsapp"
                                    url=""
                                    title="Welcome to W4AL! Follow this link to enroll in the class." 
                                    :description="this.url"
                                >
                                    <v-btn
                                        class="this-share-btn"
                                        rounded
                                        text
                                        large
                                        v-if="this.$store.state.isAuthenticated"
                                    >
                                        Invite 
                                        <v-icon>mdi-share-variant</v-icon>
                                    </v-btn>
                                </ShareNetwork>               
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
  import { mapGetters } from "vuex";
  import {CREATE_CLASS} from "@/apollo/mutations";
  import {LIST_HUBS, LIST_MEMBERS} from "@/apollo/queries";
  import { apolloClient } from '@/main'
  export  default {
    name: 'ClassBuilder',
    computed: {
      ...mapGetters('user', ['activeUser']),
        dateRangeText () {
            return this.dates.join(' - ')
        },
    },
    data: () =>  ({
        dates: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(Date.now() + 600000000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
        showModal: false,
        course: 0,
        showErr: false,
        courseKeys: ["W4ALF-English-2022","W4B-English-2022"],
        url: "",
        classId: "",
        phase: 2,
        invalidClassError: "",
        coursePhaseOptions: [
            {text: "Foundations", value: 1}, 
            {text: "Phase 2", value: 2}
        ],
        ph2Classes: [
            {text: "MSOFFICE", value: "Microsoft Office"}, 
            {text: "MSWORD", value: "Microsoft Word"},
            {text: "MSEXCEL", value: "Microsoft Excel"},
            {text: "OFFICEADM", value: "Office Admin"},
            {text: "RETAIL", value: "Retail"},
            {text: "COMPUTERS", value: "Computers"},
        ],
        loading: false,
        hub: "",
        hubs: []

    }),
    async mounted () {
        await this.listHubs()
        await this.listMembers()
        this.hub = this.activeUser.baseHub
    },
    methods: {
      async listMembers () {
        await apolloClient.query({
          query: LIST_MEMBERS,
          variables: {
            filter: "facilitators"
          }
        }).then(response => {
          console.log("ClassBuilder: response.data.listMembers: ", response.data.listMembers)
        }).catch(ball => {
          console.log("Ball is: ", ball)
        })
      },
      async listHubs() {
        await apolloClient.query({
          query: LIST_HUBS,
        })
            .then((response) => {
              console.log("ClassBuilder listHubs: ", response.data.listHubs)
              for (var i = 0; i < response.data.listHubs.length; i++) {
                this.hubs.push(response.data.listHubs[i].name)
              }
            })
      },
      async createClass () {
        this.loading = true;
        await apolloClient.mutate({
          mutation: CREATE_CLASS,
          variables: {
            course: this.courseKeys[this.course],
            name: this.activeUser.givenName + "" + this.activeUser.familyName +  "|" + this.dates[0],
            startDate: this.dates[0],
            endDate: this.dates[1],
            facilitator: this.activeUser.phoneNumber.replace(/ /g,''),
            classFormat: 1,
            hub: this.hub
          }
        }).then(response => {
                if (response !== null) {
                    // this.showModal = true
                    // this.url = "https://ep.w4al.services/classenrolment/" + this.activeUser.givenName + "|" + this.activeUser.familyName + "|" + this.courseKeys[this.course] + "|" + this.dates[0]
                    this.showErr = false
                    this.$store.commit("setCurrentClassStartDate", this.dates[0])
                    this.$store.commit("setCurrentClassEndDate", this.dates[1])
                    this.$store.commit("setCurrentClassCourseName", this.courseKeys[this.course])
                    // console.log("RESPONSE: ", response.data.createClass)
                    this.$store.commit("setCurrentClassName", response.data.createClass)
                    // console.log("STORE: ", this.$store.state.currentClassName)
                    // this.$store.commit("setCurrentClassName", this.activeUser.givenName + "" + this.activeUser.familyName + "|" + this.dates[0])
                    this.$router.push({name: 'EnrolStudents'})
                } 
            }).catch( err => {
                console.log(err)
                this.showErr = true
                if (!this.$store.state.isAuthenticated || !this.$store.state.facilitator) {
                    let user = this.$store.getters.activeUser
                    let userId = user.phoneNumber.replace(/\s/g, "")
                    this.$store.commit('deleteUserKey', userId)
                    this.$store.commit('setFirstName', "")
                    this.$store.commit('setPhoneNumber', "")
                    this.$store.commit('logout')
                    this.$router.push({name: 'HomePage'})
                    this.showModal = false
                }
            })
        this.loading = false;
        },
        validDates(arr) {
            if (arr.length === 2) {
                var s1 = arr[0]
                var s2 = arr[1]

                var d1 = new Date(s1)
                var d2 = new Date(s2)
                let difference = d2.getTime() - d1.getTime();
                let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
                if (totalDays >= 7) {
                    return true
                } else {
                    this.invalidClassError = "A foundations course can not be so short. Please fix your class dates so that the class lasts longer than 7 days."
                    return false
                }
            } else {
                this.invalidClassError = "A class needs a start date and an end date. Select an end date."
                return false
            }
        }
    }
  }
</script>

<style scoped>
.sectioncontainer {
    background: #fff;
    width: 100%;
}
.home {
    margin: 0 auto;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;

}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.this-signin-btn {
  color: #3c7872;
}
.this-right {
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  display: flex;
}
.active-btn{
    background: #3C7872;
}
.btnRow {
    justify-content: center;
    align-content: center;
    margin-left: 23px;
    margin-right: 23px;
    margin-bottom: 0px;
}
.cardRow {
    justify-content: center;
    align-content: center;
    margin: 12px;
    width: 100% !important;
}
.dateRow {
    justify-content: center;
    align-content: center;
    margin-top: -20px;
}
.chip {
    margin-left: 10px;
    margin-right: 10px;
}
.title {
    justify-content: center;
    align-content: center;
    margin-top: 5px;
}
.create-btn{
    padding: 20px;
    color: #3c7872;
}
.this-share-btn {
    color: #6A2E85;
    text-decoration: none !important;
    display: inline-block;
}
#lateral .v-btn--floating {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
  }
.classDates {
    justify-content: center;
    width: 600px;
}
  </style>