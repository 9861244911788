<template>
    <v-row class="home">
      <v-col cols="12" class="right-local">
        <v-row class="right-input">
          <v-row class="card">
            <v-col cols=12>
              <v-card
                elevation="2"
                outlined
              >
              <v-card-title>Register students</v-card-title>
              <v-card-text>
                <v-row
                  align="center"
                  class="mx-0"
                >
              </v-row>
              <div>Register students to a class you have created.</div>
              <div class="my-4 text-subtitle-1">
                <v-btn outlined rounded class="this-signin-btn" @click="enrolStudents">Register</v-btn>
              </div>
              </v-card-text>
              
              </v-card>
            </v-col>
          </v-row>
          <br>
          <v-row class="card">
            <v-col>
              <v-card
                elevation="2"
                outlined
              >
              <v-card-title>Create a class</v-card-title>
              <v-card-text>
                <v-row
                  align="center"
                  class="mx-0"
                >
              </v-row>
              <div>This allows you to create a class</div>
              <div class="my-4 text-subtitle-1">
                <v-btn outlined rounded class="this-signin-btn" @click="buildClass">create</v-btn>
              </div>
              </v-card-text>

              </v-card>
            </v-col>
            <v-col>
              <v-card
                elevation="2"
                outlined
              >
              <v-card-title>View classes</v-card-title>
              <v-card-text>
                <v-row
                  align="center"
                  class="mx-0"
                >
              </v-row>
              <div>View the classes you have taught.</div>
              <div class="my-4 text-subtitle-1">
                <v-btn outlined rounded class="this-signin-btn" @click="to=viewFacilitatorClasses()">View My Classes</v-btn>
              </div>
              </v-card-text>

              </v-card>
            </v-col>
          </v-row>
        </v-row>
      </v-col>

      <v-row class="card">
        <v-col>
          <v-card elevation="2" outlined>
            <v-card-title>Search Students</v-card-title>
            <v-card-text>
              <div v-if="$store.state.searchError" class="error">
                {{ $store.state.searchError }}
              </div>
              <br />
              <form @submit.prevent="searchStudents(searchQuery, searchType)">
                <v-row align="center" class="mx-0">
                <v-radio-group v-model="searchType">
                  <v-radio label="Phone" value="phone"></v-radio>
                  <v-radio label="Name" value="name"></v-radio>
                  <v-radio label="ID Number" value="ID"></v-radio>
                  <v-radio label="Email" value="email"></v-radio>
                </v-radio-group>
                <v-col cols="12" md="6">
                  <template v-if="searchType === 'phone'">
                    <component
                        :is="searchType === 'phone' ? 'vue-tel-input' : 'v-text-field'"
                        v-model="searchQuery"
                        :ignoredCountries="ignored"
                        :preferredCountries="preferred"
                        class="this-input-phone"
                        mode="international"
                        defaultCountry="ZA"
                        required
                    ></component>
                  </template>
                  <template v-else-if="searchType === 'name'">
                    <v-row align="center">
                      <v-col cols="10">
                        <v-text-field
                            v-model="searchQuery"
                            :label="'Search by ' + searchType"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" class="text-right">
                        <v-hover>
                          <div>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                    elevation="8"
                                    icon
                                    color="primary"
                                    v-on="on"
                                >
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>Search patterns supported:</span>
                              <ul>
                                <li>First name + Surname</li>
                                <li>First initial + Surname</li>
                                <li>First name + Surname initial</li>
                              </ul>
                            </v-tooltip>
                          </div>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-text-field
                        v-model="searchQuery"
                        :label="'Search by ' + searchType  "
                    ></v-text-field>
                  </template>

                </v-col>
                  <v-col cols="12" md="6">
                    <v-btn color="primary" rounded large type="submit" :disabled="!searchQuery || loading">
                      Start searching...
                      <v-progress-circular v-if="loading" indeterminate size="24"></v-progress-circular>
                    </v-btn>
                    <student-list ref="studentList" @student-selected="onStudentSelected"></student-list>
                  </v-col>
                </v-row>
              </form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {LIST_CLASSES, SEARCH_STUDENTS} from '@/apollo/queries';
import StudentList from '../../components/StudentList.vue';

  export  default {
    name: 'HomeFacilitator',
    components: {
      StudentList,
    },
    props:
    {
      firstName: {
          type: String,
          default: ''
      },
    },
    data() {
      return {
        loading: false,
        searchQuery: "",
        searchType: "name",
        students: [],
        preferred: ['US', 'ZA', 'MX', 'KE', 'MZ', 'BR', 'UG'],
        ignored: ['BG'],
      }
    },
    methods: {
      buildClass() {
        this.$router.push({name: 'ClassBuilder'})
      },
      enrolStudents() {
        this.$router.push({name: 'EnrolStudents'})
      },
      async viewFacilitatorClasses() {
        console.log("this.$store.state.user", this.$store.state.user); // Check the Vuex state directly
        console.log("this.activeUser", this.activeUser); // Check the computed property
        if (this.activeUser) {
          console.log("About to make GraphQL query with filter: ", this.activeUser.phoneNumber.replace(/ /g, ''));
          await this.$apollo.query({
            query: LIST_CLASSES,
            variables: {
              filter: this.activeUser.phoneNumber.replace(/ /g, '')
            }
          }).then(response => {
            console.log("Received response from GraphQL query, trying to navigate to 'ClassViewer'...");
            this.$router.push({name: 'ClassViewer', params: {classList: response.data.listClasses}})
                .catch(e => {
                  console.error("Error while navigating to 'ClassViewer': ", e);
                });
          })
              .catch(e => {
                console.error("Error while executing GraphQL query: ", e);
              });
        } else {
          console.error("this.activeUser is undefined");
        }
      },
      onStudentSelected(memberId) {
        this.$router.push({ name: 'StudentProfile', params: { memberId } });
      },
      async searchStudents(query, type) {
        if (!query) {
          this.$store.commit('setSearchError', 'Please enter a search query');
          return;
        }

        if (type === 'phone') {
          query = query.replace(/ /g, ''); // Remove spaces in phone number
        }

        try {
          this.loading = true;
          let response = await this.$apollo.query({
            query: SEARCH_STUDENTS,
            variables: {
              request: {
                searchValue: query,
                searchType: type,
              },
            },
          });

          // console.log(`searchStudents: Successful search for ${type}`, query);
          // console.log('Response:', response.data);

          if (response.data && response.data.searchStudents) {
            let searchResults = response.data.searchStudents.memberResolverSlice.members;

            if (searchResults.length === 1) {
              const memberId = searchResults[0].name;
              await this.$router.push({ name: 'StudentProfile', params: { memberId } });
            } else if (searchResults.length > 1) {
              this.$refs.studentList.open(searchResults);
            } else {
              this.$store.commit('setSearchError', `No students found with this ${type}`);
            }
          } else {
            this.$store.commit('setSearchError', `No students found with this ${type}`);
          }

          if (response.errors) {
            this.$store.commit('setSearchError', response.errors[0].message);
          }
        } catch (e) {
          this.$store.commit('setSearchError', e);
        } finally {
          this.loading = false;
        }
      },
    },
      computed: {
        ...mapGetters('user', ['activeUser']),
    },
    created() {
      this.$store.commit('setSearchError', null);
    },
    watch: {
      searchType() {
        this.searchQuery = ''; // Reset searchQuery to an empty string when searchType changes
        this.$store.commit('setSearchError', ""); // reset any previous errors
      },
    },
  }
</script>

<style scoped>
.sectioncontainer {
    background: #fff;
    width: 100%;
}
.home {
  margin: 0 auto;
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
  position: static;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.this-signin-btn {
  color: #3c7872;
}
.right-local {
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  align-items: normal;
}
  </style>