<template>
  <div style="min-width:95%; margin:5px; height: 100%; justify-content: center; align-content: center">
    <v-hover v-slot="{ hover }">
<!--      <template v-slot:default="{ isHovering, props }">-->
        <v-card :color="hover ? '#F4F4F4' : '#F8F8F8'" :height="160" :elevation="hover ? 4 : 0" class="pa-8 rounded-xl" flat >
          <v-row class="hidden-md-and-up">
            <!-- Mobile -->
            <v-col cols="12" >
              <v-card-title class="console-header-bold" style="display:flex; justify-content:center">
                <p v-if="impactScore > 1.9" class="h1-green pb-1">
                  {{impactScore}}
                </p>
                <p v-else-if="impactScore > 1.4" class="h1-green-yellow">
                  {{impactScore}}
                </p>
                <p v-else-if="impactScore > 0.9" class="h1-yellow">
                  {{impactScore}}
                </p>
                <p v-else-if="impactScore > 0.4" class="h1-yellow-red">
                  {{impactScore}}
                </p>
                <p v-else class="h1-red">
                  {{impactScore}}
                </p>
              </v-card-title>
              <v-card-text class="console-text-small subject" style="display:flex; justify-content:center">
                Impact Score
              </v-card-text>
            </v-col>
          </v-row>
          <!-- Desktop -->
          <v-row class="hidden-sm-and-down">
            <v-col cols="3" >
              <v-card-title class="console-header-bold pa-0" style="display:flex; justify-content:center">
                <p v-if="impactScore > 1.9" class="h1-green">
                  {{impactScore}}
                </p>
                <p v-else-if="impactScore > 1.4" class="h1-green-yellow">
                  {{impactScore}}
                </p>
                <p v-else-if="impactScore > 0.9" class="h1-yellow">
                  {{impactScore}}
                </p>
                <p v-else-if="impactScore > 0.4" class="h1-yellow-red">
                  {{impactScore}}
                </p>
                <p v-else class="h1-red">
                  {{impactScore}}
                </p>
              </v-card-title>
              <v-card-text class="console-text-small subject" style="display:flex; justify-content:center">
                Impact Score
              </v-card-text>
            </v-col>

            <v-col cols="3">
              <v-card-title class="console-header-bold pa-0" style="display:flex; justify-content:center">
                <p>
                  {{ numberSalvations }}
                </p>
              </v-card-title>
              <v-card-text class="console-text-small subject pa-0" style="display:flex; justify-content:center">
                Salvations
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-title class="console-header-bold pa-0" style="display:flex; justify-content:center">
                <p>
                  {{ numberEducation }}
                </p>
              </v-card-title>
              <v-card-text class="console-text-small subject pa-0" style="display:flex; justify-content:center">
                Pursuing education
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-title class="console-header-bold pa-0" style="display:flex; justify-content:center">
                <p>
                  {{ numberJobs }}
                </p>
              </v-card-title>
              <v-card-text class="console-text-small subject pa-0" style="display:flex; justify-content:center">
                Jobs
              </v-card-text>
            </v-col>
          </v-row>

        </v-card>
<!--      </template>-->
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "InfoCard",
  props: {
    numberStudents: {
      type: Number,
      default: 0
    },
    numberEducation: {
      type: Number,
      default: 0
    },
    numberSalvations: {
      type: Number,
      default: 0
    },
    numberJobs: {
      type: Number,
      default: 0
    },
    subject: {
      type: String,
      default: ""
    }
  },
  computed: {
    impactScore: function () {
      if (this.numberStudents > 0) {
        return ((this.numberEducation + this.numberSalvations + this.numberJobs)/this.numberStudents).toFixed(2)
      } else {
        return 0
      }
    }
  },
}
</script>

<style scoped>
.h1 {
  font-size: 30px;
  font-weight: 500;
}
.h1-green {
  font-size: 30px;
  font-weight: 600;
  background: -webkit-linear-gradient(rgb(1, 247, 71), rgb(105, 215, 174));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, rgb(1, 247, 71), rgb(198, 244, 168));
}

.h1-green-yellow {
  font-size: 30px;
  font-weight: 600;
  /* background: -webkit-linear-gradient(rgb(1, 247, 71), rgb(249, 224, 2)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, rgb(1, 247, 71), rgb(249, 224, 2));
}

.h1-yellow {
  font-size: 30px;
  font-weight: 600;
  /* background: -webkit-linear-gradient(rgb(255, 225, 0), rgb(255, 239, 117)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, rgb(255, 225, 0), rgb(255, 239, 117));
}

.h1-yellow-red {
  font-size: 30px;
  font-weight: 600;
  /* background: -webkit-linear-gradient(rgb(1, 247, 71), rgb(105, 215, 174)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, rgb(255, 225, 0), rgb(255, 0, 0));
}

.h1-red {
  font-size: 30px;
  font-weight: 600;
  background: -webkit-linear-gradient(rgb(1, 247, 71), rgb(105, 215, 174));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, rgb(255, 0, 0), rgb(253, 137, 137));
}
</style>