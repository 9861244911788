<!-- Have a large form with multiple questions on it. Keep the user going by allowing them to progress. Think of a slider of sorts. -->
<!-- Once the pre-course checkin is complete, we will create a new report, and update the pre-course field of the specific enrollment. -->
<!-- We will also update the users gem count (reward the user) -->



<!-- Layout -->
<!---------------------->
<!--  
    Keep Pre-course checkin card at the top as seen here: https://docs.google.com/forms/d/e/1FAIpQLScwx0EKjuBYJ1J7rHILOSQnnxDQnfGwqOacEV0W0AD3mNuxow/formResponse
    Two-three pages with +- 5 questions each
    Only of dropdowns and selects
    Purple as seen here: https://docs.google.com/forms/d/e/1FAIpQLScwx0EKjuBYJ1J7rHILOSQnnxDQnfGwqOacEV0W0AD3mNuxow/formResponse
    Reward with gem
    Redirect to ViewClasses
    Submit -> Your response has been recorded! Here's 5 gems. Back to my classes.
-->

<template>
    <v-row class="home">
      <v-col class="this-right">
        <!-- <v-list-item>  -->
            <v-row class="right-row">
                <v-card class="this-header-card rounded-lg" min-width="95%">
                    <v-card-title class="pb-0 pl-0"> 
                        <v-row class="right-row-left-align">
                            <h3 style="margin-top:20px;margin-left:20px;"> Pre-class check-in</h3>
                        </v-row> 
                    </v-card-title>
                    <!-- <v-card-text class="pa-0"> -->
                        <!-- <v-row class="right-row-left-align">
                            <p style="margin-left:20px;">Complete the form by answering all of the questions. This should be done <strong>before</strong> the class.</p>
                        </v-row>
                    </v-card-text> -->
                    <v-row class="right-row-left-align"> 
                        <p class="question">How to complete the form:</p>
                    </v-row>
                    <v-card-text class="pa-0">
                        <v-row class="right-row-left-align"> 
                            <p style="margin-left:20px; margin-right: 20px">The button you choose indicates your agreement with the statement. For Example:</p>
                        </v-row>
                    </v-card-text>
                    <v-carousel class="right-row pt-0" height="140" v-model="carousel" hide-delimiters>
                        <v-carousel-item
                        v-for="c in 5"
                        :key="c"
                        >   
                            <!-- <v-card class="this-card rounded-lg mt-0" flat min-width="95%"> -->
                                <v-card-text class="pa-0">
                                    <v-row class="right-row">
                                        <v-radio-group v-model="exVal" row>
                                            <v-tooltip bottom v-for="n in 5" :key="n">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-radio
                                                        :value="n"
                                                        :color="colors[n-1]"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-radio>
                                                </template>
                                                <span>{{labels[n-1]}}</span>
                                            </v-tooltip>
                                        </v-radio-group>
                                    </v-row>
                                    <v-row class="right-row">
                                        {{labels[exVal-1]}}
                                    </v-row>
                                </v-card-text>
                            <!-- </v-card> -->
                        </v-carousel-item>
                    </v-carousel>
                    <v-card-text class="pa-0">
                        <v-row class="right-row-left-align"> 
                            <p class="question" style="margin-right: 20px">Now that you know how to answer questions, complete the form below 👇</p>
                        </v-row>
                        <br>
                    </v-card-text>
                </v-card>
            </v-row>
            <v-row class="right-row"></v-row>

                <div v-for="i in relevantQ.length" :key="i">
                    <v-row class="right-row">
                        <v-card class="this-card rounded-lg" min-width="95%">
                                <v-card-text>
                                    <v-row class="right-row-left-align"> 
                                        <p class="question">{{relevantQ[i-1].label}}</p>
                                    </v-row>
                                    <v-row class="right-row"> 
                                        <v-radio-group v-model="relevantQ[i-1].value" row>
                                            <v-tooltip bottom v-for="n in 5" :key="n">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-radio
                                                        :key="n"
                                                        :value="n"
                                                        :color="colors[n-1]"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-radio>
                                                </template>
                                                <span>{{labels[n-1]}}</span>
                                            </v-tooltip>
                                        </v-radio-group>
                                    </v-row>
                                    <v-row class="right-row" v-if="relevantQ[i-1].value !== -1" >
                                        {{labels[relevantQ[i-1].value-1]}}
                                    </v-row>
                                    <v-row class="right-row" v-else>
                                       <br>
                                    </v-row>
                                </v-card-text>
                        </v-card>
                    </v-row>
                </div>
            <!-- </span> -->
            <v-row v-if="!allAnswered" class="right-row">
                <v-row class="right-row mr-5">
                    <p>Make sure you've answered all of the questions.</p>
                </v-row>
                <v-row class="right-row">
                    <v-btn color="white" disabled rounded height="50%" class="submit-btn">Submit</v-btn>
                </v-row>
            </v-row>
            <v-row v-else class="right-row">
                <!-- <v-btn color="white" disabled rounded class="submit-btn">Submit</v-btn> -->
                <v-btn color="white" rounded class="submit-btn" @click="createReport()">Submit</v-btn>
            </v-row>
      </v-col> 
    </v-row>
</template>

<script>
import { CREATE_SELF_ESTEEM_CHECKIN, CREATE_EMPLOYMENT_HOPE_CHECKIN, CREATE_ENTITLEMENT_CHECKIN } from '@/apollo/mutations'
import { apolloClient } from '@/main'
import { mapGetters } from "vuex";
import {v4 as uuidv4} from "uuid";

export default {
  name: 'PreCourseCheckin',
  computed: {
    ...mapGetters('user', ['activeUser']),
  },
  props: {
    encodedEnr: {type: String},
    encodedClassId: {type: String},
    encodedCourseId: {type: String},
  },
  watch: {
    carousel: function(val) {
      this.exVal = val + 1;
    },
  },
    data: () => ({
        exVal: 1,
        carousel: 0,
        radioGroup: 1,
        eg: 5,
        SE: false,
        E: false,
        EH: false,
        exQ: {label: "I am learning: ", value: 3},
        relevantQ: [],
        seQ: [
            {label: "I tend to think less of myself than I should:", value: -1},
            {label: "I am highly effective at the things I do:", value: -1},
            {label: "I am very comfortable with myself:", value: -1},
            {label: "I am almost always able to achieve what I try for:", value: -1},
            {label: "I am secure in my sense of self-worth:", value: -1},
            {label: "It is sometimes unpleasant for me to think about myself:", value: -1},
            {label: "At times, I find it difficult to achieve the things that are important to me:", value: -1},
            {label: "I have a negative attitude toward myself:", value: -1},
            {label: "I feel great about who I am:", value: -1},
            {label: "I sometimes deal badly with challenges:", value: -1},
            {label: "I never doubt my personal worth:", value: -1},
            {label: "I perform very well at many things:", value: -1},
            {label: "I sometimes fail to achieve my goals:", value: -1},
            {label: "I am very talented:", value: -1},
            {label: "I do not have enough respect for myself:", value: -1},
        ],
        eQ: [
            {label: "Everybody has the right to expect help from the government when in need:", value: -1},
            {label: "Disadvantaged persons deserve institutional help:", value: -1},
            {label: "The government should take care of the livelihood of the poorest:", value: -1},
            {label: "The government has a duty to ensure decent living conditions for people:", value: -1},
            {label: "It is the duty of the government to care for all citizens:", value: -1},
            {label: "When working or looking for a job, I am respectful towards who I am:", value: -1},
            {label: "I am worthy of working in a good job:", value: -1},
        ],
        ehQ: [
            {label: "When working or looking for a job, I am respectful towards who I am:", value: -1},
            {label: "I am worthy of working in a good job:", value: -1},
            {label: "I am capable of working in a good job:", value: -1},
            {label: "I have the strength to overcome any obstacles when it comes to working:", value: -1},
            {label: "I am aware of what my skills are to be employed in a good job.:", value: -1},
            {label: "I am aware of what my resources are to be employed in a good job:", value: -1},
            {label: "I am able to use my skills to move toward career goals.:", value: -1},
            {label: "I am able to use my resources to move toward career goals.:", value: -1},
            {label: "I am on the road toward my career goals.:", value: -1},
            {label: "I am in the process of moving forward reaching my goals:", value: -1},
            {label: "Even if I am not able to achieve my financial goals right away, I will find a way to get there:", value: -1},
            {label: "My current path will take me to where I need to be in my career.:", value: -1},
        ],
        enrCopy: "",
        colors: [
            "#7A0505",
            "#C40808",
            "gray",
            "#4B958E",
            "#3c7872",
        ],
        labels: [
            "Strongly Disagree",
            "Disagree", "Neutral", "Agree",
            "Strongly Agree"
        ],
        defaultLabel: "",
    }),
    mounted () {
        var r = Math.random()
        if (r < 0.33) {
            this.relevantQ = this.seQ
            this.SE = true
            this.E = false
            this.EH = false
        } else if (r >= 0.33 && r < 0.66) {
            this.relevantQ = this.eQ
            this.E = true
            this.EH = false
            this.SE = false
        } else {
            this.relevantQ = this.ehQ
            this.EH = true
            this.SE = false
            this.E = false
        }
        this.enr = this.decode(this.encodedEnr)
        this.classId = this.decode(this.encodedClassId)
        this.courseId = this.decode(this.encodedCourseId)
    },
  methods: {
    allAnswered() {
      return this.relevantQ.every(q => q.value !== -1) && this.relevantQ.length > 0;
    },
    decode: (str) => {
      try {
        return window.atob(str);
      } catch (err) {
        console.error('Failed to decode string:', str, err);
        return ""; // or return a default value or throw the error if you want to handle it upstream
      }
    },
    generateReportName(reportType) {
      const prefix = reportType === 4 ? 'SE' : reportType === 8 ? 'EN' : 'EH';
      return `${prefix}-${uuidv4()}`;
    },
    async createReport() {
      try {
        const { phoneNumber } = this.activeUser;
        const enrCopy = this.enr ?? this.$route.params.id;
        const responses = this.relevantQ.map(q => q.value);
        const pc = { enrollment: enrCopy, classId: this.classId, courseId: this.courseId, responses };
        let reportType, checkin;

        if (this.SE) {
          reportType = 4;
          checkin = { selfEsteemCheckin: pc };
        } else if (this.E) {
          reportType = 8;
          checkin = { entitlementCheckin: pc };
        } else {
          reportType = 6;
          checkin = { employmentHopeCheckin: pc };
        }

        const vars = {
          name: this.generateReportName(reportType),
          member: phoneNumber,
          reporter: phoneNumber,
          reportType,
          ...checkin
        };
        console.log('vars:', vars)

        let mutation;
        if (this.SE) {
          mutation = CREATE_SELF_ESTEEM_CHECKIN;
        } else if (this.E) {
          mutation = CREATE_ENTITLEMENT_CHECKIN;
        } else {
          mutation = CREATE_EMPLOYMENT_HOPE_CHECKIN;
        }

        await apolloClient.mutate({
          mutation: mutation,
          variables: vars
        }).then(() => this.$router.push({ name: 'ProfileContent' }));

      } catch (error) {
        console.error('An error occurred:', error); // Debugging Line
      }
    },
  },
}
</script>

<style scoped>
.home {
    margin: 0;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    justify-content: center;

}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
  margin: 0px;
}
.right-row-right-align {
    width: 100%;
    justify-content: right;
    margin: 1px;
}
.right-row-left-align {
    width: 100%;
    justify-content: left;
    margin: 1px;
}
.header-row{
    justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.this-list {
    justify-content: center;
    background: rgb(143, 166, 155);
    
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.this-signin-btn {
  color: #3c7872;
}
.this-right {
  box-sizing: border-box;
  background: rgb(143, 166, 155);
  color: #fff;
}
.active-btn{
    background: #3C7872;
}
.btnRow {
    justify-content: center;
    align-content: center;
    margin: 20px;
}
.cardRow {
    justify-content: center;
    align-content: center;
    margin: 12px;
}
.chip {
    margin-left: 10px;
    margin-right: 10px;
}
.title {
    justify-content: center;
    align-content: center;
    margin-top: 5px;
}
.this-card{
    margin: 10px;
}
.this-header-card{
    margin: 10px;
    border-top: 10px solid #6A2e85 !important;
}
.this-header-card-no-border{
    margin: 10px;
}
.no-wrap{
    white-space: nowrap;
    justify-content: center;
}
.this-share-btn {
    color: #6A2E85;
    text-decoration: none !important;
    display: inline-block;
    text-transform: none;
}
.submit-btn {
    color: #6A2E85;
    background: #D2DFDD;
    text-transform: none;
    margin: 20px;
}
.question {
    margin-top:20px;
    margin-left:20px;
    font-size: 16px;
    letter-spacing: .1px;
    line-height: 24px;
    word-break: break-word;
    color:black;
    font-weight: 500;
}
.large .v-radio {
    padding: 10px;
}
.large i {
    font-size: 48px;
  }
  
  .large label {
    padding-left: 24px;
  }
  
  .large [class*="__ripple"] {
    left: 0;
  }
</style>