<template>
  <v-card-actions class="button-container" v-if="localEnrollment.enrolledClass && classOwnedByFacilitator(localEnrollment.enrolledClass)">
    <div class="switch-container">
      <!--          !!TODO ultradox renable switch - once issue resolved on -->
      <v-switch
          class="switch"
          v-model="localPaid"
          inset
          color="#008D1F"
          @change="onPaidSwitchChanged"
      ></v-switch>
      <p class="payments">{{ localPaid ? 'Paid' : 'Not paid' }}</p>
    </div>
    <v-row>
      <v-col v-if="reviewing" cols="12" class="button-col">
        <v-btn @click="onSaveClick" class="action-btn success">
          <v-progress-circular v-if="isLoading" indeterminate size="24"></v-progress-circular>
          Save
        </v-btn>
        <v-btn @click="onCancelClick" class="action-btn error">
          Cancel
        </v-btn>
      </v-col>
      <v-col v-else cols="12" class="button-col flex-buttons">
        <v-btn @click="onReviewClick" class="action-btn primary"
               :class="{'secondary': !reviewed }" medium>
          {{ reviewed ? 'Edit Review' : 'Review' }}
        </v-btn>

        <v-btn class="error delete-btn" medium @click="promptDelete"
          >
          <v-icon color="white" medium>mdi-delete-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card-actions>
</template>


<script>
export default {
  name: 'EnrollmentControls',
  props: {
    reviewed: Boolean,
    reviewing: Boolean,
    isLoading: Boolean,
    reviewUser: Function,
    updateEnrollmentPaid: Function,
    paid: Boolean,
    localEnrollment: Object,
    classOwnedByFacilitator: Function,
    promptDelete: Function
  },
  data () {
    return {
      localPaid: this.paid,
    }
  },
  methods: {
    onPaidSwitchChanged() {
      this.$emit('paid-change', this.localPaid);
    },
    onReviewClick() {
      this.$emit('review-button-click');
    },
    onSaveClick() {
      this.reviewUser();
    },
    onCancelClick() {
      this.$emit('cancel');
    }
  },
  watch: {
    paid(newVal) {
      this.localPaid = newVal;
      },
  },
}
</script>
<style>
.action-btn {
  flex: 1;
  margin-left: 30px;
  margin-right: 50px;
}

.button-container {
  justify-content: center;
  align-items: center;
}

.button-col {
  display: flex;
  justify-content: center;
  margin: 8px 0;
}

.switch {
  margin-right: 10px;
}

.payments {
  text-align: center;
  margin-right: 20px;
}

.delete-btn {
  margin-right: 20px;
}

.flex-buttons {
  display: flex;
}

.switch-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
