<template>
  <v-card class="card rounded-xl" min-width="90%">
        <v-card-text>
            <div v-if="this.course.phase === 2">Skills course</div>
            <div v-else>Foundations course</div>
            <v-row class="right-row-left-align">
                <div class="text-h5 text--primary" style="font-weight: 500">
                    {{course.displayName}}
                </div>
            </v-row> 
            <v-row class="right-row-left-align" style="font-weight: 400">
                {{facilitator}}, {{setDates()}}
            </v-row> 
            <!-- <v-row class="right-row-left-align">
                
            </v-row>  -->
        </v-card-text>
            <div v-if="this.course.phase === 1">
                <v-card-actions v-if="preCheckin === null" class="right-row-edit">
                    <v-btn class="create-btn" v-if="width < 333" rounded text x-small @click="$router.push({name: 'PreCourseCheckin', params: {id: enrollment, enr: enrollment, classId: classId, courseId: course.id}})">Checkin</v-btn>
                    <v-btn class="create-btn hidden-sm" v-else rounded text small @click="$router.push({name: 'PreCourseCheckin', params: {id: enrollment, enr: enrollment, classId: classId, courseId: course.id}})">Pre-course Checkin</v-btn>
                    <v-spacer></v-spacer>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                </v-card-actions>
                <v-card-actions v-else-if="postCheckin === null" class="right-row-edit">
                    <v-btn class="create-btn" v-if="width < 333" rounded text x-small @click="$router.push({name: 'PostCourseCheckin', params: {id: enrollment, enr: enrollment, classId: classId, courseId: course.id, report: preCheckin}})">Checkin</v-btn>
                    <v-btn class="create-btn hidden-sm" v-else rounded text small @click="$router.push({name: 'PostCourseCheckin', params: {id: enrollment, enr: enrollment, classId: classId, courseId: course.id, report: preCheckin}})">Post-course Checkin</v-btn>
                    <v-spacer></v-spacer>
                    <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
                    <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                </v-card-actions>
                <v-card-actions v-else class="right-row-edit">
                    <v-btn class="create-btn-complete" v-if="width < 333" rounded text x-small>Complete</v-btn>
                    <v-btn class="create-btn-complete hidden-sm" v-else rounded text small>Checkins complete</v-btn>
                    <v-spacer></v-spacer>
                    <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
                    <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
                </v-card-actions>
            </div>
            <div v-else>
                <v-card-actions class="right-row-edit">
                    <v-btn class="create-btn" v-if="width < 333" rounded text x-small>Enrollment status: Complete</v-btn>
                    <v-btn class="create-btn hidden-sm" v-else rounded text small>Enrollment status: Complete</v-btn>
                    <v-spacer></v-spacer>
                    <v-icon color="green">mdi-check-circle-outline</v-icon>
                </v-card-actions>
            </div>
    </v-card>
</template>

<script>
export default {
    name: "MemberEnrollmentCard",
    props: {
        classId: {type: String},
        courseId: {type: String},
        facilitator: {type: String},
        date: {type: String},
        preCheckin: {type: String},
        postCheckin: {type: String},
        enrollment: {type: String}
    },
    data: () => ({
        month: "",
        year: "",
        courseNames: [
            {displayName: "Job Readiness", id: "W4ALF-English-2022", phase: 1},
            {displayName: "WIRED 4 Business", id: "W4B-English-2022", phase: 1},
            {displayName: "Barista", id: "Barista-English-2022", phase: 2},
            {displayName: "Farming God's Way", id: "FGW-English-2022", phase: 2},
            {displayName: "Accounting", id: "Accounting-English-2022", phase: 2},
            {displayName: "Cashier", id: "Cashier-English-2022", phase: 2},
            {displayName: "Computers", id: "Computer-English-2022", phase: 2},
            {displayName: "Computers Bundle", id: "ComputersBundle-English-2022", phase: 2},
            {displayName: "Computers Intro", id: "ComputersIntro-English-2022", phase: 2},
            {displayName: "English", id: "English-English-2022", phase: 2},
            {displayName: "Events", id: "Event-English-2022", phase: 2},
            {displayName: "Freelance", id: "Freelance-English-2022", phase: 2},
            {displayName: "Graphic Design", id: "GraphicDesign-English-2022", phase: 2},
            {displayName: "Human Resources", id: "HumanResources-English-2022", phase: 2},
            {displayName: "Microsoft Excel", id: "MSExcel-English-2022", phase: 2},
            {displayName: "Microsoft Excel Advanced", id: "MSExcelAdv-English-2022", phase: 2},
            {displayName: "Microsoft Powerpoint", id: "MSPowerPT-English-2022", phase: 2},
            {displayName: "Microsoft Word", id: "MSWord-English-2022", phase: 2},
            {displayName: "Microsoft Word Advanced", id: "MSWordAdv-English-2022", phase: 2},
            {displayName: "Marketing", id: "Marketing-English-2022", phase: 2},
            {displayName: "Merchandising", id: "Merchandising-English-2022", phase: 2},
            {displayName: "Office Admin", id: "OfficeAdmin-English-2022", phase: 2},
            {displayName: "Operations Management", id: "OpsMan-English-2022", phase: 2},
            {displayName: "Parenting", id: "Parenting-English-2022", phase: 2},
            {displayName: "Retail & Cashier", id: "RetailAndCashier-English-2022", phase: 2},
            {displayName: "Retail & Merchandising", id: "RetailAndMerchandising-English-2022", phase: 2},
            {displayName: "Retail Management", id: "RetailMGGT-English-2022", phase: 2},
            {displayName: "Retail Skills", id: "RetailSkills-English-2022", phase: 2},
            {displayName: "SmartFinance", id: "SmartFinance-English-2022", phase: 2},
            {displayName: "Social Marketing", id: "SocialMKTG-English-2022", phase: 2},
            {displayName: "Supply Chain", id: "SupplyChain-English-2022", phase: 2},
            {displayName: "Waiter", id: "Waiter-English-2022", phase: 2},
        ],
        monthNames: [
            {text: "January", value: "2022-01-01"},
            {text: "February", value: "2022-02-01"},
            {text: "March", value: "2022-03-01"},
            {text: "April", value: "2022-04-01"},
            {text: "May", value: "2022-05-01"},
            {text: "June", value: "2022-06-01"},
            {text: "July", value: "2022-07-01"},
            {text: "August", value: "2022-08-01"},
            {text: "September", value: "2022-09-01"},
            {text: "October", value: "2022-10-01"},
            {text: "November", value: "2022-11-01"},
            {text: "December", value: "2022-12-01"},
        ]
    }),
    computed: {
        width () {
            return this.$vuetify.breakpoint.width
        },
        course () {
            return this.courseName(this.courseId)
        },
    },
    methods: {
        courseName(val) {
            let c = this.courseNames.filter(c => c.id === val);
            if (c.length === 0) {
                return val
            }
            return c[0]
        },
        setDates() {
            this.year = this.date.split("-")[0]
            this.month = this.monthNames[this.date.split("-")[1] - 1].text
            return this.month + " " + this.year
        }
    },
}
</script>

<style scoped>
.home {
    margin: 0 auto;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    justify-content: center;

}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
  margin: 2px;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 1px;
}
.right-row-left-align {
    width: 100%;
    justify-content: left;
    margin: 1px;
}
.header-row{
    justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.this-list {
    justify-content: center;
    background: rgb(143, 166, 155);
    
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.this-signin-btn {
  color: #3c7872;
}
.this-right {
  box-sizing: border-box;
  background: rgb(143, 166, 155);
  color: #fff;
}
.active-btn{
    background: #3C7872;
}
.btnRow {
    justify-content: center;
    align-content: center;
    margin: 20px;
}
.cardRow {
    justify-content: center;
    align-content: center;
    margin: 12px;
}
.chip {
    margin-left: 10px;
    margin-right: 10px;
}
.title {
    justify-content: center;
    align-content: center;
    margin-top: 5px;
}
.create-btn{
    color: #3c7872;
    background: #D2DFDD;
    text-transform: none;
}
.create-btn-complete{
    color: #3c7872;
    background: #ffffff;
    text-transform: none;
}
.card{
    margin-left: 20px;
    margin-right: 20px;
}
.header-card{
    margin: 20px;
    border: 2px solid #3c7872 !important;
}
.header-card-red{
    margin: 20px;
    border: 2px solid #680606 !important;
}
.no-wrap{
    white-space: nowrap;
    justify-content: center;
}
.this-share-btn {
    color: #6A2E85;
    text-decoration: none !important;
    display: inline-block;
    text-transform: none;
}
.right-row-left-align {
    width: 100%;
    justify-content: left;
    margin: 1px;
}
</style>