<template>
  <div v-if="activeUser && localEnrollment">
    <v-alert v-if="showSuccessAlert" type="success">
      Report successfully updated!
    </v-alert>
    <v-alert v-if="showErrorAlert" type="warning">
      {{ errorMessage }}
    </v-alert>
    <v-card v-if="reviewing || editing"
           class="card card-light-bg">
      <EnrollmentHeader
          :student = "student"
          :localEnrollment = "localEnrollment"
          :showClassDetails = "showClassDetails"
          :enrollment = "enrollment">
      </EnrollmentHeader>
      <EnrollmentFields
          :items = "items"
          :outcomes = "outcomes"
          :r50outcomes = "r50outcomes"
          :furtherEducation.sync = "localEnrollment.furtherEducation"
          :skillsCourse.sync = "localEnrollment.skillsCourse"
          :employed.sync = "localEnrollment.employed"
          :startedBusiness.sync = "localEnrollment.startedBusiness"
          :attitude.sync = "localEnrollment.attitude"
          :communication.sync = "localEnrollment.communication"
          :timeManagement.sync = "localEnrollment.timeManagement"
          :interview.sync = "localEnrollment.interview"
          :studentOutcome.sync = "localEnrollment.studentOutcome"
          :priorSalvation.sync = "localEnrollment.priorSalvation"
          :salvationCommitment.sync = "localEnrollment.salvationCommitment"
          :discipleshipLinked.sync = "localEnrollment.discipleshipLinked"
          :interviewSent.sync = "localEnrollment.interviewSent"
          :r50Challenge.sync = "localEnrollment.r50Challenge"
          :r50ChallengeMoney.sync = "localEnrollment.r50ChallengeMoney"
          :r50ChallengeComment.sync = "localEnrollment.r50ChallengeComment"
          :deepDiveChallenge.sync = "localEnrollment.deepDiveChallenge"
          :deepDiveChallengeComment.sync = "localEnrollment.deepDiveChallengeComment"
          :feedback.sync = "localEnrollment.feedback">
      </EnrollmentFields>
      <EnrollmentControls
          :paid = "paid"
          :reviewed="Boolean(localEnrollment.enrollmentReport)"
          :reviewing = "reviewing"
          :isLoading = "isLoading"
          :reviewUser = "reviewUser"
          :localEnrollment = "localEnrollment"
          :classOwnedByFacilitator = "classOwnedByFacilitator"
          :promptDelete = "promptDelete"
          @cancel = "reviewing = false"
          @review-button-click = "onReviewClick"
          @paid-change="handlePaidChange"
      />

    </v-card>
    <v-card v-else-if="!deleted"
            class="card card-light-bg"
            v-bind:style='{"border-top" : (paid && Boolean(localEnrollment.enrollmentReport) ? "10px solid #3c7872 !important" : "10px solid #7A0000 !important" )}'
            elevation-24 min-width="90%">
      <EnrollmentHeader
          :student = "student"
          :localEnrollment = "localEnrollment"
          :showClassDetails = "showClassDetails"
          :enrollment = "enrollment">
      </EnrollmentHeader>
      <EnrollmentControls
          :paid = "paid"
          :reviewed="Boolean(localEnrollment.enrollmentReport)"
          :reviewUser = "reviewUser"
          :isLoading = "isLoading"
          :localEnrollment = "localEnrollment"
          :classOwnedByFacilitator = "classOwnedByFacilitator"
          :promptDelete = "promptDelete"
          @cancel = "reviewing = false"
          @review-button-click = "onReviewClick"
          @paid-change="handlePaidChange"
      />
      <EnrollmentDialog
          :dialog.sync = "dialog"
          @confirmDelete = "confirmDeleteMethod">
      </EnrollmentDialog>
    </v-card>
  </div>
  <v-layout v-else fill-height justify-center align-center>
    <LoadingSpinner></LoadingSpinner>
  </v-layout>
</template>

<script>
import {
  CREATE_ENROLLMENT_REPORT,
  DELETE_ENROLLMENT,
  UPDATE_ENROLLMENT,
  UPDATE_ENROLLMENT_REPORT
} from "@/apollo/mutations"
import {
  GET_ENROLLMENT,
  GET_ENROLLMENT_TYPE_REPORT,
  GET_MEMBER_ID_BY_PHONE,
  LIST_CLASSES
} from "@/apollo/queries"
import {apolloClient} from "@/main"
import {mapGetters} from "vuex";
import EnrollmentHeader from './ClassEnrollmentCard/EnrollmentHeader.vue';
import EnrollmentControls from './ClassEnrollmentCard/EnrollmentControls.vue';
import EnrollmentDialog from './ClassEnrollmentCard/EnrollmentDialog.vue';
import EnrollmentFields from './ClassEnrollmentCard/EnrollmentFields.vue';
import LoadingSpinner from './ClassEnrollmentCard/LoadingSpinner.vue';
import { EventBus } from "@/event-bus";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "ClassEnrollmentCard",
  components: {
    EnrollmentHeader,
    EnrollmentControls,
    EnrollmentDialog,
    EnrollmentFields,
    LoadingSpinner,
  },
  props: {
    enrollment: { type: Object, required: true },
    student: { type: Object, required: true },
    showClassDetails: { type: Boolean, default: false }
  },
  data: () => ({
    paid: false,
    reportReporter: '',
    memberId: null,
    courseId: null,
    classId: null,
    reportType: null,
    existingReportName: null,
    isCreatingReport: false,
    localEnrollment: null,
    reviewing: false,
    editing: false,
    items: [
      {value: 6, text: "No participation"},
      {value: 1, text: "Poor"},
      {text: "Average", value: 2},
      {text: "Good", value: 3},
      {text: "Excellent", value: 4},
    ],
    r50outcomes: [
      {value: 1, text: "Lost Money"},
      {value: 2, text: "Broke Even"},
      {value: 3, text: "R0 - R50 Profit"},
      {value: 4, text: "R50 - R100 Profit"},
      {value: 5, text: "R100+ Profit"},
    ],
    outcomes: ["Did not finish", "Completion", "2E", "3E", "4E"],
    ownedClasses: [],
    dialog: false,
    deleteId: null,
    deleted: false,
    isLoading: false,
    showSuccessAlert: false,
    showErrorAlert: false,
    errorMessage: '',
  }),
  computed: {
    ...mapGetters('user', ['activeUser']),
    attitude() {
      return this.enrollment ? this.localEnrollment.attitude : 0;
    },
  },
  async created() {
    EventBus.$on('phoneNumberUpdated', async () => {
      await this.initializeEnrollmentData();
    });
    if (this.enrollment) {
      // console.log("Enrollment: ", this.enrollment)
      this.memberId = this.enrollment.student;
      this.courseId = this.enrollment.course;
      this.classId = this.enrollment.enrolledClass;
      this.reportType = 1;
      await this.initializeEnrollmentData();
    }
  },
  destroyed() {
    EventBus.$off('phoneNumberUpdated', this.initializeEnrollmentData);
  },
  methods: {
    generateReportName() {
      return 'ER-' + uuidv4();
    },
    async initializeEnrollmentData() {
      this.localEnrollment = this.deepCopy(this.enrollment);
      await this.getOwnedClasses();
      if (this.isValidEnrollment() && this.classOwnedByFacilitator(this.localEnrollment.enrolledClass)) {
        await this.getEnrollmentReport();
      }
      await this.getEnrollment();
    },

    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    isValidEnrollment() {
      return this.enrollment &&
          this.enrollment.name &&
          this.enrollment.enrolledClass &&
          this.enrollment.course;
    },

    // Moved the error handling here for a centralized approach
    handleError(error) {
      console.error("Error:", error);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(e => console.error('GraphQLError:', e.message));
      }
      if (error.networkError) {
        console.error('NetworkError:', error.networkError);
      }
    },

    async fetchMemberID(phoneNumber) {
      try {
        const response = await apolloClient.query({
          query: GET_MEMBER_ID_BY_PHONE,
          variables: {phoneNumber}
        });
        return response.data.memberId;
      } catch (error) {
        this.handleError(error);
        throw new Error("Failed to fetch member IDs using phone numbers.");
      }
    },

    async executeMutation(mutation, reportVariables) {
      // console.log("Executing mutation:", mutation, "with variables:", reportVariables)
      try {
        return await apolloClient.mutate({
          mutation: mutation,
          variables: reportVariables,
        });
      } catch (err) {
        this.handleError(err);
        throw new Error(`Error executing mutation: ${err.message}`);
      }
    },

    async reviewUser() {
      this.isLoading = true;
      try {
        const studentMemberId = await this.fetchMemberID(this.student.phoneNumber);
        const activeUserMemberId = await this.fetchMemberID(this.activeUser.phoneNumber);
        await this.processReview(studentMemberId, activeUserMemberId);
      } catch (error) {
        this.handleReviewError(error);
      } finally {
        this.isLoading = false;
        this.reviewing = false;
      }
    },
    async getEnrollmentReport() {
      try {
        if (!this.memberId || !this.courseId || !this.classId || this.reportType === null) {
          throw new Error("Missing necessary variables");
        }
        const reportData = await this.fetchEnrollmentReport();
        this.updateLocalEnrollmentWithReportData(reportData);
        this.localEnrollment.enrollmentReport = reportData;
        // Set the existing report name
        if (reportData && reportData.name) {
          this.existingReportName = reportData.name;
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    async fetchEnrollmentReport() {
      try {
        const resp = await apolloClient.query({
          query: GET_ENROLLMENT_TYPE_REPORT,
          variables: {
            memberId: this.memberId,
            courseId: this.courseId,
            classId: this.classId,
            reportType: this.reportType
          },
        });
        return resp.data.getEnrollmentTypeReport;
      } catch (err) {
        this.handleError(err);
        throw new Error(`Error fetching enrollment report: ${err.message}`);
      }
    },

    async processReview(studentMemberId, activeUserMemberId) {
      this.isCreatingReport = !this.localEnrollment.enrollmentReport;

      const commonVariables = await this.generateCommonVariables(studentMemberId, activeUserMemberId);
      // console.log("Common variables:", commonVariables);

      const mutation = this.isCreatingReport ? CREATE_ENROLLMENT_REPORT : UPDATE_ENROLLMENT_REPORT;
      const reportVariables = this.isCreatingReport ? commonVariables : {report: commonVariables};

      // console.log("reportVariables", reportVariables)
      // console.log("mutation", mutation)

      await this.executeMutation(mutation, reportVariables);

      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 5000); // Hide after 5 seconds

      await this.getEnrollmentReport();
      await this.getEnrollment();
    },


    handleReviewError(error) {
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 5000); // Hide after 5 seconds
      this.errorMessage = "The server returned a non-fatal error. Please refresh the page, and verify the enrollment report was successfully saved."
      this.handleError(error);
    },
    onReviewClick() {
      this.reviewing = true;
    },

    async generateCommonVariables(studentMemberId, activeUserMemberId) {
      return {
        name: this.isCreatingReport ? this.generateReportName() : this.existingReportName,
        member: studentMemberId,
        reporter: activeUserMemberId,
        reportType: this.reportType,
        enrollmentReport: {
          enrollment: this.localEnrollment.name, // enrollmentId
          memberId: this.memberId,
          classId: this.classId,
          courseId: this.courseId,
          feedback: this.localEnrollment.feedback,
          excellence: this.localEnrollment.excellence,
          attitude: this.localEnrollment.attitude,
          communication: this.localEnrollment.communication,
          timeManagement: this.localEnrollment.timeManagement,
          studentOutcome: this.localEnrollment.studentOutcome,
          interview: this.localEnrollment.interview,
          salvationCommitment: this.localEnrollment.salvationCommitment,
          skillsCourse: this.localEnrollment.skillsCourse,
          discipleshipLinked: this.localEnrollment.discipleshipLinked,
          interviewSent: this.localEnrollment.interviewSent,
          priorSalvation: this.localEnrollment.priorSalvation,
          r50Challenge: this.localEnrollment.r50Challenge,
          r50ChallengeMoney: this.localEnrollment.r50ChallengeMoney,
          r50ChallengeComment: this.localEnrollment.r50ChallengeComment,
          deepDiveChallenge: this.localEnrollment.deepDiveChallenge,
          deepDiveChallengeComment: this.localEnrollment.deepDiveChallengeComment,
        }
      };
    },
    updateLocalEnrollmentWithReportData(reportData) {
      if (!reportData) {
        console.log("No report data found for enrollment");
        return;
      }

      const {
        feedback,
        excellence,
        attitude,
        communication,
        interaction,
        listening,
        interview,
        morals,
        confidence,
        timeManagement,
        studentOutcome,
        salvationCommitment,
        skillsCourse,
        furtherEducation,
        startedBusiness,
        discipleshipLinked,
        priorSalvation,
        interviewSent,
        employed,
        r50Challenge,
        r50ChallengeMoney,
        r50ChallengeComment,
        deepDiveChallenge,
        deepDiveChallengeComment,
      } = reportData.enrollmentReport;
      this.localEnrollment = {
        ...this.localEnrollment,
        feedback: feedback,
        excellence: excellence,
        attitude: attitude,
        communication: communication,
        interaction: interaction,
        listening: listening,
        interview: interview,
        morals: morals,
        confidence: confidence,
        timeManagement: timeManagement,
        studentOutcome: studentOutcome,
        salvationCommitment: salvationCommitment,
        skillsCourse: skillsCourse,
        furtherEducation: furtherEducation,
        startedBusiness: startedBusiness,
        discipleshipLinked: discipleshipLinked,
        priorSalvation: priorSalvation,
        interviewSent: interviewSent,
        employed: employed,
        r50Challenge: r50Challenge,
        r50ChallengeMoney: r50ChallengeMoney,
        r50ChallengeComment: r50ChallengeComment,
        deepDiveChallenge: deepDiveChallenge,
        deepDiveChallengeComment: deepDiveChallengeComment,
      };
      // console.log("Updated local enrollment with report data: ", this.localEnrollment);
    },

    async getEnrollment() {
      try {
        const resp = await apolloClient.query({
          query: GET_ENROLLMENT,
          variables: {
            name: this.enrollment.name,
            classId: this.enrollment.enrolledClass,
            courseId: this.enrollment.course,
          },
          fetchPolicy: 'no-cache', // ensure we always get the latest data - e.g. when phone number is updated
        });

        if (resp.data && resp.data.getEnrollment) {
          this.localEnrollment = {
            ...resp.data.getEnrollment,
            ...this.localEnrollment
          };
          this.$set(this, 'paid', this.localEnrollment.paid);
        }

      } catch (err) {
        this.handleError(err);
      }
    },
    async handlePaidChange(newPaidValue) {
      let modifiedEnrollment = { ...this.localEnrollment }; // Create a shallow copy
      modifiedEnrollment.paid = newPaidValue;
      const studentMemberId = await this.fetchMemberID(this.student.phoneNumber);
      // console.log("studentMemberId", studentMemberId)

      // Set the enrollmentReport to just the FK
      if (modifiedEnrollment.enrollmentReport && modifiedEnrollment.enrollmentReport.name) {
        modifiedEnrollment.enrollmentReport = modifiedEnrollment.enrollmentReport.name;
      }
      // console.log("this.localEnrollment.name", this.localEnrollment.name)
      // console.log("this.localEnrollment.enrolledClass", this.localEnrollment.enrolledClass)
      // console.log("this.localEnrollment.course", this.localEnrollment.course)

      try {
        await apolloClient.mutate({
          mutation: UPDATE_ENROLLMENT,
          variables: {
            course: this.localEnrollment.course,
            enrolledClass: this.localEnrollment.enrolledClass,
            name: this.enrollment.name.replace(/ /g, ''),
            student: studentMemberId,
            enrollment: modifiedEnrollment
          }
        });
        this.$set(this, 'paid', newPaidValue);
        this.localEnrollment.paid = newPaidValue;
      } catch (err) {
        console.error("Error updating enrollment: ", err);
      }
    },
    promptDelete() {
      this.dialog = true;
    },
    async confirmDeleteMethod() {
      this.dialog = false;
      try {
        await this.deleteEnrollment();
      } catch (err) {
        console.error("Error confirming deletion: ", err);
      }
    },
    async deleteEnrollment() {
      // console.log("Deleting enrollment: ", this.student.phoneNumber,  this.localEnrollment.enrolledClass, this.localEnrollment.course)
      try {
        await apolloClient.mutate({
          mutation: DELETE_ENROLLMENT,
          variables: {
            name: this.student.phoneNumber,
            classId: this.localEnrollment.enrolledClass,
            courseId: this.localEnrollment.course
          }
        });
        this.deleted = true;
      } catch (err) {
        console.error("Error deleting enrollment: ", err);
      }
    },
    async getOwnedClasses() {
      try {
        const response = await apolloClient.query({
          query: LIST_CLASSES,
          variables: {
            filter: this.activeUser.phoneNumber.replace(/ /g, '')
          }
        });
        this.reportReporter = response.data.listClasses[0].facilitator;
        this.ownedClasses = response.data.listClasses.map(c => c.name);
      } catch (err) {
        console.error("Error getting owned classes: ", err);
      }
    },
    classOwnedByFacilitator(classId) {
      return this.ownedClasses.includes(classId);
    },
  },
}
</script>
<style>
.payments{
  color: #1e5953;
  font-size: 0.9em;
  font-weight: 900;
  margin-top: -20px;
  white-space: nowrap;
}
.right-row {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
}
.text-dark-grey {
  color: #213547;
  font-weight: bold;
}
.text-smaller {
  font-size: 16px;
}
.card-light-bg {
  background-color: #e4e2e2 !important;
}
.button-elevated {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
</style>