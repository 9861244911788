<template>
  <div v-if="activeUser">
  <v-row class="progress-signin">    
        <v-col cols="12" fixed class="this-right-col pt-0" v-if="this.value === 0" >
            <v-row class="mt-2 pa-2" style="justify-content: center; position: fixed; width: 100%">
                <v-progress-linear
                    :value="completeness"
                    height="30"
                    style="border-radius: 20px; color: whitesmoke;"
                    color="#6A3805"
                    class="progress"
                    v-if="completeness <= 30"
                    >
                    <strong> Completeness: {{ Math.ceil(completeness) }}%</strong>
                </v-progress-linear>
                <v-progress-linear
                    :value="completeness"
                    height="30"
                    style="border-radius: 20px; color: whitesmoke;"
                    color="#AD8A56"
                    class="progress"
                    v-if="completeness > 30 && completeness <= 50"
                    >
                    <strong> Completeness: {{ Math.ceil(completeness) }}%</strong>
                </v-progress-linear>
                <v-progress-linear
                    :value="completeness"
                    height="30"
                    style="border-radius: 20px; color: whitesmoke;"
                    color="#B4B4B4"
                    class="progress"
                    v-if="completeness > 50 && completeness <= 70"
                    >
                    <strong> Completeness: {{ Math.ceil(completeness) }}%</strong>
                </v-progress-linear>
                <v-progress-linear
                    :value="completeness"
                    height="30"
                    style="border-radius: 20px; color: whitesmoke;"
                    color="#D7D7D7"
                    class="progress"
                    disabled
                    v-if="completeness > 70 && completeness <= 80"
                    >
                    <strong> Completeness: {{ Math.ceil(completeness) }}%</strong>
                </v-progress-linear>
                <v-progress-linear
                    :value="completeness"
                    height="30"
                    style="border-radius: 20px; color: whitesmoke;"
                    color="#C9B037"
                    class="progress"
                    v-if="completeness > 80 && completeness < 100"
                    >
                    <strong> Completeness: {{ Math.ceil(completeness) }}%</strong>
                </v-progress-linear>
                <v-progress-linear
                    :value="completeness"
                    height="30"
                    style="border-radius: 20px; color: whitesmoke; border: 3px solid #AF9500;"
                    class="progress-gold"
                    color="white"
                    v-if="completeness === 100"
                    >
                    <span class="goldpls">Profile Complete</span>
                    <v-icon class="ml-2" color="#AF9500">mdi-star-check-outline</v-icon>
                </v-progress-linear>
            </v-row>
          <v-row>
            <v-alert v-if="showEmploymentPrompt && userId && !hasEmployment && !hasOwnBusiness" dense colored-border type="info" dismissible @close="showEmploymentPrompt = false">
              <div class="employment-prompt">
                <p>Have you found employment or started your own business since attending one of our courses? <strong>Complete our a questionnaire and stand a chance to win a prize!</strong></p>
                <router-link :to="`/employment-report/${userId}`">
                  <button class="employment-button">Employment Questionnaire</button>
                </router-link>
                <router-link :to="`/own-business-report/${userId}`">
                  <button class="ownbusiness-button">Own Business Questionnaire</button>
                </router-link>
              </div>
            </v-alert>
          </v-row>
            <v-row class="right-input pa-2">
                <!-- LARGE DISPLAY -->
                <v-row class="right-row hidden-md-and-down">
                    <!-- Left hand column -->
                    <v-col cols="6">
                        <!-- Member Info -->
                        <v-card class="rounded-xl card elevation-4" min-width="100%" min-height="70px" style="overflow-y: scroll;" @click="toggleModal">
                            <v-row class="right-row" style="margin: 5px">
                                <v-card-title class="name">{{activeUser.givenName + " " + activeUser.familyName}}</v-card-title>
                                <v-icon v-if="activeUser.email && activeUser.race && activeUser.gender && activeUser.givenName && activeUser.identificationNumber && activeUser.birthDate" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                    mdi-check-circle-outline
                                </v-icon>
                                <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                    mdi-account-edit-outline
                                </v-icon>
                            </v-row>
                            <v-divider style="margin-right: 5%; margin-left: 5%;"></v-divider>
                            <v-card-text>
                            <div class="my-4 text-subtitle-1">
                                <v-icon>mdi-phone-outline</v-icon>
                                {{activeUser.phoneNumber}}
                            </div>
                            <div class="my-4 text-subtitle-1" v-if="identificationNumber !== '' && identificationNumber !== '+'">
                                <v-icon>mdi-identifier</v-icon>
                                {{identificationNumber}}
                            </div>
                            <div class="my-4 text-subtitle-1" v-if="activeUser.email !== '' && this.$store.state.stateShowEmail">
                                <v-icon>mdi-email-outline</v-icon>
                                {{activeUser.email}}
                            </div>
                            <div class="my-4 text-subtitle-1" v-if="activeUser.gender !== 0 && activeUser.race !== 0">
                                <v-icon>mdi-human-male</v-icon>
                                {{genders[activeUser.gender-1].text}}, 
                                {{races[activeUser.race-1].text}}
                            </div>
                            <div class="my-4 text-subtitle-1" v-else-if="activeUser.race !== 0">
                                <v-icon>mdi-human-male</v-icon>
                                {{races[activeUser.race-1].text}}
                            </div>
                            <div class="my-4 text-subtitle-1" v-else-if="activeUser.gender !== 0">
                                <v-icon>mdi-human-male</v-icon>
                                {{genders[activeUser.gender-1].text}}
                            </div>
                            <div class="my-4 text-subtitle-1" v-if="activeUser.birthDate !== null && activeUser.birthDate !== '' && this.$store.state.stateShowDOB">
                                <v-icon>mdi-calendar-outline</v-icon>
                                {{activeUser.birthDate}}
                            </div>
                            <div class="my-4 text-subtitle-1" v-if="jobtitle !== ''">
                                <v-icon>mdi-account-outline</v-icon>
                                {{jobtitle}}
                            </div>
                            <div class="my-4 text-subtitle-1" v-if="nationality !== ''">
                                <v-icon>mdi-earth-outline</v-icon>
                                {{nationality}}
                            </div>
                            <div class="my-4 text-subtitle-1" v-if="activeUser.sublocality !== null && activeUser.locality !== null && this.$store.state.stateShowAddress">
                                <div v-if="activeUser.addressLines[0] !== ''">
                                    <v-icon>mdi-map-marker-outline</v-icon>
                                    {{activeUser.addressLines + ', ' + activeUser.sublocality + ', ' + activeUser.locality}}
                                </div>
                                <div v-else>
                                    <v-icon>mdi-map-marker-outline</v-icon>
                                    {{ activeUser.sublocality + ', ' + activeUser.locality}}
                                </div>
                            </div>
                            <v-row class="btnRow"> 
                                <v-btn rounded outlined class="addBtn" width="50%" style="max-width: 200px"> 
                                    <v-icon>mdi-pencil-outline</v-icon>
                                    Edit
                                </v-btn>
                            </v-row> 
                            </v-card-text>
                        </v-card>
                        
                        <!-- Languages -->
                        <v-card class="card rounded-xl elevation-4" min-width="100%" max-height="700px" min-height="70px" style="overflow-y: scroll;">

                            <v-card-title class="name" @click="showLanguages = !showLanguages">
                                <v-btn icon class="mx-2">
                                    <v-icon color="#1F003D">mdi-earth</v-icon>
                                </v-btn>
                                    Languages
                                    <v-spacer></v-spacer>
                                    <v-icon v-if="list.length > 0" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                        mdi-check-circle-outline
                                    </v-icon>
                                    <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                        mdi-account-edit-outline
                                    </v-icon>
                                <v-btn icon>
                                    <v-icon>{{ showLanguages ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-expand-transition>
                                <div v-show="showLanguages">
                                    <v-card-text>
                                        <v-list class="right-row">
                                            <v-list-item v-for="item in list" :key="item.code"> 
                                                <language-list :code="item.code" :skill="item.skill"> </language-list>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row class="btnRow"> 
                                            <v-btn rounded outlined class="addBtn" @click="toggleLanguageModal"> 
                                                <v-icon>mdi-plus</v-icon>
                                                Languages
                                            </v-btn>
                                        </v-row>
                                    </v-card-actions>
                                </div>
                            </v-expand-transition>
                            
                            
                        </v-card>
                        <LanguageModal :showLanguageModal="this.showLanguageModal" @close="toggleLanguageModal"/>
                        
                        <!-- Skills -->
                        <v-card class="card rounded-xl elevation-4" min-width="100%" max-height="700px" min-height="70px" style="overflow-y: scroll;">
                            <v-card-title class="name" @click="showSkills = !showSkills">
                                <v-btn icon class="mx-2 addBtn-skill">
                                    <v-icon color="#1F003D">mdi-star-plus</v-icon>
                                </v-btn>
                                Skills
                                <v-spacer></v-spacer>
                                <v-icon v-if="activeUser.grow.skills.length" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI'">
                                    mdi-check-circle-outline
                                </v-icon>
                                <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                    mdi-account-edit-outline
                                </v-icon>
                                <v-btn icon>
                                    <v-icon>{{ showSkills ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-expand-transition>
                                <div v-show="showSkills">
                                    <v-card-text>
                                        <v-list class="right-row">
                                            <v-list-item v-for="s in sortedSkills" :key="s.skill"> 
                                                <skills-list :skill="s.skill" :skillLevel="s.skillLevel" :description="s.description" :verified="s.verified"> </skills-list>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row class="btnRow"> 
                                            <v-btn rounded outlined class="addBtn" @click="toggleSkillsModal"> 
                                                <v-icon>mdi-plus</v-icon>
                                                Skill
                                            </v-btn>
                                        </v-row>
                                    </v-card-actions>
                                </div>
                            </v-expand-transition>
                            
                            
                        </v-card>
                        <SkillModal :showSkillModal="this.showSkillModal" @close="toggleSkillsModal"/> 
                        
                        <!-- Courses -->
                        <v-card class="card rounded-xl elevation-4" min-width="100%" max-height="350px" min-height="70px" style="overflow-y: scroll;">
                            <v-card-title class="name" @click="showCourses = !showCourses">
                                <v-btn icon class="mx-2 addBtn-skill">
                                    <v-icon color="#1F003D">mdi-bookshelf</v-icon>
                                </v-btn>
                                W4AL Courses
                                <v-spacer></v-spacer>
                                <v-icon v-if="memberEnrollments.length > 0" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont">
                                    mdi-check-circle-outline
                                </v-icon>
                                <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont">
                                    mdi-account-edit-outline
                                </v-icon>
                                <v-btn icon>
                                    <v-icon>{{ showCourses ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-expand-transition>
                                <div v-show="showCourses">
                                    <v-card-text class="pt-0">
                                        <v-list class="right-row pa-0 ma-0">
                                            <v-list-item v-for="e in memberEnrollments" :key="e.enrolledClass + e.course"> 
                                                <profile-enrollments-card :courseId="e.course" :date="e.enrolledClass.split('|')[1]"></profile-enrollments-card>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </div>
                            </v-expand-transition>
                        </v-card> 

                        <v-hover v-slot:default="{ hover }" open-delay="100">
                            <v-expand-x-transition width="10px">
                                <div width="10px">
                                    <v-btn
                                        color="white"
                                        x-large
                                        rounded
                                        dark
                                        bottom
                                        right
                                        fixed
                                        class="v-btn--floating"
                                        elevation-24
                                        @click="viewProfile"
                                        v-if="hover"
                                    >
                                    <span class="mr-2">View Profile</span>
                                    <v-icon >mdi-card-search-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="white"
                                        large
                                        dark
                                        fab
                                        bottom
                                        right
                                        fixed
                                        class="v-btn--floating"
                                        elevation-10
                                        @click="viewProfile"
                                        v-else
                                    >
                                        <v-icon >mdi-card-search-outline</v-icon>
                                    </v-btn>
                                </div>
                            </v-expand-x-transition>
                        </v-hover>
                    </v-col>
                    <!-- Right hand column -->
                    <v-col cols="6">
                        <!-- Profile -->
                        <v-card class="rounded-xl card elevation-4" min-width="100%" max-height="700px" min-height="70px" style="overflow-y: scroll;">
                            <v-card-title class="name" @click="showProfile=!showProfile">
                                <v-btn icon class="mx-2">
                                    <v-icon color="#1F003D">mdi-account</v-icon>
                                </v-btn>
                                    Profile Bio
                                    <v-spacer></v-spacer>
                                    <v-icon v-if="activeUser.grow.profile" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                        mdi-check-circle-outline
                                    </v-icon>
                                    <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                        mdi-account-edit-outline
                                    </v-icon>
                                <v-btn icon>
                                    <v-icon>{{ showProfile ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-expand-transition>
                                <div v-show="showProfile">
                                    <v-card-text>
                                        {{activeUser.grow.profile}}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row class="btnRow"> 
                                            <v-btn rounded outlined class="addBtn" @click="toggleProfileModal"> 
                                                <v-icon>mdi-pencil-outline</v-icon>
                                                Edit bio
                                            </v-btn>
                                        </v-row>
                                    </v-card-actions>
                                </div>
                            </v-expand-transition>
                            <ProfileModal :showProfileModal="this.showProfileModal" @close="toggleProfileModal"/>
                        </v-card>

                        <!-- Checkins -->
                      <v-card class="rounded-xl card elevation-4" min-width="100%" max-height="350px" min-height="70px" style="overflow-y: scroll;">
                        <v-card-title class="name" @click="showSurveys=!showSurveys">
                          <v-btn icon class="mx-2">
                            <v-icon color="#1F003D">mdi-list-box-outline</v-icon>
                          </v-btn>
                          Check-ins
                          <v-spacer></v-spacer>
                          <v-icon v-if="this.hasPreCheckin" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                            mdi-check-circle-outline
                          </v-icon>
                          <v-icon v-else-if="this.hasPostCheckin" class="ml-4" style="color: dimgray; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                            mdi-clock-outline
                          </v-icon>
                          <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                            mdi-account-edit-outline
                          </v-icon>
                          <v-btn icon>
                            <v-icon>{{ showSurveys ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </v-btn>
                        </v-card-title>

                      <v-expand-transition>
                                <div v-show="showSurveys">
                                    <br>
                                    <span v-if="jobReadinessParticipant">
                                        <v-card-text class="pa-2">
                                            <span v-if="!this.hasPreCheckin">
                                                <v-row class="right-row ml-5">
                                                    <v-col cols="7">
                                                        <v-row class="pa-0">
                                                            <v-card-subtitle class="name pa-0">
                                                                <p style="font-size: 1.2em">
                                                                    Check-in 1
                                                                </p>
                                                            </v-card-subtitle>
                                                        </v-row>
                                                        <v-row class="right-row">
                                                            <v-col cols="12" class="pa-0">
                                                                This must be done at the beginning of the Job Readiness class.
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="5" class="text-right" style="justify-content: right">
                                                        <v-btn class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PreCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022')}})">Do Check-in</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="ma-5"></v-divider>
                                                <br>
                                                <v-row class="right-row ml-5">
                                                    <v-col cols="7">
                                                        <v-row class="pa-0">
                                                            <v-card-subtitle class="name pa-0">
                                                                <p style="font-size: 1.2em">
                                                                    Check-in 2
                                                                </p>
                                                            </v-card-subtitle>
                                                        </v-row>
                                                        <v-row class="right-row">
                                                            <v-col cols="12" class="pa-0">
                                                                This must be done near the end of the Job Readiness class.
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <!-- <v-spacer></v-spacer> -->
                                                    <v-col cols="5" class="text-right" style="justify-content: right">
                                                        <v-btn v-if="this.hasPreCheckin" class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PostCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022'), encodedReport: encode(preCheckinName)}})">Do Check-in</v-btn>
                                                        <v-btn v-else disabled class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PostCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022'), encodedReport: encode(preCheckinName)}})">Do Check-in</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <span v-else-if="this.hasPreCheckin">
                                                <v-row class="right-row ml-5">
                                                    <v-col cols="12">
                                                        <v-row class="pa-0">
                                                            <v-card-subtitle class="name pa-0">
                                                                <p style="font-size: 1.2em; color: #2e7d32">
                                                                    Check-in Complete
                                                                </p>
                                                            </v-card-subtitle>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                
                                                <v-divider class="ma-5"></v-divider>
                                                <br>
                                                <v-row class="right-row ml-5" v-if="this.hasPreCheckin && !this.hasPostCheckin">
                                                    <v-col cols="7">
                                                        <v-row class="pa-0">
                                                            <v-card-subtitle class="name pa-0">
                                                                <p style="font-size: 1.2em">
                                                                    Check-in 2
                                                                </p>
                                                            </v-card-subtitle>
                                                        </v-row>
                                                        <v-row class="right-row">
                                                            <v-col v-if="daysFromClassEnd < -6" cols="12" class="pa-0">
                                                                This must be done near the <strong color="red">end</strong> of the Job Readiness class.
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <!-- <v-spacer></v-spacer> -->
                                                    <v-col cols="5" class="text-right" style="justify-content: right">
                                                        <v-btn v-if="daysFromClassEnd >= -6" class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PostCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022'), encodedReport: encode(preCheckinName)}})">Do Check-in</v-btn>
                                                        <v-tooltip bottom v-else>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <div v-on="on">
                                                                    <v-btn disabled v-bind="attrs" class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PostCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022'), encodedReport: encode(preCheckinName)}})">Do Check-in</v-btn>
                                                                </div>
                                                            </template>
                                                            <span v-if="-6 - this.daysFromClassEnd === 1">You can do the last check-in in {{-6 - this.daysFromClassEnd}} day</span>
                                                            <span v-else>You can do the last check-in in {{-6 - this.daysFromClassEnd}} days</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="right-row ml-5" v-else-if="this.hasPreCheckin && this.hasPostCheckin">
                                                    <v-col cols="12">
                                                        <v-row class="pa-0">
                                                            <v-card-subtitle class="name pa-0">
                                                                <p style="font-size: 1.2em; color: #2e7d32">
                                                                    Check-ins Complete
                                                                </p>
                                                            </v-card-subtitle>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </span>
                                            <br>

                                        </v-card-text>
                                    </span>
                                    <span v-else>
                                        <v-card-text class="pa-2">
                                            <v-row class="right-row">
                                                <v-row style="justify-content: left" class="ml-2">
                                                    <v-card-subtitle>
                                                        You need to enroll in a Job Readiness course to do a survey.
                                                    </v-card-subtitle>
                                                </v-row>
                                            </v-row>
                                            <br>
                                            <br>
                                        </v-card-text>
                                    </span>
                                    </div>
                                </v-expand-transition>
                        </v-card>

                        <!-- Work -->
                        <v-card class="card rounded-xl elevation-4" min-width="100%" max-height="700px" min-height="70px" style="overflow-y: scroll;">
                            <v-card-title class="name" @click="showWork = !showWork">
                                <v-btn icon class="mx-2">
                                    <v-icon color="#1F003D">mdi-briefcase</v-icon>
                                </v-btn>
                                    Work Experience
                                    <v-spacer></v-spacer>
                                    <v-icon v-if="activeUser.grow.workExperience.length" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                        mdi-check-circle-outline
                                    </v-icon>
                                    <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                        mdi-account-edit-outline
                                    </v-icon>
                                <v-btn icon>
                                    <v-icon>{{ showWork ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-expand-transition>
                                <div v-show="showWork">
                                    <v-card-text>
                                        <v-list class="right-row">
                                            <v-list-item v-for="work in activeUser.grow.workExperience" :key="work.jobTitle"> 
                                                <work-list :jobTitle="work.jobTitle" :company="work.company" :duration="work.duration" :description="work.description"> </work-list>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row class="btnRow"> 
                                            <v-btn rounded outlined class="addBtn" @click="toggleWorkModal"> 
                                                <v-icon>mdi-plus</v-icon>
                                                Work experience
                                            </v-btn>
                                        </v-row>
                                    </v-card-actions>
                                </div>
                            </v-expand-transition>
                            
                            
                        
                        </v-card>
                        <WorkModal :showWorkModal="this.showWorkModal" @close="toggleWorkModal"/>
                        <!-- Education -->
                        <v-card class="card rounded-xl elevation-4" min-width="100%" max-height="700px" min-height="70px" style="overflow-y: scroll;">
                            <v-card-title class="name" @click="showEducation = !showEducation">
                                <v-btn icon class="mx-2">
                                    <v-icon color="#1F003D">mdi-account-school</v-icon>
                                </v-btn>
                                    Education
                                    <v-spacer></v-spacer>
                                    <v-icon v-if="activeUser.grow.education.length" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                        mdi-check-circle-outline
                                    </v-icon>
                                    <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                        mdi-account-edit-outline
                                    </v-icon>
                                <v-btn icon>
                                    <v-icon>{{ showEducation ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-expand-transition>
                                <!-- <div v-show="showEducation">
                                    <v-card-text>
                                        <v-list class="right-row">
                                            <v-list-item v-for="edu in activeUser.grow.education" :key="edu.title"> 
                                                <education-list :title="edu.title" :school="edu.school" :duration="edu.duration" :description="edu.description"> </education-list>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row class="btnRow"> 
                                            <v-btn rounded outlined class="addBtn" @click="toggleEducationModal"> 
                                                <v-icon>mdi-plus</v-icon>
                                                Education
                                            </v-btn>
                                        </v-row>
                                    </v-card-actions>
                                </div> -->
                                <div v-show="showEducation">
                                    <v-card-text class="pt-0 pb-0">
                                        <v-card class="text-center d-flex flex-column align-center justify-center" flat elevation-0 height="100%">
                                            <div>
                                                <h4 style="font-weight: 500;">Have you completed Grade 12?</h4>
                                            </div>
                                            <div>
                                                <v-switch
                                                inset
                                                :input-value="this.activeUser.highSchoolGraduate"
                                                @change="updateLocalHighSchoolGraduate()"
                                                
                                                color="success"
                                                ></v-switch>
                                            </div>
                                        </v-card>
                                        <v-list class="right-row">
                                            <v-list-item v-for="edu in activeUser.grow.education" :key="edu.title"> 
                                                <education-list :title="edu.title" :school="edu.school" :duration="edu.duration" :description="edu.description" :educationLevel="edu.educationLevel" :qualification="edu.qualification" :industry="edu.industry"> </education-list>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row class="btnRow"> 
                                            <v-btn rounded outlined class="addBtn" @click="toggleEducationModal"> 
                                                <v-icon>mdi-plus</v-icon>
                                                Education
                                            </v-btn>
                                        </v-row>
                                    </v-card-actions>
                                </div>
                                
                            </v-expand-transition>
                            
                            
                        </v-card>
                        <EducationModal :showEducationModal="this.showEducationModal" @close="toggleEducationModal"/>
                    </v-col>
                    <v-card class="card rounded-xl" min-width="100%"></v-card>
                    <v-card class="card rounded-xl" min-width="100%"></v-card>
                    <v-card class="card rounded-xl" min-width="100%"></v-card>
                </v-row>

                <!-- SMALL DISPLAY -->
                <div class="hidden-lg-and-up pa-0 ma-0" style="width: 100% !important">
                    
                    <!-- Member Info -->
                    <v-card class="rounded-xl card elevation-4" min-width="100%">
                        <v-row class="right-row" style="margin: 5px">
                            <v-card-title class="name">{{activeUser.givenName + " " + activeUser.familyName}}</v-card-title>
                            <v-icon v-if="activeUser.email && activeUser.race && activeUser.gender && activeUser.givenName && activeUser.identificationNumber && activeUser.birthDate" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                mdi-account-edit-outline
                            </v-icon>
                        </v-row>
                        <v-divider style="margin-right: 5%; margin-left: 5%;"></v-divider>
                        <v-card-text>
                        <div class="my-4 text-subtitle-1">
                            <v-icon>mdi-phone-outline</v-icon>
                            {{activeUser.phoneNumber}}
                        </div>
                        <div class="my-4 text-subtitle-1" v-if="activeUser.email !== '' && this.$store.state.stateShowEmail">
                            <v-icon>mdi-email-outline</v-icon>
                            {{activeUser.email}}
                        </div>
                        <div class="my-4 text-subtitle-1" v-if="identificationNumber !== '' && identificationNumber !== '+'">
                            <v-icon>mdi-identifier</v-icon>
                            {{identificationNumber}}
                        </div>
                        <div class="my-4 text-subtitle-1" v-if="activeUser.gender !== 0 && activeUser.race !== 0">
                            <v-icon>mdi-human-male</v-icon>
                            {{genders[activeUser.gender-1].text}}, 
                            {{races[activeUser.race-1].text}}
                        </div>
                        <div class="my-4 text-subtitle-1" v-else-if="activeUser.race !== 0">
                            <v-icon>mdi-human-male</v-icon>
                            {{races[activeUser.race-1].text}}
                        </div>
                        <div class="my-4 text-subtitle-1" v-else-if="activeUser.gender !== 0">
                            <v-icon>mdi-human-male</v-icon>
                            {{genders[activeUser.gender-1].text}}
                        </div>
                        <div class="my-4 text-subtitle-1" v-if="activeUser.birthDate !== null && activeUser.birthDate !== '' && this.$store.state.stateShowDOB">
                            <v-icon>mdi-calendar-outline</v-icon>
                            {{activeUser.birthDate}}
                        </div>
                        <div class="my-4 text-subtitle-1" v-if="jobtitle !== ''">
                            <v-icon>mdi-account-outline</v-icon>
                            {{jobtitle}}
                        </div>
                        <div class="my-4 text-subtitle-1" v-if="nationality !== ''">
                            <v-icon>mdi-earth-outline</v-icon>
                            {{nationality}}
                        </div>
                        <div class="my-4 text-subtitle-1" v-if="activeUser.sublocality !== null && activeUser.locality !== null && this.$store.state.stateShowAddress">
                            <div v-if="activeUser.addressLines[0] !== ''">
                                <v-icon>mdi-map-marker-outline</v-icon>
                                {{activeUser.addressLines + ', ' + activeUser.sublocality + ', ' + activeUser.locality}}
                            </div>
                            <div v-else>
                                <v-icon>mdi-map-marker-outline</v-icon>
                                {{ activeUser.sublocality + ', ' + activeUser.locality}}
                            </div>
                        </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-row class="btnRow"> 
                                <v-btn rounded outlined class="addBtn" width="50%" style="max-width: 200px" @click="showModal = true"> 
                                    <v-icon>mdi-pencil-outline</v-icon>
                                    Edit
                                </v-btn>
                            </v-row> 
                        </v-card-actions>
                    </v-card>
                    
                    <!-- Profile -->
                    <v-card class="rounded-xl card elevation-4" min-width="100%">
                        <v-card-title class="name" @click="showProfile=!showProfile">
                            <v-btn icon class="mx-2">
                                <v-icon color="#1F003D">mdi-account</v-icon>
                            </v-btn>
                                Profile Bio
                                <v-spacer></v-spacer>
                                <v-icon v-if="activeUser.grow.profile" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                    mdi-check-circle-outline
                                </v-icon>
                                <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                    mdi-account-edit-outline
                                </v-icon>
                            <v-btn icon>
                                <v-icon>{{ !showProfile ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-expand-transition>
                            <div v-show="!showProfile">
                                <v-card-text>
                                    {{activeUser.grow.profile}}
                                </v-card-text>
                                <v-card-actions>
                                    <v-row class="btnRow"> 
                                        <v-btn rounded outlined class="addBtn" @click="toggleProfileModal"> 
                                            <v-icon>mdi-pencil-outline</v-icon>
                                            Edit bio
                                        </v-btn>
                                    </v-row>
                                </v-card-actions>
                            </div>
                        </v-expand-transition>
                        <ProfileModal :showProfileModal="this.showProfileModal" @close="toggleProfileModal"/>
                    </v-card>

                    <!-- Checkins -->
                    <v-card class="rounded-xl card elevation-4" min-width="100%" >
                        <v-card-title class="name" @click="showSurveys=!showSurveys">
                            <v-btn icon class="mx-2">
                                <v-icon color="#1F003D">mdi-list-box-outline</v-icon>
                            </v-btn>
                                Check-ins
                                <v-spacer></v-spacer>
                                <v-icon v-if="this.hasPreCheckin && this.hasPostCheckin" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                    mdi-check-circle-outline
                                </v-icon>
                                <v-icon v-else-if="this.hasPreCheckin" class="ml-4" style="color: dimgrey; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                    mdi-clock-outline
                                </v-icon>
                                <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                    mdi-account-edit-outline
                                </v-icon>
                            <v-btn icon>
                                <v-icon>{{ showSurveys ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        
                        <v-expand-transition>
                            <div v-show="showSurveys">
                                <br>
                                <span v-if="jobReadinessParticipant">
                                    <v-card-text class="pa-2">
                                        <span v-if="!this.hasPreCheckin">
                                            <v-row class="right-row ml-5">
                                                <v-col cols="7">
                                                    <v-row class="pa-0">
                                                        <v-card-subtitle class="name pa-0">
                                                            <p style="font-size: 1.2em">
                                                                Check-in 1
                                                            </p>
                                                        </v-card-subtitle>
                                                    </v-row>
                                                    <v-row class="right-row">
                                                        <v-col cols="12" class="pa-0">
                                                            This must be done at the beginning of the Job Readiness class.
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="5" class="text-right" style="justify-content: right">
                                                    <v-btn class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PreCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022')}})">Do Check-in</v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="ma-5"></v-divider>
                                            <br>
                                            <v-row class="right-row ml-5">
                                                <v-col cols="7">
                                                    <v-row class="pa-0">
                                                        <v-card-subtitle class="name pa-0">
                                                            <p style="font-size: 1.2em">
                                                                Check-in 2
                                                            </p>
                                                        </v-card-subtitle>
                                                    </v-row>
                                                    <v-row class="right-row">
                                                        <v-col cols="12" class="pa-0">
                                                            This must be done near the end of the Job Readiness class.
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <!-- <v-spacer></v-spacer> -->
                                                <v-col cols="5" class="text-right" style="justify-content: right">
                                                    <v-btn v-if="this.hasPreCheckin" class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PostCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022'), encodedReport: encode(preCheckinName)}})">Do Check-in</v-btn>
                                                    <v-btn v-else disabled class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PostCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022'), encodedReport: encode(preCheckinName)}})">Do Check-in</v-btn>
                                                </v-col>
                                            </v-row>
                                        </span>
                                        <span v-else-if="this.hasPreCheckin">
                                            <v-row class="right-row ml-5">
                                                <v-col cols="12">
                                                    <v-row class="pa-0">
                                                        <v-card-subtitle class="name pa-0">
                                                            <p style="font-size: 1.2em; color: #2e7d32">
                                                                Check-in Complete
                                                            </p>
                                                        </v-card-subtitle>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            
                                            <v-divider class="ma-5"></v-divider>
                                            <br>
                                            <v-row class="right-row ml-5" v-if="this.hasPreCheckin && !this.hasPostCheckin">
                                                <v-col cols="7">
                                                    <v-row class="pa-0">
                                                        <v-card-subtitle class="name pa-0">
                                                            <p style="font-size: 1.2em">
                                                                Check-in 2
                                                            </p>
                                                        </v-card-subtitle>
                                                    </v-row>
                                                    <v-row class="right-row">
                                                        <v-col v-if="daysFromClassEnd < -6" cols="12" class="pa-0">
                                                            This must be done near the <strong color="red">end</strong> of the Job Readiness class.
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <!-- <v-spacer></v-spacer> -->
                                                <v-col cols="5" class="text-right" style="justify-content: right">
                                                    <v-btn v-if="daysFromClassEnd >= -6" class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PostCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022'), encodedReport: encode(preCheckinName)}})">Do Check-in</v-btn>
                                                    <v-tooltip bottom v-else>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div v-on="on">
                                                                <v-btn disabled v-bind="attrs" class="pl-0" rounded text style="margin-right: 3%;color: #3C7872;text-transform: inherit;max-width: 200px" width="75%" @click="$router.push({name: 'PostCourseCheckin', params: {id: $store.state.jobReadinessEnrollmentId, encodedEnr: encode($store.state.jobReadinessEnrollmentId), encodedClassId: encode($store.state.jobReadinessClassId), encodedCourseId:encode('W4ALF-English-2022'), encodedReport: encode(preCheckinName)}})">Do Check-in</v-btn>
                                                            </div>
                                                        </template>
                                                        <span v-if="-6 - this.daysFromClassEnd === 1">You can do the last check-in in {{-6 - this.daysFromClassEnd}} day</span>
                                                        <span v-else>You can do the last check-in in {{-6 - this.daysFromClassEnd}} days</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                            <v-row class="right-row ml-5" v-else-if="this.hasPreCheckin && this.hasPostCheckin">
                                                <v-col cols="12">
                                                    <v-row class="pa-0">
                                                        <v-card-subtitle class="name pa-0">
                                                            <p style="font-size: 1.2em; color: #2e7d32">
                                                                Check-ins Complete
                                                            </p>
                                                        </v-card-subtitle>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </span>
                                        <br>

                                    </v-card-text>
                                </span>
                                <span v-else>
                                    <v-card-text class="pa-2">
                                        <v-row class="right-row">
                                            <v-row style="justify-content: left" class="ml-2">
                                                <v-card-subtitle>
                                                    You need to enroll in a Job Readiness course to do a survey.
                                                </v-card-subtitle>
                                            </v-row>
                                        </v-row>
                                        <br>
                                        <br>
                                    </v-card-text>
                                </span>
                                </div>
                            </v-expand-transition>
                    </v-card>

                    <!-- Languages -->
                    <v-card class="card rounded-xl elevation-4" min-width="100%">

                        <v-card-title class="name" @click="showLanguages = !showLanguages">
                            <v-btn icon class="mx-2">
                                <v-icon color="#1F003D">mdi-earth</v-icon>
                            </v-btn>
                                Languages
                                <v-spacer></v-spacer>
                                <v-icon v-if="list.length > 0" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                    mdi-check-circle-outline
                                </v-icon>
                                <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                    mdi-account-edit-outline
                                </v-icon>
                            <v-btn icon>
                                <v-icon>{{ showLanguages ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-expand-transition>
                            <div v-show="showLanguages">
                                <v-card-text>
                                    <v-list class="right-row">
                                        <v-list-item v-for="item in list" :key="item.code"> 
                                            <language-list :code="item.code" :skill="item.skill"> </language-list>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row class="btnRow"> 
                                        <v-btn rounded outlined class="addBtn" @click="toggleLanguageModal"> 
                                            <v-icon>mdi-plus</v-icon>
                                            Languages
                                        </v-btn>
                                    </v-row>
                                </v-card-actions>
                            </div>
                        </v-expand-transition>
                        
                        
                    </v-card>
                    <LanguageModal :showLanguageModal="this.showLanguageModal" @close="toggleLanguageModal"/>
                    
                    <!-- Skills -->
                    <v-card class="card rounded-xl elevation-4" min-width="100%">
                        <v-card-title class="name" @click="showSkills = !showSkills">
                            <v-btn icon class="mx-2 addBtn-skill">
                                <v-icon color="#1F003D">mdi-star-plus</v-icon>
                            </v-btn>
                            Skills
                            <v-spacer></v-spacer>
                            <v-icon v-if="activeUser.grow.skills.length" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI'">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                mdi-account-edit-outline
                            </v-icon>
                            <v-btn icon>
                                <v-icon>{{ showSkills ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-expand-transition>
                            <div v-show="showSkills">
                                <v-card-text>
                                    <v-list class="right-row">
                                        <v-list-item v-for="s in sortedSkills" :key="s.skill"> 
                                            <skills-list :skill="s.skill" :skillLevel="s.skillLevel" :description="s.description" :verified="s.verified"> </skills-list>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row class="btnRow"> 
                                        <v-btn rounded outlined class="addBtn" @click="toggleSkillsModal"> 
                                            <v-icon>mdi-plus</v-icon>
                                            Skill
                                        </v-btn>
                                    </v-row>
                                </v-card-actions>
                            </div>
                        </v-expand-transition>
                        
                        
                    </v-card>
                    <SkillModal :showSkillModal="this.showSkillModal" @close="toggleSkillsModal"/> 

                    <!-- Work -->
                    <v-card class="card rounded-xl elevation-4" min-width="100%" >
                        <v-card-title class="name" @click="showWork = !showWork">
                            <v-btn icon class="mx-2">
                                <v-icon color="#1F003D">mdi-briefcase</v-icon>
                            </v-btn>
                                Work Experience
                                <v-spacer></v-spacer>
                                <v-icon v-if="activeUser.grow.workExperience.length" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                                    mdi-check-circle-outline
                                </v-icon>
                                <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                    mdi-account-edit-outline
                                </v-icon>
                            <v-btn icon>
                                <v-icon>{{ showWork ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-expand-transition>
                            <div v-show="showWork">
                                <v-card-text>
                                    <v-list class="right-row">
                                        <v-list-item v-for="work in activeUser.grow.workExperience" :key="work.jobTitle"> 
                                            <work-list :jobTitle="work.jobTitle" :company="work.company" :duration="work.duration" :description="work.description"> </work-list>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row class="btnRow"> 
                                        <v-btn rounded outlined class="addBtn" @click="toggleWorkModal"> 
                                            <v-icon>mdi-plus</v-icon>
                                            Work experience
                                        </v-btn>
                                    </v-row>
                                </v-card-actions>
                            </div>
                        </v-expand-transition>
                        
                        
                    
                    </v-card>
                    <WorkModal :showWorkModal="this.showWorkModal" @close="toggleWorkModal"/>
                    
                    <!-- Education -->
                    <v-card class="card rounded-xl elevation-4" min-width="100%">
                        <v-card-title class="name" @click="showEducation = !showEducation">
                            <v-btn icon class="mx-2">
                                <v-icon color="#1F003D">mdi-account-school</v-icon>
                            </v-btn>
                                Education
                                <v-spacer></v-spacer>
                                <v-icon v-if="activeUser.grow.education.length" class="ml-4" style="color: teal; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                    mdi-check-circle-outline
                                </v-icon>
                                <v-icon v-else class="ml-4" style="color: darkred; font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'">
                                    mdi-account-edit-outline
                                </v-icon>
                            <v-btn icon>
                                <v-icon>{{ showEducation ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-expand-transition>
                            <div v-show="showEducation">
                                <v-card-text class="pt-0 pb-0">
                                    <v-card class="text-center d-flex flex-column align-center justify-center" flat elevation-0 height="100%">
                                        <div>
                                            <h4 style="font-weight: 500;">Have you completed Grade 12?</h4>
                                        </div>
                                        <div>
                                            <v-switch
                                            inset
                                            @change="updateLocalHighSchoolGraduate()"
                                            color="success"
                                            :input-value="this.activeUser.highSchoolGraduate"
                                            ></v-switch>
                                            <!-- :value="this.activeUser.highSchoolGraduate" -->
                                        </div>
                                    </v-card>
                                    <v-list class="right-row">
                                        <v-list-item v-for="edu in activeUser.grow.education" :key="edu.title"> 
                                            <education-list :title="edu.title" :school="edu.school" :duration="edu.duration" :description="edu.description" :educationLevel="edu.educationLevel" :qualification="edu.qualification" :industry="edu.industry"> </education-list>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row class="btnRow"> 
                                        <v-btn rounded outlined class="addBtn" @click="toggleEducationModal"> 
                                            <v-icon>mdi-plus</v-icon>
                                            Education
                                        </v-btn>
                                    </v-row>
                                </v-card-actions>
                            </div>
                        </v-expand-transition>
                        
                        
                    </v-card>
                    <EducationModal :showEducationModal="this.showEducationModal" @close="toggleEducationModal"/>
                    
                     <!-- Courses -->
                     <v-card class="card rounded-xl elevation-4" min-width="100%">
                        <v-card-title class="name" @click="showCourses = !showCourses">
                            <v-btn icon class="mx-2 addBtn-skill">
                                <v-icon color="#1F003D">mdi-bookshelf</v-icon>
                            </v-btn>
                            W4AL Courses
                            <v-spacer></v-spacer>
                            <v-icon v-if="memberEnrollments.length > 0" class="ml-4" style="color: green; font-family:system-ui, -apple-system, BlinkMacSystemFont">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else class="ml-4" style="color: red; font-family:system-ui, -apple-system, BlinkMacSystemFont">
                                mdi-account-edit-outline
                            </v-icon>
                            <v-btn icon>
                                <v-icon>{{ showCourses ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-expand-transition>
                            <div v-show="showCourses">
                                <v-card-text class="pt-0">
                                    <v-list class="right-row pa-0 ma-0">
                                        <v-list-item v-for="e in memberEnrollments" :key="e.enrolledClass + e.course"> 
                                            <profile-enrollments-card :courseId="e.course" :date="e.enrolledClass.split('|')[1]"></profile-enrollments-card>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card> 
                    <v-hover v-slot:default="{ hover }" open-delay="100">
                        <v-expand-x-transition width="10px">
                            <div width="10px">
                                <v-btn
                                    color="white"
                                    x-large
                                    rounded
                                    dark
                                    bottom
                                    right
                                    fixed
                                    class="v-btn--floating"
                                    elevation-24
                                    @click="viewProfile"
                                    v-if="hover"
                                >
                                <span class="mr-2">View Profile</span>
                                <v-icon >mdi-card-search-outline</v-icon>
                                </v-btn>
                                <v-btn
                                    color="white"
                                    large
                                    dark
                                    fab
                                    bottom
                                    right
                                    fixed
                                    class="v-btn--floating"
                                    elevation-10
                                    @click="viewProfile"
                                    v-else
                                >
                                    <v-icon >mdi-card-search-outline</v-icon>
                                </v-btn>
                            </div>
                        </v-expand-x-transition>
                    </v-hover>
                    <br>
                    <br>
                    <br>
                </div>
                <v-dialog v-model="showModal" width="600" scrollable>
                        <v-card class="rounded" scrollable>
                            <v-card-title class="justify-center grey lighten-3 title-space">
                                Edit personal details
                            </v-card-title>
                            <v-card-text>
                                    <v-form class="form">
                                        <v-row class="info-right-row">  
                                            <v-text-field
                                                class="rounded-text"
                                                label="First name"
                                                required
                                                outlined
                                                filled
                                                dense
                                                v-model="givenName"
                                                ></v-text-field>
                                                <!-- :value="activeUser.givenName"
                                                @input="updateLocalGivenName($event)" -->
                                        </v-row>
                                        <v-row class="info-right-row"> 
                                            <p class="red--text" v-if="givenNameError">Invalid first name.</p>
                                        </v-row>
                                        <v-row class="info-right-row">  
                                            <v-text-field
                                                class="rounded-text"
                                                label="Surname (optional)"
                                                required
                                                outlined
                                                filled
                                                dense
                                                v-model="familyName"
                                                ></v-text-field>
                                                <!-- :value="activeUser.familyName"
                                                @input="updateLocalFamilyName($event)" -->
                                        </v-row>
                                        <v-row class="info-right-row"> 
                                            <p class="red--text" v-if="familyNameError">Invalid last name.</p>
                                        </v-row>
                                        <v-row class="info-right-row">  
                                            <v-text-field
                                                class="rounded-text"
                                                label="Identification Number"
                                                required
                                                outlined
                                                filled
                                                dense
                                                v-model="identificationNumber"
                                                ></v-text-field>
                                                <!-- :value="activeUser.identificationNumber"
                                                @input="updateLocalIdentificationNumber($event)" -->
                                        </v-row>
                                        <v-row class="info-right-row"> 
                                            <p class="red--text" v-if="familyNameError">Invalid ID.</p>
                                        </v-row>
                                        <v-row class="info-right-row">  
                                            <v-text-field
                                                class="rounded-text"
                                                label="Phone Number"
                                                required
                                                outlined
                                                filled
                                                dense
                                                disabled
                                                :value="activeUser.phoneNumber"
                                            ></v-text-field>
                                        </v-row>
                                        <v-row class="info-right-row">  
                                            <v-text-field
                                                class="rounded-text"
                                                label="Email"
                                                required
                                                outlined
                                                filled
                                                dense
                                                v-if="this.$store.state.stateShowEmail"
                                                v-model="email"
                                                ></v-text-field>
                                                <!-- :value="activeUser.email"
                                                @input="updateLocalEmail($event)" -->
                                        </v-row>
                                        <v-row class="info-right-row"> 
                                            <p class="red--text" v-if="emailError">Invalid email address.</p>
                                        </v-row>
                                        <v-row class="info-right-row">  
                                            <v-select 
                                                label="Gender" 
                                                :items="genders"
                                                :reduce="gender => gender.code"
                                                outlined
                                                filled
                                                dense
                                                v-model="gender"
                                                >
                                                <!-- :value="activeUser.gender"
                                                @change="updateLocalGender($event)" -->
                                            </v-select>
                                        </v-row>
                                        <v-row class="info-right-row">  
                                            <v-select 
                                                label="Race" 
                                                :items="races"
                                                :reduce="race => race.code"
                                                outlined
                                                filled
                                                dense
                                                v-model="race"
                                                >
                                                <!-- :value="activeUser.race"
                                                @change="updateLocalRace($event)" -->
                                            </v-select>
                                        </v-row>
                                        <v-row class="info-right-row" v-if="this.$store.state.stateShowDOB">  
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    class="rounded-text"
                                                    label="Date of birth"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    light
                                                    outlined
                                                    filled
                                                    dense
                                                    append-icon="mdi-calendar"
                                                    :value="birthDate === '+' ? activeUser.birthDate : birthDate"
                                                    @input="updateLocalDOB($event)"
                                                >
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                :value="birthDate === '+' ? activeUser.birthDate : birthDate"
                                                @input="updateLocalDOB($event)"
                                                no-title
                                                :active-picker="activePicker"
                                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                                min="1950-01-01"
                                                @change="save"
                                                color="#30ac7c"
                                            ></v-date-picker>
                                            </v-menu>
                                        </v-row>
                                        <v-row class="info-right-row">  
                                            <v-text-field
                                                class="rounded-text"
                                                v-model="jobtitle"
                                                label="Job title"
                                                outlined
                                                filled
                                                dense
                                                v-if="this.showJob"
                                            ></v-text-field>
                                        </v-row>
                                        <v-row class="info-right-row">  
                                            <v-text-field
                                                class="rounded-text"
                                                v-model="nationality"
                                                label="Nationality"
                                                outlined
                                                filled
                                                dense
                                                v-if="this.showNationality"
                                            ></v-text-field>
                                        </v-row>
                                        <v-row class="info-right-row">  
                                            <v-text-field
                                                class="rounded-text"
                                                v-model="SecondPhone"
                                                label="Second Phone"
                                                outlined
                                                dense
                                                v-if="this.showSecondPhone"
                                            ></v-text-field>
                                        </v-row>
                                        <div class="info-right-row" v-if="this.$store.state.stateShowAddress">
                                            <v-divider class="divider"></v-divider>
                                            <v-row> 
                                                <h5 style="{margin-bottom: 20px}">Address</h5>
                                            </v-row>
                                            <v-row class="info-right-row"> 
                                                <v-text-field
                                                    class="rounded-text"
                                                    :value="activeUser.addressLines"
                                                    @input="updateLocalStreet($event)"
                                                    label="Street name"
                                                    outlined
                                                    filled
                                                    dense
                                                ></v-text-field>
                                            </v-row>
                                            <v-row>
                                                <v-col  cols="6" sm="6">
                                                    
                                                    <v-text-field
                                                        :value="activeUser.sublocality"
                                                        @input="updateLocalCityOrTown($event)"
                                                        label="City/Town"
                                                        dense
                                                        outlined
                                                        filled
                                                        class="addressCol"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" sm="6">
                                                    <v-text-field
                                                        class="rounded-text"
                                                        :value="activeUser.locality"
                                                        @input="updateLocalCountry($event)"
                                                        label="Country"
                                                        outlined
                                                        filled
                                                        dense
                                                    ></v-text-field>
                                            </v-col>
                                            </v-row>
                                        </div>
                                    </v-form>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>Additional</v-card-title>
                            <v-card-text>
                                <v-chip-group multiple :value="this.$store.state.selection" active-class="active-btn accent-4 white--text" column>
                                    <v-chip @click="setDOB"><v-icon>mdi-plus</v-icon>Date of birth</v-chip>
                                    <v-chip @click="setEmail"><v-icon>mdi-plus</v-icon>Email</v-chip>
                                    <v-chip @click="setAddress"><v-icon>mdi-plus</v-icon> Address</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions>
                                <v-row class="right-row-edit"> 
                                    <v-btn class="edit-btn-cancel" rounded text large @click="resetUser">Cancel</v-btn>
                                    <v-btn class="edit-btn-save" v-if="emailError" disabled rounded large color="#3c7872"> Save</v-btn>
                                    <v-btn class="edit-btn-save" v-else rounded large color="#3c7872" @click="updateUser"> Save</v-btn>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                </v-dialog>
                
        </v-row>
        </v-col>
    </v-row>
  </div>
  <v-layout v-else fill-height justify-center align-center>
    <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
  </v-layout>
</template>

<script>
    import { mapGetters, mapState } from "vuex"
    import LanguageModal from './LanguageModal.vue'
    import LanguageList from './LanguageList.vue'
    import ProfileModal from './ProfileModal.vue'
    import WorkModal from './WorkModal.vue'
    import WorkList from "./WorkList.vue"
    import EducationModal from "./EducationModal.vue"
    import EducationList from "./EducationList.vue"
    import SkillModal from "./SkillModal.vue"
    import SkillsList from "./SkillsList.vue"
    import ProfileEnrollmentsCard from "./ProfileEnrollmentsCard.vue"
    import { apolloClient } from "@/main"
    import { GET_CLASS, LIST_ENROLLMENTS, LIST_REPORTS, GET_MEMBER_ID_BY_PHONE } from "@/apollo/queries";

    // import countries from "countries-list";
    export default {
        components: { LanguageModal, LanguageList, ProfileModal, WorkModal, WorkList, EducationModal, EducationList, SkillModal, SkillsList, ProfileEnrollmentsCard},
        name: 'ContentBuilder',
        data: () => ({
          showEmploymentPrompt: true,
          hasEmployment: false,
          hasOwnBusiness: false,
          userId: "",
            memberEnrollments: [],
            hasPreCheckin: false,
            hasPostCheckin: false,
            daysFromClassEnd: 0,
            preCheckinName: "",
            tab: null,
            // show/hide fields
            highSchoolGraduate: false,
            showCourses: false,
            showSurveys: false,
            showJob: false,
            showModal: false,
            showEmail: true,
            showAddress: false,
            showNationality: false,
            showSecondPhone: false,
            showDOB: true,
            selection: [],
            showLanguages: false,
            showLanguageModal: false,
            showProfile: true,
            showProfileModal: false,
            showWork: false,
            showWorkModal: false,
            showEducation: false,
            showEducationModal: false,
            showSkills: false,
            showSkillModal: false,
            value: 0,
            // Date picker
            date: null,
            menu: false,
            activePicker: null,
            // Field values
            addr: "your-address",
            givenName: "",
            familyName: "",
            identificationNumber: "",
            email: "",
            birthDate: "",
            phoneNumber: "",
            jobtitle: "",
            nationality: "",
            givenNameError: false,
            familyNameError: false,
            emailError: false,
            streetName: [],
            postalCode: "",
            cityOrTown: "",
            country: "",
            gender: -1,
            genders : [
                {value: 1, text: 'Female'},
                {value: 2, text: 'Male'},
            ],
            race: -1,
            races : [
                // {value: 0, text: 'Other'},
                {value: 1, text: 'Black'},
                {value: 2, text: 'Asian'},
                {value: 3, text: 'Coloured'},
                {value: 4, text: 'White'},
                {value: 5, text: 'Middle Eastern'}, 
                {value: 6, text: 'Indian'},
                {value: 7, text: 'Mixed'},
            ],
            // country: Object.keys(countries.countries).map(code => countries.countries[code].name),
            select: { state: 'South Africa'},
        }),
        watch: {
            menu (val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
            },
        },
        async mounted () {
          await this.$store.dispatch("user/getUser")
          // Fetch the student's latest JR enrollment.
          if(this.activeUser.phoneNumber) {
            await this.fetchEnrollments()
          }
          // Fetch the student's latest job readiness course.
          await this.fetchClass()
          if (this.activeUser) {
            let activeUser = this.activeUser;
            this.givenName = activeUser.givenName
            this.familyName = activeUser.familyName
            this.identificationNumber = activeUser.identificationNumber
            this.email = activeUser.email
            this.birthDate = activeUser.birthDate
            this.phoneNumber = activeUser.phoneNumber
            this.race = activeUser.race
            this.gender = activeUser.gender
          }
          await this.fetchReportsWithTag("Checkins");
          await this.fetchReportsWithTag("EmploymentReportsByStudent");
          await this.fetchReportsWithTag("OwnBusinessReportsByStudent");
          await this.fetchMemberID(this.activeUser.phoneNumber.replace(/\s/g, ""))
        },
        computed: {
          ...mapGetters('user', ['activeUser']),
          ...mapState(["jobReadinessParticipant"]),
            list() {
                let l = this.activeUser.languageCodes.map((itm, i) => {
                    return { code: itm, skill: this.activeUser.languageSkills[i] }
                })
                return l.sort(function(x, y) { return y.skill - x.skill })
            },
            sortedSkills() {
                // Sort the skills by their verified property showing verified skills first.
                let s = this.activeUser.grow.skills
                let sorted = s.sort(function(x, y) { return y.verified - x.verified });
                return sorted
            },
            completeness(){
                var count = 0
                let user =  this.activeUser
                if (user) {
                    if (user.grow.education.length) {
                        count += 15
                    }
                    if (this.hasPostCheckin) {
                        count += 10
                    }
                    if (this.hasPreCheckin) {
                        count += 5
                    }
                    if (user.grow.skills.length) {
                        count += 10
                    }
                    if (user.grow.workExperience.length) {
                        count += 15
                    }
                    if (this.list.length > 0) {
                        count += 10
                    }
                    if (user.grow.profile) {
                        count += 15
                    }
                    if (user.identificationNumber) {
                        count += 5
                    }
                    if (user.birthDate) {
                        count += 2
                    }
                    if (user.email) {
                        count += 3
                    }
                    if (user.race) {
                        count += 5
                    }
                    if (user.gender) {
                        count += 5
                    }
                    if (user.givenName && user.familyName) {
                        count += 5
                    }
                }
                return count
            }
        },
        methods: {
          async fetchMemberID(phoneNumber) {
            try {
              const response = await this.$apollo.query({
                query: GET_MEMBER_ID_BY_PHONE,
                variables: {phoneNumber},
              });

              if (response.data && response.data.memberId) {
                this.userId = response.data.memberId;
              } else {
                console.error("Unexpected GraphQL response format.");
                this.showEmploymentPrompt = false;
              }
            } catch (error) {
              console.error("Failed to fetch member IDs using phone numbers.", error);
              this.showEmploymentPrompt = false;
            }
          },
            encode(str) {
                return window.btoa(str)
            },
            viewProfile () {
                if (this.$store.state.isAuthenticated) {
                    let userId = this.activeUser.phoneNumber.replace(/\s/g, "")
                    this.$router.push({name: 'ViewProfile', params: {id: userId}})
                } else {
                    this.$router.push({name: 'HomePage'})
                }
            },
            save (date) {
                this.$refs.menu.save(date)
            },
            setActiveJob () {
                this.showJob = !this.showJob
            },
            setEmail () {
                this.showEmail = !this.showEmail
                this.$store.commit('setShowEmail')
            },
            setAddress () {
                this.showAddress = !this.showAddress
                this.$store.commit('setShowAddr')
            },
            setNationality () {
                this.showNationality = !this.showNationality
            },
            setSecondPhone () {
                this.showSecondPhone = !this.showSecondPhone
            },
            setDOB () {
                this.showDOB = !this.showDOB
                this.$store.commit('setShowDOB')
            },
            toggleModal () {
                this.showModal = !this.showModal
            },
            toggleLanguageModal () {
                this.showLanguageModal = !this.showLanguageModal
            },
            toggleProfileModal () {
                this.showProfileModal = !this.showProfileModal
            },
            toggleWorkModal () {
                this.showWorkModal = !this.showWorkModal
            },
            toggleEducationModal () {
                this.showEducationModal = !this.showEducationModal
            },
            toggleSkillsModal () {
                this.showSkillModal = !this.showSkillModal
            },
            // Fetch
          async fetchReportsWithTag(tagValue) {
            const filterValue = this.activeUser.phoneNumber;

            try {
              const reportResponse = await this.$apollo.query({
                query: LIST_REPORTS,
                variables: {
                  tag: tagValue,
                  filter: filterValue
                }
              });

              // console.log("fetchReportsWithTag: Response from GraphQL API:", reportResponse)
              if (reportResponse.data && reportResponse.data.listReports.length > 0) {
                if (tagValue === "Checkins") {
                  this.hasPreCheckin = !!reportResponse.data.listReports.find(report =>
                      [4, 6, 8].includes(report.reportType)
                  );
                  // console.log("hasPreCheckin", this.hasPreCheckin)

                  this.hasPostCheckin = !!reportResponse.data.listReports.find(report =>
                      [5, 7, 9].includes(report.reportType)
                  );
                } else if (tagValue === "EmploymentReportsByStudent") {
                  this.hasEmployment = !!reportResponse.data.listReports.find(report =>
                      [10].includes(report.reportType)
                  );
                } else if (tagValue === "OwnBusinessReportsByStudent") {
                  this.hasOwnBusiness = !!reportResponse.data.listReports.find(report =>
                      [11].includes(report.reportType)
                  );
                }
              } else {
                // this.resetReportFlags();
                console.debug("No reports found in response or response was malformed.");
              }
            } catch (error) {
              console.error("Error while fetching the reports with tag", tagValue, ":", error);
            }
          },

          async fetchEnrollments() {
                await apolloClient.query({
                    query: LIST_ENROLLMENTS,
                    variables: {
                        filter: this.activeUser.phoneNumber,
                        tag: "Member",
                    }
                }).then(async response => {
                    let resp = response.data.listEnrollments
                    let jobReadinessEnrollments = resp.filter(e => e.course === "W4ALF-English-2022")
                    if (jobReadinessEnrollments.length > 0) {
                        let latestJREnrollment = jobReadinessEnrollments[0]
                        this.memberEnrollments = resp
                        this.$store.commit('setJobReadinessParticipant', true)
                        if (latestJREnrollment.preCheckin && latestJREnrollment.postCheckin) {
                            this.$store.commit('setCheckins', 2)
                        } else if (latestJREnrollment.preCheckin) {
                            this.$store.commit('setCheckins', 1)
                            this.preCheckinName = latestJREnrollment.preCheckin
                        } else {
                            this.$store.commit('setCheckins', 0)
                        }
                        this.$store.commit('setJobReadinessClassId', latestJREnrollment.enrolledClass)
                        this.$store.commit('setJobReadinessEnrollmentId', latestJREnrollment.name)
                    } else {
                        this.$store.commit('setJobReadinessParticipant', false)
                        this.$store.commit('setCheckins', 0)
                    }
                }).catch(ball => {
                    console.log("Caught: ", ball)
                })
            },
            // Find and set the end date of the most recent JR course the student has done.
            async fetchClass() {
                await apolloClient.query({
                    query: GET_CLASS,
                    variables: {
                        name: this.$store.state.jobReadinessClassId,
                        courseId: "W4ALF-English-2022",
                    }
                }).then(response => {
                    if (response.data.getClass !== null) {
                        let date = this.convertToDate(response.data.getClass.endDate)
                        this.$store.commit('setJobReadinessEndDate', date)
                        let dateToday = new Date();
                        let difference = dateToday.getTime() - date.getTime();
                        this.daysFromClassEnd = Math.ceil(difference / (1000 * 3600 * 24));
                    }
                }).catch(ball => {
                    console.log("Caught: ", ball)
                })
            },
            // utility function converting golang string representation of date to JS date type
            convertToDate (d) {
                let str = d.split(" ")
                var year, month, day
                if (str.length === 5) {
                    year = str[0].split(":")[1]
                    month = str[2].split(":")[1]
                    day = str[4].split(":")[1]
                } else {
                    year = str[0].split(":")[1]
                    month = str[1].split(":")[1]
                    day = str[2].split(":")[1]
                }
                let date = new Date(parseInt(year), parseInt(month)-1, parseInt(day), 0, 0, 0, 0);
                return date
            },
            resetUser () {
              if (this.activeUser) {
                let activeUser = this.activeUser;
                this.showModal = false
                this.givenName = activeUser.givenName
                this.familyName = activeUser.familyName
                this.identificationNumber = activeUser.identificationNumber
                this.email = activeUser.email
                this.birthDate = activeUser.birthDate
                this.phoneNumber = activeUser.phoneNumber
                this.race = activeUser.race
                this.gender = activeUser.gender
              }
            },
            async updateUser () {
              if (this.activeUser) {
                let activeUser = this.activeUser;
                let tmp = this.setFields(activeUser)
                await this.$store.dispatch('user/updateUser', tmp)
                this.showModal = false
                await this.$store.dispatch('user/getUser')
              }
            },
            setFields (stateUser) {
              if (this.activeUser) {
                let activeUser = this.activeUser;
                let tmp = JSON.parse(JSON.stringify(stateUser));
                tmp.givenName = this.givenName
                tmp.familyName = this.familyName
                tmp.email = this.email
                tmp.birthDate = this.birthDate
                if (this.streetName.length !== 0) {
                  tmp.addressLines[0] = this.streetName[0]
                }
                tmp.postalCode = this.postalCode
                tmp.sublocality = this.cityOrTown
                tmp.locality = this.country
                tmp.locality = this.country
                tmp.race = this.race
                tmp.gender = this.gender
                tmp.identificationNumber = this.identificationNumber
                tmp.highSchoolGraduate = activeUser.highSchoolGraduate
                return tmp
              }
            },
            
            async updateLocalHighSchoolGraduate () {
                this.activeUser.highSchoolGraduate = !this.highSchoolGraduate
                this.highSchoolGraduate = !this.highSchoolGraduate
                await this.updateUser()
            },
            updateLocalDOB (event) {
                this.birthDate = event
            },
            updateLocalStreet (event) {
                this.streetName[0] = event
            },
            updateLocalCityOrTown(event) {
                this.cityOrTown = event
            },
            updateLocalCountry (event) {
                this.country = event
            },
            validateName (value) {
                // Support international names with unicode:
                const regEx = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]* *?$/u;
                if (regEx.test(value)) {
                this.givenNameError = false;
                } else{
                this.givenNameError=true;
                } 
            },
            validateLastName (value) {
                // Support international names with unicode:
                const regEx = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]* *?$/u;
                if (regEx.test(value)) {
                this.familyNameError = false;
                } else{
                this.familyNameError = true;
                } 
            },
            validateEmail (value) {
                if (value !== '') {
                const regEx = /^\w+([.-]]?\w+)*@\w+([.-]?\w+)*(\.\w+)+ *$/;
                if (regEx.test(value)) {
                    this.emailError = false;
                } else{
                    this.emailError = true;
                } 
                } else {
                this.emailError = false;
                }
            },
        }
    }
</script>

<style scoped>
.employment-prompt {
  background-color: #f5f5f5; /* Light grey background */
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  text-align: center;
}

.employment-button {
  background-color: #007BFF; /* Change to your primary theme color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin: 10px;
  transition: background-color 0.3s;
}

.ownbusiness-button {
  background-color: teal;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin: 10px;
  transition: background-color 0.3s;
}

.ownbusiness-button:hover {
  background-color: darkslategray;
}


.employment-button:hover {
  background-color: #0056b3; /* Darkened version of the primary color */
}

.progress-signin {
  margin: 0px 0px 0px;
  background-color: #f2f2f2;
  padding: 5px;
  height: 100%;
  /* max-height: 50px; */
  /* height: 100%;  Fallback for browsers do NOT support vh unit */
  /* min-height: 100vh; */
}
.this-right {
  box-sizing: border-box;
  background: #3C7872;
  color: #3c7872;
  min-height: 94vh;
} 
.right {
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  display: flex;
  align-items: center;
}
.this-right-col {
  /* box-sizing: border-box; */
  /* background: #f2f2f2; */
  color: #fff;
  max-height: 100px;
  border: #6A2E85;
  /* align-items: center; */
}
.right-input {
  justify-content: center;
  height: 80vh;
  overflow-y: scroll;
  margin-top: 80px;
  margin-bottom: 0;
  overflow-x: hidden;
}
.progress-right-row{
    margin: 0 auto;
    min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 20vh;
    position: absolute;
    position: fixed;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.left-row {
  width: 100%;
  justify-content: left;
  padding: 20px;
}
.left-row-small {
  width: 100%;
  justify-content: left;
  padding: 30px;
  margin-bottom: 50px;
}
.this-signin-btn {
  width: 51%;
  color: #3c7872;
  margin-bottom: 15px;
}
.this-enter-btn {
  width: 51%;
  color: white;
  margin-bottom: 15px;
}
.text {
  justify-content: center;
  padding: 30px;
}
.align {
  justify-content: center;
  width: 100%;
}
.buttons {
  margin: 0;
  justify-content: center;
}
.h2-2 {
  text-align: center;
  padding: 10px;
}
.h2 {
  text-align: center;
  color: rgb(196, 196, 196);
  width: 100%;
}
.h2-bold {
  text-align: center;
  color: rgba(255, 255, 255);
  width: 100%;
}
.img{
  margin-top: 100px;
}
.active-btn{
    background: #3C7872;
}
.edit-btn {
  width: 80%;
  color: #000000;
  background: #ffffff;
  margin: 10px;
  margin-top: 40px;
}
.name{
  font-weight: 700;
  margin: 0px;
}
.rounded-text{
  border-radius: 12px;
}
.title-space{
  margin-bottom: 0px;
}
.save-chip {
  color: white;
}
.cancel-chip {
  color: #3c7872;
}
.edit-btn-save{
    padding: 20px;
    color: white;
}
.edit-btn-cancel{
    padding: 20px;
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 20px;
    padding: 20px;
}
.divider {
    margin-bottom: 20px;
}
.addressCol {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
}
.form {
    margin-top: 30px;
}
.addBtn {
    color: #3C7872;
    margin: 20px;
    text-transform: inherit;
}
.btnRow {
    justify-content: center;
    align-content: center;
}
.info-row {
    background:#ececec;
    border-radius: 12px;
    padding: 10px;
}
.card{
    margin: 20px;
    margin-bottom: 5px;
    margin-left: 0px;
}
.progress{
    margin-bottom: 5px;
    margin-right: 5px;
}
.progress-gold {
    margin-bottom: 5px;
    margin-right: 5px;
    background-color: white;
    color: white;
    background: white;
}
.v-btn--floating {
    bottom: 0;
    position: fixed;
    margin: 0 0 12px 12px;
    color: #6A2E85;
    background: #c7c7c7;
    text-transform: none !important;
    z-index: 1000;
}

.domain-right-row {
    margin: 20px 20px 20px 0;
}
.goldpls{
    color: #AF9500;
}

</style>