<template>
    <v-col cols="12" @click="toggleSkillsModal">
        <!-- v-if="!this.$route.fullPath.startsWith('/profiles')" -->
        <EditSkillModal v-if="this.$route.fullPath.startsWith('/profiles/content')" :showSkillModal="this.showSkillModal" :currentSkill="this.skill" :currentSkillLevel="this.skillLevel" :currentDescription="this.description" :currentVerified="this.verified" @close="toggleSkillsModal"/>
        <v-row class="right-row-edit">
            <h4>{{this.skill}}</h4>
            <v-spacer></v-spacer>
            <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                    <v-icon v-bind="attrs" v-on="on" v-if="verified">mdi-check-decagram-outline</v-icon>
                </template>
                <span> Verified W4AL skills course</span>
            </v-tooltip>
        </v-row>
        <v-row class="right-row-edit">
            <p> {{this.description}}</p>
        </v-row>
        <v-row class="right-row-edit">
            <v-slider
                v-model="this.skillLevelProp"
                value="this.skillLevelProp"
                color="#3c7872"
                track-color="#764AF1"
                readonly
                min="0"
                max="4"
            ></v-slider>
        </v-row>
        <v-divider></v-divider>
    </v-col>
</template>

<script>
import { mapGetters } from "vuex"
import EditSkillModal from './EditSkillModal.vue'
export default {
    name: 'WorkList',
    components: {EditSkillModal},
    props: {
        skill: {type: String},
        skillLevel: {type: Number},
        description: {type: String},
        verified: {type: Boolean},
    },
    data: () => ({
        showSkillModal: false,
        levels: [
            {value: 0, key: 'Novice'},
            {value: 1, key: 'Beginner'},
            {value: 2, key: 'Skillful'},
            {value: 3, key: 'Experienced'},
            {value: 4, key: 'Expert'},
        ]
    }),
    computed: {
      ...mapGetters('user', ['activeUser']),
        skillLevelProp: {
            get () {
                return this.skillLevel
            },
            set () {
               return this.skillLevel
            }
        }  
    },
    methods: {
        toggleSkillsModal () {
            // console.log("Toggling work in here")
            this.showSkillModal = !this.showSkillModal
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: left;
    margin: 2px;
}
.right-row-icon {
    width: 100%;
    justify-content: right;
    margin-bottom: 20px;
}

</style>
