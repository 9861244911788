<template>
    <v-row class="home">
      <v-col class="this-right">
        <div v-if="!this.loaded">
        </div>
        <div v-else>
            <v-list-item v-if="this.showNone">
                <v-row class="right-row">
                    <v-card class="card rounded-xl" min-width="90%">
                        <v-card-title> 
                        <v-row class="right-row-edit no-wrap">
                            <p> No class enrollments.</p>
                        </v-row> 
                        </v-card-title>
                        <v-card-text>
                            <v-row class="header-row">
                                
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-list-item>
            <div v-else>
                <v-list-item> 
                        <v-card class="header-card rounded-xl" min-width="90%">
                            <v-card-title> 
                            <v-row class="right-row-left-align">
                                <h4 style="margin-top:10px;margin-left:10px;"> Your courses</h4>
                            </v-row> 
                            </v-card-title>
                            <v-card-text>
                                <v-row class="right-row-left-align">
                                    <p style="margin-left:10px;"> These are the courses you have enrolled to. Complete your checkins.</p>
                                </v-row>
                            </v-card-text>
                        </v-card>
                </v-list-item>
                <div v-if="enrollments.length !== 0">
                    <v-virtual-scroll
                        :items="items"
                        item-height="220"
                        :height=450
                        width="100%"
                    >
                        <template v-slot:default="{ item }">
                            <v-list-item :key="item">
                        <!-- <v-list-item v-for="i in enrollments.length" :key="i">  -->
                            <!-- <v-card class="card rounded-xl " min-width="90%">
                                <v-card-text>
                                    <div>Course</div>
                                    <v-row class="right-row-left-align">
                                        <div class="text-h5 text--primary" style="font-weight: 500">
                                            {{courseName(courses[i-1])}}
                                        </div>
                                    </v-row> 
                                    <v-row class="right-row-left-align">
                                        {{facilitators[i-1]}}
                                    </v-row> 
                                    <v-row class="right-row-left-align">
                                        {{dates[i-1]}}
                                    </v-row> 
                                </v-card-text>
                                    <v-card-actions v-if="preCheckins[i-1] === null" class="right-row-edit">
                                        <v-btn class="create-btn" v-if="width < 333" rounded text x-small @click="$router.push({name: 'PreCourseCheckin', params: {id: enrollments[i-1], report: preCheckins[i-1]}})">Checkin</v-btn>
                                        <v-btn class="create-btn hidden-sm" v-else rounded text small @click="$router.push({name: 'PreCourseCheckin', params: {id: enrollments[i-1], enr: enrollments[i-1], report: preCheckins[i-1]}})">Pre-course Checkin</v-btn>
                                        <v-spacer></v-spacer>
                                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                                    </v-card-actions>
                                    <v-card-actions v-else-if="postCheckins[i-1] === null" class="right-row-edit">
                                        <v-btn class="create-btn" v-if="width < 333" rounded text x-small @click="$router.push({name: 'PostCourseCheckin', params: {id: enrollments[i-1], enr: enrollments[i-1], report: preCheckins[i-1]}})">Checkin</v-btn>
                                        <v-btn class="create-btn hidden-sm" v-else rounded text small @click="$router.push({name: 'PostCourseCheckin', params: {id: enrollments[i-1], enr: enrollments[i-1], report: preCheckins[i-1]}})">Post-course Checkin</v-btn>
                                        <v-spacer></v-spacer>
                                        <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                                    </v-card-actions>
                                    <v-card-actions v-else class="right-row-edit">
                                        <v-btn class="create-btn-complete" v-if="width < 333" rounded text x-small>Complete</v-btn>
                                        <v-btn class="create-btn-complete hidden-sm" v-else rounded text small>Checkins complete</v-btn>
                                        <v-spacer></v-spacer>
                                        <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
                                    </v-card-actions>
                            </v-card> -->
                            <member-enrollment-card :classId="classes[item-1]" :courseId="courses[item-1]" :date="dates[item-1]" :facilitator="facilitators[item-1]" :preCheckin="preCheckins[item-1]" :postCheckin="postCheckins[item-1]" :enrollment="enrollments[item-1]"></member-enrollment-card>
                        <!-- </v-list-item> -->
                            </v-list-item>
                        </template>
                    </v-virtual-scroll>
                </div>
                <div v-else>
                    <v-list-item> 
                        <v-card class="header-card-red rounded-xl " min-width="90%">
                            <v-card-title> 
                                <v-row class="right-row-left-align">
                                    <p style="margin-top:10px;margin-left:10px;"> No enrollments yet.</p>
                                </v-row> 
                            </v-card-title>
                            <v-card-text>
                                <v-row class="right-row-left-align">
                                    <p style="margin-left:10px;"> Go to a W4AL center to enrol in a class. </p>
                                </v-row> 
                            </v-card-text>
                        </v-card>
                    </v-list-item>
                </div>
            </div>
            </div>
      </v-col> 
    </v-row>
</template>

<script>
    import { mapGetters } from "vuex";
    import {UPDATE_ENROLLMENT} from "../../apollo/mutations"
    import {LIST_ENROLLMENTS} from "../../apollo/queries"
    import MemberEnrollmentCard from '../../components/MemberEnrollmentCard.vue';
    import { apolloClient } from "../../main"
    // import EnrollmentCard from "../../components/EnrollmentCard.vue"
    export  default {
    name: 'MemberEnrollments',
    components: {MemberEnrollmentCard},
    mounted () {
        this.loaded = false;
        this.getMemberEnrollments()
        this.loaded = true;
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        width () {
            return this.$vuetify.breakpoint.width
        }
    },
    data: () =>  ({
        loaded: false,
        enrollments: [],
        classes: [],
        courses: [],
        enrollmentObj: [],
        preCheckins: [],
        postCheckins: [],
        dates: [],
        facilitators: [],
        showNone: false,
        courseNames: [
            {displayName: "Job Readiness", id: "W4ALF-English-2022", phase: 1},
            {displayName: "WIRED 4 Business", id: "W4B-English-2022", phase: 1},
            {displayName: "Barista", id: "Barista-English-2022", phase: 2},
            {displayName: "Farming God's Way", id: "FGW-English-2022", phase: 2},
            {displayName: "Accounting", id: "Accounting-English-2022", phase: 2},
            {displayName: "Cashier", id: "Cashier-English-2022", phase: 2},
            {displayName: "Computers", id: "Computer-English-2022", phase: 2},
            {displayName: "Computers Bundle", id: "ComputersBundle-English-2022", phase: 2},
            {displayName: "Computers Intro", id: "ComputersIntro-English-2022", phase: 2},
            {displayName: "English", id: "English-English-2022", phase: 2},
            {displayName: "Events", id: "Event-English-2022", phase: 2},
            {displayName: "Freelance", id: "Freelance-English-2022", phase: 2},
            {displayName: "Graphic Design", id: "GraphicDesign-English-2022", phase: 2},
            {displayName: "Human Resources", id: "HumanResources-English-2022", phase: 2},
            {displayName: "Microsoft Excel", id: "MSExcel-English-2022", phase: 2},
            {displayName: "Microsoft Excel Advanced", id: "MSExcelAdv-English-2022", phase: 2},
            {displayName: "Microsoft Powerpoint", id: "MSPowerPT-English-2022", phase: 2},
            {displayName: "Microsoft Word", id: "MSWord-English-2022", phase: 2},
            {displayName: "Microsoft Word Advanced", id: "MSWordAdv-English-2022", phase: 2},
            {displayName: "Marketing", id: "Marketing-English-2022", phase: 2},
            {displayName: "Merchandising", id: "Merchandising-English-2022", phase: 2},
            {displayName: "Office Admin", id: "OfficeAdmin-English-2022", phase: 2},
            {displayName: "Operations Management", id: "OpsMan-English-2022", phase: 2},
            {displayName: "Parenting", id: "Parenting-English-2022", phase: 2},
            {displayName: "Retail & Cashier", id: "RetailAndCashier-English-2022", phase: 2},
            {displayName: "Retail & Merchandising", id: "RetailAndMerchandising-English-2022", phase: 2},
            {displayName: "Retail Management", id: "RetailMGGT-English-2022", phase: 2},
            {displayName: "Retail Skills", id: "RetailSkills-English-2022", phase: 2},
            {displayName: "SmartFinance", id: "SmartFinance-English-2022", phase: 2},
            {displayName: "Social Marketing", id: "SocialMKTG-English-2022", phase: 2},
            {displayName: "Supply Chain", id: "SupplyChain-English-2022", phase: 2},
            {displayName: "Waiter", id: "Waiter-English-2022", phase: 2},
        ],
        items: [],
    }),
    methods: {
        courseName(val) {
            let course = this.courseNames.filter(c => c.id === val);
            if (course.length === 0) {
                return val
            }
            return course[0].displayName
        },
        async getMemberEnrollments() {
            await apolloClient.query({
                query: LIST_ENROLLMENTS,
                variables: {
                    filter: this.activeUser.phoneNumber.replace(/ /g,''),
                    tag: "Member"
                },
                authorization: this.$store.state.jwt
            }).then(resp => {
                if (resp.data.listEnrollments.length === 0) {
                    this.showNone = true
                } 
                for (var i = 0; i < resp.data.listEnrollments.length; i++) {
                    let e = resp.data.listEnrollments[i]
                    let s = resp.data.listEnrollments[i].enrolledClass
                    let enStr = s.split("|")
                    this.enrollments.push(e.name)
                    this.classes.push(s)
                    this.facilitators.push(enStr[0])
                    this.courses.push(e.course)
                    this.dates.push(enStr[1])
                    this.enrollmentObj.push(resp.data.listEnrollments[i])
                    this.preCheckins.push(resp.data.listEnrollments[i].preCheckin)
                    this.postCheckins.push(resp.data.listEnrollments[i].postCheckin)
                }
                this.items = Array.from({ length: resp.data.listEnrollments.length }, (k, v) => v + 1)
            })
        },
        async deleteUser(id, enr) {
            enr.paid = true
            await apolloClient.mutate({
                mutation: UPDATE_ENROLLMENT,
                variables: {
                    name: id,
                    enrollment: enr
                }
            }).then (_ => {
                    console.log(_)
                    for(var i = 0; i < this.enrollments.length; i++){ 
                        if ( this.enrollments[i].enrollmentId === id) { 
                            this.enrollments.splice(i, 1); 
                        }
                    }
                }
            )
        }
    }
  }
</script>

<style scoped>
.home {
    margin: 0 auto;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    justify-content: center;

}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
  margin: 2px;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 1px;
}
.right-row-left-align {
    width: 100%;
    justify-content: left;
    margin: 1px;
}
.header-row{
    justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.this-list {
    justify-content: center;
    background: rgb(143, 166, 155);
    
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.this-signin-btn {
  color: #3c7872;
}
.this-right {
  box-sizing: border-box;
  background: rgb(143, 166, 155);
  color: #fff;
}
.active-btn{
    background: #3C7872;
}
.btnRow {
    justify-content: center;
    align-content: center;
    margin: 20px;
}
.cardRow {
    justify-content: center;
    align-content: center;
    margin: 12px;
}
.chip {
    margin-left: 10px;
    margin-right: 10px;
}
.title {
    justify-content: center;
    align-content: center;
    margin-top: 5px;
}
.create-btn{
    color: #3c7872;
    background: #D2DFDD;
    text-transform: none;
}
.create-btn-complete{
    color: #3c7872;
    background: #ffffff;
    text-transform: none;
}
.card{
    margin: 20px;
}
.header-card{
    margin: 20px;
    border: 2px solid #3c7872 !important;
}
.header-card-red{
    margin: 20px;
    border: 2px solid #680606 !important;
}
.no-wrap{
    white-space: nowrap;
    justify-content: center;
}
.this-share-btn {
    color: #6A2E85;
    text-decoration: none !important;
    display: inline-block;
    text-transform: none;
}
.right-row-left-align {
    width: 100%;
    justify-content: left;
    margin: 1px;
}
  </style>