<template>
  <div class="home">
    <LandPage/>
  </div>
</template>

<script>
// @ is an alias to /src
import LandPage from '@/components/LandPage.vue'
export default {
  name: 'HomePage',
  components: {
    LandPage
  },
}
</script>