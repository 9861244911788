<template>
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
        <v-row class="right-row">
          <h1>Welcome.</h1>
        </v-row>
      </v-col>
       
      <v-col cols="12" sm="12" md="5" lg="4" class="right">
        <v-row class="right-input">
             <v-row class="right-row"> 
                <h2 class="h2">Enter your phone number</h2>
             </v-row>
            <v-row class="right-row"> 
                <vue-tel-input :ignoredCountries=this.ignored :preferredCountries=this.preferred validCharactersOnly class="this-input-phone" mode="international" required v-model="phoneNumber"  defaultCountry="ZA"></vue-tel-input>
            </v-row>
            <v-row class="right-row">
                <div>
                    <v-row class="buttons">
                        <v-row class="right-row"> 
                            <v-btn v-if="!this.phoneNumber" class="signin-btn" type="submit" rounded color="white" dark disabled>
                                <p style="margin:20px">
                                    Verify with Whatsapp
                                </p> 
                            </v-btn>
                            <v-btn v-else class="signin-btn" type="submit" rounded color="white" required dark @click="authenticateUser">
                                <p style="margin:20px">
                                    Verify with Whatsapp
                                </p> 
                            </v-btn>
                        </v-row>
                    </v-row>
                </div>
            </v-row>
        </v-row>
        </v-col> 
    </v-row>
</template>

<script>
import {AUTHENTICATE_USER} from "@/apollo/mutations"
export default {
    name: 'NewEntryPage',
    data:  () => ({
        // The returned timestamp in seconds from the authenticateUser method
        timeSeconds: -1,
        // The returned timestamp in nanoseconds from the authenticateUser method
        timeNano: -1,
        // Show an error if the authentication send fails
        showSendingError: false,
        // Phone number input preferences
        preferred: ['US', 'ZA', 'MX', 'KE', 'MZ', 'BR', 'UG'],
        ignored: ['BG'],
        // Phone number input
        phoneNumber: "",
    }),
  methods: {
    async authenticateUser () {
      this.phoneNumber = this.phoneNumber.replace(/\+/g, "");
      this.phoneNumber = this.phoneNumber.replace(/ /g, "");
      await this.$apollo.mutate({
        mutation: AUTHENTICATE_USER,
        variables: {
          method: 1,
          contactDetail: this.phoneNumber,
        }
      })
        .then((response) => {
          if (response.data.authenticateUser != null) {
            this.timeSeconds = response.data.authenticateUser[0]
            this.timeNano = response.data.authenticateUser[1]
            this.showSendingError = false
          } else {
            this.showSendingError = true
          }
        }).catch(() => {
          // Error
          this.showSendingError = true
        })
      },
    }
}
</script>

<style scoped>
.h4{
  text-align: center;
}
.h6{
  text-align: center;
  margin-bottom: 20px;
}
.signin-btn {
  width: 80%;
  color: #3c7872;
  margin: 10px;
}
.buttons {
  width: 90%;
  margin: 10px;
  justify-content: center;
  margin-left: 20px;
  text-align: center;
}
.this-input-phone{
  background: #a9c9c6;
  border: white;
  color: #3c7872;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 70%;
}
</style>