<template>
    <v-col cols="12" @click="toggleWorkModal">
        <!-- v-if="!this.$route.fullPath.startsWith('/profiles')" -->
        <EditWorkModal v-if="this.$route.fullPath.startsWith('/profiles/content')" :showWorkModal="this.showWorkModal" :currentJob="this.jobTitle" :currentCompany="this.company" :currentDuration="this.duration" :currentDescription="this.description" @close="toggleWorkModal"/>
        <v-row class="right-row-edit">
            <h4>{{this.company}},&nbsp;</h4>
            <p>{{this.jobTitle}}</p>
        </v-row>
        <v-row class="right-row-edit">
            <p> {{this.duration}}</p>
        </v-row>
        <!-- <v-row class="right-row-edit">
            <v-slider
                v-model="this.skillProp"
                value="this.skillProp"
                color="#3c7872"
                track-color="#764AF1"
                readonly
                min="0"
                max="4"
            ></v-slider>
        </v-row> -->
        <v-divider></v-divider>
    </v-col>
</template>

<script>
import { mapGetters } from "vuex"
import EditWorkModal from './EditWorkModal.vue'
export default {
    name: 'WorkList',
    components: {EditWorkModal},
    props: {
        jobTitle: {type: String},
        company: {type: String},
        duration: {type: String},
        description: {type: String},
    },
    data: () => ({
        showWorkModal: false
    }),
    computed: {
      ...mapGetters('user', ['activeUser']),
        skillProp: {
            get () {
                return this.skill
            },
            set () {
               return this.skill
            }
        }  
    },
    methods: {
        toggleWorkModal () {
            // console.log("Toggling work in here")
            this.showWorkModal = !this.showWorkModal
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: left;
    margin: 2px;
}
.right-row-icon {
    width: 100%;
    justify-content: right;
    margin-bottom: 20px;
}

</style>
