<template>
    <v-card-title>
      <v-row class="right-row">
        <v-col cols=9>
          <div class="text-dark-grey"> {{student.givenName}} {{student.familyName}} <br> <div class="text-smaller"> Phone: {{ student.phoneNumber }}</div></div>
          <br>
          <div v-if="enrollment">
            <div v-if="showClassDetails" class="text-smaller">
              <p>
                Course: {{localEnrollment.course}}
                <br>
                Class: {{localEnrollment.enrolledClass}}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-title>
</template>

<script>
export default {
  name: 'EnrollmentHeader',
  props: {
    student: Object,
    paid: Boolean,
    reviewed: Boolean,
    localEnrollment: Object,
    classOwnedByFacilitator: Function,
    showClassDetails: Boolean,
    updateEnrollment: Function,
    enrollment: Object
  },
}
</script>
