<template>
    <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable>
        <v-card >
            <v-card-title class="justify-center grey lighten-3 title-space">
                Add language
            </v-card-title>
            <v-card-text>
                <v-form class="form">
                    <v-row class="info-right-row">  
                        <v-select
                            @input="updateSpokenLanguage($event)"
                            :items="languages"
                            label="Language"
                            filled
                            class="rounded-text"
                            outlined
                            dense
                            v-model="spokenLanguage"
                        >
                        </v-select>
                    </v-row>
                    <v-row class="info-right-row">
                        <v-select
                            @input="updateLevel($event)"
                            :items="levels"
                            item-text="key"
                            item-value="value"
                            label="Language proficiency"
                            filled
                            class="rounded-text"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row class="right-row-edit"> 
                    <v-btn class="edit-btn-cancel" rounded text large @click="showModal=false">Cancel</v-btn>
                    <v-btn class="edit-btn-save" rounded large color="#3c7872" @click="updateUser" v-if="this.level !== -1 && this.spokenLanguage !== ''"> Save</v-btn>
                    <v-btn class="edit-btn-save" rounded large color="#3c7872" @click="updateUser" v-else disabled> Save</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: 'LanguageModal',
    data: () => ({
        spokenLanguage: "",
        level: -1,
        levels: [
            {value: 1, key: 'Beginner'},
            {value: 2, key: 'Elementary Proficiency'},
            {value: 3, key: 'Limited working proficiency'},
            {value: 4, key: 'Highly proficient'},
            {value: 5, key: 'Native/full proficiency'}
        ],
        languages: [
            "Xhosa",
            "English", 
            "Zulu",
            "Afrikaans",
            "Sotho",
            "Shona",
            "Venda",
            "Tswana",
            "Ndebele",
            "Tsonga",
            "Swati",
            "Other"
        ],
        errors: [
            {'language': ""},
        ],
    }),
    props: 
    // ['showLanguageModal']
    {
        showLanguageModal: {
            type: Boolean
        }
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        showModal: { 
            get () {
                return this.showLanguageModal
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
               return this.$emit("close")
            }
        }  
    },
    methods: {
        updateSpokenLanguage(e) {
                // Support international names with unicode:
                const regEx = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð-]* *?$/u;
                if (regEx.test(e)) {
                this.errors['firstName'] = '';
                } else{
                this.errors['firstName'] = 'Invalid first name';
                // console.log(this.errors.firstName)
                } 
            this.spokenLanguage = e
        },
        updateLevel(e) {
            // console.log("updateing level to: ", e)
            this.level = e
        },
        async updateUser () {
            let tmp = this.setFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            this.$store.dispatch('user/getUser')
            this.$store.getters.activeUser
            this.showModal = false
            this.spokenLanguage = ""
            this.level = -1
        },
        setFields (stateUser) {
            // console.log("active user before change: ", this.activeUser)
            let stringify = JSON.stringify(stateUser)
            // console.log("Stringify: ", stringify)
            let tmp = JSON.parse(stringify);
            // console.log("tmpUser before language change: ", tmp.languageCodes)
            // tmp.languageCodes = this.activeUser.languageSkills
            // tmp.languageSkills = this.activeUser.languageSkills
            // console.log("tmpUser before change: ", tmp)
            // for (let i = 0; i < tmp.languageCodes.length; i++) {
            //     console.log("code: ", tmp.languageCodes[i])
            //     console.log("skill: ", tmp.languageSkills[i])
            // }
            tmp.languageCodes[tmp.languageCodes.length] = this.spokenLanguage
            tmp.languageSkills[tmp.languageSkills.length]= this.level
            // console.log("tmpUser after change: ", tmp)
            return tmp
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 10px;
}

</style>
