import gql from 'graphql-tag';

export const GET_MEMBER_ID_BY_PHONE = gql`
    query getMemberIdByPhone($phoneNumber: String!) {
        memberId: getMemberIdByPhone(phoneNumber: $phoneNumber)
    }
`;

export const GET_MEMBER = gql`
  query getMember($name: String!) {
      getMember(name: $name) {
          name
          phoneNumber
          email
          gender
          languageCodes
          languageSkills
          race
          givenName
          familyName
          role
          nameShort
          birthDate
          addressLines
          sublocality
          locality
          postalCode
          baseHub
          identificationNumber
          highSchoolGraduate
          universityGraduate
          grow {
            profile
            classId
            enrollments
            highestExcellenceRating
            workExperience {
              jobTitle
              company
              duration
              description
            }
            education {
              title
              school
              duration
              description
              educationLevel
              qualification
              state
              industry
            }
            skills {
              skill
              skillLevel
              description
              verified
            }
          }
      }
  }
`;

export const SEARCH_STUDENT = gql`
    query SearchStudent($request: SearchStudentRequest!) {
        searchStudent(request: $request) {
            member {
                name
                phoneNumber
                email
                gender
                languageCodes
                languageSkills
                race
                givenName
                familyName
                role
                nameShort
                birthDate
                addressLines
                sublocality
                locality
                postalCode
                baseHub
                includeLastShareTime
                highSchoolGraduate
                universityGraduate
                identificationNumber
            }
            enrollments {
                course
                enrolledClass
                name
                student
                paid
                preCheckin
                postCheckin
                enrollmentReport
            }
        }
    }
`;

export const SEARCH_STUDENTS = gql`
    query SearchStudents($request: SearchStudentsRequest!) {
        searchStudents(request: $request) {
            memberResolverSlice {
                members {
                    name
                    phoneNumber
                    email
                    gender
                    languageCodes
                    languageSkills
                    race
                    givenName
                    familyName
                    role
                    nameShort
                    birthDate
                    addressLines
                    sublocality
                    locality
                    postalCode
                    baseHub
                    includeLastShareTime
                    highSchoolGraduate
                    universityGraduate
                    identificationNumber
                }
            }
        }
    }
`;

export const GET_CLASS = gql`
  query getClass($name: String!, $courseId: String!) {
      getClass(name: $name, courseId: $courseId) {
        course
        name
        endDate
        startDate
        facilitator
        classFormat
        hub
      }
  }
`;

export const GET_ENROLLMENT = gql`
  query getEnrollment($name: String!, $classId: String!, $courseId: String!) {
    getEnrollment(name: $name, classId: $classId, courseId: $courseId) 
    {
      name
      enrolledClass
      student
      paid
      preCheckin
      postCheckin
      enrollmentReport
      course
    }
  }
`;

// Refer issue 74 - using different key values for getting enrollment reports
// Used for enrollment reports, i.e. reportType 1,4,5,6,7,8 & 9
export const GET_ENROLLMENT_TYPE_REPORT = gql`
    query getEnrollmentTypeReport($memberId: String!, $courseId: String!, $classId: String!, $reportType: Int!) {
        getEnrollmentTypeReport(memberId: $memberId, courseId: $courseId, classId: $classId, reportType: $reportType) {
            name
            member
            reporter
            reportType
            enrollmentReport {
                enrollment
                classId
                courseId
                feedback
                excellence
                attitude
                communication
                interaction
                listening
                interview
                morals
                confidence
                timeManagement
                npsScore
                studentOutcome
                salvationCommitment
                skillsCourse
                furtherEducation
                discipleshipLinked
                priorSalvation
                interviewSent
                r50Challenge
                r50ChallengeMoney
                r50ChallengeComment
                deepDiveChallenge
                deepDiveChallengeComment
            }
        }
    }
`;

export const GET_CHECKIN_REPORT = gql`
  query getReport($name: String!) {
    getReport(name: $name) {
      name
      member
      reporter
      reportType
    }
  }
`;

export const GET_STUDENT_EMPLOYMENT_REPORT = gql`
  query getReport($name: String!) {
    getReport(name: $name) {
    name
    member
    reporter
    reportType
    studentEmploymentReport {
      employerName
      jobTitle
      startDate
      endDate
      reasonForLeaving
      currentlyEmployed
      salaryRange
      jobSatisfaction
      jobOpportunities
      promoted
      willingToShareProofOfEmployment
      sharedProofOfEmployment
      proofOfEmploymentType
      referenceName
      referenceEmail
      referencePhoneNumber
      referenceRelationship
      studentPreferredPhoneNumber
      studentHub
      studentFacilitatorName
      enterToWinPrizes
      hub
      reviewedByFacilitator
      selectedForPromotion
      facilitatorComment
    }
  }
}`

export const GET_STUDENT_OWN_BUSINESS_REPORT = gql`
    query getReport($name: String!) {
        getReport(name: $name) {
        name
        member
        reporter
        reportType
        ownBusinessReport {
            businessStage
            businessName
            businessDescription
            businessWebsite
            startDate
            fundingType
            businessType
            locationType            
            physicalAddress
            numberOfEmployees
            turnoverRange
            businessChallenges
            w4alQuestion
            willingToShareProofOfOwnBusiness
            sharedProofOfOwnBusiness
            proofOfOwnBusinessType
            proofOfOwnBusinessOther
            studentPreferredPhoneNumber
            studentHub
            studentFacilitatorName
            enterToWinPrizes
            hub
            reviewedByFacilitator
            selectedForPromotion
            facilitatorComment
        }
    }
}`

export const LIST_MEMBERS = gql`
  query listMembers($filter: String) {
      listMembers(filter: $filter) {
          name
          phoneNumber
          baseHub
          race
          givenName
          familyName
      }
  }
`;

export const LIST_CLASSES = gql`
  query listClasses($filter: String) {
      listClasses(filter: $filter) {
          name
          course
          facilitator
          startDate
          endDate
          classFormat
          hub
          savedCount
          employedCount
          furtherEducationCount
          enrollmentsCount
      }
  }
`;

export const LIST_COURSES = gql`
  query listCourses($filter: String) {
      listCourses(filter: $filter) {
        courseId
        courseName
        courseVersion
      }
  }
`;

export const LIST_ENROLLMENTS = gql` 
  query listEnrollments($filter: String, $tag: String, $parent: String) {
      listEnrollments(filter: $filter, tag: $tag, parent: $parent) {
        course
        enrolledClass
        name
        student
        paid
        preCheckin
        postCheckin
        enrollmentReport
      }
  }
`;

export const LIST_REPORTS = gql`
    query listReports($parent: String, $tag: String, $filter: String) {
        listReports(parent: $parent, tag: $tag, filter: $filter) {
            name
            member
            reporter
            reportType
        }
    }
`;

export const LIST_EMPLOYMENT_REPORTS = gql`
    query listReports($parent: String, $tag: String, $filter: String) {
        listReports(parent: $parent, tag: $tag, filter: $filter) {
            name
            member
            reporter
            reportType
            studentEmploymentReport {
                employmentType
                employerName
                jobTitle
                startDate
                endDate
                reasonForLeaving
                currentlyEmployed
                salaryRange
                jobSatisfaction
                jobOpportunities
                promoted
                willingToShareProofOfEmployment
                sharedProofOfEmployment
                proofOfEmploymentType
                referenceName
                referenceEmail
                referencePhoneNumber
                referenceRelationship
                studentPreferredPhoneNumber
                studentHub
                studentFacilitatorName
                enterToWinPrizes
                hub
                reviewedByFacilitator
                selectedForPromotion
                facilitatorComment
                createTime
                updateTime
            }
        }
    }
`;

export const LIST_HUBS = gql` 
  query listHubs($filter: String) {
      listHubs(filter: $filter) {
        name
        displayName
      }
  }
`;

export const GET_TOKEN = gql`
query getToken ($phoneNumber: String) {
  getToken (phoneNumber: $phoneNumber)
}
`;

export const GET_CHECKIN_TOKEN = gql`
query getCheckinToken ($phoneNumber: String) {
  getCheckinToken (phoneNumber: $phoneNumber)
}
`;

export const REFRESH_TOKEN = gql`
query refreshToken ($refreshToken: String!) {
  refreshToken (refreshToken: $refreshToken)
}
`;