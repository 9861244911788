<template>
    <v-card width="90vw" class="this-card rounded-xl">
        <v-card-title primary-title>
            {{studentName}}
        </v-card-title>
        
        <v-card-subtitle>
            {{phoneNumber}}
        </v-card-subtitle>
        <v-divider style="margin-left: 2%; margin-right: 2%;"></v-divider>
        <v-card-actions>
            <v-chip-group column>
                <v-chip v-for="i in courses" :key="i">
                    {{i}}
                </v-chip>
            </v-chip-group>
        </v-card-actions>

    </v-card>
</template>

<script>
export default {
    name: 'ECafeEnrollmentCard',
    props: {
        studentName: {type: String},
        phoneNumber: {type: String},
        courses: {type: Array}
    },
}
</script>

<style scoped>
.this-card {
    padding: 5px;
}
</style>