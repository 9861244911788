<template>
  <v-row class="signin">
    <v-col cols="12" class="right" >
     <v-container grid-list-sm>
        <v-row class="right-input">
        <v-btn color="white" @click="createMagicLink">CreateMagicLink</v-btn>
     </v-row>
     <v-row class="right-input">
        <v-btn color="white" @click="verifyMagicLink">VerifyMagicLink</v-btn>
     </v-row>
     </v-container>
     
    </v-col>
    </v-row>
    <!-- </v-parallax> -->
</template>

<script>
    import { CREATE_MEMBER } from "../../apollo/mutations"
    import {GET_TOKEN} from "../../apollo/queries"
    import { apolloClient } from '../../main'
    export default {
        name: 'MagicLinks',
        data: () => ({
          firstName: "SonnyBill",
          phoneNumber: "+27 74 419 0174",
          image: {backgroundImage: "../../assets/Untitled.png"},
          mobile: "",
          windowWidth: window.innerWidth
        }),
        beforeDestroy() { 
          window.removeEventListener('resize', this.onResize); 
        },
        beforeMount(){
          this.onResize()
        },
        methods: {
            // createMagicLink() {
            //     apolloClient.mutate({
            //       mutation: CREATE_MAGIC_LINK,
            //       variables: {
            //           // name: "Code#" + Math.floor(Math.random() * 101),
            //           name: "Code#" + "101",
            //           phoneNumber: "0744190174"
            //       }
            //     })
            //     .then((data) => {
            //       console.log(data)
            //     })
            // },
            // verifyMagicLink() {
            //   apolloClient.mutate({
            //       mutation: VERIFY_MAGIC_LINK,
            //       variables: {
            //           // name: "Code#" + Math.floor(Math.random() * 101),
            //           name: "Code#" + "101",
            //       }
            //     })
            //     .then((data) => {
            //       console.log(data)
            //     })
            // },
          onResize() {
            this.windowWidth = window.innerWidth
            if (this.windowWidth > 550) {
              this.mobile = "This is DESKTOP: " + this.windowWidth
            } else {
              this.mobile = "This is MOBILE: " + this.windowWidth
            }
          },
          async getToken() {
            console.log("In get Token")
            apolloClient.query({
              query: GET_TOKEN,
              variables: {
                phoneNumber: this.$store.state.phoneNumber
              }
            })
            .then(() => {
              // Result
              this.$router.push({name: 'HomeUser'})
            }).catch((error) => {
              // Error
              console.error("COULD NOT GET USER TOKEN", error)
            })
          },
          // For now, let's be extra secure.
          goToHome () {
            // this.$store.commit('setFirstName', this.firstName)
            // this.$store.commit('setPhoneNumber', this.phoneNumber)
            // this.$store.commit('setStudent')
            if (this.$store.state.student) {
              this.$router.push({name:'HomeUser'})
            } else {
              this.$router.push({name:'HomeFacilitator'})
            }
            // :to="{name:'HomeUser', params: {firstName:this.firstName}}"
            // // console.log("Pushing to enter")
            // // this.$router.push({name:'EnterPage'})
          },
          facEnter () {
            this.$store.commit('setFacilitator')
            this.$router.push({name:'EnterPage'})
          },
          enter () {
            this.$router.push({name:'EnterPage'})
          },
          auth () {
            console.log(this.$store.state.isAuthenticated)
            this.$store.commit('authenticate')
            console.log(this.$store.state.isAuthenticated)
          },
          createMember () {
            console.log("CREATING USER")
            console.log("ACCESS TOKEN IS: ", this.$store.state.accessToken)
            apolloClient.mutate({
              mutation: CREATE_MEMBER,
              variables: {
                token: this.$store.state.accessToken,
                name: "Checkcheck",
                phoneNumber: "+8787878787878787",
                email: "email",
                gender: 1,
                familyName: "Firstname",
                givenName: "LastName",
                race: 0,
                birthDate: "HBD"
              }
            })
            .then((data) => {
              
              // // signInWithCustomToken; maybe generate one and pass it back from server??
              // this.getToken()
              // Result
              console.log("Created successfully")
              console.log(data)


            }).catch((error) => {
              // Error
              console.error(error)
            })
          },
          // :to="{name:'HomeUser', params: {firstName:this.firstName}}"
        }
    }
</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.this-right {
  box-sizing: border-box;
  background: #3C7872;
  color: #3c7872;
  min-height: 94vh;
} 
.right {
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  display: flex;
  align-items: center;
}
.right-input {
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 20px;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.left-row {
  width: 100%;
  justify-content: left;
  padding: 20px;
}
.left-row-small {
  width: 100%;
  justify-content: left;
  padding: 30px;
  margin-bottom: 50px;
}
.this-signin-btn {
  width: 51%;
  color: #3c7872;
  margin-bottom: 15px;
}
.this-enter-btn {
  width: 51%;
  color: white;
  margin-bottom: 15px;
  text-transform: lowercase;
}
.this-fac-btn {
  width: 51%;
  color: white;
  margin-top: 100px;
}
.text {
  justify-content: center;
  padding: 30px;
}
.align {
  justify-content: center;
  width: 100%;
}
.buttons {
  margin: 0;
  justify-content: center;
  width: 100%;
  padding: 5px;
}
.buttons-2 {
  margin-top: 30px;
  justify-content: center;
}
.h2 {
  text-align: center;
  color: rgb(196, 196, 196);
  width: 100%;
}
.h2-bold {
  text-align: center;
  color: rgba(255, 255, 255);
  width: 100%;
}

.img{
  margin-top: 100px;
}
</style>