<template>
    <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable>
        <v-card >
            <v-card-title class="justify-center grey lighten-3 title-space">
                Create Work Experience
            </v-card-title>
            <v-card-text>
                <p class="textArea">Provide details of professional work you have done.</p>
                <v-form class="form">
                            <v-row class="info-right-row">  
                                <v-text-field
                                    class="rounded-text"
                                    label="Job title"
                                    required
                                    outlined
                                    filled
                                    dense
                                    @input="updateLocalJob($event)"
                                ></v-text-field>
                            </v-row>
                            <v-row class="info-right-row">  
                                <v-text-field
                                    class="rounded-text"
                                    label="Company"
                                    required
                                    outlined
                                    filled
                                    dense
                                    @input="updateLocalCompany($event)"
                                ></v-text-field>
                            </v-row>
                            <v-row class="info-right-row">  
                                
                                <v-col cols="6">
                                    <v-select
                                        :value="2021"
                                        :items="startYears"
                                        label="Start year"
                                        @input="updateLocalStartYear($event)"
                                    ></v-select> 
                                </v-col>
                                <v-col cols="6">
                                <v-select
                                    v-model="endYear"
                                    :items="endYears"
                                    label="End year"
                                    @input="updateLocalEndYear($event)"
                                ></v-select> 
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-textarea
                                    name="input-7-1"
                                    filled
                                    label="Description (optional)"
                                    auto-grow
                                    class="textArea"
                                    @input="updateDescription($event)"
                                ></v-textarea>
                            </v-row>
                        </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row class="right-row-edit"> 
                    <v-btn class="edit-btn-cancel" rounded text large @click="showModal=false">Cancel</v-btn>
                        <v-tooltip bottom v-if="jobTitle === '' || company === ''">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-on="on">
                                    <v-btn class="edit-btn-save" v-bind="attrs" disabled rounded large color="#3c7872" @click="updateWork"> Save</v-btn>
                                </div>
                            </template>
                            <span>Complete the form.</span>
                        </v-tooltip>
                    <v-btn class="edit-btn-save" v-else rounded large color="#3c7872" @click="updateWork"> Save</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: 'WorkModal',
    data: () => ({
        jobTitle: "",
        company: "",
        duration: "",
        description: "",
        months: [
            {value: 1, key: "Jan"},
            {value: 2, key: "Feb"},
            {value: 3, key: "Mar"},
            {value: 4, key: "Apr"},
            {value: 5, key: "May"},
            {value: 6, key: "Jun"},
            {value: 7, key: "Jul"},
            {value: 8, key: "Aug"},
            {value: 9, key: "Sep"},
            {value: 10, key: "Oct"},
            {value: 11, key: "Nov"},
            {value: 12, key: "Dec"},
        ],
        startMonth: "",
        startYear: "2022",
        endMonth: "",
        endYear: "current job",
        newWork: {
            jobTitle: "",
            company: "",
            duration: "",
            description: "",
        }
    }),
    props: 
    // ['showLanguageModal']
    {
        showWorkModal: {
            type: Boolean
        }
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        showModal: {
            get () {
                return this.showWorkModal
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
               return this.$emit("close")
            }
        },
        startYears () {
            const startYear = 2000;
            const endYear = new Date().getFullYear();
            const startYears = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index).reverse();
            return startYears;
        },
        endYears () {
            const startYear = 2000;
            const endYear = new Date().getFullYear();
            const endYears = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index).reverse();
            endYears.unshift({ text: "current job", value: "current job" });
            return endYears;
        }
    },
    methods: {
        updateLocalJob(e) {
            this.jobTitle = e
        },
        updateLocalCompany(e) {
            this.company = e
        },
        updateLocalStartMonth (e) {
            this.startMonth = e
        },
        updateLocalStartYear (e) {
            this.startYear = e
        },
        updateLocalEndMonth (e) {
            this.endMonth = e
        },
        updateLocalEndYear (e) {
            this.endYear = e
        },
        updateDescription (e) {
            this.description = e
        },
        async updateWork () {
            let tmp = this.setFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            this.$store.dispatch('user/getUser')
            this.$store.getters.activeUser
            this.showModal = false
            this.resetValues()
        },
        setFields (stateUser) {
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            // console.log("tmp user before change: ", tmp)
            if (tmp.grow.workExperience.length === 0) {
                this.newWork.jobTitle = this.jobTitle
                this.newWork.company = this.company
                this.newWork.duration = this.convertDuration()
                this.newWork.description = this.description
                tmp.grow.workExperience[0] = this.newWork
                return tmp
            }
            // tmp.grow.profile = this.profileContent
            tmp.grow.workExperience[tmp.grow.workExperience.length] = JSON.parse(JSON.stringify(tmp.grow.workExperience[tmp.grow.workExperience.length-1]))
            // for (let i = 0; i < tmp.grow.workExperience.length; i++) {
            //     console.log("work: ", tmp.grow.workExperience[i])
            // }
            //  = tmp.grow.workExperience[tmp.grow.workExperience.length -1]
            tmp.grow.workExperience[tmp.grow.workExperience.length-1].jobTitle = this.jobTitle
            tmp.grow.workExperience[tmp.grow.workExperience.length-1].company = this.company
            tmp.grow.workExperience[tmp.grow.workExperience.length-1].duration = this.convertDuration()
            tmp.grow.workExperience[tmp.grow.workExperience.length-1].description = this.description
            // console.log("tmp user after change: ", tmp)
            return tmp
        },
        convertDuration () {
            if (this.startMonth === "" || this.endMonth === "") {
                return this.startYear + " - " + this.endYear;
            } else {
                if (this.endYear === "current job") {
                    return this.startMonth + "/" + this.startYear + " - " + this.endYear;
                } else {
                    return this.startMonth + "/" + this.startYear + " - " + this.endMonth + "/" + this.endYear;
                }
            }
        },
        resetValues() {
            this.jobTitle = ""
            this.company = ""
            this.description = ""
            this.startYear = 2021
            this.endYear = "current job"
        }
    }
}
</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.this-right {
  box-sizing: border-box;
  background: #3C7872;
  color: #3c7872;
  min-height: 94vh;
} 
.right {
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  display: flex;
  align-items: center;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.left-row {
  width: 100%;
  justify-content: left;
  padding: 20px;
}
.left-row-small {
  width: 100%;
  justify-content: left;
  padding: 30px;
  margin-bottom: 50px;
}
.this-signin-btn {
  width: 51%;
  color: #3c7872;
  margin-bottom: 15px;
}
.this-enter-btn {
  width: 51%;
  color: white;
  margin-bottom: 15px;
}
.text {
  justify-content: center;
  padding: 30px;
}
.align {
  justify-content: center;
  width: 100%;
}
.buttons {
  margin: 0;
  justify-content: center;
}
.h2 {
  text-align: center;
  color: rgb(196, 196, 196);
  width: 100%;
}
.h2-bold {
  text-align: center;
  color: rgba(255, 255, 255);
  width: 100%;
}

.img{
  margin-top: 100px;
}
.active-btn{
    background: #3C7872;
}
.edit-btn {
  width: 80%;
  color: #000000;
  background: #ffffff;
  margin: 10px;
  margin-top: 40px;
}
.name{
  font-weight: 700;
}
.rounded-text{
  border-radius: 12px;
}
.title-space{
  margin-bottom: 0px;
}
.save-chip {
  color: white;
}
.cancel-chip {
  color: #3c7872;
}
.edit-btn-save{
    padding: 20px;
    color: white;
}
.edit-btn-cancel{
    padding: 20px;
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 20px;
    padding: 20px;
}
.divider {
    margin-bottom: 20px;
}
.addressCol {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
}
.textArea{
    border-radius: 12px;
    margin-top: 20px;
}
.center {
    justify-content: center;
}
.form {
    margin-top: 30px;
}
.addBtn {
    color: #3C7872;
    margin: 20px;
}
.btnRow {
    justify-content: center;
    align-content: center;
}
.info-row {
    background:#ececec;
    border-radius: 12px;
    padding: 10px;
}
.card{
    margin: 20px;
}
</style>