import Vue from 'vue';
import VueRouter from 'vue-router';
import RegisterPage from '../views/Register/RegisterPage.vue';
import HomePage from '../views/HomePage.vue'
import AuthPage from "../views/Register/AuthPage.vue"
import InfoPage from "../views/Register/InfoPage.vue"
import InfoStepper from "../views/Register/InfoStepper.vue"
import HomeUser from "../views/Home/HomeUser.vue"
import LoginPage from "../views/Login/LoginPage.vue"
import OneTimePin from "../views/Login/OneTimePin.vue"
import OneTimePinRegister from "../views/Register/OneTimePin.vue"
import CheckVerify from "../views/Register/CheckVerify.vue"
// import AccountPage from "../views/Account/AccountPage.vue"
import EnterPage from "../views/Enter/EnterPage.vue"
import ProfileContent from "../views/Profile/ProfileContent.vue"
import ViewProfile from "../views/Profile/ViewProfile.vue"
import RegisterFacilitator from "../views/Register/RegisterFacilitator.vue"
import HomeFacilitator from "../views/Home/HomeFacilitator.vue"
import HomeActivator from "../views/Home/HomeActivator.vue"
import ClassBuilder from "../views/Class/ClassBuilder.vue"
import ClassViewer from "../views/Class/ClassViewer.vue"
import ClassEnrollment from "../views/Class/ClassEnrollment.vue"
import ViewClassEnrollments from "../views/Enrollment/ViewClassEnrollments.vue"
import MemberEnrollments from "../views/Enrollment/MemberEnrollments.vue"
import PreCourseCheckin from "../views/Checkins/PreCourseCheckin.vue"
import PostCourseCheckin from "../views/Checkins/PostCourseCheckin.vue"
import Store from '../store/index.js'
// import ChangePhone from "../views/Account/ChangePhone.vue"
import SecondaryEnter from "../views/Enter/SecondaryEnter.vue"
import EnrolStudents from "../views/Class/EnrolStudents.vue"
import MagicLinks from "../views/Test/MagicLinks.vue"
import MaintenancePage from "../views/Maintenance/LandPage.vue"
import NewEntryPage from "../views/Enter/NewEntryPage.vue"
import VerifyAuthentication from "../views/Authentication/VerifyAuthentication.vue"
import { isTokenExpired, generateToken } from '@/main'

Vue.use(VueRouter)
/* Set up all the routes that our frontend will support */
const routes = [
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage
  },
  {
    path: '/enter',
    name: 'EnterPage',
    component: EnterPage
  },
  {
    path: '/newentry',
    name: 'NewEntryPage',
    component: NewEntryPage
  },
  {
    path: '/authenticate/:key',
    name: 'VerifyAuthentication',
    component: VerifyAuthentication
  },
  {
    path: '/emailenter',
    name: 'SecondaryEnter',
    component: SecondaryEnter
  },
  {
    path: '/registerfacilitator',
    name: 'RegisterFacilitator',
    component: RegisterFacilitator,
    props: true
  },
  {
    path: '/checkVerify',
    name: 'CheckVerify',
    component: CheckVerify,
    props: true
  },
  {
    path: '/auth',
    name: 'AuthPage',
    component: AuthPage,
    props: true,
  },
  {
    path: '/info',
    name: 'InfoPage',
    component: InfoPage,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/infostepper',
    name: 'InfoStepper',
    component: InfoStepper,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'HomeUser',
    component: HomeUser,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/facilitator',
    name: 'HomeFacilitator',
    component: HomeFacilitator,
    meta: { requiresFacAuth: true },
  },
  {
    path: '/activator-home',
    name: 'HomeActivator',
    component: HomeActivator,
    meta: {requiresFacAuth: true},
  },
  {
    path: '/profiles/content',
    name: 'ProfileContent',
    component: ProfileContent,
    meta: { studentAuth: true }
  },
  {
    path: '/login',
    name:  'LoginPage',
    component: LoginPage
  },

  {
    path: '/otp',
    name: 'OneTimePin',
    component: OneTimePin,
    props: true
  },
  {
    path: '/otpregister',
    name: 'OneTimePinRegister',
    component: OneTimePinRegister,
    props: true
  },
  {
    path: '/land',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/test',
    name: 'MagicLinks',
    component: MagicLinks,
  },
  {
    path: '/maintenance',
    name: 'MaintenancePage',
    component: MaintenancePage,
  },
  {
    path: '/profiles/:id',
    name: 'ViewProfile',
    component: ViewProfile,
  },
  // See CHANGELOG [1.4.7]
  // {
  //   path: '/account',
  //   name: 'AccountPage',
  //   component: AccountPage,
  //   props: true,
  // },
  //   Need refactoring to change a phone number correctly
  // {
  //   path: '/changephone',
  //   name: 'ChangePhone',
  //   component: ChangePhone,
  // },
  {
    path: '/buildclass',
    name: 'ClassBuilder',
    component: ClassBuilder,
    meta: { requiresFacAuth: true },
  },
  {
    path: '/enrolstudents',
    name: 'EnrolStudents',
    component: EnrolStudents,
    meta: { requiresFacAuth: true },
  },
  {
    path: '/viewclasses',
    name: 'ClassViewer',
    component: ClassViewer,
    props: true,
    meta: { requiresFacAuth: true },
  },
  {
    path: '/classenrolment/:id',
    name: 'ClassEnrollment',
    component: ClassEnrollment,
  },
  {
    path: '/viewclassenrolments/:id',
    name: 'ViewClassEnrollments',
    component: ViewClassEnrollments,
    props: true,
    meta: { requiresFacAuth: true }
  },
  {
    path: '/hub-activity/',
    name: 'HubActivity',
    component: () => import('@/views/Hub/HubActivity.vue'),
    meta: { requiresFacAuth: true }
  },
  {
    path: '/student-profile/:memberId',
    name: 'StudentProfile',
    component: () => import('@/views/Profile/StudentProfile.vue'),
    meta: { requiresFacAuth: true }
  },
  {
    path: '/employment-report/:memberId',
    name: 'CreateEmploymentReport',
    component: () => import('@/components/CreateEmploymentReport.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/own-business-report/:memberId',
    name: 'CreateOwnBusinessReport',
    component: () => import('@/components/CreateOwnBusinessReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/update-details/:memberId',
    name: 'UpdateStudentDetails',
    component: () => import('@/components/UpdateStudentDetails.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/myenrollments',
    name: 'MemberEnrollments',
    component: MemberEnrollments,
    meta: { studentAuth: true }
  },
  {
    path: "/precheckin/:id",
    name: 'PreCourseCheckin',
    component: PreCourseCheckin,
    props: true,
    meta: { studentAuth: true }
  },
  {
    path: "/postcheckin/:id",
    name: 'PostCourseCheckin',
    component: PostCourseCheckin,
    props: true,
    meta: { studentAuth: true }
  },
  {
    path: '*',
    redirect: '/land'
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach(async (to, from, next) => {
  // console.log(`Navigating from '${from.fullPath}' to '${to.fullPath}'...`);
  if (to.path.startsWith("/profiles/")) {
    return next();
  }

  let token = Store.getters.jwtGetter;
  if (isTokenExpired(token)) {
    try {
      await generateToken(token);
    } catch (err) {
      console.error(err);
    }
  }

  const requiresFacAuth = to.meta.requiresFacAuth;
  const requiresStudentAuth = to.meta.requiresStudentAuth;
  const isFacilitator = Store.state.facilitator;
  const isActivator = Store.state.activator;
  const isAuthenticated = Store.state.isAuthenticated;

  if (!isAuthenticated && (requiresFacAuth || requiresStudentAuth)) {
    console.log("User not authenticated");
    return next('/enter');
  }

  if (requiresFacAuth && !(isFacilitator || isActivator)) {
    // console.log(`Route '${to.fullPath}' requires facilitator authentication but user is not a facilitator or an activator.`);
    logoutCurrentUser();
    console.log("Unauthorized: Facilitator resources require facilitator or activator role.");
    return next('/enter');
  }

  if (requiresStudentAuth && (isFacilitator || isActivator)) {
    logoutCurrentUser();
    console.log("Unauthorized: Student resources cannot be accessed by facilitators or activators.");
    return next('/enter');
  }

  // if no condition is met, then let the user proceed
  return next();

  function logoutCurrentUser() {
    Store.dispatch('logout');
  }
})

export default router
