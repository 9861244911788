<template>
  <section class="sectioncontainer">
    <v-row class="signin">
      <v-col cols="8" class="left">
        <h1>Welcome to Work 4 A Living</h1>
      </v-col>
      <v-col cols="4" class="right">
        <h2 class="h2">Authenticating, please see your whatsapp</h2>
          <v-progress-circular
            :size="270"
            color="purple"
            indeterminate
            class="progress"
          ></v-progress-circular>
          <!-- <v-form @submit.prevent="submit">
              <v-text-field
                v-model="password"
                :error-messages="errors"
                label="Name"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                :type="showPass ? 'text' : 'password'"
              ></v-text-field>
              <v-text-field
                v-model="phoneNumber"
                :error-messages="errors"
                label="Phone Number"
                required
                outlined
                dark
                filled
                dense
              ></v-text-field>
            <div class="text-center">
              <v-btn class="signin-btn" type="submit" rounded color="white" dark>
                Authenticating, please see your whatsapp
              </v-btn>
            </div>
          </v-form> -->
          <v-btn class="signin-btn" :to="{name:'InfoPage', params: {phoneNumber: this.phoneNumber}}" rounded>Skip</v-btn>
          
      </v-col> 
    </v-row>
  </section>
</template>

<script>
  export  default {
    name: 'AuthPage',
    props: 
    {
        phoneNumber: {
            type: String,
            default: '{ERROR}'
        },
    }
  }
</script>

<style scoped>
.sectioncontainer {
    background: #fff;
    width: 100%;
}
.signin {
  margin: 0 auto;
  height: 92vh;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
  </style>