<template>
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
         <v-row class="right-row">
          <h1>Welcome to Work 4 A Living</h1>
         </v-row>
      </v-col>
      
      <v-col cols="12" sm="12" md="5" lg="4" class="right">
        <v-row class="right-input">
          <v-row class="right-row">
            <h2 class="h2" v-if="UseEmail">Authenticating, please see your emails</h2>
            <h2 class="h2" v-else>Authenticating, please see your messages</h2>
          </v-row>
          <!-- <v-progress-circular
            :size="270"
            color="purple"
            indeterminate
            class="progress"
          ></v-progress-circular> -->
          <v-row class="right-row"> 
            <h4 class="infoh5">Enter the code sent to your device.</h4>
          </v-row>
          <v-row class="otp-row"> 
            <v-col class="otp-col">
              <v-otp-input
                length="4"
                plain
                type="number"
                color=white
                outlined
                v-model="code"
                width="10px"
              ></v-otp-input>
            </v-col>
          </v-row>
          <v-row class="buttons"> 
            <v-row class="right-row"> 
              <v-btn class="otp-signin-btn" @click="verify" rounded>Continue</v-btn>
            </v-row>
            <!-- <v-row class="right-row"> 
              <v-btn class="otp-signin-btn" v-if="this.verified" :to="{ name: 'HomeUser', params: { firstName: this.firstName}}" rounded>Continue</v-btn>
              <v-btn class="otp-signin-btn" v-else disabled rounded>Continue</v-btn>
            </v-row> -->
          </v-row>
          <v-dialog
            v-model="dialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row class="right-row">
                <v-btn  v-show="!UseEmail" class="not-receiving-signin-btn" v-on="on" v-bind="attrs" plain>Not receiving code</v-btn>
              </v-row>
            </template>

            <v-card >
              <v-card-title class="justify-center grey lighten-5">
                <h4 class="card-h5">
                  Not receiving code
                </h4>
              </v-card-title>

              <v-card-actions >
                <v-row class="resend-input">
                <v-row class="right-row">
                  <v-btn class="resend" plain @click="resend('whatsapp')">
                    <v-icon left>
                      mdi-android-messages
                    </v-icon>
                    Resend
                  </v-btn>
                </v-row>
<!--                <v-row class="right-row">-->
<!--                  <v-btn class="resend" plain @click="resend('sms')">-->
<!--                    <v-icon left>-->
<!--                      mdi-android-messages-->
<!--                    </v-icon>-->
<!--                    Resend via SMS-->
<!--                  </v-btn>-->
<!--                </v-row>-->
              </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
</template>

<script>
  import {GET_TOKEN} from "../../apollo/queries";
  import {SEND_MESSAGE_TOTP, VERIFY, VERIFY_TOTP} from "../../apollo/mutations";
  import { apolloClient } from '../../main';
  export  default {
    name: 'OneTimePin',
    data: () => ({
      code: "",
      verified: false,
      dialog: false,
      phoneNumber: "{ERROR}",
    }),
    mounted () {
      this.phoneNumber = this.$store.state.phoneNumber
    },
    props: 
    {
        SMS: {
            type: Boolean,
            default: false
        },
        Whatsapp: {
            type: Boolean,
            default: false
        },
        familyName: {
          type: String,
          deafult: ""
        },
        UseEmail: {
          type: Boolean,
          default: false
        },
    },
    methods: {
      async resend () {
        this.dialog = false
          apolloClient.mutate({
          mutation: SEND_MESSAGE_TOTP,
          variables: {
            verifyPhoneNumber: this.phoneNumber,
          }
        })
        .then((response) => {
          this.$store.commit("setTotpKey", response.data.sendMessageTOTP)
        }).catch((error) => {
          // Error
          console.error(error)
        })
      },
      async verify () {

        if (this.UseEmail) {
          // Email verification
          await apolloClient.mutate({
            mutation: VERIFY,
            variables: {
              code: this.code,
              verifyPhoneNumber: this.phoneNumber,
              Sid: this.$store.state.verificationSID
            },
          }).then((response) => {
            if (response.data.verify == "Verified") {
              this.$store.commit('authenticate')
              this.verified = true
              this.$store.commit('setFirstName', this.familyName)
              this.$store.commit('setPhoneNumber', this.phoneNumber)
              // this.$localStorage.set('session', true)
              this.$store.dispatch("user/getUser")
              this.getToken()
              if (this.$store.state.facilitator) {
                this.$router.push({name: 'HomeFacilitator', params: {firstName: this.firstName}})
              } else if (this.$store.state.activator) {
                this.$router.push({name: 'HomeActivator', params: {firstName: this.firstName}})
              } else {
                if (this.$store.state.enrolling) {
                  // console.log("ENROLLING CLASS: ", this.$store.state.enrollingClass)
                  // console.log("enrolling: ", this.$store.state.enrollingClass)
                  this.$router.push({path: this.$store.state.enrollingClass, name: 'ClassEnrollment', params: {id: this.$store.state.enrollingClass}})
                } else {
                  // :to="{name: 'ProfileContent'}"
                  this.$router.push({name: "ProfileContent"})
                  // this.$router.push({name: 'HomeUser', params: {firstName: this.firstName}})
                }
              }
            }
          })
        } else {
          // Phone verification
          await apolloClient.mutate({
            mutation: VERIFY_TOTP,
            variables: {
              code: this.code,
              key: this.$store.state.totpKey
            },
          })
              .then((response) => {
                if (response.data.verifyTOTP == "Verified") {
                  this.$store.commit('authenticate')
                  this.verified = true
                  this.$store.commit('setFirstName', this.familyName)
                  this.$store.commit('setPhoneNumber', this.phoneNumber)
                  // this.$localStorage.set('session', true)
                  this.$store.dispatch("user/getUser")
                  this.getToken()
                  if (this.$store.state.facilitator) {
                    this.$router.push({name: 'HomeFacilitator', params: {firstName: this.firstName}})
                  } else if (this.$store.state.activator) {
                    this.$router.push({name: 'HomeActivator', params: {firstName: this.firstName}})
                  } else {
                    this.$router.push({name: "ProfileContent"})
                  }
                }
              })
        }
      },
      async getToken() {
          apolloClient.query({
          query: GET_TOKEN,
          variables: {
            phoneNumber: this.phoneNumber
          }
        })
        .then((response) => {
          // Result
          this.$store.commit('setJWT', response.data.getToken[0])
          this.$store.commit('setRefreshToken', response.data.getToken[1])
        })
        .catch((error) => {
          // Error
          console.error("COULD NOT GET USER TOKEN", error)
        })
      }
    }
  }
</script>

<style scoped>
.sectioncontainer {
    background: #fff;
    width: 100%;
}
.signin {
  margin: 0 auto;
  min-height: 100vh;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 70%;
  justify-content: center;
}
.otp-row {
  width: 70%;
  justify-content: center;
  margin: 10px;
}
.h2 {
  text-align: center;
  padding: 10px;
}
.card-h5{
  color: #3c7872;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.otp-signin-btn {
  width: 80%;
  color: #3c7872;
  margin: 10px;
}
.not-receiving-signin-btn {
  width: 80%;
  color: #ffffff;
  background: #3c7872;
  margin: 10px;
  margin-top: 40px;
}
.resend{
  width: 100%;
  color: #3c7872;
  background: white;
  margin: 10px;
}
.infoh5{
  color: #d1d1d1;
  text-align: center;
}
.h5{
  text-align: center;
  padding: 10px
}
.resend-input{
  margin: 0 auto;
  justify-content: center;
}
.buttons {
  width: 90%;
  margin: 10px;
  justify-content: center;
}
  </style>