<template>
  <v-row class="signin">
    <v-col cols="12" class="white-right" >
     <v-row class="right-input">
        <!-- LARGE -->
        <v-row class="right-row hidden-md-and-down">
            <v-col cols="6">
                <!-- Member -->
                <v-card
                    class="rounded-xl card ml-0"
                    min-width="100%"
                >

                    <v-card-title class="name">{{member.givenName + " " + member.familyName}}</v-card-title>
                    <v-card-text>
                    <div class="my-4 text-subtitle-1">
                        <v-icon>mdi-phone-outline</v-icon>
                        {{member.phoneNumber}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="member.email !== ''">
                        <v-icon>mdi-email-outline</v-icon>
                        {{member.email}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="member.identificationNumber !== '' && member.identificationNumber !== '+'">
                        <v-icon>mdi-identifier</v-icon>
                        {{member.identificationNumber}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="member.birthDate !== null && member.birthDate !== ''">
                        <v-icon>mdi-calendar-outline</v-icon>
                        {{member.birthDate}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="jobtitle !== ''">
                        <v-icon>mdi-account-outline</v-icon>
                        {{jobtitle}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="nationality !== ''">
                        <v-icon>mdi-earth-outline</v-icon>
                        {{nationality}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="member.addressLines !== [''] && member.sublocality !== null && member.locality !== null">
                        <div v-if="member.addressLines !== ['']">
                            <v-icon>mdi-map-marker-outline</v-icon>
                            {{member.addressLines + ', ' + member.sublocality + ', ' + member.locality}}
                        </div>
                        <div v-else>
                            <v-icon>mdi-map-marker-outline</v-icon>
                            {{member.sublocality + ', ' + member.locality}}
                        </div>
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="activeUser.gender !== 0 && activeUser.race !== 0">
                        <v-icon>mdi-human-male</v-icon>
                        {{genders[activeUser.gender-1].text}}, 
                        {{races[activeUser.race-1].text}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-else-if="activeUser.race !== 0">
                        <v-icon>mdi-human-male</v-icon>
                        {{races[activeUser.race-1].text}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-else-if="activeUser.gender !== 0">
                        <v-icon>mdi-human-male</v-icon>
                        {{genders[activeUser.gender-1].text}}
                    </div>
                    </v-card-text>
                </v-card>
                <!-- Courses -->
                <v-card class="card rounded-xl ml-0" min-width="100%" max-height="350px" min-height="200px" style="overflow-y: scroll;">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-bookshelf</v-icon>
                    </v-btn>
                    W4AL Courses
                    <v-spacer></v-spacer>
                    </v-card-title>
                    
                    <v-card-text class="pt-0">
                        <v-list class="right-row pa-0 ma-0">
                            <v-list-item v-for="e in memberEnrollments" :key="e.enrolledClass + e.course"> 
                                <profile-enrollments-card :courseId="e.course" :date="e.enrolledClass.split('|')[1]"></profile-enrollments-card>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
                <!-- Skills -->
                <v-card max-height="350px" min-height="200px" class="card rounded-xl ml-0" min-width="100%" style="overflow-y: scroll;">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-star-plus</v-icon>
                    </v-btn>
                    Skills
                    <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-list class="right-row">
                            <v-list-item v-for="s in sortedSkills" :key="s.skill"> 
                                <skills-list :skill="s.skill" :skillLevel="s.skillLevel" :description="s.description" :verified="s.verified"> </skills-list>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-col cols="6">
                <!-- Profile -->
                <v-card class="rounded-xl card ml-0" min-width="100%" max-height="350px" min-height="200px" style="overflow-y: scroll;">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-account</v-icon>
                    </v-btn>
                    Profile Bio
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    {{member.grow.profile}}
                    </v-card-text>
                </v-card>
                <!-- Languages -->
                <v-card class="card rounded-xl ml-0" min-width="100%" max-height="250px" min-height="200px" style="overflow-y: scroll;">
                    <v-card-title class="name">
                        <v-btn icon class="mx-2">
                            <v-icon color="#1F003D">mdi-earth</v-icon>
                        </v-btn>
                        Languages
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-list class="right-row">
                            <v-list-item v-for="item in list" :key="item.code"> 
                                <language-list :code="item.code" :skill="item.skill"> </language-list>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                    
                </v-card>
                <!-- Work experience -->
                <v-card class="card rounded-xl ml-0" min-width="100%" max-height="350px" min-height="200px" style="overflow-y: scroll;">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-briefcase</v-icon>
                    </v-btn>
                    Work Experience
                    <v-spacer></v-spacer>
                    </v-card-title>
                        <v-card-text>
                            <v-list class="right-row">
                                <v-list-item v-for="work in member.grow.workExperience" :key="work.jobTitle"> 
                                    <work-list :jobTitle="work.jobTitle" :company="work.company" :duration="work.duration" :description="work.description"> </work-list>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                </v-card>
                <!-- Education -->
                <v-card class="card rounded-xl ml-0" min-width="100%" max-height="350px" min-height="200px" style="overflow-y: scroll;">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-account-school</v-icon>
                    </v-btn>
                    Education
                    <v-spacer></v-spacer>
                    </v-card-title>
                            <v-card-text>
                                <v-list class="right-row">
                                    <v-list-item v-for="edu in member.grow.education" :key="edu.title"> 
                                        <!-- <education-list :title="edu.title" :school="edu.school" :duration="edu.duration" :description="edu.description"> </education-list> -->
                                        <education-list :title="edu.title" :school="edu.school" :duration="edu.duration" :description="edu.description" :educationLevel="edu.educationLevel" :qualification="edu.qualification" :industry="edu.industry"> </education-list>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        
        <!-- SMALL -->
        <div class="hidden-lg-and-up">
            
                <!-- Member -->
                <v-card
                    class="rounded-xl card ml-0"
                    min-width="100%"
                >

                    <v-card-title class="name">{{member.givenName + " " + member.familyName}}</v-card-title>
                    <v-card-text>
                    <div class="my-4 text-subtitle-1">
                        <v-icon>mdi-phone-outline</v-icon>
                        {{member.phoneNumber}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="member.identificationNumber !== '' && member.identificationNumber !== '+'">
                        <v-icon>mdi-identifier</v-icon>
                        {{member.identificationNumber}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="member.email !== ''">
                        <v-icon>mdi-email-outline</v-icon>
                        {{member.email}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="member.birthDate !== null && member.birthDate !== ''">
                        <v-icon>mdi-calendar-outline</v-icon>
                        {{member.birthDate}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="jobtitle !== ''">
                        <v-icon>mdi-account-outline</v-icon>
                        {{jobtitle}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="nationality !== ''">
                        <v-icon>mdi-earth-outline</v-icon>
                        {{nationality}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="member.addressLines !== [''] && member.sublocality !== null && member.locality !== null">
                        <div v-if="member.addressLines !== ['']">
                            <v-icon>mdi-map-marker-outline</v-icon>
                            {{member.addressLines + ', ' + member.sublocality + ', ' + member.locality}}
                        </div>
                        <div v-else>
                            <v-icon>mdi-map-marker-outline</v-icon>
                            {{member.sublocality + ', ' + member.locality}}
                        </div>
                    </div>
                    <div class="my-4 text-subtitle-1" v-if="activeUser.gender !== 0 && activeUser.race !== 0">
                        <v-icon>mdi-human-male</v-icon>
                        {{genders[activeUser.gender-1].text}}, 
                        {{races[activeUser.race-1].text}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-else-if="activeUser.race !== 0">
                        <v-icon>mdi-human-male</v-icon>
                        {{races[activeUser.race-1].text}}
                    </div>
                    <div class="my-4 text-subtitle-1" v-else-if="activeUser.gender !== 0">
                        <v-icon>mdi-human-male</v-icon>
                        {{genders[activeUser.gender-1].text}}
                    </div>
                    </v-card-text>
                </v-card>
                <!-- Profile -->
                <v-card class="rounded-xl card ml-0" min-width="100%"  style="overflow-y: scroll;">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-account</v-icon>
                    </v-btn>
                    Profile Bio
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    {{member.grow.profile}}
                    </v-card-text>
                </v-card>
                <!-- Languages -->
                <v-card class="card rounded-xl ml-0" min-width="100%"  style="overflow-y: scroll;">
                    <v-card-title class="name">
                        <v-btn icon class="mx-2">
                            <v-icon color="#1F003D">mdi-earth</v-icon>
                        </v-btn>
                        Languages
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-list class="right-row">
                            <v-list-item v-for="item in list" :key="item.code"> 
                                <language-list :code="item.code" :skill="item.skill"> </language-list>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                    
                </v-card>
                <!-- Courses -->
                <v-card class="card rounded-xl ml-0" min-width="100%"  style="overflow-y: scroll;">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-bookshelf</v-icon>
                    </v-btn>
                    W4AL Courses
                    <v-spacer></v-spacer>
                    </v-card-title>
                    
                    <v-card-text class="pt-0">
                        <v-list class="right-row pa-0 ma-0">
                            <v-list-item v-for="e in memberEnrollments" :key="e.enrolledClass + e.course"> 
                                <profile-enrollments-card :courseId="e.course" :date="e.enrolledClass.split('|')[1]"></profile-enrollments-card>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card> 
            
                <!-- Skills -->
                <v-card class="card rounded-xl ml-0" min-width="100%">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-star-plus</v-icon>
                    </v-btn>
                    Skills
                    <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-list class="right-row">
                            <v-list-item v-for="s in sortedSkills" :key="s.skill"> 
                                <skills-list :skill="s.skill" :skillLevel="s.skillLevel" :description="s.description" :verified="s.verified"> </skills-list>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
                <!-- Work experience -->
                <v-card class="card rounded-xl ml-0" min-width="100%">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-briefcase</v-icon>
                    </v-btn>
                    Work Experience
                    <v-spacer></v-spacer>
                    </v-card-title>
                        <v-card-text>
                            <v-list class="right-row">
                                <v-list-item v-for="work in member.grow.workExperience" :key="work.jobTitle"> 
                                    <work-list :jobTitle="work.jobTitle" :company="work.company" :duration="work.duration" :description="work.description"> </work-list>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                </v-card>
                <!-- Education -->
                <v-card class="card rounded-xl ml-0" min-width="100%">
                    <v-card-title class="name">
                    <v-btn icon class="mx-2">
                        <v-icon color="#1F003D">mdi-account-school</v-icon>
                    </v-btn>
                    Education
                    <v-spacer></v-spacer>
                    </v-card-title>
                            <v-card-text>
                                <v-list class="right-row">
                                    <v-list-item v-for="edu in member.grow.education" :key="edu.title"> 
                                        <!-- <education-list :title="edu.title" :school="edu.school" :duration="edu.duration" :description="edu.description"> </education-list> -->
                                        <education-list :title="edu.title" :school="edu.school" :duration="edu.duration" :description="edu.description" :educationLevel="edu.educationLevel" :qualification="edu.qualification" :industry="edu.industry"> </education-list>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                </v-card>
            
        </div>

         <!-- <v-fab-transition> -->
        
        
        
        <v-hover v-slot="{ hover }" open-delay="100" v-if="this.$store.state.isAuthenticated && this.activeUser.phoneNumber === this.member.phoneNumber">
            <v-expand-x-transition>
                <ShareNetwork 
                    network="Whatsapp"
                    title="View my W4AL profile! Here is the link: "
                    :description=getURL()
                    url=""
                    >
                    <v-btn
                        color="#6A2E85"
                        x-large
                        rounded
                        dark
                        v-if="hover"
                        bottom
                        right
                        fixed
                        class="v-btn--floating"
                        elevation-20
                        @click="updateMemberLastShared"
                        >  
                        <span class="mr-2">Share Profile</span> 
                        <v-icon>mdi-share-variant</v-icon>
                    </v-btn>
                    <v-btn
                        color="#6A2E85"
                        dark
                        fab
                        bottom
                        right
                        large
                        fixed
                        class="v-btn--floating"
                        elevation-20
                        @click="updateMemberLastShared"
                        v-else
                        >  
                        <v-icon>mdi-share-variant</v-icon>
                    </v-btn>
                </ShareNetwork>
            </v-expand-x-transition>
        </v-hover>    
        
        <v-hover v-slot:default="{ hover }" open-delay="100" v-if="this.$store.state.isAuthenticated && this.activeUser.phoneNumber === this.member.phoneNumber">
            <v-expand-x-transition>
                    <v-btn
                        color="white"
                        x-large
                        rounded
                        large
                        dark
                        bottom
                        right
                        fixed
                        class="v-btn--floating-2"
                        elevation-20
                        v-if="hover"
                        @click="buildProfile"
                    >
                    <span class="mr-2">Update Profile</span>
                    <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn
                        color="white"
                        large
                        dark
                        bottom
                        fab
                        right
                        fixed
                        class="v-btn--floating-2"
                        elevation-20
                        v-else
                        @click="buildProfile"
                    >
                        <v-icon >mdi-pencil-outline</v-icon>
                    </v-btn>
            </v-expand-x-transition>
        </v-hover>    
            
        <!-- </v-fab-transition> -->

        
     </v-row>
    </v-col>
    </v-row>
    <!-- </v-parallax> -->
</template>

<script>
    import { mapGetters } from "vuex"
    import LanguageList from './LanguageList.vue'
    import WorkList from "./WorkList.vue"
    import EducationList from "./EducationList.vue"
    import SkillsList from "./SkillsList.vue"
    import { apolloClient } from "@/main"
    import {GET_MEMBER, LIST_ENROLLMENTS} from "@/apollo/queries"
    import ProfileEnrollmentsCard from "./ProfileEnrollmentsCard.vue"
    // import {GET_MEMBER} from "../apollo/queries";
    // import {apolloClient} from "../main"
    // import countries from "countries-list";
    export default {
        components: { LanguageList, WorkList, EducationList, SkillsList, ProfileEnrollmentsCard},
        name: 'ContentBuilder',
        data: () => ({
            memberEnrollments: [],
            // Field values
            givenName: "+",
            familyName: "+",
            email: "+",
            birthDate: "+",
            phoneNumber: "",
            jobtitle: "",
            nationality: "",
            givenNameError: false,
            familyNameError: false,
            emailError: false,
            streetName: [],
            postalCode: "+",
            cityOrTown: "+",
            country: "+",
            // country: Object.keys(countries.countries).map(code => countries.countries[code].name),
            select: { state: 'South Africa'},
            member: {},
            url: "",
            genders: [
                {value: 1, text: 'Female'},
                {value: 2, text: 'Male'}
            ],
            races: [
                // {value: 0, text: 'Other'},
                {value: 1, text: 'Black'},
                {value: 2, text: 'Asian'},
                {value: 3, text: 'Coloured'},
                {value: 4, text: 'White'},
                {value: 5, text: 'Middle Eastern'}, 
                {value: 6, text: 'Indian'},
                {value: 7, text: 'Mixed'},
            ]
        }),
        watch: {
            menu (val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
            },
        },
        computed: {
          ...mapGetters('user', ['activeUser']),
            list() {
                let l = this.activeUser.languageCodes.map((itm, i) => {
                    return { code: itm, skill: this.activeUser.languageSkills[i] }
                })
                return l.sort(function(x, y) { return y.skill - x.skill })
            },
            sortedSkills() {
                // Sort the skills by their verified property showing verified skills first.
                let s = this.activeUser.grow.skills
                let sorted = s.sort(function(x, y) { return y.verified - x.verified });
                return sorted
            },
        },
        async mounted () {
            this.$nextTick(function () {
                this.getUser();
            });
            this.$nextTick(function () {
                this.getURL();
            });
            // Fetch the student's latest JR enrollment.
            await this.fetchEnrollments()
        },
        methods: {
            getURL () {
                // console.log("PATH: ", this.$route.fullPath)
                this.url = "https://ep.w4al.services" + this.$route.fullPath
                return "https://ep.w4al.services" + this.$route.fullPath
            },
            buildProfile () {
                if (this.$store.state.isAuthenticated) {
                    this.$router.push({name: 'ProfileContent'})  
                } else {
                    this.$router.push({name: 'HomePage'})
                }
            },
            async fetchEnrollments() {
                await apolloClient.query({
                    query: LIST_ENROLLMENTS,
                    variables: {
                        filter: this.$route.params.id,
                        tag: "Member",
                    }
                }).then(response => {
                    let resp = response.data.listEnrollments
                    this.memberEnrollments = resp
                    
                }).catch(ball => {
                    console.log("Caught: ", ball)
                })
            },
            async updateMemberLastShared() {
                let tmp = this.setFields(this.activeUser)
                await this.$store.dispatch('user/updateUser', tmp)
                this.$store.dispatch('user/getUser')
                this.$store.getters.activeUser
            },
            setFields (stateUser) {
                let tmp = JSON.parse(JSON.stringify(stateUser));
                if (this.givenName !== "+") {
                    tmp.givenName = this.givenName
                }
                if (this.familyName !== "+") {
                    tmp.familyName = this.familyName
                }
                if (this.email !== "+") {
                    tmp.email = this.email
                }
                if (this.birthDate !== "+") {
                    tmp.birthDate = this.birthDate
                }
                if (this.streetName.length !== 0) {
                    tmp.addressLines[0] = this.streetName[0]
                } 
                if (this.postalCode !== '+') {
                    tmp.postalCode = this.postalCode
                }
                if (this.cityOrTown !== '+') {
                    tmp.sublocality = this.cityOrTown
                }
                if (this.country !== '+') {
                    tmp.locality = this.country
                }
                if (this.country !== '+') {
                    tmp.locality = this.country
                }
                if (this.race !== -1) {
                    tmp.race = this.race
                }
                if (this.gender !== -1) {
                    tmp.gender = this.gender
                }
                tmp.includeLastShareTime = true
                return tmp
            },
            async getUser() {
                await apolloClient.query({
                    query: GET_MEMBER,
                    variables: {
                        name: this.$route.params.id
                    }
                }).then(user => {
                    var tmpUser = JSON.parse(JSON.stringify(user))
                    if (user.data.getMember.birthDate !== null && user.data.getMember.birthDate !== undefined) {
                        tmpUser.data.getMember.birthDate = this.convertDOB(user.data.getMember.birthDate)
                    } else {
                        tmpUser.data.getMember.birthDate = ""
                    }
                    this.member = tmpUser.data.getMember
                    // console.log("Temp user: ", this.member)
                })
            },
            convertDOB (dob) {
                let str = dob.split(" ")
                var year, month, day
                if (str.length === 5) {
                    year = str[0].split(":")[1]
                    month = str[2].split(":")[1]
                    day = str[4].split(":")[1]
                } else {
                    year = str[0].split(":")[1]
                    month = str[1].split(":")[1]
                    day = str[2].split(":")[1]
                }
                if (parseInt(month) < 10) {
                    month = "0" + month
                }
                if (parseInt(day) < 10) {
                    day = "0" + day
                }
                return year + "-" + month + "-" + day
            },
            save (date) {
                this.$refs.menu.save(date)
            },
            validateName (value) {
                // Support international names with unicode:
                const regEx = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]* *?$/u;
                if (regEx.test(value)) {
                this.givenNameError = false;
                } else{
                this.givenNameError=true;
                } 
            },
            validateLastName (value) {
                // Support international names with unicode:
                const regEx = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]* *?$/u;
                if (regEx.test(value)) {
                this.familyNameError = false;
                } else{
                this.familyNameError = true;
                } 
            },
            validateEmail (value) {
                if (value !== '') {
                const regEx = /^\w+([.-]]?\w+)*@\w+([.-]?\w+)*(\.\w+)+ *$/;
                if (regEx.test(value)) {
                    this.emailError = false;
                } else{
                    this.emailError = true;
                } 
                } else {
                this.emailError = false;
                }
            },
        }
    }
</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.this-right {
  box-sizing: border-box;
  background: #f2f2f2;
  color: #f2f2f2;
  min-height: 94vh;
} 
.right {
  box-sizing: border-box;
  background: #f2f2f2;
  color: #fff;
  display: flex;
  align-items: center;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.left-row {
  width: 100%;
  justify-content: left;
  padding: 20px;
}
.left-row-small {
  width: 100%;
  justify-content: left;
  padding: 30px;
  margin-bottom: 50px;
}
.this-signin-btn {
  width: 51%;
  color: #3c7872;
  margin-bottom: 15px;
}
.this-enter-btn {
  width: 51%;
  color: white;
  margin-bottom: 15px;
}
.text {
  justify-content: center;
  padding: 30px;
}
.align {
  justify-content: center;
  width: 100%;
}
.buttons {
  margin: 0;
  justify-content: center;
}
.h2 {
  text-align: center;
  color: rgb(196, 196, 196);
  width: 100%;
}
.h2-bold {
  text-align: center;
  color: rgba(255, 255, 255);
  width: 100%;
}

.img{
  margin-top: 100px;
}
.active-btn{
    background: #3C7872;
}
.edit-btn {
  width: 80%;
  color: #000000;
  background: #ffffff;
  margin: 10px;
  margin-top: 40px;
}
.name{
  font-weight: 700;
  justify-content: center;
}
.rounded-text{
  border-radius: 12px;
}
.title-space{
  margin-bottom: 0px;
}
.save-chip {
  color: white;
}
.cancel-chip {
  color: #3c7872;
}
.edit-btn-save{
    padding: 20px;
    color: white;
}
.edit-btn-cancel{
    padding: 20px;
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 20px;
    padding: 20px;
}
.divider {
    margin-bottom: 20px;
}
.addressCol {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
}
.form {
    margin-top: 30px;
}
.addBtn {
    color: #3C7872;
    margin: 20px;
}
.btnRow {
    justify-content: center;
    align-content: center;
}
.info-row {
    background:#ececec;
    border-radius: 12px;
    padding: 10px;
}
.card{
    margin: 20px;
}
#lateral .v-btn--floating {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
    text-transform: none !important;
  }
.v-btn--floating-2 {
    bottom: 0;
    position: fixed;
    margin: 0 0 96px 16px;
    color: #6A2E85;
    background: #ffffff;
    text-transform: none !important;
}

.fab-container {
    position: fixed;
    bottom: 0;
    right: 0;
}
</style>