<template>
    <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable>
        <v-card >
            <v-card-title class="justify-center grey lighten-3 title-space">
                Edit skill
            </v-card-title>
            <v-card-text>
                <p class="textArea">Provide details of a skill you possess. This should, ideally, be something useful to a business.</p>
                <v-form class="form">
                    <v-row class="info-right-row">  
                        <v-text-field
                            class="rounded-text"
                            label="Name of skill"
                            required
                            v-if="!currentVerified"
                            outlined
                            filled
                            dense
                            @input="updateLocalSkill($event)"
                            :value="this.currentSkill"
                        ></v-text-field>
                        <v-text-field
                            class="rounded-text"
                            label="Name of skill"
                            required
                            v-else
                            disabled
                            outlined
                            filled
                            dense
                            @input="updateLocalSkill($event)"
                            :value="this.currentSkill"
                        ></v-text-field>
                    </v-row>
                    <v-row class="info-right-row">  
                        <v-textarea
                            class="rounded-text"
                            label="Information (recommended)"
                            required
                            v-if="!currentVerified"
                            filled
                            rows="2"
                            @input="updateLocalDesc($event)"
                            :value="this.currentDescription"
                        ></v-textarea>
                        <v-textarea
                            class="rounded-text"
                            label="Information (recommended)"
                            required
                            v-else
                            disabled
                            filled
                            rows="2"
                            @input="updateLocalDesc($event)"
                            :value="this.currentDescription"
                        ></v-textarea>
                    </v-row>
                    <v-row>
                        <v-select
                            :value="this.currentSkillLevel"
                            @input="updateLocalSkillLevel($event)"
                            :items="levels"
                            item-text="key"
                            item-value="value"
                            label="Select skill level"
                            filled
                            class="rounded-text"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row class="right-row-edit"> 
                    <v-btn class="edit-btn-cancel" v-if="currentVerified" disabled rounded text align-left large @click="deleteSkill"> <v-icon small>mdi-delete</v-icon> Delete</v-btn>
                    <v-btn class="edit-btn-cancel" v-else rounded text align-left large @click="deleteSkill"> <v-icon small>mdi-delete</v-icon> Delete</v-btn>
                    <v-btn class="edit-btn-cancel" rounded text large @click="showModal=false">Cancel</v-btn>
                    <v-btn class="edit-btn-save" rounded large color="#3c7872" @click="updateSkill"> Save</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: 'EditSkillModal',
    data: () => ({
        localSkill: "",
        localSkillLevel: -1,
        localDesc: "",
        level: -1,
        levels: [
            {value: 0, key: 'Novice'},
            {value: 1, key: 'Beginner'},
            {value: 2, key: 'Skillful'},
            {value: 3, key: 'Experienced'},
            {value: 4, key: 'Expert'}
        ],
    }),
    props: 
    // ['showLanguageModal']
    {
        showSkillModal: {
            type: Boolean
        },
        currentSkill: {
            type: String
        },
        currentSkillLevel: {
            type: Number
        },
        currentDescription: {
            type: String
        },
        currentVerified: {
            type: Boolean
        },
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        showModal: {
            get () {
                return this.showSkillModal
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
               return this.$emit("close")
            }
        },
    },
    methods: {
        updateLocalSkill(e) {
            this.localSkill = e
        },
        updateLocalSkillLevel(e) {
            this.localSkillLevel = e
        },
        updateLocalDesc(e) {
            this.localDesc = e
        },
        async updateSkill () {
            let tmp = this.setFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            this.$store.getters.activeUser
            this.showModal = false
        },
        setFields (stateUser) {
            if (this.localSkill === "") {
                this.localSkill = this.currentSkill
            }
            if (this.localSkillLevel === -1) {
                this.localSkillLevel = this.currentSkillLevel
            }
            if (this.localDesc === "") {
                this.localDesc = this.currentDescription
            }
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            // console.log("tmpUser before language change: ", tmp.grow.skills)
            for (let i = 0; i < tmp.grow.skills.length; i++) {
                if (tmp.grow.skills[i].skill === this.currentSkill && tmp.grow.skills[i].skillLevel === this.currentSkillLevel) {
                    tmp.grow.skills[i].skill = this.localSkill
                    tmp.grow.skills[i].skillLevel = this.localSkillLevel
                    tmp.grow.skills[i].description = this.localDesc
                    tmp.grow.skills[i].verified = this.currentVerified
                }
            }
            //  = tmp.grow.workExperience[tmp.grow.workExperience.length -1]
            // console.log("tmpUser after change: ", tmp)
            return tmp
        },
        async deleteSkill () {
            
            let tmp = this.removeFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            // this.$store.dispatch('getUser')
            this.$store.getters.activeUser
            this.showModal = false
        },
        removeFields (stateUser) {
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            for (let i = 0; i < tmp.grow.skills.length; i++) {
                if (tmp.grow.skills[i].skill === this.currentSkill && tmp.grow.skills[i].skillLevel === this.currentSkillLevel) {
                    // tmp.languageCodes[i] = this.spokenLanguage
                    // tmp.languageSkills[i] = this.level
                    tmp.grow.skills.splice(i, 1)
                    break
                }
            }
            // console.log("tmpUser after language change: ", tmp.languageCodes)
            // tmp.languageCodes[tmp.languageCodes.length] = this.spokenLanguage
            // tmp.languageSkills[tmp.languageSkills.length]= this.level
            return tmp
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 10px;
}
.duration-row {
    margin: 20px;
    margin-bottom: 40px;
}
</style>
