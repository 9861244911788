<template>
  <v-app>
    <v-app-bar app color="" light class="navbar" toggleable="lg">
      <v-spacer></v-spacer>
      Site Under Maintenance
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-card>
              <v-card-title class="headline">
                Site Under Maintenance
              </v-card-title>
              <v-card-text>
                This site is no longer being maintained. Our new site will be available soon, we apologise for any inconvenience.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  async mounted() {
    await this.logout();
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch('logout');
      } catch (e) {
        console.error('Error logging out: ', e);
      }
    },
  },
};
</script>
