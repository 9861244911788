<template>
    <v-dialog v-if="showModal" v-model="showModal" width="600" scrollable>
        <v-card >
            <v-card-title class="justify-center grey lighten-3 title-space">
                Edit work
            </v-card-title>
            <v-card-text>
                <v-form class="form">
                    <v-row class="info-right-row">  
                        <v-text-field
                            class="rounded-text"
                            label="Job Title"
                            required
                            outlined
                            filled
                            dense
                            :value="this.currentJob"
                            @input="updateJob($event)"
                        ></v-text-field>
                    </v-row>
                    <v-row class="info-right-row">  
                        <v-text-field
                            class="rounded-text"
                            label="Company"
                            required
                            outlined
                            filled
                            dense
                            :value="this.currentCompany"
                            @input="updateCompany($event)"
                        ></v-text-field>
                    </v-row>
                    <v-row class="duration-row"> 
                        <h4>{{this.currentDuration}}</h4>
                        <v-spacer></v-spacer>
                        <!-- <v-btn icon @click="showDuration = !showDuration"> -->
                            <v-icon @click="showDuration = !showDuration">{{ showDuration ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        <!-- </v-btn> -->
                    </v-row>
                    <v-expand-transition>
                        <div v-show="showDuration">
                            <v-row class="info-right-row">   
                            </v-row>
                            <v-row class="info-right-row">  
                                <v-col cols="6">
                                    <v-select
                                        :items="startYears"
                                        label="Start year"
                                        @input="updateLocalStartYear($event)"
                                    ></v-select> 
                                </v-col>
                                <v-col cols="6">
                                <v-select
                                    v-model="endYear"
                                    :items="endYears"
                                    label="End year"
                                    @input="updateLocalEndYear($event)"
                                ></v-select> 
                                </v-col>
                            </v-row> 
                        </div>
                    </v-expand-transition>
                   <v-row>
                        <v-textarea
                            name="input-7-1"
                            filled
                            label="Description (optional)"
                            auto-grow
                            class="textArea"
                            :value="this.currentDescription"
                            @input="updateDescription($event)"
                        ></v-textarea>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row class="right-row-edit"> 
                    <v-btn class="edit-btn-cancel" rounded text align-left large @click="deleteWork"> <v-icon small>mdi-delete</v-icon> Delete</v-btn>
                    <v-btn class="edit-btn-cancel" rounded text large @click="showModal=false">Cancel</v-btn>
                    <v-btn class="edit-btn-save" rounded large color="#3c7872" @click="updateWork"> Save</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: 'EditWorkModal',
    data: () => ({
        localJob: "",
        localCompany: "",
        localDuration: "",
        localDesc: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        showDuration: false,
        months: [
            {value: 1, key: "Jan"},
            {value: 2, key: "Feb"},
            {value: 3, key: "Mar"},
            {value: 4, key: "Apr"},
            {value: 5, key: "May"},
            {value: 6, key: "Jun"},
            {value: 7, key: "Jul"},
            {value: 8, key: "Aug"},
            {value: 9, key: "Sep"},
            {value: 10, key: "Oct"},
            {value: 11, key: "Nov"},
            {value: 12, key: "Dec"},
        ],
    }),
    props: 
    // ['showLanguageModal']
    {
        showWorkModal: {
            type: Boolean
        },
        currentJob: {
            type: String
        },
        currentCompany: {
            type: String
        },
        currentDuration: {
            type: String
        },
        currentDescription: {
            type: String
        },
    },
    computed: {
      ...mapGetters('user', ['activeUser']),
        startYears () {
            const startYear = 2000;
            const endYear = new Date().getFullYear();
            const startYears = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index).reverse();
            return startYears;
        },
        endYears () {
            const startYear = 2000;
            const endYear = new Date().getFullYear();
            const endYears = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index).reverse();
            endYears.unshift({ text: "current job", value: "current job" });
            return endYears;
        },
        showModal: {
            get () {
                return this.showWorkModal
            },
            // Hits the @close event in PersonalInfo.vue
            set () {
               return this.$emit("close")
            }
        },
    },
    methods: {
        updateJob(e) {
            this.localJob = e
        },
        updateCompany(e) {
            this.localCompany = e
        },
        updateLocalStartMonth (e) {
            this.startMonth = e
        },
        updateLocalStartYear (e) {
            this.startYear = e
        },
        updateLocalEndMonth (e) {
            this.endMonth = e
        },
        updateLocalEndYear (e) {
            this.endYear = e
        },
        updateDescription(e) {
            this.localDesc = e
        },
        async updateWork () {
            let tmp = this.setFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            // this.$store.dispatch('getUser')
            this.$store.getters.activeUser
            this.showModal = false
        },
        setFields (stateUser) {
            if (this.localJob === "") {
                this.localJob = this.currentJob
            }
            if (this.localCompany === "") {
                this.localCompany = this.currentCompany
            }
            if (this.localDesc === "") {
                this.localDesc = this.currentDescription
            }
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            // console.log("tmpUser before language change: ", tmp.grow.workExperience)
            for (let i = 0; i < tmp.grow.workExperience.length; i++) {
                if (tmp.grow.workExperience[i].jobTitle === this.currentJob && tmp.grow.workExperience[i].company === this.currentCompany) {
                    tmp.grow.workExperience[i].jobTitle = this.localJob
                    tmp.grow.workExperience[i].company = this.localCompany
                    tmp.grow.workExperience[i].duration = this.convertDuration()
                    tmp.grow.workExperience[i].description = this.localDesc
                }
                // console.log("work: ", tmp.grow.workExperience[i])
            }
            //  = tmp.grow.workExperience[tmp.grow.workExperience.length -1]
            // console.log("tmpUser after change: ", tmp)
            return tmp
        },
        convertDuration () {
            if (this.startMonth === "" || this.endMonth === "") {
                return this.startYear + " - " + this.endYear;
            } else {
                if (this.endYear === "current job") {
                    return this.startMonth + "/" + this.startYear + " - " + this.endYear;
                } else {
                    return this.startMonth + "/" + this.startYear + " - " + this.endMonth + "/" + this.endYear;
                }
            }
        },
        async deleteWork () {
            let tmp = this.removeFields(this.activeUser)
            await this.$store.dispatch('user/updateUser', tmp)
            // this.$store.dispatch('getUser')
            this.$store.getters.activeUser
            this.showModal = false
        },
        removeFields (stateUser) {
            let stringify = JSON.stringify(stateUser)
            let tmp = JSON.parse(stringify);
            // console.log("tmpUser before language change: ", tmp.languageCodes)
            for (let i = 0; i < tmp.grow.workExperience.length; i++) {
                if (tmp.grow.workExperience[i].jobTitle === this.currentJob && tmp.grow.workExperience[i].company === this.currentCompany) {
                    // tmp.languageCodes[i] = this.spokenLanguage
                    // tmp.languageSkills[i] = this.level
                    tmp.grow.workExperience.splice(i, 1)
                    break
                }
            }
            // console.log("tmpUser after language change: ", tmp.languageCodes)
            return tmp
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: right;
    margin: 10px;
}
.duration-row {
    margin: 20px;
    margin-bottom: 40px;
}
</style>
