<template>
<v-row class="bg">
    <v-row class="home">
      <v-col cols="12" class="this-right text-xs-center">
        <!-- <v-row class="right-input"> -->
          <!-- <v-row class="card"> -->
          <v-col>
            <v-card
              outlined
              color="rgb(143, 166, 155)"
            >
            <v-row class="title">
                <v-card-title class="white--text">{{course}} Foundations</v-card-title>
            </v-row>
            <v-card-text class="white--text">
              <v-row class="right-row" style="margin-top: 10px;"> 
                This {{course}} class is facilitated by &nbsp;<strong>{{this.facilitatorFirst}}   {{this.facilitatorLast}} </strong> &nbsp; and starts on {{date}}.
              </v-row>
            </v-card-text>
            <v-card-text class="white--text" v-if="facilitatorError">
              <v-row class="right-row" style="margin-top: 10px;"> 
                You can not enrol in a class as a facilitator.
              </v-row>
            </v-card-text>
            <v-card-actions>
                    <v-row class="cardRow">
                        <v-btn class="create-btn" text large rounded @click=enrol()>
                            Enrol now
                            <v-icon color="white" right>
                                mdi-arrow-right-thin
                            </v-icon>
                        </v-btn>
                    </v-row>
            </v-card-actions>
            </v-card>
          </v-col>
        <!-- </v-row> -->
        
        <!-- </v-row> -->
      </v-col> 
    </v-row>
</v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { CREATE_ENROLLMENT } from "@/apollo/mutations"

export default {
  name: 'ClassEnrollment',
  computed: {
    ...mapGetters('user', ['activeUser']),
  },
  data: () =>  ({
    classInfo: "",
    course: "",
    facilitatorFirst: "",
    facilitatorLast: "",
    date: "",
    facilitatorError: false,
  }),
  mounted () {
    this.getClassInfo()
  },
  methods: {
    getClassInfo () {
      var str
      let thisClass = this.$route.params.id
      if  (thisClass.includes("|")) {
        // safari
        str = thisClass.split("|")
      } else {
        // chrome
        str = thisClass.split("%7C")
      }
      this.facilitatorFirst = str[0]
      this.facilitatorLast = str[1]
      this.course = str[2]
      this.date = str[3]
    },
    async enrol () {
      // no active user, no auth etc
      if (this.activeUser === undefined || !this.$store.state.isAuthenticated) {
        this.$store.commit('setEnrolling', this.facilitatorFirst + "|" + this.facilitatorLast + "|" + this.course + "|" +this.date)
        this.$router.push({name: 'EnterPage'})
      } else {
        if (this.$store.state.facilitator) {
          this.facilitatorError = true
          this.$store.commit('unsetEnrolling')
          this.$router.push({name: 'HomeFacilitator'})
        } else {
          let phone = this.activeUser.phoneNumber.replace(/ /g,'')
          try {
            await this.$apollo.mutate({
              mutation: CREATE_ENROLLMENT,
              variables: {
                courseId: this.course,
                classId: this.facilitatorFirst+ "" + this.facilitatorLast + "|" + this.date,
                name: phone,
                student: phone, // !!TODO ultradox switch to GUID
                paid: false
              }
            })
            this.$store.getters.activeUser
            this.$store.commit('unsetEnrolling')
            this.$router.push({name: 'MemberEnrollments'})
          } catch (error) {
            console.error("Error on enrollment: ", error);
          }
        }
      }
    },
  }
}
</script>

<style scoped>
.bg {
    margin: 0 auto;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    background: rgb(143, 166, 155);
}
.home {
    margin: 0 auto;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    align-content: center;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.this-signin-btn {
  color: #3c7872;
}
.this-right {
    box-sizing: border-box;
    background: rgb(143, 166, 155);
    color: #fff;
    display: flex;
}
.active-btn{
    background: #3C7872;
}
.btnRow {
    justify-content: center;
    align-content: center;
    margin: 20px;
}
.cardRow {
    justify-content: center;
    align-content: center;
    margin: 12px;
}
.chip {
    margin-left: 10px;
    margin-right: 10px;
}
.title {
    justify-content: center;
    align-content: center;
    margin-top: 5px;
}
.create-btn{
    padding: 20px;
    color: white;
    text-transform: lowercase;
}
.this-share-btn {
    color: #6A2E85;
    text-decoration: none !important;
    display: inline-block;
}
  </style>