<template>
    <!-- <v-row class="home"> -->
      <!-- <v-col cols="12" class="this-right">
        <v-row class="right-input"> -->
            <content-builder> </content-builder>
        <!-- </v-row>
      </v-col>  -->
    <!-- </v-row> -->
</template>

<script>
import ContentBuilder from '../../components/ContentBuilder.vue'
import { mapGetters } from "vuex";
export  default {
  components: { ContentBuilder },
  name: 'ProfileContent',
  computed: {
    ...mapGetters('user', ['activeUser']),
  }
}
</script>

<style scoped>
.sectioncontainer {
    background: #fff;
    width: 100%;
}
.home {
  margin: 0 auto;
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.this-right {
  box-sizing: border-box;
  background: #f2f2f2;
  color: #fff;
  display: flex;
  align-items: center;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.this-signin-btn {
  color: #3c7872;
}
  </style>