<template>
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
        <v-row class="right-row">
          <h1>Welcome.</h1>
        </v-row>
      </v-col>
       
      <v-col cols="12" sm="12" md="5" lg="4" class="right">
        <v-row class="right-input">
             <v-row class="right-row"> 
                <h2 class="h2">Enter your details</h2>
             </v-row>
              <v-row class="info-right-row"> 
                <v-text-field
                  label="Email"
                  required
                  dark
                  dense
                  suffix="@work4aliving.org"
                  v-model="email"
                  class="hidden-sm-and-up"
                ></v-text-field>
              </v-row>
              <v-row class="info-right-row"> 
                <v-text-field
                  label="Email address"
                  required
                  dark
                  dense
                  suffix="@work4aliving.org"
                  v-model="email"
                  class="hidden-xs-only"
                ></v-text-field>
              </v-row>
              <v-row class="info-right-row" v-if="this.$store.state.facilitator">  
                <p class="red--text" v-if="errors.email">{{errors.email}}</p>
              </v-row>
            
            <v-row class="right-row"> 
                <!-- Open space -->
            </v-row> 
            <v-row class="right-row">
              <v-btn v-if="!this.email || errors.email" class="signin-btn" type="submit" rounded color="white" dark disabled>
                  Verify
              </v-btn>
              <v-btn v-else class="signin-btn" type="submit" rounded color="white" required dark @click="getMember">
                  Verify
              </v-btn>
            </v-row>
            <v-row v-if="this.showErr">
              <!-- Open space -->
            </v-row>
            <v-row class="info-right-row">  
              <p style="color: #7A0000" v-if="showErr">{{errorMessage}}</p>
            </v-row>
            
        </v-row>
        </v-col> 
    </v-row>
</template>

<script>
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import Vue from 'vue'
  import OneTimePinRegister from "./OneTimePin.vue"
  import {SEND_VERIFICATION} from "../../apollo/mutations";
  import { apolloClient } from '../../main';
  import {GET_MEMBER} from "../../apollo/queries";
  Vue.component('vue-phone-number-input', VuePhoneNumberInput);
  export  default {
    name: 'RegisterFacilitator',
    mounted () {
      this.phoneNumber = this.$store.state.phoneNumber
    },
    data: () => ({
      msg: [],
      exists: true,
      errorMessage: "",
      errorTitle: "",
      email: "",
      errors: [
        {'email': ""}
      ],
      showErr: false,
      phoneNumber: "",
    }),
    watch: {
      phoneNumber(value) {
        this.validatePhone(value)
      },
      email(value) {
        this.validateEmail(value)
      }
    },
    methods: {
      changeExistence() {
        if (this.exists) {
          this.exists = false
        } else {
          this.exists = true
        }
      },
      validatePhone(value) {
        const regEx = /^(?:[0-9]●?){6,14}[0-9]$/
        if (regEx.test(value)) {
          this.msg['phoneNumber'] = '';
        } else{
          this.msg['phoneNumber'] = 'Invalid Phone Number';
        } 
      },
      validateEmail (value) {
        if (value !== '') { 
          const regEx = /^\w+([.-]]?\w+)*$/;
          if (regEx.test(value)) {
            console.log("Pass")
            this.errors['email'] = '';
          } else{
            console.log("Failure")
            this.errors['email'] = 'Invalid email address.';
          } 
        } else {
          this.errors['email'] = '';
        }
      },

      async getMember() {
        // /*Development purposes*/
        // if (this.email === "luther") {
        //   let e = this.email + "@work4aliving.org"
        //   this.$store.commit("setEmail", e)
        //   this.$router.push({name: 'OneTimePinRegister', component: OneTimePinRegister, params: {phoneNumber: this.phoneNumber, email: this.email + "@work4aliving.org", Email: true}})
        //   return
        // }
        apolloClient.query({
            query: GET_MEMBER,
            variables: {
                name: this.email + "@work4aliving.org",
            },
        })
        .then((response) => {
            if (response.data.getMember != null) {
              this.showErr = true
              this.errorMessage = "An account with this email already exists. If this is you, please login."
            } else {
              this.sendVerification()
            }
        })
        .catch(() => {
          this.sendVerification()
        })
      },

      // Send facilitator verification using email. It should also handle nav via a push.
      async sendVerification () {
          let e = this.email + "@work4aliving.org"
          this.$store.commit("setEmail", e)
          await apolloClient.mutate({
            mutation: SEND_VERIFICATION,
            variables: {
              verifyPhoneNumber: this.$store.state.phoneNumber,
              verifyEmail: e,
              method: 'email'
            }
          })
          .then((response) => {
              this.$store.commit("setVerificationSID", response.data.sendVerification)
              this.$router.push({name: 'OneTimePinRegister', component: OneTimePinRegister, params: {phoneNumber: this.phoneNumber, email: this.email + "@work4aliving.org", Email: true}})
          }).catch((error) => {
            // Error
            console.error(error)
            this.showErr = true
            this.errorMessage = "Error sending email. Make sure you email address is correct."
          })
      },
    }
  }
</script>

<style scoped>
.signin {
  margin: 0;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.info-right-row {
  min-width: 70%;
  max-width:10mm;
  justify-content: center;
  padding: 2px;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 70%;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 10px;
}
.signin-btn {
  width: 80%;
  color: #3c7872;
  margin: 10px;
}
.signin-btn-2 {
  width: 80%;
  color: white;
  margin: 10px;
}
.this-enter-btn {
  width: 51%;
  color: white;
  margin-bottom: 15px;
}
.h5 {
  text-align: center;
  padding: 30px;
  margin-top: 20px;
}
.h3{
  text-align: center;
}
.h4{
  text-align: center;
}
.h6{
  text-align: center;
  margin-bottom: 20px;
}
.p{
  text-align: center;
  padding: 20px;
}
.atag {
  color: rgb(165, 207, 241);
}
.buttons {
  width: 90%;
  margin: 10px;
  justify-content: center;
  margin-left: 20px;
}
.rounded-text{
  border-radius: 12px;
}
.form {
  margin-top: 50px;
}
.this-input-phone{
  background: #a9c9c6;
  border: white;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 70%;
}

</style>