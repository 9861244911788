<template>
    <v-row class="signin">
      <v-col cols="12" md="7" lg="8" class="left hidden-sm-and-down">
        <v-row class="right-row">
          <h1>Sign in to continue.</h1>
        </v-row>
      </v-col>

      <v-col cols="12" sm="12" md="5" lg="4" class="right">
        <v-row class="right-input">
        <v-row class="right-row"> 
          <h2 class="h2">Sign in with your phone number</h2>
        </v-row>
        <v-row class="right-row"> 
          <vue-tel-input class="input-phone" mode="international" required v-model="phoneNumber" defaultCountry="ZA"></vue-tel-input>
        </v-row>
        <v-row class="right-row">
          <div v-if="!this.exists"> 
            <h4 class="h4">{{errorTitle}}</h4>
            <h6 class="h6">{{errorMessage}}</h6>
            <!-- <v-btn class="signin-btn" type="submit" rounded color="white" dark disabled>
              Sign in with Whatsapp
            </v-btn>
            <v-btn class="signin-btn-2" outlined type="submit" rounded dark disabled>
              Sign in with SMS
            </v-btn> -->
            <v-btn @click="changeExistence" class="signin-btn" type="submit" rounded color="white" dark>
              Try again
            </v-btn>
            <v-btn class="signin-btn-2" outlined type="submit" rounded dark :to="{name:'RegisterPage'}">
              Sign Up
            </v-btn>
            
          </div>
          <div v-else>
            <v-row class="buttons">
              <v-btn v-if="!this.phoneNumber" class="signin-btn" type="submit" rounded color="white" dark disabled>
                Sign in with Whatsapp
              </v-btn>
              <v-btn v-else class="signin-btn" type="submit" rounded color="white" required dark @click.native="getMember('whatsapp')">
                Sign in
              </v-btn>
            </v-row>
          </div>
        </v-row>
        <v-row class="right-row"> 
          <p class="p"> Don't have an account yet? <a href="register" class="atag">Sign up.</a> </p>
        </v-row>
        </v-row>
        </v-col> 
    </v-row>
</template>

<script>
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import Vue from 'vue'
  import {GET_MEMBER} from "../../apollo/queries";
  import {SEND_MESSAGE_TOTP} from "../../apollo/mutations";
  import { apolloClient } from '../../main';
  Vue.component('vue-phone-number-input', VuePhoneNumberInput);
  export  default {
    name: 'LoginPage',
    data: () => ({
      phoneNumber: "",
      msg: [],
      exists: true,
      errorMessage: "",
      errorTitle: ""
    }),
    watch: {
      phoneNumber(value) {
        this.validatePhone(value)
      }
    },
    methods: {
      changeExistence() {
        if (this.exists) {
          this.exists = false
        } else {
          this.exists = true
        }
      },
      validatePhone(value) {
        const regEx = /^(?:[0-9]●?){6,14}[0-9]$/
        if (regEx.test(value)) {
          this.msg['phoneNumber'] = '';
        } else{
          this.msg['phoneNumber'] = 'Invalid Phone Number';
        } 
      },
      async getMember (method) {
        apolloClient.query({
            query: GET_MEMBER,
            variables: {
                name: this.phoneNumber,
            },
        })
        .then(async (response) => {
            if (response.data.getMember != null) {
                this.exists = true
                this.$store.commit('setFirstName', response.data.getMember.familyName)
                console.log(method)
                // Delete above line
                await this.sendVerification()

            }
        }).catch(error => {
          console.log("Member not found")
          this.exists = false
          this.errorTitle = "Account does not exist"
          this.errorMessage = `There is no account registered for ${this.phoneNumber}`
          console.log(error)
        })
      },
      async sendVerification () {
        apolloClient.mutate({
          mutation: SEND_MESSAGE_TOTP,
          variables: {
            verifyPhoneNumber: this.phoneNumber,
          }
        })
        .then((response) => {
          this.$store.commit("setTotpKey", response.data.sendMessageTOTP)
          // named route with params to let the router build the url
          this.$router.push({name:'OneTimePin', params: {phoneNumber: this.phoneNumber, Whatsapp: true, familyName: response.data.getMember.familyName}})
        }).catch((error) => {
          // Error
          console.error(error)
        })
      },
    }
  }
</script>

<style scoped>
.signin {
  margin: 0 auto;
  height: 100%;  /* Fallback for browsers do NOT support vh unit */
}
.this-right{
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  display: flex;
  align-items: center;
}

.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 10px;
}
.signin-btn {
  width: 100%;
  color: #3c7872;
  margin: 10px;
}
.signin-btn-2 {
  width: 100%;
  color: white;
  margin: 10px;
}
.h5 {
  text-align: center;
  padding: 30px;
  margin-top: 20px;
}
.h3{
  text-align: center;
}
.h4{
  text-align: center;
}
.h6{
  text-align: center;
  margin-bottom: 20px;
}
.p{
  text-align: center;
  padding: 20px;
}
.atag {
  color: rgb(165, 207, 241);
}
.buttons {
  margin: 0;
  padding: 30px;
  padding-top: 10px;
  justify-content: center;
}
</style>