import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls";
import user from "./user";
import router from "@/router";

const ls = new SecureLS({ isCompression: true});

Vue.use(Vuex)

// Initial state
const initialState = {
  name: "", // the 'name' attribute holds memberId
  isAuthenticated: false,
  firstName: "",
  phoneNumber: "",
  email: "",
  verificationSID: "",
  totpKey: "",
  stateShowAddress: false,
  stateShowEmail: false,
  stateShowDOB: false,
  selection: [],
  searchError: null,
  facilitator: false,
  student: false,
  activator: false,
  enrolling: false,
  enrollingClass: "",
  currentClassStartDate: "",
  currentClassEndDate: "",
  currentClassCourseName: "",
  currentClassName: "",
  facRegister: false,
  actRegister: false,
  jwt: "",
  refreshToken: "",
  checkinToken: "",
  checkins: 0,
  jobReadinessParticipant: false,
  jobReadinessClassId: "",
  jobReadinessEnrollmentId: "",
  jobReadinessEndDate: null,
};

// Vuex store
const Store = new Vuex.Store({
  modules: {
    user
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  state: {...initialState},
  getters: {
    jwtGetter (state) {
      return state.jwt
    },
    refreshGetter (state) {
      return state.refreshToken
    }
  },
  mutations: {
    setName(state, name) {
      state.name = name;
    },
    setJobReadinessEndDate(state, val) {
      state.jobReadinessEndDate = val
    },
    setJobReadinessEnrollmentId(state, val) {
      state.jobReadinessEnrollmentId = val
    },
    setJobReadinessClassId(state, val) {
      state.jobReadinessClassId = val
    },
    setJobReadinessParticipant(state, val) {
      state.jobReadinessParticipant = val
    },
    setCheckins(state, number) {
      state.checkins = number
    },
    setCurrentClassStartDate(state, start) {
      state.currentClassStartDate = start
    },
    setCurrentClassEndDate(state, end) {
      state.currentClassEndDate = end
    },
    setCurrentClassCourseName(state, course) {
      state.currentClassCourseName = course
    },
    setCurrentClassName (state, name) {
      state.currentClassName = name
    },
    setJWT(state, value) {
      state.jwt = value
    },
    setCheckinToken(state, value) {
      state.checkinToken = value
    },
    setRefreshToken(state, value) {
      state.refreshToken = value
    },
    setFacRegister(state, value) {
      state.facRegister = value
    },
    setActRegister(state, value) {
      state.actRegister = value
    },
    setEnrolling (state, enrollingClass) {
      state.enrolling = true
      state.enrollingClass = enrollingClass
    },
    unsetEnrolling (state) {
      state.enrolling = false
      state.enrollingClass = ""
    },
    setFacilitator(state) {
      state.facilitator = true
      state.student = false
      state.activator = false
    },
    setStudent(state) {
      state.student = true
      state.facilitator = false
      state.activator = false
    },
    setSearchError(state, error) {
      state.searchError = error;
    },
    setActivator(state) {
      state.activator = true
      state.student = false
      state.facilitator = false
    },
    setShowDOB (state) {
      state.stateShowDOB = !state.stateShowDOB
      if (state.stateShowDOB === true) {
        state.selection.push(0)
        // console.log("setShowDOB() state.selection: ", state.selection)
      } else {
        const index = state.selection.indexOf(0);
        if (index > -1) {
          state.selection.splice(index, 1); // 2nd parameter means remove one item only
        }
        // console.log("setShowDOB() state.selection: ", state.selection)
      }
    },
    setShowAddr (state) {
      state.stateShowAddress = !state.stateShowAddress
      if (state.stateShowAddress === true) {
        state.selection.push(2)
        // console.log("setShowAddr() state.selection: ", state.selection)
      } else {
        const index = state.selection.indexOf(2);
        if (index > -1) {
          state.selection.splice(index, 1); // 2nd parameter means remove one item only
        }
        // console.log("setShowAddr() state.selection: ", state.selection)
      }
    },
    setShowEmail (state) {
      state.stateShowEmail = !state.stateShowEmail
      if (state.stateShowEmail === true) {
        state.selection.push(1)
        // console.log("setShowEmail() state.selection: ", state.selection)
      } else {
        const index = state.selection.indexOf(1);
        if (index > -1) {
          state.selection.splice(index, 1); // 2nd parameter means remove one item only
        }
        // console.log(state.selection)
      }
    },
    authenticate (state) {
      state.isAuthenticated = true
    },
    setFirstName (state, name) {
      state.firstName = name
    },
    setPhoneNumber (state, phoneNumber) {
      state.phoneNumber = phoneNumber.replace(/ /g, "")
    },
    setEmail (state, email) {
      state.email = email
    },
    setVerificationSID (state, verificationSID) {
      state.verificationSID = verificationSID
    },
    setTotpKey (state, totpKey) {
      state.totpKey = totpKey
    },
    RESET_STATE(state) {
      Object.assign(state, initialState); // This line resets the state
    },
  },
  actions: {
    logout({ commit }) {
      let user = this.getters['user/activeUser'];
      let userId = user && user.phoneNumber ? user.phoneNumber.replace(/\s/g, "") : null;
      commit('user/deleteUserKey', userId);
      commit('RESET_STATE');
      commit('user/RESET_USER_STATE');
      router.push({name: 'HomePage'}).then(r => r);
    }
  },
})

export default Store;
