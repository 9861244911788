<template>
  <v-row class="signin">
    <v-col cols="12" class="this-right">
    <v-row class="right-input">
      <v-card
        color="#3c7872"
        dark
        flat
        tile
        class="card"
      >
        <v-window v-model="onboarding">
          <v-window-item
            v-for="n in length"
            :key="`card-${n}`"
          >
            <v-card
              color="transparent"
              height="200"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <v-card-title class="text-center">
                  {{text[n-1]}}
                </v-card-title>
              </v-row>
            </v-card>
          </v-window-item>
        </v-window>

      <v-card-actions class="justify-space-between">
        <v-btn
          text
          @click="prev"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-item-group
          v-model="onboarding"
          class="text-center"
          mandatory
        >
          <v-item
            v-for="n in length"
            :key="`btn-${n}`"
            v-slot="{ active, toggle }"
          >
            <v-btn
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
        <v-btn
          text
          @click="next"
          v-if="onboarding < 2"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          text
          @click="proceed"
          v-else
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    name: "InfoStepper",
    data: () => ({
      length: 3,
      onboarding: 0,
      text: ["Build your profile.", "Share it.", "Find your dream job."]
    }),
    computed: {
      ...mapGetters('user', ['activeUser']),
    },
    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === this.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.length - 1
          : this.onboarding - 1
      },
      proceed () {
        this.$router.push({name: 'ProfileContent'})
      }
    },
  }
</script>

<style scoped>

.card {
  margin: 1px;
  justify-content: center;
  align-items: center;
}

.signin {
  margin: 0 auto;
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  background: black;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.this-right{
  box-sizing: border-box;
  background: #3c7872;
  color: #fff;
  display: flex;
  align-items: center;
}
</style>

<!-- justify-content: center;
  align-items: center;
  height: 95vh; -->

  <!-- height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; -->