<template>
    <v-row class="home">
      <v-col cols="12" class="white-right">
        <v-row class="right-input">
          <v-row class="right-row">
            <h2 v-if="activeUser.givenName === ''" class="h2">Welcome</h2>
            <h2 v-else class="h2">Welcome, {{activeUser.givenName}}</h2>
          </v-row>
          <v-row class="card">
          <v-col>
            <v-card
              elevation="2"
              outlined
            >
            <v-card-title>Build your Profile</v-card-title>
            <v-card-text>
              <v-row
                align="center"
                class="mx-0"
              >
            </v-row>
            <div>Create your online profile and share it to be recognised!</div>
            <div class="my-4 text-subtitle-1">
              <v-btn outlined rounded class="this-signin-btn" :to="{name: 'ProfileContent'}">Let's go</v-btn>
            </div>
            </v-card-text>
            
            </v-card>
          </v-col>
        </v-row>
        <v-row class="card">
          <v-col>
            <v-card
              elevation="2"
              outlined
            >
            <v-card-title>View your Profile</v-card-title>
            <v-card-text>
              <v-row
                align="center"
                class="mx-0"
              >
            </v-row>
            <div>View the public version of your profile.</div>
            <div class="my-4 text-subtitle-1">
              <v-btn outlined rounded class="this-signin-btn" :to="{name: 'ViewProfile', component: 'ViewProfile', params: {id: activeUser.phoneNumber}}">View</v-btn>
            </div>
            </v-card-text>
            
            </v-card>
          </v-col>
        </v-row>

          <v-row class="card">
            <v-col>
              <v-card
                elevation="2"
                outlined
              >
              <v-card-title>Courses</v-card-title>
              <v-card-text>
                <div>These are the W4AL courses you have completed.</div>
                <div class="my-4 text-subtitle-1">
                  <v-btn outlined rounded class="this-signin-btn" :to="{name: 'MemberEnrollments', component: 'MemberEnrollments'}">View courses</v-btn>
                </div>
              </v-card-text>
              </v-card>
            </v-col>
            <!-- <v-col>
              <v-card
                elevation="2"
                outlined
              >
              <v-card-title>W4AL Reports</v-card-title>
              <v-card-text>
                <v-row
                  align="center"
                  class="mx-0"
                >
                <v-rating
                  :value="4.5"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="14"
                ></v-rating>

                <div class="grey--text ms-4">
                  4.5 (413)
                </div>
              </v-row>

              <div class="my-4 text-subtitle-1">
                
              </div>

              <div>These are reports given about you by W4AL facilitators and activators.</div>
              <div class="my-4 text-subtitle-1">
                <v-btn outlined rounded disabled class="this-signin-btn">View more</v-btn>
              </div>
              </v-card-text>
              </v-card>
            </v-col> -->
          </v-row>
        <!-- <v-row class="card">
          <v-col>
            <v-card
              elevation="2"
              outlined
            >
            <v-card-title>Employer reviews</v-card-title>
            <v-card-text>
              <v-row
                align="center"
                class="mx-0"
              >
              <v-rating
                :value="4.6"
                color="amber"
                dense
                half-increments
                readonly
                size="14"
              ></v-rating>

              <div class="grey--text ms-4">
                4.6 (3)
              </div>
            </v-row>

            <div class="my-4 text-subtitle-1">
              Reviews made by previous employers.
            </div>

            <div>
              <v-card>
                <v-card-title> 
                  "He was an excellent employee."
                </v-card-title>
              </v-card>
            </div>
            <div class="my-4 text-subtitle-1">
              <v-btn outlined rounded disabled class="this-signin-btn">View more</v-btn>
            </div>
            </v-card-text>
            
            </v-card>
          </v-col>
        </v-row> -->
        </v-row>
      </v-col> 
    </v-row>
</template>

<script>
  import { mapGetters } from "vuex";
  export  default {
    name: 'HomeUser',
    props: 
    {
      firstName: {
          type: String,
          default: ''
      },
    },
    // created() {
    //   this.$store.dispatch("getUser")
    // },
    // // An activeUser getter is used to pass data to components.
    computed: {
      ...mapGetters('user', ['activeUser']),
    }
  }
</script>

<style scoped>
.white-right {
  box-sizing: border-box;
  background: #f2f2f2;
  color: #fff;
  display: flex;
  align-items: center;
}
.sectioncontainer {
    background: #fff;
    width: 100%;
}
.home {
  margin: 0 auto;
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
  color: rgb(35, 35, 35);
  font-weight: 500;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.this-signin-btn {
  color: #3c7872;
}
  </style>