<template>
    <v-col cols="12" @click="toggleEducationModal">
        <EditEducationModal v-if="this.$route.fullPath.startsWith('/profiles/content')" :showEducationModal="this.showEducationModal" :currentTitle="this.title" :currentSchool="this.school" :currentDuration="this.duration" :currentDescription="this.description" :currentEducationLevel="this.educationLevel" :currentQualification="this.qualification" :currentIndustry="this.industry" @close="toggleEducationModal"/>
            <v-row class="right-row-edit">
                <h4>{{this.school}}&nbsp;</h4>
            </v-row>
            <v-row class="right-row-edit">
                <p class="mb-0">{{this.title}}</p>
            </v-row>
            <v-row class="right-row-edit">
                <v-col cols="9" class="ma-0 pa-0"><p>{{qualifications[this.qualification-1].key}}&nbsp;</p></v-col>
                <v-col cols="3" class="ma-0 pa-0">
                    <p >{{ this.duration }}</p>
                </v-col>
            </v-row>
        <v-divider></v-divider>
    </v-col>
</template>

<script>
import { mapGetters } from "vuex"
import EditEducationModal from './EditEducationModal.vue'
export default {
    name: 'EducationList',
    components: {EditEducationModal},
    props: {
        title: {type: String},
        school: {type: String},
        duration: {type: String},
        description: {type: String},
        educationLevel: {type: Number},
        qualification: {type: Number},
        industry: {type: Number}
    },
    data: () => ({
        showEducationModal: false,
        qualifications: [{value: 1, key: "Less than Grade 9"}, {value: 2, key: "Grade 9"}, {value: 3, key: "Grade 10"}, {value: 4, key: "Grade 11"}, {value: 5, key: "Grade 12"}, {value: 6, key: "Training Course"},{value: 7, key: "Diploma"},{value: 8, key: "Bachelors Degree"},{value: 9, key: "Honours degree"},{value: 10, key: "Masters degree"},{value: 11, key: "Doctorate degree"}],
    }),
    computed: {
      ...mapGetters('user', ['activeUser']),
        skillProp: {
            get () {
                return this.skill
            },
            set () {
               return this.skill
            }
        }  
    },
    methods: {
        toggleEducationModal () {
            this.showEducationModal = !this.showEducationModal
        },
    }
}
</script>

<style scoped>
.title-space {
    margin-bottom: 0px;
}
.form {
    margin-top: 30px;
}
.rounded-text{
  border-radius: 12px;
}
.edit-btn-save{
    color: white;
}
.edit-btn-cancel{
    color: #3c7872;
}
.right-row-edit {
    width: 100%;
    justify-content: left;
    margin: 2px;
}
.right-row-icon {
    width: 100%;
    justify-content: right;
    margin-bottom: 20px;
}

</style>
