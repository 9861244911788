<template>
    <v-row class="home">
      <v-col cols="12" class="white-right">
        <v-row class="right-input">
            <content-viewer> </content-viewer>
        </v-row>
      </v-col> 
    </v-row>
</template>

<script>
import ContentViewer from '../../components/ContentViewer.vue'
  export  default {
    components: { ContentViewer },
    name: 'ViewProfile',
  }
</script>

<style scoped>
.sectioncontainer {
    background: #fff;
    width: 100%;
}
.home {
  margin: 0 auto;
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
}
.right-input {
  margin: 0 auto;
  min-height: 20%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 20vh;
  justify-content: center;
}
.right-row {
  width: 100%;
  justify-content: center;
}
.h2 {
  text-align: center;
  padding: 30px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.progress {
  align-items: center;
  display: block;
  margin: 0 auto;
  
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
  margin-top:70px;
}
.card {
  padding: 20px;
}
.this-signin-btn {
  color: #3c7872;
}
  </style>